import { emailAssert } from '@hc-frontend/core-ui-components';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ErrorMessage } from '../../atoms/rx-customer-error/rx-customer-error';
import { credentialsSignInError } from './signin-form.config';
import type { PropTypes } from './signin-form.types';

function SignInForm({
  onSubmitCallback,
  signinError,
  initialFormState,
  isMobile,
  isSubmitting = false,
}: PropTypes) {
  const { t } = useTranslation('form');

  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: initialFormState.email,
      password: initialFormState.password,
      rememberMe: initialFormState.rememberMe,
    },
  });
  const { errors } = formState;

  useEffect(() => {
    reset(initialFormState);
  }, [initialFormState, reset]);

  return (
    <Grid item xs={12}>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmitCallback(data);
        })}
      >
        <Grid container spacing={4}>
          {signinError === credentialsSignInError ? (
            <Grid item xs={12}>
              <ErrorMessage variant="filled" severity="error">
                {t('error')}
              </ErrorMessage>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Controller
              name="email"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              rules={emailAssert({
                requiredMessage: t('emailRequired'),
                patternMessage: t('invalidEmail'),
                required: true,
              })}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="email"
                  inputProps={{
                    'data-testid': 'email',
                  }}
                  label={t('email')}
                  helperText={errors.email?.message}
                  fullWidth
                  error={fieldState.invalid}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              rules={{
                required: t('passwordRequired'),
              }}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="password"
                  inputProps={{
                    'data-testid': 'password',
                  }}
                  label={t('password')}
                  type="password"
                  helperText={errors.password?.message}
                  fullWidth
                  error={fieldState.invalid}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            pb={3}
            justifyContent="space-between"
          >
            <Controller
              name="rememberMe"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  sx={{
                    alignItems: 'start',
                  }}
                  control={
                    <Checkbox sx={{ paddingTop: 0 }} checked={field.value} />
                  }
                  label={t('checkLabel')}
                  name="rememberMe"
                />
              )}
            />
            {isMobile ? null : (
              <Link
                underline="none"
                justifySelf="center"
                href="/reset-password"
                data-testid="forgotPassword"
                sx={{ fontSize: (theme) => theme.typography.paragraphLG }}
              >
                {t('forgotPassword')}
              </Link>
            )}
          </Grid>

          <Grid component={Box} item xs={12} mt={1}>
            <LoadingButton
              data-testid="submitButton"
              fullWidth
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              {t('loginBtn')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
export { SignInForm };
