import { PancakeStackLayout } from '@hc-frontend/core-ui-components';
import { pharmacyCustomerTheme } from '@hc-frontend/core-ui-theme';
import { useAppConfig } from '@hc-frontend/shells-rx-cards-ui';
import {
  FooterLogos,
  FooterPharmacy,
} from '@hc-frontend/shells-rx-ui-customer';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import ThemeProvider from '@mui/system/ThemeProvider';

export function LayoutWithoutHeader({ children }: React.PropsWithChildren) {
  const {
    public: {
      constants: { phoneNumber, baseUrl, tty },
    },
  } = useAppConfig();

  return (
    <PancakeStackLayout>
      {children}
      <ThemeProvider theme={pharmacyCustomerTheme}>
        <ScopedCssBaseline />
        <FooterPharmacy
          phone={phoneNumber}
          tty={tty}
          baseUrl={baseUrl}
          logos={[FooterLogos.LEGIT_SCRIPT]}
        />
      </ThemeProvider>
    </PancakeStackLayout>
  );
}
