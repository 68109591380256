import { Header } from '@hc-frontend/shells-rx-cards-ui';

import { LayoutWithoutHeader } from './layout-without-header';

export function LayoutWithHeader({ children }: React.PropsWithChildren) {
  return (
    <LayoutWithoutHeader>
      <Header />
      {children}
    </LayoutWithoutHeader>
  );
}
