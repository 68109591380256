import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { StyleProps } from './transfer-rx-form-layout.types';

export const ArrowBack = styled(ArrowBackIcon)(({ size = 24 }: StyleProps) => ({
  width: size,
  height: size,
  marginRight: '2px',
}));

export const SecondaryText = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
  }),
);

export const Button = styled(MuiButton)(() => ({
  padding: '0px 40px',
  minWidth: '160px',
}));

export const AvatarWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  height: 60,
  width: 60,
  padding: '3px',
  borderRadius: '50%',
  background: theme.palette.gradient?.main,
}));
