import { isValidDate } from '@hc-frontend/core-utils-validations';

function getDefaultDate(date?: Date) {
  if (date) {
    return date;
  }

  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const defaultDate = new Date(Date.now() - tzoffset);
  return defaultDate;
}

/**
 * Get datetime in ISO format yyyy-MM-ddTHH:mm:ss.SSS
 * @public
 *
 * @param date - Date to get time from
 *
 * @returns Datetime in ISO format yyyy-MM-ddTHH:mm:ss.SSS
 *
 * @example
 * ```ts
 * const isoDateTime =  getISODateTime(new Date(2021, 11, 25))
 * console.log(isoDate);
 * // -> '2021-11-25T00:00:00.000'
 * ```
 */
function getISODateTime(date?: Date): string | undefined {
  const currentDate = getDefaultDate(date);

  return isValidDate(currentDate)
    ? currentDate.toISOString().slice(0, -1)
    : undefined;
}

export { getISODateTime };
