import Image from 'next/image';
import Script from 'next/script';

import type { HeadScriptProps } from './head-script.types';

const facebookScript = (id: string) =>
  `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${id}');fbq('track', 'PageView');`;

export function FacebookAdsHeadScript({ id }: HeadScriptProps) {
  return id ? (
    <>
      <Script id="facebook-analytics" strategy="afterInteractive">
        {facebookScript(id.trim())}
      </Script>
      <noscript>
        <Image
          alt="no script facebook img"
          height="1"
          width="1"
          src="https://www.facebook.com/tr?id=409867821339808&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  ) : null;
}
