const path = require('path');

let reloadOnPrerender = true;
let debug = JSON.parse(process.env.NEXT_DEBUG_I8 || false);
const localePath =
  process.env.LOCALE_PATH || path.resolve('./apps/rx-cards/public/locales');

if (process.env.NODE_ENV === 'production') {
  reloadOnPrerender = false;
  debug = false;
}

module.exports = {
  debug,
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'es'],
  },
  react: { useSuspense: false },
  localePath,
  reloadOnPrerender,
};
