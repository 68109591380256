import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  SaveCookieValueRepository,
} from '@hc-frontend/deprecated-repositories';
import type { NextResponse } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextWriteResponseCookieAdapterFactory(
  provider: LogRepository,
  isLocal: boolean,
  response: NextResponse,
) {
  return buildAdapter<SaveCookieValueRepository<ErrorCodes.COOKIE_NOT_SAVED>>()(
    provider,
    function nextWriteResponseCookieAdapter(key, value, options) {
      response.cookies.set(key, value, {
        domain: isLocal ? undefined : options.domain,
        maxAge:
          options.maxAge === undefined ? undefined : options.maxAge * 1000,
      });
      return Promise.resolve();
    },
    ErrorCodes.COOKIE_NOT_SAVED,
  );
}
