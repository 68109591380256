import type { AgentId } from './Agent';
import type { Email } from './SingleValues';

export type SessionId = string;
export type UserId = string;
export type DataId = string;

export interface IRequest {
  rootDomain: string;
  userAgent: string;
  ipAddress: string;
  referrer: string;
  landingPage: string;
  device: string;
}

export interface ITracking {
  src: string;
  agentId: AgentId;
  subid: string;
  aid: string;
  pubid: string;
  gclid: string;
  msclkid: string;
  irpid: string;
  ircid: string;
  iv: string;
  pcid: string;
  prPid: string;
  utmSource: string;
  utmCampaign: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
}

export enum Products {
  Health = 'HEALTH',
  Medicare = 'MEDICARE',
}

export interface ISessionId {
  sessionId: SessionId;
  userId: UserId;
  dataId?: DataId; // TODO: rethink the mental model if user id changes when email or other data changes
}

export interface ISession extends ISessionId {
  tracking: Partial<ITracking>;
  request: IRequest;
  product: Products;
  email?: Email;
}
