import { dateToAge } from '../date-to-age/date-to-age';
import { stringToDate } from '../string-to-date/string-to-date';

/**
 * Converts a string date to an age.
 * @public
 *
 * @param dateAsString - The date as a string.
 *
 * @returns The age or 0 if the date is invalid.
 *
 * @example
 * ```ts
 * // if today is 01/01/2020
 * const result = stringToAge('01/01/1970');
 * console.log(result);
 * // -> 50
 * ```
 */
function stringToAge(dateAsString: string): number {
  const birthDate = stringToDate(dateAsString);

  return birthDate ? dateToAge(birthDate) : 0;
}

export { stringToAge };
