/**
 * Convert a phone number from E.164 format into (xxx)-xxx-xxxx format
 * @public
 *
 * @param phone - An unformatted 12-digit phone number string
 *
 * @returns phone number in (xxx) xxx-xxxx format
 *
 *
 * @example
 * ```ts
 * cont result = fromPhoneE164('+12125559656');
 * console.log(result);
 * // -> '(212) 555-9656'
 * ```
 */
function fromPhoneE164(phone: string): string {
  if (phone.length === 12) {
    return `(${phone.substring(2, 5)}) ${phone.substring(
      5,
      8,
    )}-${phone.substring(8, 12)}`;
  }

  return phone;
}

export { fromPhoneE164 };
