import { grey } from '@mui/material/colors';
import type { ThemeOptions } from '@mui/material/styles';
import { alpha, createTheme } from '@mui/material/styles';

import type { FontTheme } from './util/generate-font-face/generate-font-face';
import { withBaseTheme } from './with-base';

const DRAWER_WIDTH = 100;

const fontsToLoadFromCDN: FontTheme[] = [
  {
    name: 'proxima-nova',
    weightOptions: [400, 600, 700],
  },
  {
    name: 'bree-serif',
    weightOptions: [400, 600, 700],
  },
];

const baseTheme = withBaseTheme(
  {
    typography: {
      fontFamily: 'proxima-nova, sans-serif',
      fontFamilySecondary: 'bree-serif, serif',
      h6: {
        fontWeight: 700,
      },
      button: {
        textTransform: 'revert',
        fontSize: 18,
      },
    },
    palette: {
      background: {
        default: '#fafafa',
      },
      primary: {
        light: grey['700'],
        main: grey['900'],
      },
      secondary: {
        main: '#11427C',
        dark: '#0D3463',
        contrastText: '#fafafa',
      },
      tertiary: {
        light: '#f2f2f2',
        main: '#FE8829',
        dark: '#DBDBDB',
      },
      warning: {
        light: '#FFC24D',
        main: '#FE8829',
      },
      success: {
        main: '#2AD2A0',
      },
      info: {
        main: '#19B4FA',
      },
    },
  },
  fontsToLoadFromCDN,
);

export const pharmacistAdminTheme = createTheme(baseTheme, {
  components: {
    MuiLink: {
      defaultProps: {
        color: grey['A200'],
        sx: {
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          background: baseTheme.grayShades['300'],
          borderRadius: `${baseTheme.borderRadius.large} ${baseTheme.borderRadius.large} 0 0`,
          minHeight: '45px',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: baseTheme.grayShades['900'],
          minHeight: '40px',
          padding: '1% 1%',
          margin: '0.5%',
          borderRadius: baseTheme.borderRadius.large,
          fontSize: baseTheme.typography.paragraphSM['fontSize'],
          fontWeight: baseTheme.typography.headingThree['fontWeight'],
          '&.Mui-selected, .Mui-selected': {
            backgroundColor: baseTheme.palette.secondary.main,
            color: baseTheme.palette.secondary.contrastText,
          },
          '&:hover': {
            backgroundColor: baseTheme.grayShades['400'],
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          background: baseTheme.grayShades['100'],
          border: `1px solid ${baseTheme.grayShades['300']}`,
          '& .MuiInputBase-root': {
            paddingRight: 0,
            background: baseTheme.grayShades['300'],
          },
          '& input': {
            padding: '2px 14px',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            border: '0',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&:hover, &.Mui-selected:hover, .Mui-selected:hover': {
            color: baseTheme.grayShades['100'],
            backgroundColor: baseTheme.palette.warning.light,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.grayShades['100'],
          border: '1px solid rgba(0, 0, 0, 0.12)',
          '.MuiToggleButtonGroup-grouped, .MuiToggleButtonGroup-grouped:not(:last-of-type), .MuiToggleButtonGroup-grouped:not(:first-of-type)':
            {
              border: 'none',
              padding: '8px',
              fontSize: baseTheme.typography.paragraphSM['fontSize'],
              borderRadius: baseTheme.borderRadius.small,
              borderTopRightRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
            },
          '.MuiToggleButtonGroup-grouped.Mui-selected': {
            color: baseTheme.grayShades['100'],
            backgroundColor:
              baseTheme.palette.tertiary?.main ??
              baseTheme.palette.primary.main,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-main': {
            borderRadius: '8px 8px 0 0',
            border: `2px solid ${baseTheme.palette.tertiary?.dark}`,
          },
          '& .MuiDataGrid-row': {
            marginTop: '1px',
            marginBottom: '1px',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeigth: 400,
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: baseTheme.palette.secondary.main,
            color: baseTheme.palette.background.default,
          },
          '& .MuiDataGrid-columnHeader svg': {
            color: baseTheme.palette.background.default,
          },
          '& .MuiDataGrid-columnSeparator svg': {
            color: baseTheme.palette.secondary.main,
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cellContent': {
            backgroundColor: `${baseTheme.palette.tertiary?.light}`,
            padding: '6%',
            width: '100%',
            borderRadius: '6px',
            zIndex: '-1',
          },
          '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row.Mui-selected':
            {
              border: `2px solid ${baseTheme.palette.tertiary?.main}`,
              backgroundColor: `${alpha(baseTheme.palette.warning.main, 0.1)}`,
            },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover > .MuiDataGrid-cellContent': {},
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: DRAWER_WIDTH,
          color: baseTheme.palette.primary.contrastText,
          flexShrink: 0,
          '.MuiSvgIcon-root': {
            color: baseTheme.palette.primary.contrastText,
          },
          '.MuiTypography-root': {
            color: baseTheme.palette.primary.contrastText,
            textAlign: 'center',
          },
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
            overflow: 'hidden',
          },
        },
      },
      defaultProps: {
        PaperProps: {
          sx: {
            background: baseTheme.palette.secondary.main,
            color: baseTheme.palette.secondary.contrastText,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
      defaultProps: {
        sx: {
          background: baseTheme.palette.primary.contrastText,
          color: baseTheme.palette.primary.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            paddingRight: 0,
            background: baseTheme.grayShades['300'],

            '& .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              border: '0',
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.paragraphSM,
          height: baseTheme.spacing(6),
          '& input': {
            padding: '2px 14px',
          },

          '&.MuiInputBase-multiline': {
            height: '100%',
          },
          '& .Mui-disabled': {
            color: grey['900'],
            WebkitTextFillColor: `${baseTheme.grayShades['700']}`,
          },
          '& input:disabled': {
            background: baseTheme.grayShades['300'],
          },
          '& input:-internal-autofill-selected': {
            padding: '5px 14px',
          },
          '& .MuiSelect-select': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        outline: {
          styleOverrides: {},
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: `${baseTheme.palette.tertiary?.main}`,
          },
          '&.Mui-disabled': {
            color: grey['300'],
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.disclaimer,
          fontWeight: 'bold',
          color: grey['900'],
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          display: 'contents',
        },
        inputRoot: {
          '&.MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: baseTheme.spacing(1),
            height: '100%',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
          sx: {
            color: grey['900'],
            fontWeight: 'bold',
            display: 'contents',
          },
        },
      },
      styleOverrides: {
        root: {
          fieldset: {
            legend: {
              width: 0,
            },
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: baseTheme.spacing(2),
          '&.Mui-disabled': {
            opacity: 0.9,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: baseTheme.spacing(2),
          borderTopRightRadius: baseTheme.spacing(2),
          backgroundColor: baseTheme.palette.secondary.main,
          color: baseTheme.palette.secondary.contrastText,
          padding: '20px',
          '&.MuiAccordionSummary-root': {
            height: baseTheme.spacing(6),
            minHeight: baseTheme.spacing(6),
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderBottomLeftRadius: baseTheme.spacing(2),
          borderBottomRightRadius: baseTheme.spacing(2),
          borderColor: baseTheme.palette.grey['400'],
          borderStyle: 'solid',
          borderWidth: 'thin',
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          ...baseTheme.typography.helpText,
          color: grey['A200'],
        },
        contained: {
          borderRadius: 7,
          backgroundColor: baseTheme.palette.secondary.main,
          padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(2)}`,
        },
        outlined: {
          borderColor: baseTheme.palette.primary.main,
          backgroundColor: 'transparent',
          borderRadius: 7,
          color: baseTheme.palette.primary.main,
        },
      },
    },
  },
} as ThemeOptions);
