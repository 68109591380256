import type { Nullable } from '@hc-frontend/core-utils-types';
import List from '@mui/material/List';
import { useState } from 'react';

import { ListNestedItem } from './list-nested-item';
import { ListSingleItem } from './list-single-item';
import type {
  NestedMenuItem,
  NestedMenuProps,
  ToggleStates,
} from './nested-menu.types';

const validateOpenValue = (value?: Nullable<boolean>): boolean => !!value;
const setInitialToggleStates = (menuItems: NestedMenuItem[]): ToggleStates =>
  menuItems.reduce((acc: ToggleStates, current) => {
    if (current?.nestedItems) {
      acc[current.name] = { isOpen: false };
    }
    return acc;
  }, {});

export function NestedMenu({
  items,
  handleItemOnClick,
  typographyVariant = 'paragraphMD',
  sx,
}: NestedMenuProps) {
  const [toggles, setToggles] = useState<ToggleStates>(
    setInitialToggleStates(items),
  );

  const handleClick = (name: string) => {
    return () =>
      setToggles((prevData) => ({
        ...prevData,
        ...{ [name]: { isOpen: validateOpenValue(!prevData[name]?.isOpen) } },
      }));
  };

  return (
    <List sx={sx}>
      {items.map((menu) => {
        if (menu?.nestedItems) {
          return (
            <ListNestedItem
              key={menu.id}
              item={menu}
              onToggle={handleClick(menu.name)}
              isOpen={validateOpenValue(toggles[menu.name]?.isOpen)}
              nestedItems={menu.nestedItems}
              onClickItem={handleItemOnClick}
              typographyVariant={typographyVariant}
            />
          );
        }

        return (
          <ListSingleItem
            key={menu.id}
            item={menu}
            onClickItem={handleItemOnClick}
            typographyVariant={typographyVariant}
          />
        );
      })}
    </List>
  );
}
