import { always } from 'ramda';

/**
 * A function that does nothing
 * @public
 *
 * @returns Value
 *
 * @example
 * ```ts
 * const result = noon()
 * console.log(result)
 * // -> undefined
 * ```
 */
const noon = always(undefined);

export { noon };
