import { createTheme } from '@mui/material/styles';

import type { FontTheme } from './util/generate-font-face/generate-font-face';
import { withBaseTheme } from './with-rebranding-base';

const fontsToLoadFromCDN: FontTheme[] = [
  {
    name: 'dm-sans',
    weightOptions: [
      {
        weight: 400,
        source:
          'url("https://cdn.healthcare.com/fonts/dm-sans/DMSans-Regular.ttf")format("truetype")',
      },
      {
        weight: 600,
        source:
          'url("https://cdn.healthcare.com/fonts/dm-sans/DMSans-Medium.ttf")format("truetype")',
      },
      {
        weight: 700,
        source:
          'url("https://cdn.healthcare.com/fonts/dm-sans/DMSans-Bold.ttf")format("truetype")',
      },
    ],
  },
  {
    name: 'poppins',
    weightOptions: [
      {
        weight: 400,
        source:
          'url("https://cdn.healthcare.com/fonts/poppins/Poppins-Regular.ttf")format("truetype")',
      },
      {
        weight: 600,
        source:
          'url("https://cdn.healthcare.com/fonts/poppins/Poppins-Medium.ttf")format("truetype")',
      },
      {
        weight: 700,
        source:
          'url("https://cdn.healthcare.com/fonts/poppins/Poppins-Bold.ttf")format("truetype")',
      },
    ],
  },
];

const baseTheme = withBaseTheme(
  {
    typography: {
      fontFamily: 'dm-sans, helvetica',
      fontFamilySecondary: 'poppins, georgia',
    },
    palette: {
      primary: {
        main: '#2a2f60',
        light: '#ddeaee',
        dark: '#2a2f60',
        contrastText: '#fffff',
      },
      secondary: {
        main: '#f5a500',
        light: '#fbe0a9',
        dark: '#f5a500',
        contrastText: '#ffffff',
      },
      tertiary: {
        main: '#3768b1',
        light: '#f7fcfe',
        dark: '#3768b1',
        contrastText: '#ffffff',
      },
      text: {
        primary: '#2a2e60',
        secondary: '#2a2e60',
        disabled: '#2a2e60',
      },
      warning: {
        main: '#ffc24d',
        light: '#fff8e1',
        dark: '#ffc24d',
        contrastText: '#2a2e60',
      },
      success: {
        main: '#87be42',
        light: '#e8f3d9',
        dark: '#87be42',
        contrastText: '#ffffff',
      },
      error: {
        main: '#e45d59',
        light: '#fde9e8',
        dark: '#e45d59',
        contrastText: '#ffffff',
      },
      neutral: {
        quaternaryGray: '#e6e6e6',
        tertiaryGray: '#959595',
        secondaryGray: '#4a4a4a',
        primaryGray: '#0e283e',
      },
      accent: {
        main: '#87be42',
        light: '#c2d98c',
      },
      componentBackground: {
        main: '#ddeaee',
        light: '#f7fcfe',
      },
      planCards: {
        gold: {
          main: '#edb31f',
        },
        silver: {
          main: '#909090',
        },
        bronze: {
          main: '#bb781a',
        },
      },
    },
  },
  fontsToLoadFromCDN,
);

const pxToRem = baseTheme.typography.pxToRem;

export const pharmacyCardsTheme = createTheme(baseTheme, {
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    button: {
      textTransform: 'revert',
      fontWeight: 700,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(18),

      [baseTheme.breakpoints.up('md')]: {
        fontSize: pxToRem(20),
        lineHeight: pxToRem(20),
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: `${baseTheme.spacing(4)} ${baseTheme.spacing(8)}`,
          borderRadius: baseTheme.spacing(3),
          backgroundColor: baseTheme.palette.secondary.main,
          color: baseTheme.palette.secondary.contrastText,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: baseTheme.spacing(2),
            border: `1px solid ${baseTheme.palette.neutral?.primaryGray}`,
          },
          '.MuiInputBase-input': {
            padding: pxToRem(12),
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${baseTheme.palette.neutral?.primaryGray} !important`,
              boxShadow: `0px 3px 10px 0px ${baseTheme.palette.neutral?.primaryGray}`,
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.grayShades[100],
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
  },
});
