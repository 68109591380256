import { isString } from '../is-string/is-string';
import { NAME_REGEX } from '../regex';

/**
 * Validates the name of a member.
 * @public
 *
 * @param arg - The name to validate.
 *
 * @returns True if the name is valid, false otherwise.
 *
 * @example
 * ```ts
 * isValidName('John Doe'); // -> true
 * ```
 */
function isValidName<T>(arg: unknown): arg is T {
  return isString(arg) && NAME_REGEX.test(arg as string);
}

export { isValidName };
