import { getISODateTime } from '../get-ISO-date-time/get-ISO-date-time';

/**
 * Get date in ISO format yyyy-MM-dd
 * @public
 *
 * @param date - Date to convert
 *
 * @returns Date in ISO format yyyy-MM-dd or undefined
 *
 * @example
 * ```ts
 * const isoDate = getISODate(new Date(2021, 11, 25))
 * console.log(isoDate);
 * // -> '2021-11-25'
 * ```
 */
function getISODate(date?: Date): string | undefined {
  const isoDateTime = getISODateTime(date);

  return isoDateTime ? isoDateTime.split('T')[0] : undefined;
}

export { getISODate };
