import type { HttpResponse } from '@hc-frontend/core-utils-net';
import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { Email } from '@hc-frontend/deprecated-entities';

import { getPivotBackendURI } from '../config';
import type { IFetchActiveApplicationsResponse } from './fetch-active-applications-service-response';

/**
 * Fetches previous applications made by the customer based on his email and date of birth.
 * If the user doesn't have previous applications, it will return an empty data array.
 *
 * @param email - The customer's email address
 * @param dob - The customer's date of birth, ex. '08/28/1989'
 * @returns ??
 *
 * @public
 *
 * @example
 * ```ts
 * const activeApplications = await getActiveApplications('qa@healthcare.com', '08/28/1989');
 * ```
 */

function getActiveApplications(
  email: Email,
  dob: string,
): Promise<HttpResponse<IFetchActiveApplicationsResponse>> {
  return fetchJSON<IFetchActiveApplicationsResponse>(
    `${getPivotBackendURI()}/applications-by-member`,
    {
      queryParams: {
        email,
        dob,
      },
    },
  );
}

export { getActiveApplications };
