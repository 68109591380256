import { compress } from '@hc-frontend/core-utils-file';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import type { DropzoneOptions, ErrorCode } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import { ErrorMessageBanner } from '../../atoms';
import type {
  FilePickerActionProps,
  FilePickerPreviewProps,
  FilePickerProps,
  FilePreview,
  PDFPreviewProps,
} from './file-picker.types';

const FilePickerAction = ({ icon, text, onClick }: FilePickerActionProps) => {
  return (
    <Button variant="text" startIcon={icon} onClick={onClick}>
      <Typography
        variant="paragraphSM"
        component="span"
        sx={{
          fontWeight: 700,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

function PdfPreview({ file, fileName }: PDFPreviewProps) {
  return (
    <Box data-testid="pdf-file-picker-preview">
      <embed src={file} key={fileName} width="100%" height="100%" />;
    </Box>
  );
}

export function FilePickerPreview({ file }: FilePickerPreviewProps) {
  const [preview, setPreview] = useState('');
  const tifThumbnailAsset = '/assets/tif_thumbnail.png';

  const handlePreviewCleanup = (preview: string) => {
    URL.revokeObjectURL(preview);
  };

  useEffect(() => {
    setPreview(URL.createObjectURL(file));
  }, [file]);

  return preview ? (
    <Box
      sx={{
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'fill',
        borderRadius: (theme) => theme.borderRadius.small,
        backgroundColor: (theme) => theme.palette.grey[200],
      }}
      data-testid={
        file.name.endsWith('.tiff')
          ? 'tiff-file-picker-preview'
          : 'file-picker-preview'
      }
      component="img"
      key={file.name}
      alt={file.name}
      src={
        file.name.endsWith('.tiff') || file.name.endsWith('.tif')
          ? tifThumbnailAsset
          : preview
      }
      onLoad={() => handlePreviewCleanup(preview)}
      onError={() => handlePreviewCleanup(preview)}
    />
  ) : null;
}

export function FilePicker({
  addText,
  removeText,
  labelText,
  errorMessages,
  icon,
  width = 228,
  height = 144,
  onDrop,
  onRemove,
  onDropRejected,
  onShowError,
  currentFile,
  useCompression = false,
  compressedFileSizeMB = 2,
  ...options
}: FilePickerProps) {
  const [file, setFile] = useState<FilePreview | null>(currentFile ?? null);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const handleError = (error: string) => {
    setError(error);
    onShowError?.(!!error);
  };

  const convertToFilePreview = (file: File): FilePreview =>
    (file as FilePreview).preview
      ? (file as FilePreview)
      : Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

  useEffect(() => {
    if (currentFile) setFile(convertToFilePreview(currentFile));
  }, [currentFile]);

  const internalOnDrop: DropzoneOptions['onDrop'] = async (files, ...args) => {
    if (files.length > 0) {
      setIsUploading(true);

      let file = files[0] as File;
      let error: string | null = null;

      if (useCompression) {
        const compressionResult = await compress({
          file: files[0], // Restriction to only one file
          maxSizeMB: compressedFileSizeMB,
        });

        if (compressionResult.success) {
          file = compressionResult.file;
        } else {
          error = errorMessages['compression-error'];
        }
      }

      if (!error) {
        const filePreview = convertToFilePreview(file);
        setFile(filePreview);
        handleError('');
        onDrop?.([filePreview], ...args);
      } else {
        handleError(error);
      }

      setIsUploading(false);
    }
  };

  const internalOnDropRejected: DropzoneOptions['onDropRejected'] = (
    files,
    ...args
  ) => {
    const code = files[0].errors[0].code as ErrorCode;
    handleError(errorMessages[code]);
    onDropRejected?.(files, ...args);
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    maxFiles: 1, // Restriction to only one file
    multiple: false,
    onDrop: internalOnDrop,
    onDropRejected: internalOnDropRejected,
  });

  const handleRemove = () => {
    setFile(null);
    onRemove?.();
  };

  // Return a preview if a file is picked
  if (file) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: `${width}px`,
          height: '100%',
        }}
      >
        <Typography
          variant="disclaimer"
          color={(theme: Theme) => theme.palette.secondary.main}
          sx={{
            width: '90%',
            marginBottom: (theme) => theme.spacing(),
          }}
        >
          {labelText ?? file.name}
        </Typography>
        <Box
          sx={{
            width,
            height,
            marginTop: 'auto',
          }}
        >
          {file.name.endsWith('.pdf') ? (
            <PdfPreview file={file.preview} fileName={file.name} />
          ) : (
            <FilePickerPreview file={file} />
          )}
        </Box>

        <FilePickerAction
          icon={<HighlightOffIcon fontSize="small" color="primary" />}
          text={removeText}
          onClick={handleRemove}
        />
      </Box>
    );
  }

  // Return spinner if a file is being uploaded
  if (isUploading)
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={35} />
      </Box>
    );

  // Return dropzone if no file is picked yet
  return (
    <Box
      sx={{
        width,
        display: 'grid',
        gridTemplateColumns: `${width}px`,
        rowGap: (theme) => theme.spacing(2),
      }}
    >
      <ErrorMessageBanner
        open={error !== ''}
        message={error}
        onClose={() => handleError('')}
      />
      <Box
        data-testid="file-picker-dropzone"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingX: (theme) => theme.spacing(3),
          paddingY: (theme) => theme.spacing(5),
          borderStyle: 'dashed',
          borderWidth: '1px',
          cursor: 'pointer',
          borderRadius: (theme) => theme.borderRadius.large,
          borderColor: (theme) => theme.palette.grey[500],
          backgroundColor: (theme) => theme.palette.grey[100],
          '&:hover': {
            borderColor: (theme) => theme.palette.primary.main,
          },
        }}
        {...getRootProps()}
      >
        <input data-testid={options.id} {...getInputProps()} />
        {icon}
        <FilePickerAction
          icon={<AddCircleIcon fontSize="small" color="primary" />}
          text={addText}
        />
      </Box>
    </Box>
  );
}
