import { onlyDigits } from '../only-digits/only-digits';

/**
 * Convert a phone number to E.164 format
 * @public
 *
 * @param phone - An unformatted phone number string
 *
 * @returns phone number in E.164 format
 *
 * @example
 * ```ts
 * cont result = toPhoneE164('212-555-9656');
 * console.log(result);
 * // -> '+12125559656'
 * ```
 */
function toPhoneE164(phone: string): string {
  return `+1${onlyDigits(phone)}`;
}

export { toPhoneE164 };
