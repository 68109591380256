import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type { SetUserRepository } from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';
import type { RaygunAdapterOptions } from '../types';

export function raygunSetLogUserAdapterFactory({
  rg4js,
  raygun,
  isLocal,
}: RaygunAdapterOptions) {
  return buildAdapter<SetUserRepository, ErrorCodes.SET_LOG_USER_FAILED>(
    function raygunSetLogUserAdapter(user) {
      if (rg4js) {
        rg4js('setUser', {
          identifier: user.userId,
          uuid: user.sessionId,
        });
      }

      if (raygun) {
        raygun.setUser({
          identifier: user.userId,
          uuid: user.sessionId,
        });
      }

      return Promise.resolve();
    },
    isLocal,
    ErrorCodes.SET_LOG_USER_FAILED,
    console.debug,
  );
}
