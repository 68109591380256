import type { IPureFunction, JSONValue } from '@hc-frontend/core-utils-types';

import { sendViaBeacon } from './send-via-beacon';
import { sendViaXmlHttpRequest } from './send-via-XmlHttpRequest';

export function sendAnalytics<T extends JSONValue>(
  endpoint: string,
  args: T,
  onMessage?: IPureFunction<[], void>,
  onError?: IPureFunction<[], void>,
): void {
  const shippingMethod =
    typeof navigator.sendBeacon === 'function'
      ? sendViaBeacon
      : sendViaXmlHttpRequest;

  return shippingMethod(endpoint, JSON.stringify(args), onMessage, onError);
}
