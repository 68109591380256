import type { IDictionary } from '@hc-frontend/core-utils-types';
import type {
  IOperationError,
  ISessionId,
} from '@hc-frontend/deprecated-entities';

/**
 * Port for defining the repository contract for logging errors.
 *
 * @public
 */
export interface LogErrorRepository<E = string, I = IDictionary<string>> {
  (
    reason: string,
    options: {
      cause?: Error;
      data?: I;
      operationErrors?: IOperationError<E, I>[];
    },
  ): Promise<void>;
}

/**
 * Port for defining the repository contract for logging info.
 *
 * @public
 */
export interface LogInfoRepository {
  <T>(message: string, data?: T): Promise<void>;
}

export interface AddLogTagsRepository {
  (...tags: string[]): Promise<void>;
}

export interface SetUserRepository {
  (id: ISessionId): Promise<void>;
}

export enum LogSeverity {
  Info = 'info',
  Error = 'error',
  Debug = 'debug',
}

export interface LogRepository<E = string, I = IDictionary<string>> {
  logInfo: LogInfoRepository;
  logError: LogErrorRepository<E, I>;
}
