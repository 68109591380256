/**
 * This file serves as a collection for input assertions used in questions. Each object
 * exported in this file must be a valid react-hook-form rule.
 *
 * How to create these: https://react-hook-form.com/get-started#Applyvalidation
 */

import {
  ALPHA_NUM_REGEX,
  DATE_FORMAT_REGEX,
  EMAIL_REGEX,
  isValidUSPhone,
  NAME_REGEX,
  PASSWORD_REGEX,
  US_PHONE,
} from '@hc-frontend/core-utils-validations';
import { curry } from 'ramda';

import type {
  buildAssertionProps,
  phoneAssertionProps,
} from './assertions.types';

export const buildAssertion = (
  regex: RegExp,
  {
    requiredMessage = 'This field is required. Please fill it with valid information.',
    patternMessage = 'Please enter a valid value.',
    required = true,
    validate = undefined,
  }: buildAssertionProps,
) => {
  return {
    required: {
      value: required,
      message: requiredMessage,
    },
    pattern: {
      value: regex,
      message: patternMessage,
    },
    validate,
  };
};

/**
 * Useful to create regex patterns: https://regex101.com/
 */

/**
 * Validation for a name input
 * @public
 * @param object - validation messages
 * @returns object - React hook form assertion
 */
export const nameAssert = curry(buildAssertion)(NAME_REGEX);
export const alphaNumericAssert = curry(buildAssertion)(ALPHA_NUM_REGEX);

/**
 * Validation for a email input
 * @public
 * @param object - validation messages
 * @returns object - React hook form assertion
 */
export const emailAssert = curry(buildAssertion)(EMAIL_REGEX);

/**
 * Validation for a password input
 *
 * Validate with:
 * 8 characters minimum
 * 1 upper case character
 * 1 lower case character
 * 1 number
 *
 * @public
 * @param object - validation messages
 * @returns object - React hook form assertion
 */
export const passwordAssert = curry(buildAssertion)(PASSWORD_REGEX);

/**
 * Validation for a phone input
 * @public
 * @param object - validation messages
 * @returns object - React hook form assertion
 */
export const dobAssert = curry(buildAssertion)(DATE_FORMAT_REGEX);

/**
 * Validation for a phone input
 * @public
 * @param object - validation messages
 * @returns object - React hook form assertion
 */
export const phoneAssert = ({
  requiredMessage = 'This field is required. Please fill it with valid information.',
  patternMessage = 'Please enter a valid phone in (___) ___-____ format.',
  invalidNumberMessage = 'Please enter a valid US phone number.',
  required = true,
  validate = (v) =>
    (!required && v === '') || isValidUSPhone(v) || invalidNumberMessage,
}: phoneAssertionProps = {}) => {
  return curry(buildAssertion)(US_PHONE)({
    requiredMessage,
    patternMessage,
    required,
    validate,
  });
};
