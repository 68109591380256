/**
 * List of environment names
 *
 * @public
 */
export enum EnvironmentName {
  STG = 'STG',
  QA = 'QA',
  PROD = 'PROD',
}
