import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadUrlRepository,
} from '@hc-frontend/deprecated-repositories';
import type { NextRequest } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextReadUrlAdapterFactory(
  provider: LogRepository,
  request: NextRequest,
  isLocal: boolean,
) {
  return buildAdapter<ReadUrlRepository<ErrorCodes.URL_NOT_READ>>()(
    provider,
    function nextReadUrlAdapter() {
      return Promise.resolve(
        `https://${
          isLocal
            ? 'local.rxcards.healthcare.com'
            : request.headers.get('host') || 'local.rxcards.healthcare.com'
        }${request.nextUrl.pathname}${request.nextUrl.search}`,
      );
    },
    ErrorCodes.URL_NOT_READ,
  );
}
