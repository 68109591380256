/* eslint-disable @typescript-eslint/no-explicit-any */

export function buildAdapter<
  T extends (...args: any[]) => Promise<void>,
  E extends string,
>(
  adapter: T,
  isLocal: boolean,
  displayError: E,
  fallback: (...args: Parameters<T>) => any,
): T {
  return (async (...args: Parameters<T>) => {
    try {
      if (isLocal) {
        console.group(adapter.name); // As de intention is to log only in local environment, we use console.group to make it easier to read and understand the logs
        fallback(...args);
        console.groupEnd();
      } else {
        await adapter(...args);
      }
    } catch (error) {
      if (!isLocal) {
        const details = `Logging failed, fallback to console. Reason: ${
          (error as Error).message
        }`;
        console.error(displayError, {
          cause: new Error(details),
        });
        fallback(...args);
      }
    }
    return Promise.resolve();
  }) as T;
}
