import type { FontTheme } from './util/generate-font-face/generate-font-face';
import { withBaseTheme } from './with-base';

const fontsToLoadFromCDN: FontTheme[] = [
  {
    name: 'proxima-nova',
    weightOptions: [400, 600, 700],
  },
  {
    name: 'bree-serif',
    weightOptions: [400, 600, 700],
  },
];

export const enrollPivotHealthComTheme = withBaseTheme(
  {
    palette: {
      primary: {
        main: '#556cd6',
      },
      secondary: {
        main: '#19857b',
      },
    },
    typography: {
      fontFamily: 'proxima-nova, sans-serif',
      fontFamilySecondary: 'bree-serif, serif',
    },
  },
  fontsToLoadFromCDN,
);
