import { trackUserClick } from '@hc-frontend/core-third-party-snowplow';
import {
  DialogHC,
  FlowLayout,
  XCenteredLayout,
} from '@hc-frontend/core-ui-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import {
  clickUploadIdPhotoEvent,
  clickUploadInsurancePhotoEvent,
  submitUploadIdPhotoEvent,
  submitUploadInsurancePhotoEvent,
} from '../../constants';
import type { ShowImageProps } from '../../molecules';
import { ShowImage, UploadCardHeader } from '../../molecules';
import { DocumentType, UploadCards } from '../upload-id-cards';
import type { UploadDocumentProps } from './upload-document.types';

export function UploadDocument({
  ns,
  nsKeyPrefix,
  onCardUploaded,
  currentCard,
  documentType,
  existingCard,
}: UploadDocumentProps) {
  const { t } = useTranslation(ns, { keyPrefix: nsKeyPrefix });
  const [openDialog, setOpenDialog] = useState(false);
  const [internalCard, setInternalCard] = useState(currentCard);
  const [hasErrors, setHasErrors] = useState({
    front: false,
    back: false,
  });

  const onOpenDialog = () => {
    if (documentType === DocumentType.insurance) {
      trackUserClick(clickUploadInsurancePhotoEvent);
    } else if (documentType === DocumentType.id) {
      trackUserClick(clickUploadIdPhotoEvent);
    }

    setOpenDialog(true);
  };

  const handleCardUploaded = () => {
    if (documentType === DocumentType.insurance) {
      trackUserClick(submitUploadInsurancePhotoEvent);
    } else if (documentType === DocumentType.id) {
      trackUserClick(submitUploadIdPhotoEvent);
    }
    onCardUploaded(internalCard);
    setOpenDialog(false);
  };

  const existingFrontProps =
    existingCard?.front?.fileName && existingCard?.front?.image
      ? ({
          ns,
          ...existingCard.front,
        } as ShowImageProps)
      : undefined;

  const existingBackProps =
    existingCard?.back?.fileName && existingCard?.back?.image
      ? ({
          ns,
          ...existingCard.back,
        } as ShowImageProps)
      : undefined;

  const noFiles =
    !currentCard.front &&
    !currentCard.back &&
    !existingFrontProps &&
    !existingBackProps;

  const renderFront = () => {
    if (currentCard.front)
      return (
        <ShowImage
          ns={ns}
          fileName={currentCard.front.name}
          image={currentCard.front.preview}
          removeAction={() => {
            setInternalCard({ front: undefined, back: internalCard.back });
            onCardUploaded({ front: undefined, back: currentCard.back });
          }}
          deleteTestId="removeFrontFile"
        />
      );

    if (existingFrontProps) {
      return <ShowImage {...existingFrontProps} />;
    }

    if (currentCard.back || existingBackProps)
      return (
        <Box>
          <Button
            sx={(theme) => ({
              fontSize: theme.typography.paragraphSM['fontSize'],
              whiteSpace: 'nowrap',
              height: 'fit-content',
              py: theme.spacing(1),
              mx: theme.spacing(3),
            })}
            onClick={() => setOpenDialog(true)}
          >
            {t('uploadFront')}
          </Button>
        </Box>
      );

    return undefined;
  };

  const renderBack = () => {
    if (currentCard.back)
      return (
        <ShowImage
          ns={ns}
          fileName={currentCard.back.name}
          image={currentCard.back.preview}
          removeAction={() => {
            setInternalCard({ front: internalCard.front, back: undefined });
            onCardUploaded({ front: currentCard.front, back: undefined });
          }}
          deleteTestId="removeBackFile"
        />
      );

    if (existingBackProps) {
      return <ShowImage {...existingBackProps} />;
    }

    if (currentCard.front || existingFrontProps)
      return (
        <Box>
          <Button
            sx={(theme) => ({
              fontSize: theme.typography.paragraphSM['fontSize'],
              whiteSpace: 'nowrap',
              height: 'fit-content',
              py: theme.spacing(1),
              mx: theme.spacing(3),
            })}
            onClick={() => setOpenDialog(true)}
          >
            {t('uploadBack')}
          </Button>
        </Box>
      );

    return undefined;
  };

  const submitIsDisabled = hasErrors.front || hasErrors.back;

  return (
    <Box>
      <Stack my={4}>
        <Typography
          variant="paragraphLG"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            display: 'flex',
            my: 3,
          }}
          component="div"
        >
          {t('title')}
        </Typography>
        {noFiles && (
          <>
            <Typography
              variant="paragraphMD"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {t('subTitle')}
            </Typography>
            <Typography variant="paragraphSM">{t('body')}</Typography>
          </>
        )}
      </Stack>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gridGap: theme.spacing(4),
          alignItems: 'center',
        })}
      >
        {renderFront()}
        {renderBack()}
      </Box>

      {noFiles && (
        <Button
          sx={(theme) => ({
            fontSize: theme.typography.paragraphMD['fontSize'],
            px: 5,
            py: 1,
            height: 'initial',
            lineHeight: null,
          })}
          fullWidth={false}
          onClick={onOpenDialog}
        >
          {t('uploadPhotos')}
        </Button>
      )}
      <DialogHC
        open={openDialog}
        maxWidth="md"
        onClose={() => setOpenDialog(false)}
        fullWidth
      >
        <XCenteredLayout sx={{ p: 4 }}>
          <UploadCardHeader ns={ns} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '475px',
            }}
          >
            <UploadCards
              onShowError={(showErrors) => {
                setHasErrors((oldErrors) => ({ ...oldErrors, ...showErrors }));
              }}
              ns={ns}
              currentCard={internalCard}
              onCardUploaded={setInternalCard}
              existingCard={{
                front: existingFrontProps && {
                  ...existingFrontProps,
                  removeAction: null, // Avoid removing existing card on modal to keep UX consistent
                },
                back: existingBackProps && {
                  ...existingBackProps,
                  removeAction: null, // Avoid removing existing card on modal to keep UX consistent
                },
              }}
              documentType={documentType}
            />

            <FlowLayout
              containerProps={{
                justifyContent: 'center',
                columnSpacing: 7,
                rowSpacing: 7,
                padding: 7,
              }}
              itemProps={{
                xs: 12,
                sm: 5,
              }}
            >
              <Button
                fullWidth
                startIcon={<ArrowBackIcon />}
                onClick={() => setOpenDialog(false)}
                variant="outlined"
              >
                {t('back')}
              </Button>
              <Button
                disabled={submitIsDisabled}
                fullWidth
                onClick={handleCardUploaded}
              >
                {t('submit')}
              </Button>
            </FlowLayout>
          </Box>
        </XCenteredLayout>
      </DialogHC>
    </Box>
  );
}
