import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ArrowBack,
  AvatarWrapper,
  Button,
  SecondaryText,
} from './transfer-rx-form-layout.common';
import type { TransferPropTypes } from './transfer-rx-form-layout.types';

// TODO: Refactor texts into translations prop. We should not set
// default values for texts in order to prevent not translating
// content.

function TransferFormContainer({
  children,
  title,
  subTitle,
  profileImage,
  imageCaption,
  formCaption,
  nextDisabled,
  nextButtonText = 'Next',
  nextButtonFormId,
  backButtonDesktopText = 'Back',
  backButtonMobileText = 'Go Back',
  containerMaxWidth = 'sm',
  keepSameOnDesktop,
  hideBackIcon,
  hideImage,
  onNextButtonClick,
  onBackButtonClick,
}: TransferPropTypes) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box mt={6}>
      {isMobile && !keepSameOnDesktop && (
        <SecondaryText
          as={FormLabel}
          sx={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: '1em',
          }}
          onClick={onBackButtonClick}
        >
          {!hideBackIcon && <ArrowBack size={17} />}
          {backButtonMobileText}
        </SecondaryText>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {!hideImage && (
          <Grid component={Box} item xs={12} mb={{ sm: 4, xs: 2 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <AvatarWrapper data-testid="avatar-wrapper">
                  <Avatar
                    src={profileImage}
                    sx={{
                      height: 54,
                      width: 54,
                    }}
                  />
                </AvatarWrapper>
              </Grid>
              <Grid component={Box} item xs={12} mt={{ sm: 3, xs: 2 }}>
                <SecondaryText as={FormLabel}>{imageCaption}</SecondaryText>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Container maxWidth={containerMaxWidth}>
          <Grid component={Box} item xs={12} my={{ sm: 2, xs: 4 }}>
            <Typography variant="h1" align="center">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SecondaryText variant="paragraphMD" align="center">
              {subTitle}
            </SecondaryText>
          </Grid>
        </Container>
      </Grid>
      <Container maxWidth={containerMaxWidth}>
        <Grid
          component={Box}
          item
          xs={12}
          pt={{ sm: '30px', xs: '40px' }}
          pb={{ sm: '90px', xs: '60px' }}
        >
          {children}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="paragraphSM"
            color={theme.palette.text.disabled}
            sx={{
              color: theme.palette.text.disabled,
            }}
            align="center"
            py={6}
          >
            {formCaption}
          </Typography>
        </Grid>
      </Container>
      <Container maxWidth={keepSameOnDesktop ? containerMaxWidth : false}>
        <Grid item xs={12}>
          <Grid container spacing={4} justifyContent="center">
            {(!isMobile || keepSameOnDesktop) && (
              <Grid
                item
                data-testid="desktop-back-button"
                xs={keepSameOnDesktop && 6}
                sm={keepSameOnDesktop && 6}
              >
                <Button
                  fullWidth={keepSameOnDesktop}
                  variant="outlined"
                  onClick={onBackButtonClick}
                >
                  {!hideBackIcon && <ArrowBack />}
                  {backButtonDesktopText}
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={keepSameOnDesktop ? 6 : isMobile && 12}
              sm={keepSameOnDesktop && 6}
            >
              {nextButtonFormId ? (
                <Button
                  form={nextButtonFormId}
                  type="submit"
                  fullWidth={isMobile || keepSameOnDesktop}
                  disabled={nextDisabled}
                >
                  {nextButtonText}
                </Button>
              ) : (
                <Button
                  fullWidth={isMobile || keepSameOnDesktop}
                  disabled={nextDisabled}
                  onClick={onNextButtonClick}
                >
                  {nextButtonText}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export { TransferFormContainer };
