import {
  ADDRESS_LINE_REGEX,
  isValidUSPhone,
  NAME_REGEX,
  US_PHONE,
  WHITESPACE_CHECK_REGEX,
  ZIP_CODE_REGEX,
} from '@hc-frontend/core-utils-validations';

import type {
  TransferPrescriberFormTranslations,
  TransferPrescriberFormValidationRules,
} from './transfer-prescriber-form.types';

export const defaultValues = {
  firstName: '',
  lastName: '',
  suffix: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  phoneNumber: '',
};

export const generateValidations = (
  translations: TransferPrescriberFormTranslations,
): TransferPrescriberFormValidationRules => ({
  firstName: {
    required: {
      value: true,
      message: translations.firstNameRequiredError,
    },
    pattern: {
      value: NAME_REGEX,
      message: translations.firstNamePatternError,
    },
  },
  lastName: {
    required: {
      value: true,
      message: translations.lastNameRequiredError,
    },
    pattern: {
      value: NAME_REGEX,
      message: translations.lastNamePatternError,
    },
  },
  suffix: {
    required: false,
  },
  streetAddress: {
    required: {
      value: true,
      message: translations.addressRequiredError,
    },
    pattern: {
      value: ADDRESS_LINE_REGEX,
      message: translations.addressPatternError,
    },
  },
  city: {
    required: {
      value: true,
      message: translations.cityRequiredError,
    },
    pattern: {
      value: WHITESPACE_CHECK_REGEX,
      message: 'translations.cityValidateError',
    },
  },
  state: {
    required: {
      value: true,
      message: translations.stateRequiredError,
    },
  },
  zipCode: {
    required: {
      value: true,
      message: translations.zipRequiredError,
    },
    pattern: {
      value: ZIP_CODE_REGEX,
      message: translations.zipPatternError,
    },
  },
  phoneNumber: {
    required: {
      value: true,
      message: translations.phoneRequiredError,
    },
    pattern: {
      value: US_PHONE,
      message: translations.phonePatternError,
    },
    validate: (v) =>
      isValidUSPhone(v, { enable800: true }) || translations.phoneValidateError,
  },
});
