export enum AdapterResponseStatus {
  Ok = 'Ok',
  ServiceError = 'ServiceError',
  NoResponse = 'NoResponse',
}

export type IAdapterResponseError = {
  details?: string;
  message: string;
};

export type IAdapterResponse<
  T,
  Y extends IAdapterResponseError[] = IAdapterResponseError[],
> =
  | {
      data: T;
      status: AdapterResponseStatus.Ok;
      errors: [];
    }
  | {
      data: undefined;
      status: Exclude<AdapterResponseStatus, 'Ok'>;
      errors: Y;
    };

export interface IUseCaseError {
  details?: string;
  message: string;
}

export type IUseCaseResponse<T> =
  | {
      data: T;
      success: true;
      errors: [];
    }
  | {
      data?: undefined;
      success: false;
      errors: IUseCaseError[];
    };
