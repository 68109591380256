import { isObjectNotNull } from '@hc-frontend/core-utils-validations';
import {
  cond,
  identity,
  map,
  mapObjIndexed,
  mergeDeepLeft,
  not,
  pipe,
  T,
} from 'ramda';

/**
 * Deep clone an object.
 * @public
 *
 * @param obj - The object to clone.
 * @returns The cloned object.
 *
 * @example
 * ```ts
 * const obj = { a: 1, b: { c: 2 } };
 * const result = deepClone(obj);
 * console.log(result);
 * // -> { a: 1, b: { c: 2 } }
 * ```
 */
function deepClone<T>(obj: T): T {
  type DeepClone<C> = (obj: C) => C;

  return cond([
    [pipe(isObjectNotNull, not), identity as DeepClone<T>],
    [Array.isArray, map(deepClone) as unknown as DeepClone<T>],
    [
      T,
      pipe(
        mergeDeepLeft({}),
        mapObjIndexed(deepClone),
      ) as unknown as DeepClone<T>,
    ],
  ])(obj);
}

export { deepClone };
