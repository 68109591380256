import Script from 'next/script';

import type { HeadScriptProps } from './head-script.types';

export function GoogleAdsHeadScript({ id }: HeadScriptProps) {
  return id ? (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${id.trim()}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
     window.dataLayer = window.dataLayer || [];
     function gtag(){dataLayer.push(arguments);}
     gtag('js', new Date());
     gtag('config', '${id.trim()}');
    `}
      </Script>
    </>
  ) : null;
}
