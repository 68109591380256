/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  IOperationResult,
  ISuccessOperationResult,
} from '@hc-frontend/deprecated-entities';
import type { LogRepository } from '@hc-frontend/deprecated-repositories';

export type AsSuccessOperationResultProvider = LogRepository;

export function asSuccessOperationResult<
  T extends AsSuccessOperationResultProvider,
  TA extends (...args: any[]) => Promise<IOperationResult<any, any, any>>,
  TArgs extends TA extends (
    ...args: any[]
  ) => Promise<IOperationResult<infer R, any, any>>
    ? R extends never | void
      ? []
      : [defaultValue: R]
    : [],
>(
  provider: T,
  previousOperation: TA,
  ...[defaultValue]: TArgs
): (
  ...args: Parameters<TA>
) => Promise<
  ISuccessOperationResult<
    TA extends IOperationResult<infer R, any, any> ? R : never,
    TA extends IOperationResult<any, infer E, any> ? E : never,
    TA extends IOperationResult<any, any, infer R> ? R : never
  >
> {
  return async (...args) => {
    const result = await previousOperation(...args);
    if (result.success === false) {
      if (previousOperation.name)
        provider.logInfo(
          `${previousOperation.name} result changed to success.`,
        );

      return Promise.resolve({
        success: true,
        data: defaultValue,
        errors: [],
      });
    }

    return Promise.resolve(result);
  };
}
