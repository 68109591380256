/**
 * Error codes for RX Cards business.
 *
 * @public
 */
export enum RxCardsErrorCodes {
  EMAIL_OR_PHONE_REQUIRED = 'EMAIL_OR_PHONE_REQUIRED',
  EMAIL_IS_REQUIRED = 'EMAIL_IS_REQUIRED',
  EMAIL_IS_INVALID = 'EMAIL_IS_INVALID',
  PHONE_IS_REQUIRED = 'PHONE_IS_REQUIRED',
  PHONE_IS_INVALID = 'PHONE_IS_INVALID',
  PHONE_NUMBER_INCORRECT_FORMAT = 'PHONE_NUMBER_INCORRECT_FORMAT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NO_RESPONSE = 'NO_RESPONSE',
  HC_SESSION_ID_IS_REQUIRED = 'HC_SESSION_ID_IS_REQUIRED',
  HC_SESSION_ID_IS_INVALID = 'HC_SESSION_ID_IS_INVALID',
  RX_CARDS_SESSION_ID_IS_REQUIRED = 'RX_CARDS_SESSION_ID_IS_REQUIRED',
  RX_CARDS_SESSION_ID_IS_INVALID = 'RX_CARDS_SESSION_ID_IS_INVALID',
  INVALID_SESSION_ID = 'INVALID_SESSION_ID',

  // Operations
  POST_RX_CARD = 'POST_RX_CARD',
  GET_RX_CARD = 'GET_RX_CARD',

  REPORT_CONVERSION_FAILED = 'REPORT_CONVERSION_FAILED',
  TRACK_CREATE_CARD_CTA_FAILED = 'TRACK_CREATE_CARD_CTA_FAILED',
  GET_CREATE_CARD_CONVERSION_FAILED = 'GET_CREATE_CARD_CONVERSION_FAILED',
  GET_CONFIG_FAILED = 'GET_CONFIG_FAILED',
}
