/**
 * Validates that the value is an array.
 * @public
 *
 * @param arg - The argument to validate.
 *
 * @returns A boolean indicating whether the arg is an Array or not.
 *
 * @example
 * ```ts
 * const result = isArray([]);
 * console.log(result);
 * // -> true
 * ```
 */
function isArray<T>(arg: unknown): arg is T[] {
  return Array.isArray(arg);
}

export { isArray };
