import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useAppConfig } from '../../hooks';

export function ReCaptchaProvider({
  children,
  container,
}: React.PropsWithChildren<Record<'container', string>>) {
  const {
    public: {
      reCaptcha: { siteKey },
    },
  } = useAppConfig();

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={siteKey}
        container={{
          element: container,
          parameters: { badge: 'bottomright' },
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
      <div
        id={container}
        data-testid={container}
        style={{
          height: 0,
          width: 0,
          overflow: 'hidden',
        }}
      />
    </>
  );
}
