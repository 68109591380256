import { YCenteredLayout } from '@hc-frontend/core-ui-components';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { CloudImage } from '../../atoms';

export function CardHeader() {
  const { t } = useTranslation('card');

  return (
    <YCenteredLayout
      direction={{ xs: 'column', sm: 'row' }}
      sx={{
        alignItems: 'center',
        gap: '3%',
      }}
    >
      <CloudImage
        assetName="healthcare-logo.webp"
        height={92}
        width={405}
        alt="Healthcare.com Logo"
        priority
      />
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography
        variant="title"
        color="primary.main"
        sx={{
          '@media print': {
            fontSize: '20px',
          },
        }}
      >
        {t('cardHeader')}
      </Typography>
    </YCenteredLayout>
  );
}
