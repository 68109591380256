import type { IRxCardEntity } from '@hc-frontend/shells-rx-cards-entities';

/**
 * Convert a given value into a managed value, which is a value that should be validated with a proper use case.
 *
 * @public
 *
 * @param value - The value to be casted to a validated entity.
 *
 * @returns The same value without any changes, only casted to a validated entity.
 *
 * @deprecated Use the shared version instead with IBusinessEntity
 *
 * @example
 * ```ts
 * const obj = { name: 'John Doe' };
 * const result = buildManagedValue(obj);
 * // -> result = { name: 'John Doe' } but casted to IRxCardValue<{ name: string }>
 *
 * console.log(obj === result);
 * // -> true
 * ```
 *
 */
export function buildManagedValue<TValue>(
  value: TValue,
): IRxCardEntity<TValue> {
  return value as IRxCardEntity<TValue>;
}
