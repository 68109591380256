import { SHA256 } from 'crypto-js';

const NORMALIZATION_SCHEMA = 'NFC';
const DIACRITICS: { [key: string]: string } = {
  a: 'áăắặằẳẵǎâấậầẩẫäǟȧǡạȁàảȃāąåǻḁãǽǣ',
  b: 'ḃḅḇ',
  c: 'ćčçḉĉċ',
  d: 'ďḑḓḋḍḏ',
  e: 'éĕěȩḝêếệềểễḙëėẹȅèẻȇēḗḕęẽḛé',
  f: 'ḟ',
  g: 'ǵğǧģĝġḡ',
  h: 'ḫȟḩĥḧḣḥẖ',
  i: 'íĭǐîïḯi̇ịȉìỉȋīįĩḭ',
  j: 'ǰĵ',
  k: 'ḱǩķḳḵ',
  l: 'ĺľļḽḷḹḻ',
  m: 'ḿṁṃ',
  n: 'ńňņṋṅṇǹṉñ',
  o: 'óŏǒôốộồổỗöȫȯȱọőȍòỏơớợờởỡȏōṓṑǫǭõṍṏȭǿø',
  p: 'ṕṗ',
  r: 'ŕřŗṙṛṝȑȓṟ',
  s: 'śṥšṧşŝșṡẛṣṩ',
  t: 'ťţṱțẗṫṭṯ',
  u: 'úŭǔûṷüǘǚǜǖṳụűȕùủưứựừửữȗūṻųůũṹṵ',
  v: 'ṿṽ',
  w: 'ẃŵẅẇẉẁẘ',
  x: 'ẍẋ',
  y: 'ýŷÿẏỵỳỷȳẙỹ',
  z: 'źžẑżẓẕ',
};

/**
 * Normalize a string by removing special characters and excess whitespace, and converting to lower-case.
 * v.0.8.0;
 * @public
 *
 * @param string - String to be normalized
 *
 * @example
 * ```ts
 *
 * import {normalizeString} from '@hc-frontend/core-third-party-snowplow';
 *
 * normalizeString("Snówplow  TEST #1")
 * ```
 */
function normalize(string: string): string {
  // Steps:
  // 1. Unicode normalization
  // 2. Lowecase convertion
  // 3. Diacritical replacement
  // 4. Whitespace reduction
  // 5. Additional character removal
  // 6. Triming
  let normalized = string.normalize(NORMALIZATION_SCHEMA).toLowerCase();
  Object.keys(DIACRITICS).forEach((mapLetter) => {
    const diacritics = DIACRITICS[mapLetter].normalize(NORMALIZATION_SCHEMA);
    normalized = normalized.replace(
      new RegExp(`[${diacritics}]`, 'g'),
      mapLetter,
    );
  });
  return normalized
    .replace(/\s+/g, ' ')
    .replace(/[^\w@. ]/g, '')
    .trim();
}

/**
 * Runs a one-way hash algorithm on a string.
 * v.0.8.0;
 * @public
 *
 * @param string - String to be hashed.
 *
 * @example
 * ```ts
 *
 * import {hash} from '@hc-frontend/core-third-party-snowplow';
 *
 * hash("Snówplow  TEST #1")
 * ```
 */
function hash(string: string): string {
  return SHA256(string).toString();
}

/**
 * Runs normalization and hashing on a string.
 * v.0.8.0;
 * @public
 *
 * @param string - String to be normalized and hashed.
 *
 * @example
 * ```ts
 *
 * import {normalizeAndHash} from '@hc-frontend/core-third-party-snowplow';
 *
 * normalizeAndHash("Snówplow  TEST #1")
 * ```
 */
function normalizeAndHash(string: string): string {
  return hash(normalize(string));
}

export { hash, normalize, normalizeAndHash };
