import getConfig from 'next/config';

type PublicRuntimeConfiguration = {
  absmartly: {
    apiKey: string;
    applicationName: string;
    applicationVersion: string;
    environment: string;
    productName: string;
  };
  app: {
    environment: string;
    rxCardsApi: string;
    sessionApi: string;
    rootDomain: string;
    isLocal: boolean;
  };
  reCaptcha: {
    siteKey: string;
  };
  pixels: {
    headerIds: {
      facebook: string;
      bing: string;
      google: string;
    };
    events: {
      signUp: {
        facebook: string;
        google: string;
        bing: string;
      };
    };
  };
  raygun: {
    apiKey: string;
    disableCrashReporting: boolean;
  };
  userway: {
    apiKey: string;
  };
  cloudFront: {
    url: string;
  };
  constants: {
    phoneNumber: '866-215-2905';
    tty: '711';
    baseUrl: 'https://pharmacy.healthcare.com';
    sessionId: 'healthcareSession';
    userId: 'healthcareUser';
    recaptchaContainerId: 'recaptcha-container';
  };
};

type RxCardsAppConfig = {
  public: PublicRuntimeConfiguration;
  private: unknown;
};

let rxCardsAppConfig: RxCardsAppConfig | undefined;

/**
 * @internal
 */
export function getRxCardsConfig(): RxCardsAppConfig {
  if (!rxCardsAppConfig) {
    const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

    rxCardsAppConfig = {
      public: {
        ...publicRuntimeConfig,
        constants: {
          phoneNumber: '866-215-2905',
          tty: '711',
          baseUrl: 'https://pharmacy.healthcare.com',
          sessionId: 'healthcareSession',
          userId: 'healthcareUser',
          recaptchaContainerId: 'recaptcha-container',
        },
      },
      private: serverRuntimeConfig,
    };
  }

  return rxCardsAppConfig as RxCardsAppConfig;
}
