/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/util-function` is a package containing functional programming
 * utilities.
 *
 * @packageDocumentation
 */

export * from './noon/noon';
