import MuiButton from '@mui/lab/LoadingButton';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { ExtendedPalette } from './view-edit-details.types';

export const DetailsHeader = styled(({ ...props }) => (
  <Typography noWrap variant="helpText" component="div" {...props} />
))(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.disabled,
  lineHeight: '160%',
}));

export const InfoWrapper = styled(({ ...props }) => (
  <Typography noWrap variant="paragraphSM" component="div" {...props} />
))(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.main,
  lineHeight: '160%',
}));

export const PlaceHolderWrapper = styled(Typography)(
  ({ theme }: { theme: Theme }) => ({
    ...theme.typography.helpText,
    color: (theme.palette as ExtendedPalette).neutral.tertiaryGray,
    lineHeight: '129%',
  }),
);

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const ViewEditDetailsWrapper = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.grayShades[100],
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(4),
    boxShadow: `inset ${theme.shadows[1]}`,
  }),
);

export const Button = styled(MuiButton)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.helpText,
  borderRadius: theme.borderRadius.full,
  fontWeight: theme.typography.fontWeightBold,
  height: 'auto',
  paddingBlock: 0,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  lineHeight: '160%',
  color: (theme.palette as ExtendedPalette).neutral.tertiaryGray,
  display: 'initial-block',
  '.MuiButton-startIcon': {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
  '&.MuiButton-contained': {
    color: theme.grayShades[100],
    ':focus': {
      color: theme.grayShades[100],
    },
  },
  ':focus': {
    color: (theme.palette as ExtendedPalette).neutral.tertiaryGray,
  },
}));

export const EditInput = styled(InputBase)(({ theme }: { theme: Theme }) => ({
  input: {
    paddingBlock: theme.spacing(2) + ' !important',
    paddingInline: theme.spacing(4) + ' !important',
    ...theme.typography.paragraphSM,
  },
  [theme.breakpoints.up('md')]: {
    marginBlock: '-' + theme.spacing(2),
    marginInline: '-' + theme.spacing(4),
  },
}));
