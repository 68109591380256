import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { IOperationResult } from '@hc-frontend/deprecated-entities';
import type {
  IRxCardImage,
  RxCardsErrorCodes,
} from '@hc-frontend/shells-rx-cards-entities';

import type { IRxCardDTO, IRxCardsServiceResponse } from '../../dto';
import { createCardImageFromCardDTO } from '../../factory';
import { executeRxCardsService } from '../../helpers';

/**
 * Get a RX Card image.
 *
 * @public
 *
 * @param basePath - Base path to rx-cards api.
 *
 * @param cardId - CardId.
 *
 *
 * @returns Response with card information.
 *
 * @example
 * ```ts
 * fetchRxCardApiAdapter(getRxCardService, 'cardId')
 * ```
 *
 */
export function fetchRxCardApiAdapter(
  basePath: string,
  cardId: string,
): Promise<IOperationResult<IRxCardImage, RxCardsErrorCodes>> {
  return executeRxCardsService(async () => {
    const { parsedBody } = await fetchJSON<IRxCardsServiceResponse<IRxCardDTO>>(
      `${basePath}/rx-cards/api/v1/card/${cardId}`,
    );
    return parsedBody;
  }, createCardImageFromCardDTO);
}
