// eslint-disable-next-line no-restricted-imports
import type { FetchJSONError } from '@hc-frontend/core-utils-net';
import {
  createNoOperationResult,
  createOperationErrorResult,
  createOperationSuccessResult,
  createOperationUnknownErrorResult,
} from '@hc-frontend/deprecated-business';
import type { IOperationResult } from '@hc-frontend/deprecated-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import type { IRxCardsServiceResponse } from '../../dto';
import { mapError } from '../map-error/map-error';

/**
 * Execute an RX Card service api layer and map it to a business operation
 *
 * @param callService - An service layer operation
 *
 * @param transform - A transform function to convert from a DTO entity to business entity
 *
 * @returns An operation with a business entity ready to use in upper layers
 *
 * ```ts
 * executeRxService(() => fetchRxUser(id, token), createEntity)
 * ```
 */
export async function executeRxCardsService<TIn, TOut = TIn>(
  callService: () => Promise<IRxCardsServiceResponse<TIn>>,
  transform: (data: TIn) => TOut,
): Promise<IOperationResult<TOut, RxCardsErrorCodes>> {
  try {
    const response = await callService();
    const data = response?.data ?? null;
    const success = response?.success;
    const errors = response?.errors || [{ code: '0000000' }];
    if (success === false) return createOperationErrorResult(mapError, errors);

    if (data) {
      return createOperationSuccessResult(transform(data));
    }

    return createNoOperationResult(
      RxCardsErrorCodes.NO_RESPONSE,
      JSON.stringify(response),
    );
  } catch (serviceError) {
    const error = serviceError as FetchJSONError;

    try {
      const response: IRxCardsServiceResponse<TIn> | null = JSON.parse(
        error.message,
      );
      const errors = response?.errors || [
        { code: '0000000', description: 'Something went wrong' },
      ];

      return createOperationErrorResult(mapError, errors);
    } catch (unknownError) {
      return createOperationUnknownErrorResult(
        RxCardsErrorCodes.UNKNOWN_ERROR,
        (error as Error).message,
      );
    }
  }
}
