import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { PromNull } from '@hc-frontend/core-utils-types';

import { getGoogleURI } from '../config/endpoints';
import type {
  IPostGoogleRecaptchaVerifyParams,
  IPostGoogleRecaptchaVerifyResponse,
} from './post-google-recaptcha-verify.types';

/**
 * Post call to verify captcha token
 *
 * @param params - Query params required and optional for the request
 * @returns - The response from google verify
 *
 * @public
 *
 * @example
 *
 * ```ts
 * const params = { ... }
 * const response = await postGoogleRecaptchaVerify(params);
 * ```
 */
export async function postGoogleRecaptchaVerify({
  secret,
  response,
  remoteip,
}: IPostGoogleRecaptchaVerifyParams): PromNull<IPostGoogleRecaptchaVerifyResponse> {
  const url = `${getGoogleURI()}/recaptcha/api/siteverify`;

  const { parsedBody } = await fetchJSON<IPostGoogleRecaptchaVerifyResponse>(
    url,
    {
      method: 'POST',
      queryParams: {
        secret,
        response,
        remoteip,
      },
    },
  );

  return parsedBody ?? null;
}
