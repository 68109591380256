export enum Placement {
  bottom = 'form-bottom',
  modal = 'modal',
}

export interface BuildEventProps {
  category?: string;
  placement?: Placement;
  action?: string;
  label: string;
  value: string;
  elementId: string;
  targetUrl?: string;
  description?: string;
}
