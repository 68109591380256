import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import {
  FooterCardDisclosure,
  FooterClinicalResources,
  FooterCompany,
  FooterContactUs,
  FooterCopyright,
  FooterHaveQuestions,
  FooterLogo,
  FooterNoticeOfPrivacyPractices,
  FooterPatientRights,
  FooterPrivacyPolicy,
  FooterTermsOfUse,
} from './footer-pharmacy.common';
import type {
  FooterLogosProps,
  FooterPharmacyProps,
} from './footer-pharmacy.types';
import { FooterLogos } from './footer-pharmacy.types';

const getBlobURL = (code: string, type: string) => {
  const blob = new Blob([code], { type });

  return window.URL.createObjectURL(blob);
};

const getLegitScriptIframe = () => {
  const source = `
  <html style="overflow: hidden">
    <body style="margin: 0">
      <script src="https://static.legitscript.com/seals/470514.js"></script>
      <script>
      setTimeout(function(){
        const img = document.getElementsByTagName('img')[0];

        if(img) {
          img.src = img.src.replace('blob', 'https')
        }

      }, 10);
      </script>
    </body>
  </html>
`;

  return getBlobURL(source, 'text/html');
};

function buildLogos({ iframeUrl }: Record<'iframeUrl', string>) {
  return {
    [FooterLogos.DOT_PHARMACY]: (
      <Grid
        item
        xs={5}
        md={3}
        lg={2}
        display={'inline-block'}
        key={FooterLogos.DOT_PHARMACY}
        sx={{
          width: '100%',
          height: '75px',
          maxWidth: '250px',
        }}
        data-testid="dotpharmacy-logo"
      >
        <a
          href="https://safe.pharmacy/buy-safely/?url=pharmacy.healthcare.com"
          target="_blank"
          data-testid="dotpharmacy-link"
          rel="noreferrer noopener"
          aria-label="Dot Pharmacy"
        >
          <Box position="relative" height={25} width={'100%'}>
            <Image
              src="/assets/dot-pharmacy-logo.png"
              fill
              priority
              alt="Dot Pharmacy Certification"
            />
          </Box>
        </a>
      </Grid>
    ),
    [FooterLogos.LEGIT_SCRIPT]: iframeUrl !== '' && (
      <Grid
        item
        xs={12}
        md={3}
        lg={2}
        display={'inline-block'}
        key={FooterLogos.LEGIT_SCRIPT}
        data-testid="legitscript-logo"
        sx={{
          iframe: {
            border: 'none',
          },
        }}
      >
        <iframe
          src={iframeUrl}
          id="legitscript-iframe"
          title="legitscript-iframe"
          width={150}
          height={110}
          sandbox="allow-scripts allow-popups"
          loading="eager"
        ></iframe>
      </Grid>
    ),
  };
}

function FooterPharmacy({
  phone,
  tty,
  baseUrl = '',
  logos,
}: FooterPharmacyProps & FooterLogosProps) {
  const [iframeUrl, setIframeUrl] = useState('');
  const logoComponents = buildLogos({ iframeUrl });

  useEffect(() => {
    setIframeUrl(getLegitScriptIframe());
  }, []);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
      })}
    >
      <Grid container px="10vw">
        <Grid item sm={12} pt="100px">
          <FooterLogo />
        </Grid>
        <Grid xs={12} sm={9} item>
          <Grid
            container
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            pt={'47px'}
            rowSpacing={'47px'}
          >
            <Grid xs={12} sm={4} item>
              <FooterContactUs phone={phone} tty={tty} />
            </Grid>
            <Grid xs={12} sm={8} item>
              <FooterCompany baseUrl={baseUrl} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sm={3}
          display={{
            xs: 'none',
            md: 'block',
          }}
          pt="47px"
          item
          id="foo"
        >
          <FooterHaveQuestions phone={phone} tty={tty} />
        </Grid>
        <Grid
          item
          sm={12}
          pt={{
            xs: '30px',
            sm: '3vh',
          }}
          pb="30px"
        >
          <Grid
            container
            direction={{
              xs: 'column-reverse',
              md: 'row',
            }}
            rowSpacing={'47px'}
          >
            <Grid item xs={12} sm={4}>
              <FooterCopyright />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={'12px'}
              >
                <FooterClinicalResources baseUrl={baseUrl} />
                <FooterNoticeOfPrivacyPractices baseUrl={baseUrl} />
                <FooterPatientRights baseUrl={baseUrl} />
                <FooterTermsOfUse baseUrl={baseUrl} />
                <FooterPrivacyPolicy baseUrl={baseUrl} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  gap: '25px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                mt={{
                  xs: '4vh',
                }}
              >
                {logos.map((logo) => logoComponents[logo])}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FooterCardDisclosure />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export { FooterPharmacy };
