import Alert from '@mui/material/Alert';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

type ErrorProps = {
  theme: Theme;
};

const ErrorMessage = styled(Alert)(({ theme }: ErrorProps) => ({
  borderRadius: 0,
  padding: '2px',
  '.MuiAlert-icon': {
    marginInline: '11px',
  },
  '.MuiAlert-message': {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.error.main,
    padding: '8px',
  },
}));

export { ErrorMessage };
