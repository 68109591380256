/**
 * Return the numbers found in a text string
 * @public
 *
 * @param str - Text string to evaluate
 *
 * @returns A string with the numbers found
 *
 * @example
 * ```ts
 * cont result = onlyDigits('(210) 371-2121')
 * console.log(result);
 * // -> '2103712121'
 * ```
 */
function onlyDigits(str: string): string {
  return str.replace(/\D/g, '');
}

export { onlyDigits };
