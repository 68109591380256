import type {
  Experiment,
  Smartly,
} from '@hc-frontend/core-third-party-absmartly';
import type { IExperiment } from '@hc-frontend/deprecated-entities';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  GetExperimentsTreatmentsRepository,
  LogRepository,
} from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';

export function absmartlyGetTreatmentAdapterFactory(
  provider: LogRepository,
  smartly: Smartly,
) {
  return buildAdapter<
    GetExperimentsTreatmentsRepository<ErrorCodes.GET_EXPERIMENT_TREATMENT_FAILED>
  >(smartly)(
    provider,
    function absmartlyGetTreatmentAdapter(...experimentsToRun) {
      const { experiments } = smartly.context.data();
      const filteredExperiments: IExperiment[] = experiments
        .filter((experiment: Experiment) =>
          experimentsToRun.includes(experiment.name),
        )
        .map((experiment: Experiment) => ({
          id: experiment.id,
          name: experiment.name,
          variant: {
            id: smartly.context.treatment(experiment.name),
          },
        }));
      return Promise.resolve(filteredExperiments);
    },
    ErrorCodes.GET_EXPERIMENT_TREATMENT_FAILED,
  );
}
