/**
 * Get the value of a particular cookie.
 * @public
 *
 * @param cookieName - Name of the cookie whose value is to be fetched
 *
 * @returns "" if no cookie name is passed
 *
 * @example
 * ```ts
 * const rememberedEmail = getCookieValue("rememberMeCookie"); // Output: "test@email.com"
 * const rememberedEmail = getCookieValue(""); // Output: ""
 * ```
 */

function getCookieValue(cookieName: string): string {
  const matchingCookie = document.cookie.match(
    new RegExp(`(^| )${cookieName}=([^;]+)`),
  );
  if (matchingCookie && matchingCookie.length === 3) {
    return matchingCookie[2];
  }
  return '';
}

export { getCookieValue };
