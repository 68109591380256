export enum ErrorCodes {
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  NO_RESPONSE = 'NO_RESPONSE',
  MISSING_LANDING_PAGE_URL = 'MISSING_LANDING_PAGE_URL',
  SESSION_NOT_CREATED = 'SESSION_NOT_CREATED',
  INVALID_SESSION_DATA = 'INVALID_SESSION_DATA',
  INVALID_SESSION = 'INVALID_SESSION',
  SESSION_FAILED = 'SESSION_FAILED',
  MOVE_TO_VIEW_FAILED = 'MOVE_TO_VIEW_FAILED',
  PARAMETER_NOT_READ = 'PARAMETER_NOT_READ',
  COOKIE_NOT_SAVED = 'COOKIE_NOT_SAVED',
  HEADER_NOT_READ = 'HEADER_NOT_READ',
  COOKIE_NOT_READ = 'COOKIE_NOT_READ',
  DOMAIN_NOT_READ = 'DOMAIN_NOT_READ',
  URL_NOT_READ = 'URL_NOT_READ',
  PARSING_USER_AGENT_FAILED = 'PARSING_USER_AGENT_FAILED',
  /**
   * General error code for unknown errors or any error happened and not handled properly.
   *
   * @public
   */
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',

  /**
   * Error code indicating that tracking an experiment into the analytics service failed.
   *
   * @public
   */
  TRACK_EXPERIMENT_FAILED = 'TRACK_EXPERIMENT_FAILED',

  /**
   * Error code indicating that getting the treatment for an experiment failed.
   *
   * @public
   */
  GET_EXPERIMENT_TREATMENT_FAILED = 'GET_EXPERIMENT_TREATMENT_FAILED',

  /**
   * Error code indicating that the experiment context is not ready.
   *
   * @public
   */
  EXPERIMENT_CONTEXT_IS_NOT_READY = 'EXPERIMENT_CONTEXT_IS_NOT_READY',

  /**
   * Error code indicating that the page experiments failed to load in the server.
   *
   * @public
   */
  PAGE_EXPERIMENTS_NOT_LOADED = 'PAGE_EXPERIMENTS_NOT_LOADED',

  /**
   * Error code indicating that the experiment is not found.
   * @public
   */
  EXPERIMENT_NOT_FOUND = 'EXPERIMENT_NOT_FOUND',

  /**
   * Error code indicating that the experiment variant is not found.
   *
   * @public
   */
  EXPERIMENT_VARIANT_NOT_FOUND = 'EXPERIMENT_VARIANT_NOT_FOUND',

  /**
   * Error code indicating that the current geo location can't be determined using the user IP address.
   *
   * @public
   */
  FETCH_LOCATION_BY_IP_FAILED = 'FETCH_LOCATION_BY_IP_FAILED',

  /**
   * Error indicating that the user segment can't be set.
   */
  SET_USER_EXPERIMENT_SEGMENT_FAILED = 'SET_USER_EXPERIMENT_SEGMENT_FAILED',
  READ_COOKIE_FAILED = 'READ_COOKIE_FAILED',

  /**
   * Error indicating that the log user can't be set.
   */
  SET_LOG_USER_FAILED = 'SET_LOG_USER_FAILED',

  /**
   * Error indicating that the log tags couldn't be added.
   */
  LOG_TAGS_NOT_ADDED = 'LOG_TAGS_NOT_ADDED',

  /**
   * Error indicating that the host was not read.
   */
  HOST_NOT_READ = 'HOST_NOT_READ',

  /**
   * Error indicating that the path was not read.
   */
  PATH_NOT_READ = 'PATH_NOT_READ',

  /**
   * Error indicating that a goal couldn't be tracked in the experiment service.
   */
  TRACK_GOAL_FAILED = 'TRACK_GOAL_FAILED',

  /**
   * Error indicating that the current logger didn't work.
   */
  LOG_INFO_FAILED = 'LOG_INFO_FAILED',

  /**
   * Error indicating that the current logger didn't work.
   */
  LOG_ERROR_FAILED = 'LOG_ERROR_FAILED',

  /**
   * Error indicating that the current logger didn't work.
   */
  EXPERIMENT_COULD_NOT_BE_LOADED = 'EXPERIMENT_COULD_NOT_BE_LOADED',

  /**
   * Error indicating that the current experimentation context didn't work or failed.
   */
  EXPERIMENTATION_FAILED = 'EXPERIMENTATION_FAILED',
}
