import type { StackProps } from '@mui/material/Stack';
import Stack from '@mui/material/Stack';

export function YCenteredLayout({ children, ...props }: StackProps) {
  return (
    <Stack
      flexDirection={'column'}
      justifyContent="center"
      height="100%"
      {...props}
    >
      {children}
    </Stack>
  );
}
