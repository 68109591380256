/**
 * Validates if the value is a string
 * @public
 *
 * @param arg - The argument to validate.
 *
 * @returns A boolean indicating if the arg is a String or not.
 *
 * @example
 * ```ts
 * const result = isString('string');
 * console.log(result);
 * // -> true
 * ```
 */
function isString<T>(arg: unknown): arg is T {
  return typeof arg === 'string';
}

export { isString };
