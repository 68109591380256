import { stringIsValidIsoUtcDate } from '@hc-frontend/core-utils-validations';

/**
 * Convert string 'YYYY-MM-DDTHH:MM:SS+00:00' or 'YYYY-MM-DDTHH:MM:SS.mmmZ' to us date format string 'MM/DD/YYYY'
 *
 * @public
 *
 * @param value - Date string in  iso utc 'YYYY-MM-DDTHH:MM:SS+00:00' or 'YYYY-MM-DDTHH:MM:SS.mmmZ' format
 *
 * @returns Date string in 'MM/DD/YYYY' format
 *
 * @remarks
 *
 * In order to keep consistency across all of our date functions, we need to add the current offset to the date provided by value.
 * If we dont' add the offset then we're going to have differences with locale times.
 *
 * @example
 * ```ts
 * const newDate = isoUtcStringDateToDate('1995-11-18T00:00:00.000+00:00');
 * console.log(newDate);
 * // -> 11/18/1995
 * ```
 */
function isoUtcStringDateToDateString(value: string): string | undefined {
  if (!stringIsValidIsoUtcDate(value)) return undefined;

  const date = new Date(value);

  const year = date.getFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return `${month}/${day}/${year}`;
}

export { isoUtcStringDateToDateString };
