import {
  ADDRESS_LINE_MAX_LENGTH,
  ADDRESS_LINE_REGEX,
  isValidUSPhone,
  ZIP_CODE_REGEX,
} from '@hc-frontend/core-utils-validations';

import type {
  DeliveryDetailsFormTranslations,
  DeliveryDetailsFormValidationRules,
} from './delivery-details-form.types';

export const defaultValues = {
  primaryAddress: '',
  secondaryAddress: '',
  city: '',
  state: '',
  zipCode: '',
  phoneNumber: '',
  instruction: '',
};

export const generateValidations = (
  translations: DeliveryDetailsFormTranslations,
): DeliveryDetailsFormValidationRules => ({
  primaryAddress: {
    required: {
      value: true,
      message: translations.address1RequiredError,
    },
    pattern: {
      value: ADDRESS_LINE_REGEX,
      message: translations.address1PatternError,
    },
    maxLength: {
      value: ADDRESS_LINE_MAX_LENGTH,
      message: translations.maxCharactersForAddress,
    },
  },

  secondaryAddress: {
    required: {
      value: false,
      message: '',
    },
    pattern: {
      value: ADDRESS_LINE_REGEX,
      message: translations.address2PatternError,
    },
    maxLength: {
      value: ADDRESS_LINE_MAX_LENGTH,
      message: translations.maxCharactersForAddress,
    },
  },

  city: {
    required: {
      value: true,
      message: translations.cityRequiredError,
    },
  },
  state: {
    required: {
      value: true,
      message: translations.stateRequiredError,
    },
  },
  zipCode: {
    required: {
      value: true,
      message: translations.zipRequiredError,
    },
    pattern: {
      value: ZIP_CODE_REGEX,
      message: translations.zipPatternError,
    },
  },
  phoneNumber: {
    required: {
      value: true,
      message: translations.phoneRequiredError,
    },
    validate: (v) => isValidUSPhone(v) || translations.phoneValidateError,
  },
  instruction: {
    required: {
      value: false,
      message: '',
    },
  },
});
