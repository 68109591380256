import Box from '@mui/material/Box';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

function PancakeStackLayout({ children }: Props) {
  return (
    <Box display="grid" gridTemplateRows="auto 1fr auto" minHeight="100vh">
      {children}
    </Box>
  );
}

export { PancakeStackLayout };
