import { showVariantToUser } from '@hc-frontend/deprecated-business';
import type {
  IBusinessEntity,
  IExperiment,
} from '@hc-frontend/deprecated-entities';
import { createContext, useContext } from 'react';

export const ExperimentsContext = createContext<{ [key: string]: IExperiment }>(
  {},
);

export function useTreatment<T>(
  options: {
    experimentName: string;
    variants: { [key: number]: T };
    control: T;
  },
  provider: Parameters<typeof showVariantToUser>[0],
): IBusinessEntity<T> {
  const runningExperiments = useContext(ExperimentsContext);

  const showVariant = showVariantToUser(
    provider,
    options.experimentName,
    runningExperiments,
    options.variants,
    options.control,
  );

  return showVariant.data;
}
