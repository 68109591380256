import { isValidDate } from '@hc-frontend/core-utils-validations';

import { getTodayDate } from '../get-today-date/get-today-date';

/**
 * Get age from birthDate up to current date
 * @public
 *
 * @param birthDate - Date to calculate age
 *
 * @returns The age corresponding to birthDate
 *
 * @example
 * ```ts
 * // todayDate -> '01/01/2021'
 * const age = dateToAge(new Date('01/01/1995'));
 * console.log(age);
 * // -> 26
 * ```
 */
function dateToAge(birthDate: Date): number {
  let age = 0;

  if (isValidDate(birthDate)) {
    const today: Date = getTodayDate();
    const diff: number = today.getTime() - birthDate.getTime();
    const ageDate: Date = new Date(diff);

    age = Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  return age;
}

export { dateToAge };
