/**
 * Get the prefix for environment.
 *
 * @public
 *
 * @example
 * ```ts
 * const url = geoLocationURI(EnvironmentPrefix.STG);
 * console.log(url); // https://geolocation.stg.healthcare.com
 * ```
 */
enum EnvironmentPrefix {
  QA = 'qa.',
  STG = 'stg.',
  LOCAL = 'qa.',
  LOCALHOST = 'qa.',
  PRD = '',
  DEV = 'dev.',
}

export { EnvironmentPrefix };
