import { xss } from '@hc-frontend/core-utils-format';
import type { IDictionary } from '@hc-frontend/core-utils-types';
import { DOMAIN_PARTS } from '@hc-frontend/core-utils-validations';

/**
 * Extract query parameters from a url in a safe manner, preventing xss attacks
 * @public
 *
 * @param url - An url to extract the parameters
 *
 * @returns The url safe parameters as a key-value dictionary.
 *
 * @example
 * ```ts
 * let parameters = getSafeURLParameters('?p.fname=fname&p.lname=lname')
 * console.log(parameters);
 * // -> {fname: 'fname',lname:'lname}
 * ```
 */
function getSafeURLParameters(
  url = window.location.search,
): IDictionary<string> {
  const result: IDictionary<string> = {};
  const stripURL = url.trim().split('#')[0];

  if (stripURL === '') {
    return result;
  }

  const parts = stripURL.match(DOMAIN_PARTS);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const queryString = parts![6];
  const searchParams = new URLSearchParams(queryString);

  searchParams.forEach((value, key) => {
    result[key.toLowerCase().replace('p.', '')] = xss(
      decodeURIComponent(value),
    );
  });

  return result;
}

export { getSafeURLParameters };
