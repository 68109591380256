import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { JSONObject } from '@hc-frontend/core-utils-types';
import type { LogRepository } from '@hc-frontend/deprecated-repositories';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';
import type { CreateRxCardRepository } from '@hc-frontend/shells-rx-cards-repositories';

import { buildAdapter } from '../../build-adapter/build-adapter';
import type {
  IRxCardApiDTO,
  IRxCardCaptchaDTO,
  IRxCardsServiceResponse,
} from '../../dto';
import {
  createCardDataIdFromCardDTO,
  createCardDTOFromCard,
} from '../../factory';

/**
 * Calls rx-cards api to create a new RX Card and then return an object with the dataId of the new card.
 *
 * @public
 *
 * @param basePath - Base path to rx-cards api.
 *
 * @param captchaToken - Token to validate captcha.
 *
 * @param card - Request to create rxCard.
 *
 * @returns Response with data id if operation is successful or array with errors if something went wrong.
 *
 * @example
 * ```ts
 * createRxCardApiAdapterFactory(provider, url, tokenOptions)(card);
 * ```
 *
 */
export function createRxCardApiAdapterFactory(
  provider: LogRepository,
  basePath: string,
  { captchaToken, captchaBypass }: Partial<IRxCardCaptchaDTO>,
) {
  return buildAdapter<
    CreateRxCardRepository<RxCardsErrorCodes.POST_RX_CARD>,
    IRxCardsServiceResponse<Pick<Required<IRxCardApiDTO>, 'hash'>>
  >(captchaToken)(
    provider,
    async function createRxCardApiAdapter(card) {
      const { parsedBody } = await fetchJSON<
        IRxCardsServiceResponse<Pick<Required<IRxCardApiDTO>, 'hash'>>
      >(`${basePath}/rx-cards/api/v1/card`, {
        body: createCardDTOFromCard(card) as unknown as JSONObject,
        queryParams: {
          captchaToken,
          captchaBypass,
        },
      });

      return parsedBody;
    },
    RxCardsErrorCodes.POST_RX_CARD,
    createCardDataIdFromCardDTO,
  );
}
