import type {
  DataExperiments,
  Smartly,
} from '@hc-frontend/core-third-party-absmartly';
import { initSmartly } from '@hc-frontend/core-third-party-absmartly';
import { getCookieValue } from '@hc-frontend/core-utils-dom';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import { useMemo } from 'react';

import { useAppConfig } from '../use-app-config';
import { useLogProvider } from '../use-log-provider';

let currentInstance: Smartly | undefined;

function resetSmartly() {
  currentInstance = undefined;
}

export function useSmartly(data?: DataExperiments): {
  smartly: { instance: Smartly };
  resetSmartly: () => void;
} {
  const {
    public: {
      absmartly: { apiKey, environment, applicationName, applicationVersion },
    },
  } = useAppConfig();
  const logger = useLogProvider();

  const smartly = useMemo(
    () => ({
      get instance() {
        if (!currentInstance) {
          currentInstance = initSmartly({
            eventLogger: (_, eventName, args) => {
              if (
                ['error', 'publish', 'exposure', 'goal'].includes(eventName)
              ) {
                if (eventName === 'error') {
                  logger.logError(ErrorCodes.EXPERIMENTATION_FAILED, {
                    cause: args as Error,
                  });
                } else {
                  logger.logInfo(eventName, args);
                }
              }
            },
            config: {
              apiKey,
              application: {
                name: applicationName,
                version: applicationVersion,
              },
              environment,
            },
            options: {
              userId: getCookieValue('healthcareUser'),
              sessionId: getCookieValue('healthcareSession'),
            },
            url: window.location.href,
            existingData: data,
          });
        }

        return currentInstance;
      },
    }),
    [apiKey, applicationName, applicationVersion, environment, logger, data],
  );

  const result = useMemo(() => ({ smartly, resetSmartly }), [smartly]);

  return result;
}
