import type { Nullable } from '@hc-frontend/core-utils-types';
import { isEmptyString } from '@hc-frontend/core-utils-validations';
import {
  createBusinessErrorOperationResult,
  createBusinessSuccessOperationResult,
} from '@hc-frontend/deprecated-business';
import type {
  IOperationResult,
  SessionId,
} from '@hc-frontend/deprecated-entities';
import type { IRxCardEntity } from '@hc-frontend/shells-rx-cards-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import { buildManagedValue } from '../build-managed-value/build-managed-value';

export function buildSessionId(
  sessionId?: Nullable<SessionId>,
): IOperationResult<IRxCardEntity<SessionId>, RxCardsErrorCodes> {
  if (isEmptyString(sessionId))
    return createBusinessErrorOperationResult(
      RxCardsErrorCodes.RX_CARDS_SESSION_ID_IS_REQUIRED,
    );

  const existingSessionId = sessionId as string;

  return createBusinessSuccessOperationResult(
    buildManagedValue(existingSessionId),
  );
}
