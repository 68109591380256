import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { PromNull } from '@hc-frontend/core-utils-types';

import { semContentStaticURI } from '../config';
import type { IFetchLowestPricesByStateResponse } from './fetch-lowest-prices-by-state-response';

/**
 * Fetch all possible locations that match the `zip` code
 *
 * @param payload - The payload to send to search ads endpoint
 * @returns The array of possible locations related with the zip, otherwise an empty array will be returned
 *
 * @public
 *
 * @example
 * ```ts
 * const locations = await fetchLowestPricesByStateService('3133');
 * ```
 */
async function fetchLowestPricesByStateService(
  state: string,
): PromNull<IFetchLowestPricesByStateResponse> {
  const url = `${semContentStaticURI()}/lowest/price/health/metalevel/${state}`;
  const { parsedBody } = await fetchJSON<IFetchLowestPricesByStateResponse>(
    url,
  );
  return parsedBody?.data ? parsedBody : null;
}

export { fetchLowestPricesByStateService };
