import { propAccessor } from '@hc-frontend/core-utils-object';
import type { IDictionary, PromNull } from '@hc-frontend/core-utils-types';
import type { ISession } from '@hc-frontend/deprecated-entities';

import type { ICreateSessionProps } from '../../../repository/update-session-repository';
import { getDeviceNameForSession } from '../../../use-cases/Session/get-device-name-for-session/get-device-name-for-session';

function createSessionV1(
  props: ICreateSessionProps,
  LoggedInAgentId: string,
): PromNull<ISession> {
  const params = props as unknown as IDictionary<unknown>;

  const iv = propAccessor(params, 'tracking.iv', '') as string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sessionPayload = {
    Partner: '',
    TrafficSource: propAccessor(params, 'tracking.utm_source', ''),
    BiddingToolId: iv,
    Email: props.email,
    Label: {
      Channel: 'SEM', // TODO: check this
      Domain: document.domain,
      Product: '', // getProduct(document.domain), // TODO: check this
      Src: propAccessor(params, 'tracking.src', ''),
      Source: document.referrer,
      SubId: propAccessor(params, 'tracking.subid', ''),
      Medium: propAccessor(params, 'tracking.utm_medium', ''),
      Keyword: propAccessor(params, 'tracking.utm_term', ''),
      Campaign: propAccessor(params, 'tracking.utm_campaing', ''),
      CampaignId: '',
      AdGroup: '',
      AdGroupId: '',
      AdId: '',
      LandingPage: window.location.href,
      MatchType: '',
      ImpactRadiusCampaignId: '',
      OptimizelyExperimentId: '',
      PublisherCampaignId: '',
      Ircid: propAccessor(params, 'tracking.ircid', ''),
      Gclid: propAccessor(params, 'tracking.gclid', ''),
      agent_id: propAccessor(params, 'tracking.agent_id', ''),
    },
    UserContext: {
      UserId: props.uid || '',
      ClientId: '',
      SessionId: props.sid || '',
      UserAgent: navigator.userAgent,
      IpAddress: '',
      Browser: navigator.appName,
      BrowserVersion: navigator.appVersion,
      OperatingSystem: navigator.platform,

      // TODO: check types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      OperatingSystemVersion: navigator.oscpu,

      Device: getDeviceNameForSession(iv),
      LoggedInAgentId,
    },
    FlowContext: {
      FlowId: '',
      ExperimentId: '',
      FlowVersion: '',
      ExperimentVariation: '',
      FlowStep: '',
    },
  };

  return Promise.resolve(null);
}

export { createSessionV1 };
