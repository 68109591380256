import { DATE_FORMAT_REGEX } from '../regex';

/**
 * Valid if a string date is valid, considering leap years
 *
 * @param date - The string date to validate
 * @returns If value is a valid date string then returns true otherwise returns false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = stringIsValidDate('2020-01-01');
 * console.log(result);
 * // -> false
 * ```
 */
function stringIsValidDate(date: string): boolean {
  if (!DATE_FORMAT_REGEX.test(date)) {
    return false;
  }

  const newDate = new Date(date);
  const [month, day, year] = date.split('/');

  return (
    newDate.getFullYear() === +year &&
    newDate.getMonth() === +month - 1 &&
    newDate.getDate() === +day
  );
}

export { stringIsValidDate };
