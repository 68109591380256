import type { IPureAsyncFunction } from '@hc-frontend/core-utils-types';
import {
  createBusinessSuccessOperationResult,
  overwriteError,
} from '@hc-frontend/deprecated-business';
import type { IOperationResult } from '@hc-frontend/deprecated-entities';
import type { IRxCardImage } from '@hc-frontend/shells-rx-cards-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

export interface FetchRxCardRepository {
  fetchRxCard: IPureAsyncFunction<
    [string],
    IOperationResult<IRxCardImage, RxCardsErrorCodes>
  >;
}

/**
 *
 * Get a Rx Card.
 *
 * @param repository - The repository instance with the adapter to use. See {@link fetchRxCardApiAdapter}
 *
 * @param cardId - Id of card.
 *
 * @returns The data from a card.
 *
 * @public
 *
 * @example
 * ```ts
 * await fetchRxCard(fetchRxCardApiAdapter, 'cardId');
 * ```
 */
async function fetchRxCard<T extends FetchRxCardRepository>(
  repository: T,
  cardId: string,
): Promise<IOperationResult<IRxCardImage, RxCardsErrorCodes>> {
  const result = await repository.fetchRxCard(cardId);

  if (result.success) return createBusinessSuccessOperationResult(result.data);

  return overwriteError(
    result,
    RxCardsErrorCodes.UNKNOWN_ERROR,
    RxCardsErrorCodes.GET_RX_CARD,
  );
}

export { fetchRxCard };
