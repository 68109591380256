import type {
  TransferSelectMemberFormTranslations,
  TransferSelectMemberFormValidationRules,
} from './transfer-select-member-form.types';

export const defaultValues = {
  userId: '',
};

export const generateValidations = (
  translations: TransferSelectMemberFormTranslations,
): TransferSelectMemberFormValidationRules => ({
  userId: {
    required: {
      value: true,
      message: translations.personRequiredError,
    },
  },
});
