import { AdTrackingPlugin } from '@snowplow/browser-plugin-ad-tracking';
import { ClientHintsPlugin } from '@snowplow/browser-plugin-client-hints';
import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from '@snowplow/browser-plugin-link-click-tracking';
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing';
import type {
  BrowserPlugin,
  BrowserTracker,
  ConditionalContextProvider,
  ContextPrimitive,
} from '@snowplow/browser-tracker';
import {
  addGlobalContexts,
  crossDomainLinker,
  newTracker,
  trackPageView,
} from '@snowplow/browser-tracker';

import { getCollector, getRootDomain } from '../url/url';
import type { CrossDomainLinkerCriterion } from './cross-domain-linker-criterion';
import { linksToHcDomainCriterion } from './cross-domain-linker-criterion';

interface InitSnowPlowTracker {
  isProd?: boolean;
  plugins?: BrowserPlugin[];
  crossDomainLinkerCriterion?: CrossDomainLinkerCriterion | null;
  contexts?: Array<ConditionalContextProvider | ContextPrimitive> | null;
}

interface ResponseSnowPlow {
  tracker: BrowserTracker;
  pageView: typeof trackPageView;
}

/**
 * Initialize the tracker
 * v.0.14.0;
 * @public
 *
 * @param config - SnowPlow configuration
 * @returns A Snowplow tracker
 *
 * @example
 * ```ts
 *
 * import { initSnowplowTracker, getHealthcareCollector } from '@hc-frontend/core-third-party-snowplow';
 *
 * initSnowplowTracker({
 *   appId: 'healthcare.com',
 *   collector: getHealthcareCollector({ isProd: true }),
 *   crossDomainLinkerCriterion: linkElement => linkElement.getAttribute("data-is-cross-domain") === "true"
 *   contexts: [{ schema: "iglu:com.healthcare/context_schema/jsonschema/1-0-0", data: { key: "value" } }]
 * });
 * ```
 */
function initSnowplowTracker({
  isProd = false,
  plugins = [],
  crossDomainLinkerCriterion = linksToHcDomainCriterion,
  contexts = [],
}: InitSnowPlowTracker): ResponseSnowPlow {
  const currentPlugins = [
    LinkClickTrackingPlugin(),
    PerformanceTimingPlugin(),
    ClientHintsPlugin(),
    AdTrackingPlugin(),
  ];

  const appId = getRootDomain();
  const collector = getCollector(window.location.hostname, isProd);

  const tracker = newTracker('sp', collector, {
    appId,
    discoverRootDomain: true,
    cookieSameSite: 'Lax',
    plugins: currentPlugins.concat(plugins),
  });

  enableLinkClickTracking({ pseudoClicks: true, trackContent: true });
  if (crossDomainLinkerCriterion != null)
    crossDomainLinker(crossDomainLinkerCriterion);

  if (contexts != null && contexts.length > 0) addGlobalContexts(contexts);

  return { tracker, pageView: trackPageView };
}

export { initSnowplowTracker };
