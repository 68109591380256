import type { RaygunV2 } from 'raygun4js';

import type { SerializedRaygunBrowserOptions } from './raygun-browser.types';

declare global {
  interface Window {
    /**
     * Raygun4JS global object, loaded from the Raygun CDN. The instance can be undefined depending on the loading state.
     *
     * @public
     */
    rg4js?: RaygunV2;
  }
}

/**
 * Function to generate the Raygun script using CDN, enabling tracking for errors when app is loading.
 *
 * @param options - Raygun options to be used when initializing the Raygun script. It should be a valid JS code string.
 *
 * @returns A string containing the Raygun script that needs to be placed in a script tag.
 *
 * @public
 *
 * @example
 * ```ts
 * const scriptCode = generateRaygunScript(buildDefaultOptions({
 *      apiKey: '1234',
 *      version: '1.0.0',
 *      enableCrashReporting: true,
 *      tags: [
 *        'company:healthcare',
 *        'service:rx-cards',
 *        'page:/',
 *        'site:healthcare.com',
 *        'env:DEV',
 *        'tag:value',
 *      ],
 *      saveIfOffline: true,
 *    }));
 *
 * <script dangerouslySetInnerHTML={{ __html: scriptCode }} type='text/javascript' />
 * ```
 */
export function generateRaygunScript(
  options: SerializedRaygunBrowserOptions,
): string {
  return `!function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
    (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
    f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
    h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
    e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");

    ${options}
    `;
}

/**
 * Local queue to store events if Raygun is not loaded yet.
 *
 * @internal
 *
 */
const queue: Array<Parameters<RaygunV2>> = [];

/**
 * Function to exhaust the local queue and send all events to Raygun.
 *
 * @internal
 */
function exhaustQueue() {
  if (typeof window !== 'undefined' && typeof window.rg4js === 'function') {
    const rg4js = window.rg4js;
    queue.forEach((savedArgs) => rg4js(...savedArgs));
    queue.length = 0;
  }
}

let timeoutId: NodeJS.Timeout | undefined;

/**
 * Function to flush the queue after a timeout. This is used to avoid sending too many events to Raygun at the same time.
 *
 * @returns The timeout id to be used to clear the timeout.
 */
function withExhaustQueue(callback: RaygunV2): RaygunV2 {
  clearTimeout(timeoutId);

  return ((...args: Parameters<RaygunV2>): ReturnType<RaygunV2> => {
    const result = callback(...args);

    timeoutId = setTimeout(exhaustQueue, 1000);

    return result;
  }) as RaygunV2;
}

function pushToQueue(...args: Parameters<RaygunV2>): ReturnType<RaygunV2> {
  queue.push(args);
}

/**
 * Callback to be used for invoking Raygun. If Raygun is not loaded yet, the event will be stored in a local queue and
 * the queue will be flushed after a timeout.
 *
 * @returns A function to be used to send events to Raygun.
 */
export function getBrowserRaygun(): RaygunV2 {
  if (typeof window !== 'undefined' && typeof window.rg4js === 'function') {
    return withExhaustQueue(window.rg4js);
  } else {
    return withExhaustQueue(pushToQueue as RaygunV2);
  }
}
