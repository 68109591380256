import { getSafeURLParameters } from '@hc-frontend/core-utils-dom';
import type { PlainObject } from '@hc-frontend/core-utils-types';

export const experimentRegex = /^(EXP-)/i;

/**
 * Gets values from the URI provided and store them in an object.
 *
 * @internal
 *
 * @param url - Optional URL, otherwise current window.location object will be used.
 *
 * @returns An object with all URL parameters as key with their respective values.
 *
 * @example
 * ```ts
 * getExperimentUrlParams('http://localhost:4200/welcome?EXP-123=1&EXP-321=0&custom-param=any');
 * // => {'EXP-123': '1', 'EXP-321': '0'}
 * ```
 */
export function getExperimentUrlParams(url: string): PlainObject {
  const expParams: PlainObject = {};
  const rawParams = getSafeURLParameters(url);
  const result = Object.keys(rawParams).reduce((actual, currentKey) => {
    if (experimentRegex.test(currentKey)) {
      return Object.assign(actual, {
        [currentKey.toUpperCase()]: rawParams[currentKey],
      });
    }

    return actual;
  }, expParams);
  return result;
}
