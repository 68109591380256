import MenuIcon from '@mui/icons-material/Menu';
import { default as MuiAppBar } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { DRAWER_WIDTH, useDrawer } from '../drawer';
import type { AppBarProps, StyleAppBarParams } from './app-bar.types';

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: string) => prop !== 'open',
})<AppBarProps>(({ theme, open }: StyleAppBarParams) => {
  return {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: `${DRAWER_WIDTH}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

type AppBarAdminProps = {
  title: string;
  children?: JSX.Element;
};

function AppBar({ title, children }: AppBarAdminProps) {
  const { isMenuOpen, toggleMenu } = useDrawer();

  return (
    <StyledAppBar position="fixed" open={isMenuOpen}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleMenu}
          edge="start"
          sx={{ mr: 2, ...(isMenuOpen && { display: 'none' }) }}
          data-testid="app-bar-menu-button"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {title}
        </Typography>
        {children}
      </Toolbar>
    </StyledAppBar>
  );
}

export { AppBar };
