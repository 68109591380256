import { filterXSS } from 'xss';

/**
 * Sanitize untrusted HTML (to prevent XSS).
 *
 * @param str - string to sanitize
 *
 * @returns sanitized string
 *
 * @example
 * ```ts
 * const result = xss('<script>alert("xss")</script>');
 * console.log(result);
 * // => '&lt;script&gt;alert("xss")&lt;/script&gt;
 * ```
 */
function xss(str: string): string {
  return filterXSS(str);
}

export { xss };
