/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/util-random` is a package containing general purpose
 *  utilities that do not fit on any of the other categories.
 *
 * @packageDocumentation
 */

export * from './constants';
export * from './generate-v4-uuid/generate-v4-uuid';
