/**
 * @remarks
 * @example How to use command queue?
 * ```html
 * <script>
 * // init global object
 * window.ph=window.ph||function(){(ph.q=ph.q||[]).push(arguments)};ph.l=+new Date;
 *
 * //dispatch command to the global object
 * ph('salesforce.lead.send');
 * </script>
 * ```
 *
 * by default 'ph' is used as global object
 * we can use PivotHealthAnalyticsObject to rename the object
 *
 * @example Rename PivotHealthAnalyticsObject
 * ```ts
 * window.PivotHealthAnalyticsObject = 'anotherName'
 * window.anotherName=window.anotherName||function(){(anotherName.q=anotherName.q||[]).push(arguments)};anotherName.l=+new Date;
 * anotherName('command');
 * ```
 *
 * @packageDocumentation
 */

import type { IDictionary } from '@hc-frontend/core-utils-types';
import {
  isArray,
  isFunction,
  isString,
} from '@hc-frontend/core-utils-validations';

declare global {
  interface Window {
    PivotHealthAnalyticsObject?: unknown;
  }
}

interface HandleCommand {
  (...args: unknown[]): void;
}

interface CommandQueue {
  globalObject: (...arg: unknown[]) => void;
  registerCommand(commandName: string, handleCommand: HandleCommand): void;
  runCommands(): void;
}

/**
 * @internal
 *
 * @param commandLog - Dictionary of commands registered
 * @param args - Array of arguments passed to the global object
 *
 */
function executeCommand(
  commandLog: IDictionary<HandleCommand>,
  [command, ...arg]: unknown[],
): void {
  if (isFunction(command) && commandLog['default']) {
    commandLog['default'](command, ...arg);
  }

  if (isString<string>(command) && commandLog[command]) {
    commandLog[command](...arg);
  }
}

/**
 * Create a command queue to execute commands from the global object
 * @public
 *
 * @param defaultName - Global object name used to interact with the commands
 *
 * @returns CommandQueue instance
 *
 * @example
 * ```ts
 * import { createCommandQueue } from '@hc-healthcare/util-storage'
 *
 * const { runCommands, registerCommand, globalObject } = createCommandQueue('ph');
 *
 * registerCommand('salesforce.lead.send', salesforceLeadSendCommand);
 * registerCommand('salesforce.opportunity.send', salesforceOpportunitySendCommand);
 *
 * // command to execute when called as follows ph(() => {})
 * registerCommand('default', defaultCommand);
 *
 * runCommands();
 * ```
 */
function createCommandQueue(defaultName = 'ph'): CommandQueue {
  const globalPivotObjectName = window.PivotHealthAnalyticsObject;

  const globalObjectName = isString<string>(globalPivotObjectName)
    ? globalPivotObjectName.replace(/^[\s\xa0]+|[\s\xa0]+$/g, '') // remove any dangerous whitespace
    : defaultName;

  // TODO: check if this is the correct way to handle the global object
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!isFunction(window[globalObjectName])) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[globalObjectName] = (...arg: unknown[]) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      globalObject.q.push(arg);
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const globalObject = window[globalObjectName];
  const isArr = isArray(globalObject.q);
  globalObject.q = isArr ? globalObject.q : [];

  const commandLog: IDictionary<HandleCommand> = {};

  return {
    globalObject,
    registerCommand(commandName: string, handleCommand: HandleCommand) {
      commandLog[commandName] = handleCommand;
    },
    runCommands() {
      globalObject.q.forEach((arg: unknown[]) => {
        executeCommand(commandLog, arg);
      });

      globalObject.q = {
        push: function push(args: unknown[]): void {
          executeCommand(commandLog, args);
        },
      };
    },
  };
}

export { createCommandQueue };
