const toDataURL = (url: string, token?: string) => {
  const headers = token
    ? {
        authorization: `Bearer ${token}`,
      }
    : undefined;

  return fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    headers,
  })
    .then((response: Response) => {
      if (response.ok) {
        return response.blob();
      }
      throw new Error('unavailable');
    })
    .then((blob) => URL.createObjectURL(blob));
};

export async function downloadBase64File(
  base64Data: string,
  fileName: string,
  token?: string,
): Promise<void | Error> {
  const downloadLink = document.createElement('a');
  const blobImage = await toDataURL(base64Data, token);
  downloadLink.href = blobImage;
  downloadLink.download = fileName;
  downloadLink.click();
}
