/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  AdapterBuilderRepository,
  AdapterImplementation,
  AdapterImplementationResult,
} from '@hc-frontend/deprecated-repositories';

export function buildAdapter<
  T extends AdapterImplementation,
  TDto,
>(): AdapterBuilderRepository<
  T,
  unknown,
  [mapper: (result: NonNullable<TDto>) => AdapterImplementationResult<T>],
  TDto,
  ErrorCodes.NO_RESPONSE
> {
  return (provider, adapter, displayError, mapper) =>
    async (...args) => {
      try {
        const result = await adapter(...args);

        if (!result) {
          const message = 'Response was successful but empty.';
          const details = `${adapter.name} (Failed). Reason: ${message}`;

          provider.logError(displayError as string, {
            cause: new Error(details),
          });
          return {
            data: undefined,
            errors: [
              {
                message: ErrorCodes.NO_RESPONSE,
                details,
              },
            ],
            success: false,
          };
        }

        const mappedResult = await mapper(result as NonNullable<TDto>);

        return {
          data: mappedResult,
          errors: [],
          success: true,
        };
      } catch (error) {
        const details = `${adapter.name} (Failed). Reason: ${
          (error as Error).message
        }`;

        provider.logError(displayError as string, {
          cause: new Error(details),
        });
        return {
          data: undefined,
          errors: [
            {
              message: displayError,
              details,
            },
          ],
          success: false,
        };
      }
    };
}
