import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type {
  CollapsePanelProps,
  ExpandIconButtonCss,
  ExpandIconButtonProps,
} from './collapse-panel.types';

export const ExpandIconButton = styled((props: ExpandIconButtonProps) => {
  const { expand, testId, ...other } = props;
  return <IconButton data-testid={testId} sx={{ padding: 0 }} {...other} />;
})(({ theme, expand }: ExpandIconButtonCss) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(90deg)',
  margin: 0,
  marginRight: theme.spacing(3),
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const CollapsePanel = ({
  expanded,
  handleClick,
  icon = <ChevronRight />,
  title,
  children,
}: CollapsePanelProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          my: 5,
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <ExpandIconButton
          testId="expand-current-orders"
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {icon}
        </ExpandIconButton>
        <Typography variant="paragraphMD" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <Collapse
        in={expanded}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
      <Divider />
    </>
  );
};
