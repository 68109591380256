/**
 * List of device names
 *
 * @public
 */
export enum DeviceName {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}
