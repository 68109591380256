import type {
  IErrorOperationResult,
  IOperationResult,
} from '@hc-frontend/deprecated-entities';
import { isNil, reject } from 'ramda';

/**
 * Overwrite a given generic error with a more generic error
 *
 * @public
 *
 * @param result - An operation result with errors
 *
 * @param unknownError - An string identifying an unknown error
 *
 * @param fallbackError - An more specific error for substituting the unknown error
 *
 * @returns An operation result with the errors replaced
 *
 * @example
 *
 * ```ts
 * overwriteError(
 *   {
 *     errors: [
 *       {
 *         message: 'REPLACE_ME',
 *       },
 *     ],
 *     success: false,
 *     data: undefined,
 *   },
 *   'REPLACE_ME',
 *   'CONTROLLED_ERROR',
 * );
 * // -> ...errors: [
 * //     {
 * //       message: 'CONTROLLED_ERROR',
 * //     },
 * //   ]
 * ```
 */
export function overwriteError<T, E extends string>(
  result: IOperationResult<T, E>,
  unknownError: E,
  fallbackError: E,
): IErrorOperationResult<E> {
  return {
    data: undefined,
    success: false,
    errors: result.errors.map((error) =>
      error.message === unknownError
        ? {
            ...reject(isNil)(error),
            message: fallbackError,
          }
        : error,
    ),
  };
}
