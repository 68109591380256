import { DialogHC } from '@hc-frontend/core-ui-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { InfoDialogProps } from './info-dialog.types';

function InfoDialog({
  dataTestId = 'info-dialog',
  handleClose,
  isTitleBlue,
  subTitle,
  subTitleProps,
  title,
  titleProps,
  open,
  ...props
}: InfoDialogProps): JSX.Element {
  const theme = useTheme();

  return (
    <DialogHC open={open} onClose={handleClose} {...props}>
      <Box
        pt={{ sm: theme.spacing(5), xs: theme.spacing(2) }}
        pb={{ sm: theme.spacing(5), xs: theme.spacing(2) }}
        pr={{ xs: theme.spacing(6) }}
        pl={{ xs: theme.spacing(6) }}
        data-testid={dataTestId}
      >
        <Grid component={Box} item xs={12} my={{ sm: 2, xs: 4 }}>
          <Typography
            sx={{ color: isTitleBlue ? theme.palette.primary.main : '' }}
            variant={isTitleBlue ? 'headingTwo' : 'headingOne'}
            fontWeight={isTitleBlue ? 'bold' : ''}
            align="center"
            {...titleProps}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="paragraphMD" align="center" {...subTitleProps}>
            {subTitle}
          </Typography>
        </Grid>
      </Box>
    </DialogHC>
  );
}

export { InfoDialog };
