/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  ABSmartlyInitOptions,
  ABSmartlySDKConfig,
  DataExperiments,
  EventLoggerCallback,
  Experiment,
  SDKContext,
  SDKContextOptions,
  SDKContextParams,
} from '@absmartly/javascript-sdk';
import type { JSONValue, Optional } from '@hc-frontend/core-utils-types';

export type ABSmartlyApplicationConfig = Required<
  Pick<ABSmartlySDKConfig, 'environment'> &
    Pick<SDKContextOptions, 'apiKey' | 'application'>
>;

export interface Smartly {
  /**
   * @deprecated - Let the dev decide when to track
   */
  isReady: boolean;
  context: SDKContext;
  config: ABSmartlySDKConfig;
  request: SDKContextParams;
}

export type SmartlySettings = {
  options: ABSmartlyInitOptions;
  config: ABSmartlyApplicationConfig;
  eventLogger: EventLoggerCallback<Optional<SDKContext>>;
  url?: string;
  existingData?: DataExperiments;
};

export type RunningExperiments = {
  experiment: Experiment;
  variant: number;
};

export type TrackOptions<T extends JSONValue> = {
  experimentName: string;
  goal: string;
  args?: T;
};

export enum ExperimentActions {
  Track = 'TRACK',
}

export type ExperimentActionArgs<T extends JSONValue> = {
  id: string;
  type: ExperimentActions;
  data?: T;
};
