import type { BuildEventProps } from './snowplow-events.types';
import { Placement } from './snowplow-events.types';

const CATEGORY = 'Companion';
const CLICK_ACTION = 'click';

const UPLOAD_PHOTO_LABEL = 'Upload Photos';
const UPLOAD_FRONT_LABEL = 'Upload Front';
const UPLOAD_BACK_LABEL = 'Upload Back';
const SUBMIT_LABEL = 'Submit';

const NEXT_LABEL = 'Next';

const buildEvent = ({
  category = CATEGORY,
  placement = Placement.bottom,
  action = CLICK_ACTION,
  label,
  value,
  elementId,
  targetUrl,
  description,
}: BuildEventProps) => {
  return {
    category,
    placement,
    action,
    label,
    value,
    elementId,
    targetUrl,
    description,
  };
};

// ---------------------
// Create account events
// ---------------------
export const createAccountEvent = buildEvent({
  label: 'Create Account',
  value: 'user_id',
  elementId: 'create_acct',
  targetUrl: '/registration',
  description:
    'Create an event when user clicks on Create Account button from Get Started page',
});

// ---------------------
// Click button to upload photos events
// ---------------------
export const clickUploadInsurancePhotoEvent = buildEvent({
  label: UPLOAD_PHOTO_LABEL,
  value: 'Upload Insurance ID',
  elementId: 'upload_ins_id',
  description: 'Create an event when user clicks on Upload Insurance Photos',
});

export const clickUploadIdPhotoEvent = buildEvent({
  label: UPLOAD_PHOTO_LABEL,
  value: 'Upload Personal ID',
  elementId: 'upload_prsnl_id',
  description: 'Create an event when user clicks on Upload Personal ID Photos',
});

// ---------------------
// Submit a photo events
// ---------------------

export const submitUploadInsurancePhotoEvent = buildEvent({
  placement: Placement.modal,
  label: SUBMIT_LABEL,
  value: 'Submit Ins ID',
  elementId: 'submit_upload_insurance',
  description:
    'Create an event when user clicks on Submit to Upload Insurance ID',
});

export const submitUploadIdPhotoEvent = buildEvent({
  placement: Placement.modal,
  label: SUBMIT_LABEL,
  value: 'Submit Personal ID',
  elementId: 'submit_upload_prsnlid',
  description:
    'Create an event when user clicks on Submit to Upload Insurance ID',
});

// ---------------------
// Upload a photo events
// ---------------------

export const uploadFrontInsurancePhotoEvent = buildEvent({
  placement: Placement.modal,
  label: UPLOAD_FRONT_LABEL,
  value: 'Upload Front Ins ID',
  elementId: 'upload_frnt_ins_id',
  description:
    'Create an event when user uploads the front of the Insurance ID',
});

export const uploadBackInsurancePhotoEvent = buildEvent({
  placement: Placement.modal,
  label: UPLOAD_BACK_LABEL,
  value: 'Upload Back Ins ID',
  elementId: 'upload_bck_ins_id',
  description: 'Create an event when user uploads the back of the Insurance ID',
});

export const uploadFrontIdPhotoEvent = buildEvent({
  placement: Placement.modal,
  label: UPLOAD_FRONT_LABEL,
  value: 'Upload Front Personal ID',
  elementId: 'upload_frnt_prsnl_id',
  description: 'Create an event when user uploads the front of the Personal ID',
});

export const uploadBackIdPhotoEvent = buildEvent({
  placement: Placement.modal,
  label: UPLOAD_BACK_LABEL,
  value: 'Upload Back Personal ID',
  elementId: 'upload_bck_prsnl_id',
  description: 'Create an event when user uploads the back of the Personal ID',
});

// ---------------------
// Request a prescription events
// ---------------------

export const requestAPrescriptionEvent = buildEvent({
  label: 'Request a prescription',
  value: 'user_id',
  elementId: 'request_prescription',
  targetUrl: '/request-rx',
  description: 'Create an event when request prescription is clicked',
});

export const getStartedTransferEvent = buildEvent({
  label: 'Get started',
  value: 'user_id',
  elementId: 'get_started',
  targetUrl: '/transfer-rx',
  description: 'Create an event when request prescription is clicked',
});

// Next Events

export const addPharmacyNextEvent = buildEvent({
  label: NEXT_LABEL,
  value: 'Add Pharmacy',
  elementId: 'add_pharmacy_next',
  description:
    'Create an event when user clicks on Next from Add Pharmacy page',
});

export const addPrescriberNextEvent = buildEvent({
  label: NEXT_LABEL,
  value: 'Add Prescriber',
  elementId: 'add_prescriber_next',
  description:
    'Create an event when user clicks on Next from Add Prescriber page',
});

export const addMedicationNextEvent = buildEvent({
  label: NEXT_LABEL,
  value: 'Add Medication',
  elementId: 'add_medication_next',
  description:
    'Create an event when user clicks on Next from Add Medications page',
});

export const addHealthAllergiesNextEvent = buildEvent({
  label: NEXT_LABEL,
  value: 'Add Health-Allergies',
  elementId: 'health_allergies_next',
  description:
    'Create an event when user clicks on Next from Health_Allergies page',
});

export const addInsuranceInfoNextEvent = buildEvent({
  label: NEXT_LABEL,
  value: 'Add Insurance Info',
  elementId: 'insurance_info_next',
  description:
    'Create an event when user clicks on Next from Insurance Info page',
});

export const submitRxTransferEvent = buildEvent({
  label: 'Submit Rx transfer',
  value: 'user_id',
  elementId: 'submit_rx_transfer',
  targetUrl: '/home',
  description:
    'Create an event when user clicks on Next from Submit Rx review page',
});

// ---------------------
// Order refill events
// ---------------------

export const orderRefillEvent = buildEvent({
  label: 'Order refill',
  value: 'prescription_number-refill_number',
  elementId: 'medications_order_refill',
  targetUrl: '/home',
  description: 'Create an event when Order Refill is clicked from Orders tab',
});

export const refillOrderEvent = buildEvent({
  label: 'Refill order',
  value: 'prescription_number-refill_number',
  elementId: 'home_refill_order',
  targetUrl: '/home',
  description: 'Create an event when Refill order is clicked from Home tab',
});
