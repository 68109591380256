import { ExperimentsContext } from '@hc-frontend/core-ui-components';
import type { IExperiment } from '@hc-frontend/deprecated-entities';

export function ExperimentProvider({
  children,
  runningExperiments,
}: React.PropsWithChildren<
  Record<'runningExperiments', { [key: string]: IExperiment }>
>) {
  return (
    <ExperimentsContext.Provider value={runningExperiments}>
      {children}
    </ExperimentsContext.Provider>
  );
}
