import { forwardRef } from 'react';
import type { NumberFormatProps } from 'react-number-format';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  placeholder?: string;
  customInput?: React.FunctionComponent;
  value?: number | string;
}

export const DateInputFormat = forwardRef<NumberFormat<unknown>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format="##/##/####"
        mask="_"
        getInputRef={ref}
        placeholder={props.placeholder}
        customInput={props.customInput}
        value={props.value}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.formattedValue,
            },
          });
        }}
      />
    );
  },
) as React.FunctionComponent<CustomProps & NumberFormatProps>;
