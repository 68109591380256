import type {
  AdapterBuilderRepository,
  AdapterImplementation,
} from '@hc-frontend/deprecated-repositories';

export function buildAdapter<
  T extends AdapterImplementation,
>(): AdapterBuilderRepository<T> {
  return (provider, adapter, displayError) =>
    async (...args) => {
      try {
        const result = await adapter(...args);

        return {
          data: result,
          errors: [],
          success: true,
        };
      } catch (error) {
        const details = `${adapter.name} (Failed). Reason: ${
          (error as Error).message
        }`;

        provider.logError(displayError as string, {
          cause: new Error(details),
        });
        return {
          data: undefined,
          errors: [
            {
              message: displayError,
              details,
            },
          ],
          success: false,
        };
      }
    };
}
