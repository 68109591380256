import type {
  IAdapterResponse,
  IGlobalLocation,
  IZipLocation,
  Zip,
} from '@hc-frontend/deprecated-entities';
import {
  AdapterResponseStatus,
  ErrorCodes,
  LocationType,
} from '@hc-frontend/deprecated-entities';
import {
  fetchAllLocationsByZipService,
  fetchLocationByIpService,
} from '@hc-frontend/deprecated-services';
import { always, applySpec, map, prop } from 'ramda';

import type {
  ILocationByIpRepository,
  ILocationByZipRepository,
} from '../../../repository/location-repository';

const createGlobalLocation = applySpec<IGlobalLocation>({
  zip: prop('postal'),
  stateAcronym: prop('region_code'),
  stateName: prop('region'),
  city: prop('city'),
  county: prop('county'),
  country: prop('country'),
  type: always(LocationType.GEO),
});

const createZipLocations = map(
  applySpec<IZipLocation>({
    zip: prop('zipCode'),
    stateAcronym: prop('stateAcronym'),
    stateName: prop('stateName'),
    stateId: prop('stateId'),
    city: prop('city'),
    countyId: prop('countyId'),
    county: prop('countyName'),
    type: always(LocationType.ZIP),
  }),
);

const locationRepositoryByIpV1 = <ILocationByIpRepository>{
  async fetchLocationByIp(): Promise<IAdapterResponse<IGlobalLocation>> {
    const response = await fetchLocationByIpService();

    if (response.parsedBody) {
      return {
        data: createGlobalLocation(response.parsedBody),
        status: AdapterResponseStatus.Ok,
        errors: [],
      };
    }

    return {
      data: undefined,
      status: AdapterResponseStatus.NoResponse,
      errors: [
        {
          message: ErrorCodes.NO_RESPONSE,
        },
      ],
    };
  },
};

const locationRepositoryZipIpV1 = <ILocationByZipRepository>{
  async fetchAllLocationsByZip(
    zip: Zip,
  ): Promise<IAdapterResponse<IZipLocation[]>> {
    const response = await fetchAllLocationsByZipService(zip);

    if (response.parsedBody) {
      return {
        data: createZipLocations(response.parsedBody),
        status: AdapterResponseStatus.Ok,
        errors: [],
      };
    }

    return {
      data: undefined,
      status: AdapterResponseStatus.NoResponse,
      errors: [
        {
          message: ErrorCodes.NO_RESPONSE,
        },
      ],
    };
  },
};

export { locationRepositoryByIpV1, locationRepositoryZipIpV1 };
