import { isEmptyString } from '@hc-frontend/core-utils-validations';
import type {
  IBusinessEntity,
  IOperationResult,
  ISessionId,
} from '@hc-frontend/deprecated-entities';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';

import {
  createBusinessErrorOperationResult,
  createBusinessSuccessOperationResult,
} from '../../../helpers';
import { buildManagedValue } from '../build-managed-value/build-managed-value';

/**
 * Build and validate the session by checking that the user id and session id are not empty. If they are empty, then the session is invalid.
 *
 * @param session - The current session with the user id and session id. This can be a partial object or containing empty values.
 *
 * @public
 *
 * @returns an IOperationResult indicating if the session is valid or not.
 *
 */
export async function buildExistingSessionId(
  session: Partial<ISessionId>,
): Promise<IOperationResult<IBusinessEntity<ISessionId>, ErrorCodes>> {
  const { userId, sessionId } = session;

  if (isEmptyString(userId) || isEmptyString(sessionId)) {
    return createBusinessErrorOperationResult(ErrorCodes.INVALID_SESSION_DATA);
  }

  return createBusinessSuccessOperationResult(
    buildManagedValue({
      userId,
      sessionId,
    }),
  );
}
