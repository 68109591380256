import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type { LogErrorRepository } from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';
import type { RaygunAdapterOptions } from '../types';

export function raygunLogErrorAdapterFactory<E>({
  raygun,
  rg4js,
  isLocal,
}: RaygunAdapterOptions) {
  return buildAdapter<LogErrorRepository<E>, ErrorCodes.LOG_ERROR_FAILED>(
    function raygunLogErrorAdapter(reason, options) {
      const error = new Error(
        `${reason}. ${(options.operationErrors || [])
          ?.map((e) => `Details='${JSON.stringify(e)}'`)
          .join(', ')}`,
      );

      if (rg4js) {
        rg4js('send', error);
      }

      if (raygun) {
        raygun.send(error);
      }

      return Promise.resolve();
    },
    isLocal,
    ErrorCodes.LOG_ERROR_FAILED,
    console.error,
  );
}
