import type { DefaultValue, Nullable } from '@hc-frontend/core-utils-types';
import { isDefined } from '@hc-frontend/core-utils-validations';

/**
 * Loads an object from the web storage
 * @alpha
 *
 * @typeParam TObject - the type of the object to be loaded
 * @typeParam TDefault - the type of the object to be loaded, this is used to infer the type of the `defaultValue` so we make sure to always return a value
 *
 * @param key - The key identifying the object in the storage
 * @param defaultValue - A default value to set in case the object does not exist in the storage
 *
 * @returns The object loaded from the storage if found or `defaultValue` if was provided, else null
 *
 * @example
 * ```ts
 * const unknownObject = loadObject('unknown?'); // unknownObject is unknown
 * const alwaysObject = loadObject('noNullAssertionNeeded', {a:'b'}); // alwaysObject is expected to always have a value in the form of defaultValue
 * const possibleNullObject = loadObject<{a:string}>('nullAssertionNeeded'); // possibleNullObject is expected to have a value in the form of defaultValue or null
 * ```
 */
function loadObject<TObject, TDefault extends TObject | undefined = undefined>(
  key: string,
  defaultValue?: TDefault,
): DefaultValue<TObject, TDefault> {
  const result: Nullable<TObject> = JSON.parse(
    localStorage.getItem(key) ?? 'null',
  );

  if (isDefined(result)) return result as DefaultValue<TObject, TDefault>;

  if (isDefined(defaultValue))
    return defaultValue as DefaultValue<TObject, TDefault>;

  return null as DefaultValue<TObject, TDefault>;
}

export { loadObject };
