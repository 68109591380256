import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ParseUserAgentRepository,
} from '@hc-frontend/deprecated-repositories';
import type { NextRequest } from 'next/server';
import { UAParser } from 'ua-parser-js';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextParseUserAgentAdapterFactory(
  provider: LogRepository,
  request: NextRequest,
) {
  return buildAdapter<
    ParseUserAgentRepository<ErrorCodes.PARSING_USER_AGENT_FAILED>
  >()(
    provider,
    function nextParseUserAgentAdapter() {
      const parser = new UAParser(request.headers.get('user-agent') || '');
      const device = parser.getDevice();

      return Promise.resolve({
        device:
          device.type !== 'mobile' && device.type !== 'tablet'
            ? 'desktop'
            : device.type,
      });
    },
    ErrorCodes.PARSING_USER_AGENT_FAILED,
  );
}
