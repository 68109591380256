export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return null;
  }
};

// copy of isValidUrl to remove extra dependencies on myget
export function getRootDomain(hostname?: string): string {
  const url = hostname || window.location.origin;
  if (isValidUrl(url)) {
    const domain = new URL(url);
    const m = domain.hostname.match(
      /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/,
    );
    if (m) {
      return m[1];
    }
  }
  return '';
}

export function isPharmacy(origin: string) {
  return origin.split('.').some((part) => part === 'pharmacy');
}

export function getCollector(origin: string, isProd: boolean) {
  const appId = getRootDomain();

  if (isPharmacy(origin)) {
    return isProd
      ? 'collector.pharmacy.healthcare.com'
      : 'collector.dev.pharmacy.healthcare.com';
  }

  return `sp.${isProd ? '' : `dev.`}${appId}`;
}

type UrlParts = {
  hostname: string;
  path: string;
  query: string;
};

export function getUrlParts(url: string): UrlParts {
  const { hostname, pathname, search } = new URL(url);
  return {
    hostname,
    path: pathname,
    query: search.length > 0 ? search.substring(1) : search,
  };
}
