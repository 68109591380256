import { sendAnalytics } from '@hc-frontend/core-utils-net';
import type { IPureFunction, JSONValue } from '@hc-frontend/core-utils-types';
import { useCallback } from 'react';

import type { ExperimentActionArgs, TrackOptions } from '../types';
import { ExperimentActions } from '../types';

export function useTrack<T extends JSONValue>({
  args,
  experimentName,
  goal,
}: TrackOptions<T>): IPureFunction<[T?], void> {
  const trackCallback = useCallback(
    (newArgs?: T) => {
      const endpoint = `/api/experiments/${experimentName}/`;
      const data = newArgs ?? args;

      sendAnalytics<ExperimentActionArgs<T>>(endpoint, {
        type: ExperimentActions.Track,
        id: goal,
        data,
      });
    },
    [args, experimentName, goal],
  );

  return trackCallback;
}
