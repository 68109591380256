import type { SelfDescribingJson } from '@snowplow/browser-tracker';
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';

interface ExperimentViewEventData {
  experimentId?: string;
  experimentName: string;
  variantIndex: number;
  variantName?: string;
  targetUrl?: string;
  description?: string;
}

/**
 * Track Experiment view event in Snowplow.
 * v.0.16.0;
 * @public
 *
 * @param eventData - Object that contains event properties described in the Experiment View Event document
 * (see https://healthcareinc.atlassian.net/wiki/spaces/ENGINEERING/pages/2718826518/Experiment+View#Schema-Fields).
 *
 * @example
 * ```ts
 *
 * import {trackExperimentView} from '@hc-frontend/core-third-party-snowplow';
 *
 * trackExperimentView({
 *     experimentId: '365',
 *     experimentName: 'STORE_EXPERIMENT_NAME_U65',
 *     variantIndex: 0,
 *     variantName: 'VARIANT_NAME',
 *     description: 'Additional description',
 * });
 * ```
 */
function trackExperimentView(
  eventData: ExperimentViewEventData,
  contexts?: Array<SelfDescribingJson> | null,
): void {
  const {
    experimentId,
    experimentName,
    variantIndex,
    variantName,
    targetUrl,
    description,
  } = eventData;
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.healthcare/experiment_view/jsonschema/1-0-0',
      data: {
        experiment_id: experimentId,
        experiment_name: experimentName,
        variant_index: variantIndex,
        variant_name: variantName,
        target_url: targetUrl,
        description,
      },
    },
    context: contexts,
  });
}

export { trackExperimentView };
