import { alterDate } from '@hc-frontend/core-utils-date';
import type { DOB } from '@hc-frontend/deprecated-entities';

/**
 * Check if the date of birth is eligible for Medicare
 *
 * @param dateOfBirth - The date of birth to check
 * @returns If the date of birth is less that 64.5 years (-65 years plus 6 month gap) then return true otherwise return false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isDOBEligibleForMedicare(new Date(2000, 1 , 1));
 * console.log(result);
 * /// -> true
 * ```
 */
function isDOBEligibleForMedicare(dateOfBirth: DOB): dateOfBirth is Date {
  const dobTime = dateOfBirth.getTime();
  const dateToCompare = alterDate({ years: -65, months: 6 }).getTime();

  return dateToCompare >= dobTime;
}

/**
 * Check if the applicant is eligible for Medicare
 *
 * @param applicant - The applicant to check
 * @returns Returns true if applicant can apply for Medicare otherwise returns false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isMemberEligibleForMedicare(applicant);
 * console.log(result);
 * /// -> false
 * ```
 */
function isMemberEligibleForMedicare<T extends { dob: DOB }>(
  member: T,
): member is T {
  return isDOBEligibleForMedicare(member.dob);
}

/**
 * Check if the date of birth is eligible for health
 *
 * @param dateOfBirth - The date of birth to check
 * @returns If the date of birth is under 64.5 years (-65 years plus 6 month gap) then return true otherwise return false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isDOBEligibleForHealth(new Date(2000, 1 , 1));
 * console.log(result);
 * /// -> false
 * ```
 */
function isDOBEligibleForHealth(dateOfBirth: DOB): dateOfBirth is Date {
  return !isDOBEligibleForMedicare(dateOfBirth);
}

/**
 * Check if the member is eligible for health
 *
 * @param member - The member to check
 * @returns Returns true if member can apply for health otherwise returns false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isMemberEligibleForHealth(member);
 * console.log(result);
 * /// -> false
 * ```
 */
function isMemberEligibleForHealth<T extends { dob: DOB }>(
  member: T,
): member is T {
  return isDOBEligibleForHealth(member.dob);
}

/**
 * Check if the date of birth is eligible for BTM
 *
 * @param dateOfBirth - The date of birth to check
 * @returns If the date of birth is under 64.5 years (-65 years plus 6 month gap) then return true otherwise return false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isDOBEligibleForHealth(new Date(2000, 1 , 1));
 * console.log(result);
 * /// -> false
 * ```
 */
function isDOBEligibleForBTM(dateOfBirth: DOB): dateOfBirth is Date {
  const dobTime = dateOfBirth.getTime();
  const back64Years11Months = alterDate({
    years: -65,
    months: 1,
    days: 2,
  }).getTime();

  const back60Years = alterDate({ years: -60, days: 1 }).getTime();

  return dobTime >= back64Years11Months && dobTime <= back60Years;
}

/**
 * Check if the member is eligible for BTM
 *
 * @param member - The member to check
 * @returns Returns true if member can apply for health otherwise returns false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isMemberEligibleForBTM(member);
 * console.log(result);
 * /// -> false
 * ```
 */
function isMemberEligibleForBTM<T extends { dob: DOB }>(
  member: T,
): member is T {
  return isDOBEligibleForBTM(member.dob);
}

export {
  isDOBEligibleForBTM,
  isDOBEligibleForHealth,
  isDOBEligibleForMedicare,
  isMemberEligibleForBTM,
  isMemberEligibleForHealth,
  isMemberEligibleForMedicare,
};
