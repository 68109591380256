import { prefixEnvironment } from '../prefix-environment/prefix-environment';

/**
 * Get the "stg" prefix of the environment in the given url or current if not passed.
 * @public
 *
 * @param host - A domain url
 *
 * @returns "stg." if url has stg, qa, local or localhost in its parts otherwise empty because there is no environment
 *
 * @example
 * ```ts
 * const stgPrefix1 = prefixEnvironmentWithoutQAandDEV("https://enroll.stg.pivothealth.com"); // Output: "stg."
 * const stgPrefix2 = prefixEnvironmentWithoutQAandDEV("https://enroll.qa.pivothealth.com"); // Output: "stg."
 * const stgPrefix3 = prefixEnvironmentWithoutQAandDEV("https://enroll.dev.pivothealth.com"); // Output: "stg."
 * ```
 */
function prefixEnvironmentWithoutQAandDEV(host = ''): string {
  return prefixEnvironment(host).replace(/qa.|dev./gi, 'stg.');
}

export { prefixEnvironmentWithoutQAandDEV };
