import type { IMember } from '@hc-frontend/deprecated-entities';

import {
  findChildren,
  findDependents,
} from '../find-member-by-type/find-member-by-type';

/**
 * Number of non-applicant members
 *
 * @param members - Members to count.
 * @returns - Number of dependents.
 *
 * @public
 *
 * @example
 * ```ts
 * const total = countDependents(members);
 * ```
 */
function countDependents(members: IMember[]): number {
  return findDependents(members).length;
}

/**
 * Number of children members
 *
 * @param members - Members to count.
 * @returns Number of children.
 *
 * @public
 *
 * @example
 * ```ts
 * const total = countChildren(members);
 * ```
 */
function countChildren(members: IMember[]): number {
  return findChildren(members).length;
}

export { countChildren, countDependents };
