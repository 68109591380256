import { isArray } from '../is-array/is-array';
import { isString } from '../is-string/is-string';
import { US_PHONE } from '../regex';
import { TOLL_FREE_CODES, US_AREA_CODES } from '../us-area-codes';

interface UsPhoneValidationConfig {
  enable800?: boolean;
  bannedPhones?: string[];
}

/**
 * Returns true if the area code is valid in the US
 *
 * @param arg - A phone number string
 * @param enable800 - A boolean flag to allow {@link TOLL_FREE_CODES | toll-free area codes}
 *
 * @returns A boolean indicating whether the phone has a valid area code or not.
 *
 * @internal
 *
 * @example
 * ```ts
 * const result = isValidUSPhoneAreaCode('(219) 336-0666');
 * console.log(result);
 * // -> true
 * ```
 * */
function isValidUSPhoneAreaCode(arg: string, enable800 = false): boolean {
  const areaCode = +arg.replace(/\D/g, '').substring(0, 3);
  const areaCodes = US_AREA_CODES.slice(0);

  if (enable800) {
    areaCodes.push(...TOLL_FREE_CODES);
  }

  return areaCodes.indexOf(areaCode) >= 0;
}

/**
 * Checks if the provided phone appears in the list of
 * banned phone numbers provided.
 *
 * @param arg - A string phone number
 * @param bannedPhones - An optional list of banned phones
 *
 * @returns A boolean indicating whether the phone is banned or not.
 *
 * @internal
 *
 * @example
 * ```ts
 * const result = isBannedPhone('(219) 336-0666', ['2193360666']);
 * console.log(result);
 * // -> true
 * ```
 */
function isBannedPhone(arg: string, bannedPhones?: string[]): boolean {
  return (
    isArray(bannedPhones) &&
    bannedPhones.some(
      (banned) => banned.replace(/\D/g, '') === arg.replace(/\D/g, ''),
    )
  );
}

/**
 * Returns true if the string is a valid US phone
 * @public
 *
 * @param arg - A generic argument to check.
 * @param config - Config flags to modify the validation logic.
 *
 * @returns A boolean indicating whether the input is a valid US phone or not.
 *
 * @example
 * ```ts
 * const result = isValidUSPhone('(219) 336-0666');
 * console.log(result);
 * // -> true
 * ```
 *
 * @see https://developers.google.com/style/phone-numbers
 * */
function isValidUSPhone<T>(
  arg: unknown,
  config?: UsPhoneValidationConfig,
): arg is T {
  return (
    isString(arg) &&
    US_PHONE.test(arg as string) &&
    isValidUSPhoneAreaCode(arg as string, config?.enable800) &&
    !isBannedPhone(arg as string, config?.bannedPhones)
  );
}

export { isValidUSPhone };
