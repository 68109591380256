import { StateFormControl } from '@hc-frontend/core-ui-components';
import { isValidZipCodeFormat } from '@hc-frontend/core-utils-validations';
import { locationRepositoryZipIpV1 } from '@hc-frontend/deprecated-business';
import type { Zip } from '@hc-frontend/deprecated-entities';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { generateValidations } from './zip-integration-controls.config';
import type {
  ZipIntegrationControlData,
  ZipIntegrationControlsPropTypes,
} from './zip-integration-controls.types';

const ZipIntegrationControls = ({
  formId,
  translations,
  errors,
  setValue,
  control,
}: ZipIntegrationControlsPropTypes) => {
  const cityInputId = `${formId}-city`;
  const stateInputId = `${formId}-state`;
  const zipInputId = `${formId}-zip`;
  const validations = generateValidations(translations);
  const [checkedValid, setCheckedValid] = useState(false);
  const [checkedInValid, setCheckedInValid] = useState(false);
  const [isStateDisabled, setIsStateDisabled] = useState(false);

  const handleZipIntegration = (zip: Zip) => {
    locationRepositoryZipIpV1.fetchAllLocationsByZip(zip).then((data) => {
      if (data.data && data.data.length > 0) {
        setValue('city', data.data[0].city, { shouldValidate: true });
        setValue('state', data.data[0].stateAcronym, { shouldValidate: true });
        setCheckedValid(true);
        setCheckedInValid(false);
        setIsStateDisabled(true);
      } else {
        setCheckedValid(false);
        setCheckedInValid(true);
        setIsStateDisabled(false);
        clearValues();
      }
    });
  };

  const clearValues = () => {
    setValue('city', '');
    setValue('state', '');
    setIsStateDisabled(false);
  };

  const circleStyle = {
    fontSize: '1.5rem',
    position: 'absolute',
    right: '10px',
    top: '35px',
  };

  return (
    <Grid container columnSpacing={6} rowSpacing={4}>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          error={!!errors.zipCode?.message}
          data-testid="zipCode"
        >
          <FormLabel htmlFor={zipInputId}>{translations.zipLabel}</FormLabel>
          <Controller<ZipIntegrationControlData>
            name="zipCode"
            rules={validations.zipCode}
            control={control}
            render={({ field, fieldState }) => (
              <InputBase
                {...field}
                error={fieldState.invalid}
                id={zipInputId}
                type="text"
                fullWidth
                onChange={(event) => {
                  const { value } = event.target;
                  field.onChange(event);
                  if (isValidZipCodeFormat(value)) {
                    handleZipIntegration(value);
                  } else {
                    setCheckedValid(false);
                    setCheckedInValid(false);
                    clearValues();
                  }
                }}
              />
            )}
          />
          {checkedValid && (
            <CheckIcon
              sx={{
                ...circleStyle,
                color: 'success.main',
              }}
            />
          )}
          {checkedInValid && <CancelIcon sx={{ ...circleStyle }} />}
          <FormHelperText error>{errors.zipCode?.message}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <StateFormControl
          inputId={stateInputId}
          control={control}
          controlName="state"
          isDisabled={isStateDisabled}
          rules={validations.state}
          label={translations.stateLabel}
          errorMessage={errors.state?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.city?.message}>
          <FormLabel htmlFor={cityInputId}>{translations.cityLabel}</FormLabel>
          <Controller<ZipIntegrationControlData>
            name="city"
            rules={validations.city}
            control={control}
            render={({ field, fieldState }) => (
              <InputBase
                {...field}
                error={fieldState.invalid}
                id={cityInputId}
                type="text"
                fullWidth
              />
            )}
          />
          <FormHelperText error>{errors.city?.message}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export { ZipIntegrationControls };
