import type {
  HcSessionData,
  HcSessionDataProvider,
} from '@hc-frontend/core-third-party-snowplow';
import {
  hcSessionContextProvider,
  initSnowplowTracker,
} from '@hc-frontend/core-third-party-snowplow';
import { getCookieValue } from '@hc-frontend/core-utils-dom';
import { exhaustSnowplowQueue } from '@hc-frontend/shells-rx-cards-adapter-snowplow';
import { useAppConfig } from '@hc-frontend/shells-rx-cards-ui';
import { useRouter } from 'next/router';
import type React from 'react';
import { useEffect } from 'react';

const cookieStorageHcSessionDataProvider: HcSessionDataProvider = {
  readHcSessionContextData: (): HcSessionData => {
    try {
      const healthcareUser = getCookieValue('healthcareUser');
      const healthcareSession = getCookieValue('healthcareSession');

      return {
        userId: healthcareUser || null,
        sessionId: healthcareSession || null,
      };
    } catch (error) {
      return {
        userId: null,
        sessionId: null,
      };
    }
  },
};

export function SnowplowProvider({ children }: React.PropsWithChildren) {
  const router = useRouter();
  const {
    public: {
      app: { environment },
    },
  } = useAppConfig();

  useEffect(() => {
    const { pageView } = initSnowplowTracker({
      isProd: environment === 'PRD',
      crossDomainLinkerCriterion: (
        element: HTMLAnchorElement | HTMLAreaElement,
      ) => {
        const { hostname } = new URL(element.href);
        return hostname !== window.location.hostname;
      },
      contexts: [hcSessionContextProvider(cookieStorageHcSessionDataProvider)],
    });
    pageView();

    router.events.on('routeChangeComplete', pageView);

    exhaustSnowplowQueue();

    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events, environment]);

  return children as JSX.Element;
}
