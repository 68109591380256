import { emailAssert } from '@hc-frontend/core-ui-components';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';

import type { ForgotPasswordFormPropTypes } from './forgot-password-form.types';

const ForgotPasswordForm = ({
  submitChangePwdRequest,
  translations,
  goBackRoute,
  isSubmitting = false,
}: ForgotPasswordFormPropTypes) => {
  const { control, handleSubmit, formState } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
  });

  const { errors } = formState;

  return (
    <Container maxWidth="sm">
      <form
        onSubmit={handleSubmit((data) => {
          submitChangePwdRequest(data);
        })}
      >
        <Box mt={2}>
          <Controller
            name="email"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            rules={emailAssert({
              requiredMessage: translations.emailRequired,
              patternMessage: translations.emailRequired,
              required: true,
            })}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                id="email"
                data-testid="email"
                helperText={errors.email?.message}
                fullWidth
                error={fieldState.invalid}
              />
            )}
          />
        </Box>
        <Box mt={6}>
          <LoadingButton
            fullWidth
            data-testid="submitButton"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {translations.submitButton}
          </LoadingButton>
        </Box>
        <Box mt={7} pt={3}>
          <Button
            data-testid="backButton"
            href={goBackRoute}
            fullWidth
            variant="outlined"
          >
            {translations.backButton}
          </Button>
        </Box>
      </form>
    </Container>
  );
};
export { ForgotPasswordForm };
