import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { forwardRef } from 'react';

import type { CheckBoxLabelProp } from './check-box.types';

export const CheckboxLabels = forwardRef(
  (
    {
      labelText,
      handleChange,
      isChecked,
      labelTextProps,
      inputProps,
    }: CheckBoxLabelProp,
    ref: React.ForwardedRef<typeof FormControlLabel>,
  ) => {
    return (
      <FormControlLabel
        ref={ref}
        sx={(theme) => ({ color: theme.grayShades[600] })}
        control={
          <Checkbox
            sx={(theme) => ({
              fontSize: theme.typography.helpText,
              color: theme.grayShades[600],
              pr: theme.spacing(2),
            })}
            checked={isChecked}
            onChange={handleChange}
            data-testid="check-box"
            inputProps={inputProps}
          />
        }
        label={
          <Typography variant="helpText" sx={{ ...labelTextProps }}>
            {labelText}
          </Typography>
        }
      />
    );
  },
);
