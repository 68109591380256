import { omit } from 'ramda';

/**
 * Create an object by omitting some of its keys
 * @public
 *
 * @typeParam T - The input type of the object
 * @typeParam TOut - The output object without keys
 *
 * @param object - The object to be transformed by omitting keys
 * @param keys - The keys to omit
 *
 * @returns A new copy of the object without the keys specified
 *
 * @example
 * ```ts
 * const coolObject = omitKeys({status: 'amICool', option1: 'yes', option2: 'no'}, 'option2'); // {status: 'amICool', option1: 'yes'}
 * ```
 */
function omitKeys<T, K extends string>(
  object: T,
  ...keys: readonly K[]
): Omit<T, K> {
  return omit(keys, object);
}

export { omitKeys };
