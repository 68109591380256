import { isString } from '../is-string/is-string';
import { ZIP_CODE_REGEX } from '../regex';

/**
 * Returns true if the string is a valid ZIP code
 * @public
 *
 * @param  arg - A generic argument to check
 * @returns true if the string is a valid ZIP code
 *
 *
 * @example
 * ```ts
 * const result = isValidZipCodeFormat('90210');
 * console.log(result);
 * // -> true
 * ```
 * */
function isValidZipCodeFormat<T>(arg: unknown): arg is T {
  return isString(arg) && ZIP_CODE_REGEX.test(arg as string);
}

export { isValidZipCodeFormat };
