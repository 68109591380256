import { FilePickerPreview } from '@hc-frontend/core-ui-components';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import type { UploadDocumentPreviewProps } from './upload-document-preview.types';

export function UploadDocumentPreview({
  alt,
  file,
  onDelete,
  deleteId: id,
}: UploadDocumentPreviewProps) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: grey['200'],
        height: theme.spacing(9),
        display: 'flex',
        alignItems: 'center',
        my: theme.spacing(3),
        border: 'solid',
        borderColor: grey['300'],
      })}
    >
      <Box
        sx={(theme) => ({
          height: theme.spacing(6),
        })}
        mx={2}
      >
        <FilePickerPreview file={file} />
      </Box>
      <Typography component="span" variant="disclaimer" flexGrow={10}>
        {alt}
      </Typography>
      <IconButton
        sx={{
          alignSelf: 'center',
        }}
        onClick={onDelete}
        data-testid={id}
      >
        <CancelIcon
          sx={{
            color: grey['400'],
          }}
          fontSize="large"
        />
      </IconButton>
    </Box>
  );
}
