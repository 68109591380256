import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadHeaderRepository,
} from '@hc-frontend/deprecated-repositories';
import type { NextRequest } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextReadRequestHeaderAdapterFactory(
  provider: LogRepository,
  request: NextRequest,
) {
  return buildAdapter<ReadHeaderRepository<ErrorCodes.HEADER_NOT_READ>>()(
    provider,
    function nextReadRequestHeaderAdapter(key) {
      return Promise.resolve(request.headers.get(key) || '');
    },
    ErrorCodes.HEADER_NOT_READ,
  );
}
