import Image from 'next/image';

import { useAppConfig } from '../../hooks';
import type { CloudImageProps } from './cloud-image.types';

export function CloudImage({
  cloudUrl,
  assetName,
  ...imageProps
}: CloudImageProps) {
  const {
    public: { cloudFront },
  } = useAppConfig();

  const url = cloudUrl ?? cloudFront.url;

  return <Image src={`${url}/${assetName}`} unoptimized {...imageProps} />;
}
