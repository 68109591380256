import {
  buildManagedValue,
  createBusinessSuccessOperationResult,
  fetchLocationByIp,
} from '@hc-frontend/deprecated-business';
import type {
  AsSuccessOperationResult,
  ErrorCodes,
  IBusinessEntity,
  IOperationResult,
} from '@hc-frontend/deprecated-entities';
import type {
  FetchLocationByIpRepository,
  GetUserAgentRepository,
  LogRepository,
  SetUserSegmentsRepository,
} from '@hc-frontend/deprecated-repositories';
import { SetUserSegmentsRepositoryType } from '@hc-frontend/deprecated-repositories';

export interface UserViewPageProvider extends LogRepository<ErrorCodes> {
  fetchLocationByIp: FetchLocationByIpRepository<ErrorCodes>;
  setUserSegment: SetUserSegmentsRepository<ErrorCodes>;
  getUserAgent: AsSuccessOperationResult<GetUserAgentRepository<ErrorCodes>>;
}

/**
 * Use case triggered by the user viewing the create card page.
 *
 * @param provider - The provider of the use case
 *
 * @param route - Current route of the page
 *
 * @public
 *
 * @returns An operation result with the success status of the use case.
 *
 * @example
 * ```ts
 * const result = await userViewLandingPage(provider, route);
 * ```
 */
export async function userViewAnyPage<T extends UserViewPageProvider>(
  provider: T,
  route: string,
): Promise<IOperationResult<IBusinessEntity<void>, ErrorCodes>> {
  provider.logInfo(`User viewed ${route} page`);

  const location = await fetchLocationByIp(provider);

  if (!location.success) {
    provider.logError(
      'Segment could not be set, location by IP could not be fetched',
      {
        operationErrors: location.errors,
      },
    );
  } else {
    await provider.setUserSegment({
      type: SetUserSegmentsRepositoryType.LOCATION,
      userSegment: location.data,
    });

    const userAgentResult = await provider.getUserAgent();
    await provider.setUserSegment({
      type: SetUserSegmentsRepositoryType.USER_AGENT,
      userSegment: userAgentResult.data,
    });
  }

  return createBusinessSuccessOperationResult(buildManagedValue(undefined));
}
