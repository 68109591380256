import {
  XCenteredLayout,
  YCenteredLayout,
} from '@hc-frontend/core-ui-components';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

type PageLoaderProps = {
  isLoader: boolean;
};
export const PageLoader = ({ isLoader }: PageLoaderProps) => {
  const { t } = useTranslation('common');

  return isLoader ? (
    <Backdrop
      open
      sx={{
        backgroundColor: (theme) => theme.grayShades['100'],
        height: '100vh',
        zIndex: '999',
      }}
    >
      <XCenteredLayout height="100vh">
        <YCenteredLayout>
          <Typography variant="paragraphSM" align="center">
            {t('loadingText1')}
          </Typography>
          <Typography variant="paragraphSM" align="center">
            {t('loadingText2')}
          </Typography>
          <Box p={8}>
            <CircularProgress size="50px" color="primary" />
          </Box>
        </YCenteredLayout>
      </XCenteredLayout>
    </Backdrop>
  ) : null;
};
