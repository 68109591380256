import type { HttpResponse } from '@hc-frontend/core-utils-net';
import { fetchJSON } from '@hc-frontend/core-utils-net';

import { geoIpURI } from '../config';
import type { IFetchLocationByIpServiceResponse } from './fetch-location-by-ip-service-response';

/**
 * Fetch the location using the current ip of the browser
 *
 * @public
 *
 * @returns The location information based on the current ip of the request or null if we can't determine the location
 *
 * @example
 * ```ts
 * const location = await fetchLocationByIpService();
 * ```
 */
function fetchLocationByIpService(): Promise<
  HttpResponse<IFetchLocationByIpServiceResponse>
> {
  return fetchJSON<IFetchLocationByIpServiceResponse>(geoIpURI());
}

export { fetchLocationByIpService };
