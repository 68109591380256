import { UserWay } from '@hc-frontend/core-third-party-next-userway';
import {
  BingAdsHeadScript,
  FacebookAdsHeadScript,
  GoogleAdsHeadScript,
} from '@hc-frontend/core-third-party-pixels';
import { useAppConfig } from '@hc-frontend/shells-rx-cards-ui';

export function ConfigProvider({ children }: React.PropsWithChildren) {
  const {
    public: {
      pixels: {
        headerIds: { facebook, bing, google },
      },
      userway,
    },
  } = useAppConfig();
  return (
    <>
      <UserWay account={userway.apiKey} />
      <FacebookAdsHeadScript id={facebook} />
      <GoogleAdsHeadScript id={google} />
      <BingAdsHeadScript id={bing} />
      {children}
    </>
  );
}
