import type { IDictionary } from '@hc-frontend/core-utils-types';
import { defaultTo, path as pathToKey } from 'ramda';

/**
 * Retrieves the value of an object property. This could look for nesded properties providing a dot notation query.
 * @public
 *
 * @param objectToBeChecked - object to access
 * @param path - string or array with dot notation path
 * @param defaultValue - optional parameter for default if the full key in path is missing
 *
 * @returns The value that corresponds to the key provided or a default value.
 *
 * @example
 * ```ts
 * const config = {
 *   key: 'value',
 * }
 * const result = propAccessor(config, 'key')
 * // -> result === 'value'
 * ```
 */
function propAccessor<T>(
  objectToBeChecked: IDictionary<unknown>,
  path: string | string[],
  defaultValue?: T,
): T | undefined {
  return defaultTo(defaultValue)(
    pathToKey(
      typeof path === 'string' ? path.split('.') : path,
      objectToBeChecked,
    ),
  );
}

export { propAccessor };
