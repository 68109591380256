/**
 * Regular expression used for date format
 *
 * @public
 */
export const DATE_FORMAT_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;

/**
 * Regular expression used for date format. specifically to test if months are numbers between 1 and 12
 *
 * @public
 */
export const DATE_1_TO_12_REGEX = /(^0?[1-9]$)|(^1[0-2]$)/;

/**
 * Regular expression used for date format
 *
 * @public
 */
export const ISO_DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Regular expression used for iso date format with milliseconds and optional local time specifier
 */
export const ISO_DATE_UTC_TIMEZONE_REGEX =
  /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|-)(?:\d{2}):?(?:\d{2}))/;

/**
 * Regular expression used for email validation (accepts the form of email\@domain.com, E.mail\@domain.com, email\@Domain.COM)
 *
 * @public
 */
export const EMAIL_REGEX =
  /^([\w-+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?$)/;

/**
 * Regular expression used for name validation
 *
 * @public
 */
export const NAME_REGEX = /^(?!\s)[a-z\u0027\u2019\u0020\u00E0-\u00FC]+$/i;

/**
 * Regular expression used for US phone validation
 * It does not accept empty start space nor 2 continuos spaces in the string
 * @public
 */
export const ALPHA_NUM_REGEX =
  /^\S((?!(\s{2,}))[a-z0-9 \u0026\u002D\u0028\u0029\u0027\u002C\u002E])*$/i;

/**
 * Regular expression used for ID number validation
 *
 * @public
 */
export const ALPHA_NUM_WO_SPECIAL_CHAR_REGEX = /^[a-z0-9]+$/i;

/**
 * Regular expression used for US phone validation
 *
 * @public
 */
export const US_PHONE = /^1?\W*([2-9][0-8]\d)\W*(\d\d{2})\W*(\d{4})$/;

/**
 * Regular expression used for ZIP code format validation
 *
 * @public
 */
export const ZIP_CODE_REGEX = /^\d{5}$/;

/**
 * Regular expression used for domain parts
 *
 * @public
 */
export const DOMAIN_PARTS =
  /^(https?:\/\/)?(([^:/?#]*)(?::(\d+))?)([/]{0,1}[^?#]*)?(\?[^#]*|)$/;

/**
 * Regular expression used for Password validation
 *
 * 8 characters minimum
 * 1 upper case character
 * 1 lower case character
 * 1 number
 *
 * @public
 */
export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

/**
 * Regular expression used for Medicare Beneficiary Identifiers (MBIs)
 *
 * Position 1 – numeric values 1 thru 9
 * Position 2 – alphabetic values A thru Z (minus S, L, O, I, B, Z)
 * Position 3 – alpha-numeric values 0 thru 9 and A thru Z (minus S, L, O, I, B, Z)
 * Position 4 – numeric values 0 thru 9
 * Position 5 – alphabetic values A thru Z (minus S, L, O, I, B, Z)
 * Position 6 – alpha-numeric values 0 thru 9 and A thru Z (minus S, L, O, I, B, Z)
 * Position 7 – numeric values 0 thru 9
 * Position 8 – alphabetic values A thru Z (minus S, L, O, I, B, Z)
 * Position 9 – alphabetic values A thru Z (minus S, L, O, I, B, Z)
 * Position 10 – numeric values 0 thru 9
 * Position 11 – numeric values 0 thru 9
 *
 * @public
 */
export const MBI_REGEX = new RegExp(
  [
    '^',
    '\\d',
    '(?![SLOIBZ])[A-Z]',
    '\\d|(?![SLOIBZ])[A-Z]',
    '\\d',
    '(?![SLOIBZ])[A-Z]',
    '\\d|(?![SLOIBZ])[A-Z]',
    '\\d',
    '(?![SLOIBZ])[A-Z]',
    '(?![SLOIBZ])[A-Z]',
    '\\d',
    '\\d',
  ].join(''),
);

/**
 * Regular expression used for checking spaces more then 1 between characters
 *
 * @public
 */
export const WHITESPACE_CHECK_REGEX = /^\S((?!(\s{2,})).)*$/g;

/**
 * Address line regex
 * Accepts Alpahanumeric with underscore and these special characters: -\@'#%&,/()
 * It does not accept staring space character nor 2 continuous  spaces
 * @public
 */
export const ADDRESS_LINE_REGEX = /^\S((?!(\s{2,}))[(\w .\-@'#%&,/)])*$/g;

/**
 * Allows any character (alphanumeric or symbol) except spaces
 *
 * @public
 */
export const ANY_CHARACTER_WITHOUT_SPACES = /^\S+$/g;

/**
 * Check if a string is a valid UUID v4
 *
 * @public
 */
export const UUID_V4 =
  /[A-Z0-9]+-[A-Z0-9]+-4[A-Z0-9]+-(a|b|8|9)[A-Z0-9]+-[A-Z0-9]+/i;
