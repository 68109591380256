/**
 * Saves an object in the web storage
 * @alpha
 *
 * @typeParam T - the type of the object to be saved.
 *
 * @param key - The key identifying the object in the storage
 * @param object - A default value to set in case the object does not exist in the storage
 *
 * @example
 * ```ts
 * saveObject('example', {a: 'b'});
 * ```
 */
function saveObject<T>(key: string, object: T): void {
  localStorage.setItem(key, JSON.stringify(object));
}

export { saveObject };
