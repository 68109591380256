import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';

import type { MarkdownDocumentProps } from './markdown-document.types';

const UnderlineText = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) => <span {...props}>{children}</span>;

export function MarkdownDocument({
  children,
}: MarkdownDocumentProps): JSX.Element {
  return (
    <Container
      maxWidth="md"
      sx={(theme) => ({
        marginTop: theme.spacing(5),
        textAlign: 'justify',
        textJustify: 'inter-word',
      })}
    >
      <Typography variant="paragraphMD" component="div" mb={9}>
        <Markdown
          options={{
            disableParsingRawHTML: true,
            wrapper: 'article',
            overrides: {
              code: {
                component: UnderlineText,
                props: {
                  style: {
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  },
                },
              },
              a: {
                props: {
                  style: {
                    wordBreak: 'break-all',
                    color: 'revert',
                  },
                },
              },
            },
          }}
        >
          {children}
        </Markdown>
      </Typography>
    </Container>
  );
}
