import type { TypographyProps } from '@mui/material/Typography';

export interface FooterTextProps extends TypographyProps {
  children: React.ReactNode;
}

export interface FooterButtonProps {
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
}

export interface FooterLinkProps {
  baseUrl?: string;
}

export enum FooterLogos {
  LEGIT_SCRIPT,
  DOT_PHARMACY,
}

export interface FooterLogosProps {
  logos: FooterLogos[];
}

export interface FooterPharmacyProps extends FooterLinkProps {
  phone: string;
  tty: string;
}
