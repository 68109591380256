import type { IPureFunction } from '@hc-frontend/core-utils-types';

/**
 * @internal
 *
 *
 * @param path - The path to the endpoint
 * @param body - The body of the request
 * @param onMessage - A function to call when the request is successful
 * @param onError - A function to call when the request fails
 */
function sendViaBeacon(
  path: string,
  body: string,
  onMessage?: IPureFunction<[], void>,
  onError?: IPureFunction<[], void>,
): void {
  const success = navigator.sendBeacon(path, body);

  if (success) {
    onMessage?.();
  } else {
    onError?.();
  }
}

export { sendViaBeacon };
