import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import type { Control, FieldValues } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import {
  defaultValues,
  generateValidations,
} from './transfer-select-member-form.config';
import type { TransferSelectMemberFormPropTypes } from './transfer-select-member-form.types';

const TransferSelectMemberForm = ({
  formId,
  onSubmit,
  translations,
  peopleData,
}: TransferSelectMemberFormPropTypes) => {
  const personInputId = `${formId}-person`;

  const validations = generateValidations(translations);
  const { control, handleSubmit, formState } = useForm({
    mode: 'onTouched',
    defaultValues,
  });

  const { errors } = formState;

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.userId?.message}>
            <FormLabel htmlFor={personInputId}>
              {translations.personLabel}
            </FormLabel>
            <Controller
              name="userId"
              rules={validations.userId}
              control={control as unknown as Control<FieldValues>}
              render={({ field }) => (
                <Select {...field} id={personInputId} native>
                  <option value="">{translations.personEmptyLabel}</option>
                  {peopleData.map((person) => (
                    <option key={person.id} value={person.id}>
                      {person.displayName}
                    </option>
                  ))}
                </Select>
              )}
            />
            <FormHelperText error>{errors.userId?.message}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
export { TransferSelectMemberForm };
