import { PhoneInputFormat } from '@hc-frontend/core-ui-components';
import { PHONE_INPUT_MASK } from '@hc-frontend/core-utils-random';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import { Controller, useForm } from 'react-hook-form';

import type { ZipIntegrationControlsPropTypes } from '../zip-integration-controls';
import { ZipIntegrationControls } from '../zip-integration-controls';
import {
  defaultValues,
  generateValidations,
} from './transfer-pharmacy-form.config';
import type {
  TransferPharmacyFormFields,
  TransferPharmacyFormPropTypes,
} from './transfer-pharmacy-form.types';

const TransferPharmacyForm = ({
  formId,
  onSubmit,
  initialData,
  translations,
}: TransferPharmacyFormPropTypes) => {
  const validations = generateValidations(translations);
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: 'onTouched',
    defaultValues: initialData ?? defaultValues,
  });

  const { errors } = formState;

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.name?.message}
            data-testid="pharmacyName"
          >
            <FormLabel htmlFor="pharmacy-name">
              {translations.nameLabel}
            </FormLabel>
            <Controller<TransferPharmacyFormFields>
              name="name"
              rules={validations.name}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  id="pharmacy-name"
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormHelperText error>{errors.name?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.streetAddress?.message}
            data-testid="pharmacyStreetAddress"
          >
            <FormLabel htmlFor="pharmacy-streetAddress">
              {translations.addressLabel}
            </FormLabel>
            <Controller<TransferPharmacyFormFields>
              name="streetAddress"
              rules={validations.streetAddress}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  id="pharmacy-streetAddress"
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormHelperText error>
              {errors.streetAddress?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ZipIntegrationControls
            formId={formId}
            translations={translations}
            setValue={
              setValue as unknown as ZipIntegrationControlsPropTypes['setValue']
            }
            control={
              control as unknown as ZipIntegrationControlsPropTypes['control']
            }
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.phoneNumber?.message}
            data-testid="pharmacyPhoneNumber"
          >
            <FormLabel htmlFor="pharmacy-phoneNumber">
              {translations.phoneLabel}
            </FormLabel>
            <Controller<TransferPharmacyFormFields>
              name="phoneNumber"
              rules={validations.phoneNumber}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  placeholder={PHONE_INPUT_MASK}
                  id="pharmacy-phoneNumber"
                  type="text"
                  fullWidth
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent={PhoneInputFormat as any}
                />
              )}
            />
            <FormHelperText error>{errors.phoneNumber?.message}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
export { TransferPharmacyForm };
