import type { HttpResponse } from '@hc-frontend/core-utils-net';
import { fetchJSON } from '@hc-frontend/core-utils-net';

import { geoLocationURI } from '../config';
import type { IFetchAllLocationsByZipServiceResponse } from './fetch-all-locations-by-zip-service-response';

/**
 * Fetch all possible locations that match the `zip` code
 *
 * @param zip - The zip code to be fetched
 * @returns The array of possible locations related with the zip, otherwise an empty array will be returned
 *
 * @public
 *
 * @example
 * ```ts
 * const locations = await fetchAllLocationsByZipService('3133');
 * ```
 */
async function fetchAllLocationsByZipService(
  zip: string,
): Promise<HttpResponse<IFetchAllLocationsByZipServiceResponse[]>> {
  const url = `${geoLocationURI()}/GeoLocation/${zip}`;
  return fetchJSON<Array<IFetchAllLocationsByZipServiceResponse>>(url);
}

export { fetchAllLocationsByZipService };
