import {
  fromPhoneE164,
  getE164Parts,
  toPhoneE164,
} from '@hc-frontend/core-utils-format';
import type {
  AgentId,
  IAdapterResponse,
  IAgent,
} from '@hc-frontend/deprecated-entities';
import {
  AdapterResponseStatus,
  ErrorCodes,
} from '@hc-frontend/deprecated-entities';
import type { IFetchAgentInformationServiceResponseData } from '@hc-frontend/deprecated-services';
import { fetchAgentInformationService } from '@hc-frontend/deprecated-services';
import { always, applySpec, curry, join, path, pipe, prop, props } from 'ramda';

import type { IAgentRepository } from '../../../repository/agent-repository';

const toE164 = pipe<
  [string, IFetchAgentInformationServiceResponseData],
  string,
  string
>(prop, toPhoneE164 as (a: string) => string);

const fromE164 = pipe(toE164, fromPhoneE164);

const getParts = pipe(toE164, getE164Parts as (a: string) => string[]);

const createAgent = applySpec<IAgent>({
  id: prop('agent_id'),
  firstName: prop('first_name'),
  lastName: prop('last_name'),
  phone: {
    number: curry(toE164)('phone'),
    cta: curry(fromE164)('phone'),
    parts: curry(getParts)('phone'),
    schedule: always(null),
    tty: always(null),
    extension: always(null),
  },
  email: prop('email'),
  agency: {
    name: path(['agency_name']),
    id: path(['agency_id']),
  },
  fullName: pipe(props(['first_name', 'last_name']), join(' ')),
  channel: path(['channel', 'channelName']),
  isTest: prop('is_test_agent'),
});

const agentRepositoryV1 = <IAgentRepository>{
  async fetchAgentInformation(
    agentId: AgentId,
  ): Promise<IAdapterResponse<IAgent>> {
    const response = await fetchAgentInformationService(agentId);

    if (response.parsedBody) {
      return {
        data: createAgent(response.parsedBody.data),
        status: AdapterResponseStatus.Ok,
        errors: [],
      };
    }
    return {
      data: undefined,
      status: AdapterResponseStatus.NoResponse,
      errors: [
        {
          message: ErrorCodes.NO_RESPONSE,
        },
      ],
    };
  },
};

export { agentRepositoryV1 };
