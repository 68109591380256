import { PhoneInputFormat } from '@hc-frontend/core-ui-components';
import { PHONE_INPUT_MASK } from '@hc-frontend/core-utils-random';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';

import type { ZipIntegrationControlsPropTypes } from '../zip-integration-controls';
import { ZipIntegrationControls } from '../zip-integration-controls';
import {
  defaultValues,
  generateValidations,
} from './delivery-details-form.config';
import type {
  DeliveryDetailsFormFields,
  DeliveryDetailsFormPropTypes,
} from './delivery-details-form.types';

const DeliveryDetailsForm = ({
  formId,
  onSubmit,
  initialData,
  translations,
  isBillingType,
}: DeliveryDetailsFormPropTypes) => {
  const validations = generateValidations(translations);
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: 'onTouched',
    defaultValues: { ...defaultValues, ...initialData },
  });

  const { errors } = formState;

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.primaryAddress?.message}
            data-testid="address1"
          >
            <FormLabel htmlFor="delivery-address-1">
              {translations.address1Label}
            </FormLabel>
            <Controller<DeliveryDetailsFormFields>
              name="primaryAddress"
              rules={validations.primaryAddress}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  id="delivery-address-1"
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormHelperText error>
              {errors.primaryAddress?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.secondaryAddress?.message}
            data-testid="address2"
          >
            <FormLabel htmlFor="delivery-address-2">
              {translations.address2Label}
            </FormLabel>
            <Controller<DeliveryDetailsFormFields>
              name="secondaryAddress"
              rules={validations.secondaryAddress}
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  id="delivery-address-2"
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormHelperText error>
              {errors.secondaryAddress?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ZipIntegrationControls
            formId={formId}
            translations={translations}
            errors={errors}
            control={
              control as unknown as ZipIntegrationControlsPropTypes['control']
            }
            setValue={
              setValue as unknown as ZipIntegrationControlsPropTypes['setValue']
            }
          />
        </Grid>
        {!isBillingType ? (
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={!!errors.phoneNumber?.message}
              data-testid="phoneNumber"
            >
              <FormLabel htmlFor="delivery-phoneNumber">
                {translations.phoneLabel}
              </FormLabel>
              <Controller<DeliveryDetailsFormFields>
                name="phoneNumber"
                rules={validations.phoneNumber}
                control={control}
                render={({ field, fieldState }) => (
                  <InputBase
                    {...field}
                    error={fieldState.invalid}
                    placeholder={PHONE_INPUT_MASK}
                    id="delivery-phoneNumber"
                    type="text"
                    fullWidth
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent={PhoneInputFormat as any}
                  />
                )}
              />
              <FormHelperText error>
                {errors.phoneNumber?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        ) : null}
        {!isBillingType ? (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography variant="paragraphSM" fontWeight={'bold'}>
                {translations.deliveryInstructionsLabel}
              </Typography>
              <Typography variant="paragraphSM">
                {translations.deliveryInstructionsHelpLabel}
              </Typography>
              <FormLabel htmlFor="delivery-instructions" sx={{ mt: 2, mb: 2 }}>
                {translations.deliveryInstructionsDescLabel}
              </FormLabel>
              <Controller<DeliveryDetailsFormFields>
                name="instruction"
                rules={validations.instruction}
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    id="delivery-instructions"
                    type="text"
                    fullWidth
                    data-testid="delivery-instructions"
                  />
                )}
              />
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
};
export { DeliveryDetailsForm };
