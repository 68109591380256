import type { IOperationError } from '@hc-frontend/deprecated-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import { RxCardsServiceErrorCodes } from '../../enums/error-code';

/**
 * Map a RX Cards API error to a business error
 *
 * @public
 *
 * @param param0 - An API error
 *
 * @returns An operation business error
 *
 * @example
 *
 * ```ts
 * mapError({code: '01009006'});
 * // -> { message: 'USER_ALREADY_EXISTS' };
 * ```
 */
export function mapError<T extends { code: string; description?: string }>({
  code,
  description,
}: T): IOperationError<RxCardsErrorCodes> {
  return {
    message:
      (RxCardsServiceErrorCodes[
        code as unknown as RxCardsServiceErrorCodes
      ] as RxCardsErrorCodes) || RxCardsErrorCodes.UNKNOWN_ERROR,
    details: `${description || 'RX cards service API failed'}. Code=${code}`,
  };
}
