/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  IPureAsyncFunction,
  IPureFunction,
} from '@hc-frontend/core-utils-types';
import type { IOperationResult } from '@hc-frontend/deprecated-entities';
import type { Reducer } from 'react';
import { useCallback, useMemo, useReducer } from 'react';

import type {
  ExtractAction,
  ExtractOperation,
  ExtractUseCaseErrorType,
  ExtractUseCaseType,
  UseBusinessCaseOptions,
} from './use-business-case.types';

const createDefaultState = () => ({
  errors: [],
  success: false,
  data: undefined,
  loading: false,
  fail: false,
  ready: true,
});

function reducer<
  T extends IPureAsyncFunction<any[], IOperationResult<any, any>>,
>(_: ExtractOperation<T>, action: ExtractAction<T>): ExtractOperation<T> {
  const getAction = () =>
    ((
      {
        ready: {
          ...createDefaultState(),
        },
        loading: {
          errors: [],
          success: false,
          data: undefined,
          loading: true,
          fail: false,
          ready: false,
        },
        done: {
          errors: action.payload.errors,
          success: action.payload.success,
          data: action.payload.data,
          loading: false,
          fail: false,
          ready: false,
        },
        error: {
          errors: action.payload.errors,
          success: false,
          data: undefined,
          loading: false,
          fail: true,
          ready: false,
        },
      } as { [key in typeof action.type]: ExtractOperation<T> }
    )[action.type]);

  return getAction();
}

export function useBusinessCase<
  T extends IPureAsyncFunction<any[], IOperationResult<any, any>>,
>(
  callable: T,
  {
    onError,
    onSuccess,
    defaultError,
    debounce,
  }: UseBusinessCaseOptions<ExtractUseCaseType<T>, ExtractUseCaseErrorType<T>>,
): [
  IPureAsyncFunction<Parameters<T>, void>,
  IPureFunction,
  ExtractOperation<T>,
] {
  const defaultValue = useMemo(
    () => createDefaultState(),
    [],
  ) as ExtractOperation<T>;
  const [state, dispatch] = useReducer<
    Reducer<ExtractOperation<T>, ExtractAction<T>>
  >(reducer, defaultValue);

  const reset = useCallback(() => {
    if (!state.ready)
      dispatch({
        type: 'ready',
        payload: defaultValue,
      } as ExtractAction<T>);
  }, [dispatch, defaultValue, state.ready]);

  const execute = useCallback(
    async (...args: Parameters<T>) => {
      dispatch({
        type: 'loading',
        payload: defaultValue,
      } as ExtractAction<T>);

      try {
        const result = await callable(...args);

        const reportResult = () => {
          if (result.success) {
            dispatch({
              type: 'done',
              payload: result,
            } as ExtractAction<T>);
            onSuccess?.(result.data);
          } else {
            dispatch({
              type: 'error',
              payload: result,
            } as ExtractAction<T>);
            onError?.(result.errors);
          }
        };

        if (debounce) setTimeout(reportResult, debounce);
        else reportResult();
      } catch (error) {
        const updatedValue = {
          ...defaultValue,
          errors: [{ message: defaultError }].map((e) => ({
            ...e,
            details: error,
          })),
        };

        dispatch({
          type: 'error',
          payload: updatedValue,
        } as ExtractAction<T>);

        onError?.(updatedValue.errors as any);
      }
    },
    [callable, defaultValue, onError, onSuccess, defaultError, debounce],
  );

  return [execute, reset, state];
}
