import type { PhoneNumber } from './SingleValues';

/**
 * Identifier for the type of user phone
 *
 * @public
 */
export enum PhoneType {
  Mobile = 'm',
  Home = 'h',
}

/**
 * Stores the user phone information, including the type of phone
 *
 * @public
 */
export type IUserPhone = {
  type: PhoneType;
  phone: PhoneNumber;
};
