import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useAppConfig } from '../../hooks';
import type { ConfirmationLayoutProps } from './confirmation-layout.types';

export function ConfirmationLayout({
  image,
  title,
  tip,
  header,
  banner,
}: ConfirmationLayoutProps) {
  const {
    public: { cloudFront },
  } = useAppConfig();

  return (
    <>
      <Stack
        style={{
          backgroundColor: '#f7fcfe',
          backgroundImage: `url(${cloudFront.url}/top-bg.webp)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          flexDirection: 'column',
        }}
      >
        <Box flex="0 1 auto">{header}</Box>
        <Box flex="1 1 auto" pt={6}>
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              textAlign="center"
              display={{
                xs: 'block',
                lg: 'none',
              }}
              width={{
                xs: '70.9vw',
                md: '100%',
              }}
              pb={5}
            >
              {title}
            </Box>
          </Box>
          <Grid
            container
            wrap="nowrap"
            mt={{
              xs: 0,
              md: '36px',
              lg: 0,
            }}
            pb={{
              xs: 0,
              md: '36px',
              lg: 0,
            }}
          >
            <Grid
              item
              xs={0}
              md="auto"
              display="block"
              height={{
                md: '54.9vw',
                lg: '46.4vw',
              }}
              width={{
                md: '44.5vw',
                lg: '45.5vw',
              }}
              alignSelf="end"
            >
              {image}
            </Grid>
            <Grid
              item
              xs={12}
              md="auto"
              px={(theme) => ({
                xs: 0,
                md: theme.typography.pxToRem(40),
                lg: theme.typography.pxToRem(76),
              })}
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
              height={{
                md: '54.9vw',
                lg: '46.4vw',
              }}
              mb={{ xs: 5, md: 0, lg: 5 }}
            >
              <Box
                textAlign="center"
                display={{
                  xs: 'none',
                  lg: 'block',
                }}
                width="42vw"
                mb={6}
              >
                {title}
              </Box>
              <Box display="flex" width={{ md: '100%' }}>
                {tip}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Box>{banner}</Box>
    </>
  );
}

export default ConfirmationLayout;
