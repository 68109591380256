import { fromPhoneE164, onlyDigits } from '@hc-frontend/core-utils-format';
import { isEmptyString } from '@hc-frontend/core-utils-validations';
import type {
  IRxCard,
  IRxCardImage,
} from '@hc-frontend/shells-rx-cards-entities';
import { applySpec, defaultTo, evolve, pipe, prop, reject } from 'ramda';

import type { IRxCardApiDTO, IRxCardDTO } from '../../dto';

/**
 * Leave a phone number with digits only.
 *
 * @internal
 */
export const createCardDTOFromCard = pipe<
  [Partial<IRxCard>],
  Partial<IRxCardApiDTO>,
  Partial<IRxCardApiDTO>
>(
  evolve({
    phoneNumber: pipe(defaultTo(''), fromPhoneE164, onlyDigits),
  }),
  reject(isEmptyString),
);

export const createCardDataIdFromCardDTO = applySpec<
  Pick<Required<IRxCard>, 'dataId'>
>({
  dataId: prop('hash'),
});

const base64AsSource = (base64: string) => `data:${base64}`;

export const createCardImageFromCardDTO = applySpec<IRxCardImage>({
  cardId: prop<keyof IRxCardDTO>('cardId'),
  userId: prop<keyof IRxCardDTO>('userId'),
  hccUserId: prop<keyof IRxCardDTO>('hccUserId'),
  url: pipe(prop<keyof IRxCardDTO, string>('base64Card'), base64AsSource),
});
