import { noon } from '@hc-frontend/core-utils-function';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import type { MouseEvent } from 'react';

import type { CloseButtonProps, DialogHCProps } from './dialog.types';

const CloseButton = ({ onClick = noon }: CloseButtonProps) => {
  return (
    <IconButton
      size={'small'}
      color={'primary'}
      onClick={(event: MouseEvent<HTMLElement>) =>
        onClick(event, 'backdropClick')
      }
      sx={{
        position: 'absolute',
        right: '1%',
        top: '1.5%',
      }}
      data-testid="dialog-close-button"
    >
      <CloseIcon />
    </IconButton>
  );
};

const DialogHC = ({
  children,
  withCloseButton = true,
  ...props
}: DialogHCProps) => {
  return (
    <MuiDialog fullWidth {...props}>
      {withCloseButton && <CloseButton onClick={props.onClose} />}
      {children}
    </MuiDialog>
  );
};

export { CloseButton, DialogHC };
