import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { JSONObject } from '@hc-frontend/core-utils-types';

import { getHealthcareApiURI } from '../config/endpoints';
import type { IFetchMedicareEnrollmentProspectPayload } from './fetch-medicare-enrollment-prospect-payload';
import type { IFetchMedicareEnrollmentProspectResponse } from './fetch-medicare-enrollment-prospect-response';

/**
 * Function to get url to redirect users to shop.healthcare.com.
 *
 * API repository
 * @see https://github.com/healthcarecom/pivot-health-medicare-enrollment-api/
 *
 * Sunfire Documentation
 * @see https://www.sunfirematrix.com/documentation/#incoming-prospect-post-api
 *
 * @param payload - Payload required for endpoint
 * @returns Object with prospect urls
 *
 * @public
 *
 * @example
 *
 * ```ts
 * const payload = { ... }
 * const response = await fetchRedirectToShopHCService(payload);
 * ```
 */
async function fetchMedicareEnrollmentProspectService(
  payload: IFetchMedicareEnrollmentProspectPayload,
): Promise<IFetchMedicareEnrollmentProspectResponse | null> {
  const { parsedBody } =
    await fetchJSON<IFetchMedicareEnrollmentProspectResponse>(
      `${getHealthcareApiURI()}/medicare-enrollment/prospect/`,
      {
        body: payload as unknown as JSONObject,
      },
    );
  return parsedBody?.id ? parsedBody : null;
}

export { fetchMedicareEnrollmentProspectService };
