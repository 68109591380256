import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadParameterRepository,
} from '@hc-frontend/deprecated-repositories';
import type { NextRequest } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextReadParameterAdapterFactory(
  provider: LogRepository,
  request: NextRequest,
) {
  return buildAdapter<ReadParameterRepository<ErrorCodes.PARAMETER_NOT_READ>>()(
    provider,
    function nextReadParameterAdapter(key) {
      return Promise.resolve(request.nextUrl.searchParams.get(key) || '');
    },
    ErrorCodes.PARAMETER_NOT_READ,
  );
}
