import type {
  AsSuccessOperationResult,
  ErrorCodes,
  IBusinessEntity,
  IOperationResult,
} from '@hc-frontend/deprecated-entities';
import type {
  AddLogTagsRepository,
  ReadDomainRepository as ReadHostRepository,
  ReadPathRepository,
  SetUserRepository,
} from '@hc-frontend/deprecated-repositories';

import { createBusinessSuccessOperationResult } from '../../../helpers';
import { buildExistingSessionId, buildManagedValue } from '../../Entity';
import type { GetCurrentSessionIdsProvider } from '../../Session';
import { getCurrentSessionIds } from '../../Session';

export interface SetLogUserProvider extends GetCurrentSessionIdsProvider {
  readHost: AsSuccessOperationResult<ReadHostRepository<ErrorCodes>>;
  readPath: AsSuccessOperationResult<ReadPathRepository<ErrorCodes>>;
  addLogTags: AddLogTagsRepository;
  setUser: SetUserRepository;
}

/**
 * Set the core log tags for a page lifecycle including experiments, host and url.
 *
 * @param provider - The provider of the use case for reading the host, url and setting the log tags.
 *
 *
 * @public
 *
 * @returns An operation result always returning success to avoid breaking the page lifecycle.
 */
export async function setLogUser<T extends SetLogUserProvider>(
  provider: T,
  service: string,
  environment: string,
  runtime: 'client' | 'server',
): Promise<IOperationResult<IBusinessEntity<true>, ErrorCodes>> {
  const domainResult = await provider.readHost();
  const pathResult = await provider.readPath();

  const sessionIdResult = await getCurrentSessionIds(provider);
  const validSessionIdResult = await buildExistingSessionId(
    sessionIdResult.data,
  );

  if (!validSessionIdResult.success)
    return validSessionIdResult as IOperationResult<
      IBusinessEntity<true>,
      ErrorCodes
    >;

  await provider.setUser(validSessionIdResult.data);

  await provider.addLogTags(
    'company:hc',
    `service:${service}`,
    `env:${environment}`,
    `page:${pathResult.data}`,
    `site:${domainResult.data}`,
    `runtime:${runtime}`,
  );

  return createBusinessSuccessOperationResult(buildManagedValue(true));
}
