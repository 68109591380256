import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import { CloudImage } from '../../atoms';

export function Header() {
  return (
    <AppBar
      position="static"
      data-testid="app-bar-logo"
      sx={(theme) => ({
        height: 80,
        justifyContent: 'center',
        px: 4,
        margin: '1px',
        border: `1px solid ${theme.grayShades[300]}`,
      })}
    >
      <Toolbar
        sx={{
          justifyContent: {
            xs: 'center',
            md: 'start',
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: 192,
              md: 224,
              lg: 256,
              xl: 320,
            },
          }}
        >
          <CloudImage
            assetName="healthcare-logo-cards.webp"
            priority
            width={320}
            height={72}
            alt="Healthcare.com Logo"
            layout="responsive"
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
