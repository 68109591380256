import type { IDictionary } from '@hc-frontend/core-utils-types';
import { mapObjIndexed, mergeDeepLeft, pipe } from 'ramda';

/**
 * Map dictionary keys into a new values
 * @public
 *
 * @param mapper - Function to be applied to each root key
 * @param input - The dictionary to be transformed
 *
 * @returns A new dictionary with same keys but different values
 *
 * @example
 * ```ts
 * const config: = {
 *  key: 'value',
 *  key2: 'value2',
 * }
 * const result = mapDictionary(
 *   (value, key) => key.toUpperCase(),
 *   config
 * );
 * console.log(result);
 * // -> {
 * //   key: 'KEY',
 * //   key2: 'KEY2',
 * // }
 * ```
 */
function mapDictionary<T, U>(
  mapper: (value: T, key: string) => U,
  input: IDictionary<T>,
): IDictionary<U> {
  return pipe(mergeDeepLeft({}), mapObjIndexed(mapper))(input);
}

export { mapDictionary };
