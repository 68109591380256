import type { IPureFunction } from '@hc-frontend/core-utils-types';

/**
 * @internal
 *
 * @param path - The path to the endpoint
 * @param body - The body of the request
 * @param onMessage - A function to call when the request is successful
 * @param onError - A function to call when the request fails
 */
function sendViaXmlHttpRequest(
  path: string,
  body: string,
  onMessage?: IPureFunction<[], void>,
  onError?: IPureFunction<[], void>,
): void {
  const xhr = new XMLHttpRequest();

  xhr.addEventListener('load', () => {
    if (xhr.status === 200) {
      onMessage?.();
    } else {
      onError?.();
    }
  });
  if (onError) {
    xhr.addEventListener('error', onError);
    xhr.addEventListener('abort', onError);
  }
  xhr.open('POST', path, true);

  xhr.send(body);
}

export { sendViaXmlHttpRequest };
