import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { CloudImage } from '../../atoms';

export function CardConfirmationImage() {
  return (
    <Box position="relative" width="100%" height="100%">
      <CloudImage
        assetName="confirmation.webp"
        layout="fill"
        priority
        data-testid="card-image"
        objectFit="cover"
        objectPosition="right top"
        alt="daughter hugging her mother confirmation page image"
      />
    </Box>
  );
}

export function CardConfirmationTitle() {
  const { t } = useTranslation('confirmation');

  return (
    <Typography
      component="h1"
      variant="headingOne"
      sx={(theme) => ({
        lineHeight: {
          xs: theme.typography.pxToRem(34),
          md: 'normal',
        },
      })}
      fontFamily="secondary"
    >
      {t('title')}
    </Typography>
  );
}

function CardConfirmationElement({
  assetName,
  title,
  description,
  id,
}: Record<'assetName' | 'title' | 'description' | 'id', string>) {
  return (
    <Grid item>
      <Stack spacing={4}>
        <Paper
          elevation={5}
          sx={{
            height: {
              xs: '18.6vw',
              md: '10.5vw',
              lg: '9.3vw',
            },
            width: {
              xs: '25.3vw',
              md: '14.1vw',
              lg: '12.6vw',
            },
            position: 'relative',
            borderRadius: 8,
            alignSelf: 'center',
          }}
        >
          <CloudImage
            assetName={assetName}
            layout="fill"
            priority
            data-testid="card-image"
            style={{
              borderRadius: 8,
            }}
            alt={id === 'phone-card-element' ? 'phone image' : 'email image'}
          />
        </Paper>
        <Stack spacing="4px">
          <Typography
            component="p"
            variant="paragraphMD"
            textAlign="center"
            sx={(theme) => ({
              fontWeight: 'bold',
              fontSize: {
                xs: theme.typography.pxToRem(18),
                md: theme.typography.pxToRem(14),
                lg: theme.typography.pxToRem(18),
              },
              width: {
                xs: '30.1vw',
                md: '16.9vw',
                lg: theme.typography.pxToRem(216),
              },
            })}
          >
            {title}
          </Typography>
          <Typography
            component="p"
            variant="paragraphSM"
            textAlign="center"
            sx={(theme) => ({
              fontSize: {
                xs: theme.typography.pxToRem(14),
                lg: theme.typography.pxToRem(16),
              },
              width: {
                xs: '29vw',
                md: '19.4vw',
                lg: theme.typography.pxToRem(221),
              },
            })}
          >
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
}

export function CardConfirmationTip() {
  const { t } = useTranslation('confirmation');

  return (
    <Card
      sx={(theme) => ({
        borderRadius: theme.typography.pxToRem(30),
        height: { lg: '100%' },
        mt: {
          md: -5,
          lg: 0,
        },
        py: {
          xs: 4,
          lg: theme.typography.pxToRem(44),
        },
        pb: { md: 4, lg: theme.typography.pxToRem(42) },
        border: '1px solid',
        borderColor: theme.palette.grey[300],
        width: {
          xs: '85.8vw',
          md: '48.3vw',
          lg: '42.7vw',
        },
      })}
      elevation={0}
      data-testid="confirmation-tip"
    >
      <CardContent
        sx={{
          py: 0,
        }}
      >
        <Stack>
          <Typography
            component="h2"
            variant="headingThree"
            fontWeight="bold"
            textAlign="center"
            mb={{ md: 2, lg: 6 }}
            sx={(theme) => ({
              fontSize: {
                lg: theme.typography.pxToRem(28),
              },
            })}
          >
            {t('tipsTitle')}
          </Typography>
          <Grid container justifyContent="center" columnSpacing={{ lg: 6 }}>
            <CardConfirmationElement
              assetName="phone.webp"
              title={t('textTitle')}
              description={t('textDescription')}
              id="phone-card-element"
            />

            <CardConfirmationElement
              assetName="email.webp"
              title={t('emailTitle')}
              description={t('emailDescription')}
              id="email-card-element"
            />
          </Grid>
        </Stack>
      </CardContent>
      <CardActions
        sx={(theme) => ({
          justifyContent: 'center',
          mt: theme.typography.pxToRem(15),
        })}
      >
        <Button
          href="https://www.healthcare.com"
          sx={(theme) => ({
            px: 0,
            py: 4,
            height: theme.typography.pxToRem(58),
            width: {
              xs: '56.8vw',
              md: '35vw',
              lg: '18.4vw',
            },
          })}
          color="secondary"
        >
          {t('back')}
        </Button>
      </CardActions>
    </Card>
  );
}
