import { loadObject, saveObject } from '@hc-frontend/core-utils-storage';
import type { IMember } from '@hc-frontend/deprecated-entities';

import { ObjectStorageKeys } from '../../../enums/objects-storage-keys';
import type { IMembersRepository } from '../../../repository/members-repository';

const membersRepositoryV1 = <IMembersRepository>{
  getMembers() {
    return loadObject<IMember[]>(ObjectStorageKeys.Members);
  },
  saveMembers(members: IMember[]): void {
    saveObject(ObjectStorageKeys.Members, members);
  },
};

export { membersRepositoryV1 };
