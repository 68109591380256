import { getRootDomain } from '@hc-frontend/core-utils-dom';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadUrlRepository,
} from '@hc-frontend/deprecated-repositories';
import type { NextRequest } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextReadDomainAdapterFactory(
  provider: LogRepository,
  request: NextRequest,
  rootDomain: string,
) {
  return buildAdapter<ReadUrlRepository<ErrorCodes.DOMAIN_NOT_READ>>()(
    provider,
    function nextReadDomainAdapter() {
      return Promise.resolve(
        getRootDomain(request.nextUrl.origin) || rootDomain || '',
      );
    },
    ErrorCodes.DOMAIN_NOT_READ,
  );
}
