/**
 * Extract the E164 parts from a phone number
 * @public
 *
 * @param phone - An unformatted 12-digit phone number string
 *
 * @returns phone number parts if phone is valid, otherwise empty array
 *
 *
 * @example
 * ```ts
 * cont result = getE164Parts('+12125559656');
 * console.log(result);
 * // -> ['212', '555', '9656']
 * ```
 */
function getE164Parts(phone: string): [string, string, string] | [] {
  if (phone.length === 12) {
    return [
      phone.substring(2, 5),
      phone.substring(5, 8),
      phone.substring(8, 12),
    ];
  }

  return [];
}

export { getE164Parts };
