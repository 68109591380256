import type {
  IBusinessEntity,
  IExperiment,
  ISuccessOperationResult,
} from '@hc-frontend/deprecated-entities';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  TrackExperimentViewRepository,
} from '@hc-frontend/deprecated-repositories';

import { createBusinessSuccessOperationResult } from '../../../helpers';
import { buildManagedValue } from '../../Entity';

export interface ShowVariantToUserProvider extends LogRepository<ErrorCodes> {
  trackExperimentView: TrackExperimentViewRepository<ErrorCodes>;
}

/**
 * This use case is responsible of showing a variant to a user. This method is not async as we need to block UI until we get a variant without showing any loading state.
 * This will be possible once we migrate to a newer version of NextJS. The current platform shouldn't be an issue for the use cases but in this specific case,
 * we can't do any workaround and we should not impact SEO and UX because of this. The caveat of this approach is to call trackExperimentView without waiting,
 * but that is totally fine.
 *
 * @param provider - The provider for this use case
 * @param experimentName - The name of the experiment
 * @param runningExperiments - The running experiments for the user in the current page
 * @param variants - The possible variants to be displayed to the user
 * @param control - The control variant to be displayed to the user if any error occurs
 *
 * @returns A success operation result with the variant to be displayed to the user. It will always return a success operation result, even if the experiment is not found or the variant is not found.
 * The reason for this is that we don't want to break the user experience if something goes wrong. We will log the error and return the control variant.
 *
 *
 * @public
 *
 * @example
 * ```ts
 * const result = showVariantToUser(
 *  provider,
 *  experimentName,
 *  runningExperiments,
 *  variants,
 *  control,
 * );
 *
 * // ---> result.data
 * ```
 */
export function showVariantToUser<
  T extends ShowVariantToUserProvider,
  TDisplay,
>(
  provider: T,
  experimentName: string,
  runningExperiments: {
    [key: string]: IExperiment;
  },
  variants: {
    [key: number]: TDisplay;
  },
  control: TDisplay,
): ISuccessOperationResult<IBusinessEntity<TDisplay>, ErrorCodes> {
  const experiment = runningExperiments[experimentName];

  if (!experiment) {
    provider.logError(
      `Experiment ${experimentName} not found, returning control variant`,
      {
        operationErrors: [
          {
            message: ErrorCodes.EXPERIMENT_NOT_FOUND,
          },
        ],
      },
    );

    return createBusinessSuccessOperationResult(
      buildManagedValue(control),
    ) as ISuccessOperationResult<IBusinessEntity<TDisplay>, ErrorCodes>;
  }

  const variantId = experiment.variant.id;
  const variant = variants[variantId];

  if (!variant) {
    provider.logError(
      `Experiment ${experimentName} has no variant ${variantId}, returning control variant`,
      {
        operationErrors: [
          {
            message: ErrorCodes.EXPERIMENT_VARIANT_NOT_FOUND,
          },
        ],
        data: {
          experiment: experiment.name,
          variant: experiment.variant.id.toString(),
        },
      },
    );

    return createBusinessSuccessOperationResult(
      buildManagedValue(control),
    ) as ISuccessOperationResult<IBusinessEntity<TDisplay>, ErrorCodes>;
  }

  provider.trackExperimentView(experiment); // We don't wait for this to finish

  return createBusinessSuccessOperationResult(
    buildManagedValue(variant),
  ) as ISuccessOperationResult<IBusinessEntity<TDisplay>, ErrorCodes>;
}
