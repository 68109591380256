import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Children, useState } from 'react';

import type { BasicSelectProps } from './basic-select.types';

export function BasicSelect({
  children,
  defaultOption,
  handleOnChange,
  defaultOptionText = 'Select one',
  isDefaultOptionDisabled = false,
  ...selectOptions
}: BasicSelectProps) {
  const [value, setValue] = useState<string>(
    (selectOptions?.value as string) ?? '',
  );

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    setValue(event.target.value as string);
    handleOnChange && handleOnChange(event, child);
  };

  return (
    <Select
      value={value}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      sx={(theme: Theme) => ({
        minWidth: '150px',
        color:
          value === ''
            ? theme.palette.text.disabled
            : theme.palette.text.primary,
      })}
      onChange={handleChange}
      IconComponent={KeyboardArrowDownIcon}
      {...selectOptions}
    >
      {defaultOption ? (
        <MenuItem disabled={isDefaultOptionDisabled} value="">
          {defaultOption}
        </MenuItem>
      ) : (
        <MenuItem disabled={isDefaultOptionDisabled} value="">
          <Typography variant="paragraphMD">{defaultOptionText}</Typography>
        </MenuItem>
      )}

      {Children.map(children, (child) => child)}
    </Select>
  );
}
