import type { Smartly } from '@hc-frontend/core-third-party-absmartly';
import type { PlainObject } from '@hc-frontend/core-utils-types';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  TrackGoalRepository,
} from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';

export function absmartlyTrackGoalAdapterFactory(
  provider: LogRepository,
  smartly: Smartly,
) {
  return buildAdapter<TrackGoalRepository<ErrorCodes.TRACK_GOAL_FAILED>>(
    smartly,
  )(
    provider,
    function trackGoalAdapter(goalName, goalValue) {
      smartly.context.track(goalName, goalValue as PlainObject<unknown>);
      return Promise.resolve();
    },
    ErrorCodes.TRACK_GOAL_FAILED,
  );
}
