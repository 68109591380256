import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import type { TabsProps } from '@mui/material/Tabs';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';

import type { BasicTabsProps } from './basic-tabs.types';

export function BasicTabs({
  tabLabels,
  tabPanels,
  activeTab = 1,
  onTabChange,
  ...tabsOptions
}: BasicTabsProps & TabsProps) {
  const [selectedTab, setSelectedTab] = useState<string>(`${activeTab}`);

  const handleChange = async (_event: React.SyntheticEvent, index: string) => {
    setSelectedTab(index);
    onTabChange && onTabChange?.(index);
  };

  return (
    <TabContext value={selectedTab}>
      <Tabs value={selectedTab} onChange={handleChange} {...tabsOptions}>
        {tabLabels.map((tab) => (
          <Tab key={tab.id} label={tab.label} value={`${tab.id}`} />
        ))}
      </Tabs>

      {tabPanels.map((panel) => (
        <TabPanel key={panel.id} value={`${panel.id}`}>
          {panel.content}
        </TabPanel>
      ))}
    </TabContext>
  );
}
