import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

export const HoverLayerStyled = styled(Box, { name: 'MenuItemHoverLayer' })(
  ({ theme }: { theme: Theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    pointerEvents: 'none',
    background: `${theme.palette.grey[200]}`,
    '&::after': {
      content: '""',
      background: `${theme.palette.warning.main}`,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: '5px',
    },
  }),
);

export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (prop: string) => prop !== 'hoverOn',
})<{
  hoverOn: boolean;
}>(({ theme, hoverOn }: { theme: Theme; hoverOn?: boolean }) => ({
  minHeight: `${theme.spacing(6)}`,
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 1,
  ...(!hoverOn && {
    '&:hover': {
      background: 'transparent',
    },
  }),
}));
