import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadHeaderRepository,
} from '@hc-frontend/deprecated-repositories';
import type { GetServerSidePropsContext } from 'next';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextReadIncomingMessageHeaderAdapterFactory(
  provider: LogRepository,
  request: GetServerSidePropsContext['req'],
) {
  return buildAdapter<ReadHeaderRepository<ErrorCodes.HEADER_NOT_READ>>()(
    provider,
    function nextReadIncomingMessageHeaderAdapter(key) {
      return Promise.resolve((request.headers[key] as string) || '');
    },
    ErrorCodes.HEADER_NOT_READ,
  );
}
