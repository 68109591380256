import { ISO_DATE_UTC_TIMEZONE_REGEX } from '../regex';

/**
 * Valid if a string date is valid, considering leap years
 *
 * @param date - The string date to validate
 * @returns If value is a valid date string then returns true otherwise returns false
 *
 * @public
 *
 * @example
 * ```ts
 * const result = stringIsValidIsoUtcDate('2020-04-20T09:32:28+00:00');
 * console.log(result);
 * // -> true
 * const result = stringIsValidIsoUtcDate('2020-04-20T09:32:28.123Z');
 * console.log(result);
 * // -> true
 * const result = stringIsValidIsoUtcDate('2020-09-1012:30:00-0100');
 * console.log(result);
 * // -> false
 * ```
 */
function stringIsValidIsoUtcDate(date: string): boolean {
  return ISO_DATE_UTC_TIMEZONE_REGEX.test(date);
}

export { stringIsValidIsoUtcDate };
