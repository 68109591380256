import type { Nullable } from '@hc-frontend/core-utils-types';
import { isEmpty, isNil } from 'ramda';

/**
 * Validates if the value is an empty string or not.
 * @public
 *
 * @param arg - The argument to validate.
 *
 * @returns A boolean indicating if the arg is an String or not.
 *
 * @example
 * ```ts
 * const result = isEmptyString('string');
 * console.log(result); // -> false
 * const result2 = isEmptyString(''); // will be the same for null or undefined
 * console.log(result); // -> true
 * ```
 */
function isEmptyString(arg: Nullable<string> | undefined): boolean {
  return isNil(arg) || isEmpty(arg);
}

export { isEmptyString };
