import { trackUserClick } from '@hc-frontend/core-third-party-snowplow';
import type { LogRepository } from '@hc-frontend/deprecated-repositories';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';
import type { TrackCreateCardCallToAction } from '@hc-frontend/shells-rx-cards-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';

export function snowplowTrackCreateCardCTAAdapterFactory(
  provider: LogRepository,
) {
  return buildAdapter<
    TrackCreateCardCallToAction<RxCardsErrorCodes.TRACK_CREATE_CARD_CTA_FAILED>
  >()(
    provider,
    function snowplowTrackCreateCardCTAAdapter(id) {
      trackUserClick({
        label: 'Send Card',
        value: id,
        elementId: 'send_card',
        targetUrl: '/confirmation',
        action: 'click',
        category: 'rx_cards',
        placement: 'form-bottom',
      });
      return Promise.resolve();
    },
    RxCardsErrorCodes.TRACK_CREATE_CARD_CTA_FAILED,
  );
}
