import type {
  AsSuccessOperationResult,
  ErrorCodes,
  IBusinessEntity,
  ISessionId,
  ISuccessOperationResult,
} from '@hc-frontend/deprecated-entities';
import type { ReadCookieValueRepository } from '@hc-frontend/deprecated-repositories';

import { createBusinessSuccessOperationResult } from '../../../helpers';
import { buildManagedValue } from '../../Entity/build-managed-value/build-managed-value';

export type GetCurrentSessionIdsProvider = {
  readCookieValue: AsSuccessOperationResult<
    ReadCookieValueRepository<ErrorCodes>
  >; // We should continue executing this use case no matter if reading cookie failed.
};

/**
 * Returns the session id object containing only the session id and user id to improve performance, letting the client to fetch the session lazily.
 *
 * @internal
 *
 * @param provider - The providers to read the user id and session id from the store.
 *
 * @returns A session id object consisting of the user id and session id.
 *
 * @remarks This function is aimed to be used only for session use cases and not to be exposed to the public.
 *
 * @example
 * ```ts
 * const sessionId = getCurrentSessionIds(repository);
 * ```
 */
export async function getCurrentSessionIds<
  T extends GetCurrentSessionIdsProvider,
>(
  provider: T,
): Promise<
  ISuccessOperationResult<IBusinessEntity<Partial<ISessionId>>, ErrorCodes>
> {
  const userId = buildManagedValue(
    (await provider.readCookieValue('healthcareUser')).data || undefined,
  );
  const sessionId = buildManagedValue(
    (await provider.readCookieValue('healthcareSession')).data || undefined,
  );

  return createBusinessSuccessOperationResult(
    buildManagedValue({
      userId,
      sessionId,
    }),
  ) as ISuccessOperationResult<
    IBusinessEntity<Partial<ISessionId>>,
    ErrorCodes
  >;
}
