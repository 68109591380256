/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/util-format` is a package that contains utility functions
 * independent of any project or business logic.
 *
 * @packageDocumentation
 */

export * from './capitalize/capitalize';
export * from './from-phone-E164/from-phone-E164';
export * from './get-E164-parts/get-E164-parts';
export * from './number-to-locale-string/number-to-locale-string';
export * from './only-digits/only-digits';
export * from './to-phone-E164/to-phone-E164';
export * from './to-slug/to-slug';
export * from './to-us-phone/to-us-phone';
export * from './xss/xss';
