import { PhoneInputFormat } from '@hc-frontend/core-ui-components';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import { Controller, useForm } from 'react-hook-form';

import type { ZipIntegrationControlsPropTypes } from '../zip-integration-controls';
import { ZipIntegrationControls } from '../zip-integration-controls';
import {
  defaultValues,
  generateValidations,
} from './transfer-prescriber-form.config';
import type {
  TransferPrescriberFormFields,
  TransferPrescriberFormPropTypes,
} from './transfer-prescriber-form.types';

const TransferPrescriberForm = ({
  formId,
  onSubmit,
  initialValues,
  translations,
}: TransferPrescriberFormPropTypes) => {
  const suffixOptions = ['MD', 'DO', 'NP', 'PA'];
  const firstNameInputId = `${formId}-fistName`;
  const lastNameInputId = `${formId}-lastName`;
  const suffixInputId = `${formId}-suffix`;
  const addressInputId = `${formId}-address`;
  const phoneInputId = `${formId}-phone`;

  const validations = generateValidations(translations);
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: 'onTouched',
    defaultValues: { ...defaultValues, ...initialValues },
  });

  const { errors } = formState;

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <Grid container columnSpacing={6} rowSpacing={4}>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.firstName?.message}
            data-testid="prescriberFirstName"
          >
            <FormLabel htmlFor={firstNameInputId}>
              {translations.firstNameLabel}
            </FormLabel>
            <Controller<TransferPrescriberFormFields>
              name="firstName"
              rules={validations.firstName}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  id={firstNameInputId}
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormHelperText error>{errors.firstName?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={8}>
          <FormControl
            fullWidth
            error={!!errors.lastName?.message}
            data-testid="prescriberLastName"
          >
            <FormLabel htmlFor={lastNameInputId}>
              {translations.lastNameLabel}
            </FormLabel>
            <Controller<TransferPrescriberFormFields>
              name="lastName"
              rules={validations.lastName}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  id={lastNameInputId}
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormHelperText error>{errors.lastName?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <FormLabel htmlFor={suffixInputId}>
              {translations.suffixLabel}
            </FormLabel>
            <Controller<TransferPrescriberFormFields>
              name="suffix"
              rules={validations.suffix}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id={suffixInputId}
                  data-testid="prescriberSuffix"
                  native
                >
                  <option value="">{translations.notApplicable}</option>
                  {suffixOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.streetAddress?.message}
            data-testid="prescriberStreetAddress"
          >
            <FormLabel htmlFor={addressInputId}>
              {translations.addressLabel}
            </FormLabel>
            <Controller<TransferPrescriberFormFields>
              name="streetAddress"
              rules={validations.streetAddress}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  id={addressInputId}
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormHelperText error>
              {errors.streetAddress?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ZipIntegrationControls
            formId={formId}
            translations={translations}
            errors={errors}
            control={
              control as unknown as ZipIntegrationControlsPropTypes['control']
            }
            setValue={
              setValue as unknown as ZipIntegrationControlsPropTypes['setValue']
            }
          ></ZipIntegrationControls>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.phoneNumber?.message}
            data-testid="prescriberPhoneNumber"
          >
            <FormLabel htmlFor={phoneInputId}>
              {translations.phoneLabel}
            </FormLabel>
            <Controller<TransferPrescriberFormFields>
              name="phoneNumber"
              rules={validations.phoneNumber}
              control={control}
              render={({ field, fieldState }) => (
                <InputBase
                  {...field}
                  error={fieldState.invalid}
                  placeholder="(___) ___-____"
                  id={phoneInputId}
                  type="text"
                  fullWidth
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent={PhoneInputFormat as any}
                />
              )}
            />
            <FormHelperText error>{errors.phoneNumber?.message}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};
export { TransferPrescriberForm };
