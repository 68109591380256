import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import type { UploadCardHeaderProps } from './upload-card-header.types';

export function UploadCardHeader({
  ns,
  nsKeyPrefix,
}: UploadCardHeaderProps): JSX.Element {
  const { t } = useTranslation(ns, { keyPrefix: nsKeyPrefix });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="headingFour" color="primary">
        {t('uploadIdCardsTitle')}
      </Typography>
      <Typography
        variant="paragraphMD"
        sx={{ mt: (theme) => theme.spacing(1) }}
      >
        {t('uploadIdCardsSubtitle')}
      </Typography>
      <Typography
        variant="paragraphSM"
        sx={{ mt: (theme) => theme.spacing(5) }}
      >
        {t('uploadIdCardsInstructions1')}
      </Typography>
      <Typography variant="disclaimer">
        {t('uploadIdCardsInstructions2')}
      </Typography>
    </Box>
  );
}
