import { noon } from '@hc-frontend/core-utils-function';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import type { CardRightContentProps } from './card-right-content.types';

/*
  Image property loading is set to Eager *on purpose*.
  If not, it could not load the image for printing purposes on firefox and edge
*/
export function CardRigthContent({
  imageUrl,
  isLoading,
  onDownload = noon,
  onLoadingComplete = noon,
}: CardRightContentProps): JSX.Element {
  const { t } = useTranslation('card');

  const cardFullHeight = 446;
  const cardFullWidth = 700;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
      }}
    >
      {isLoading && <CircularProgress data-testid="cardImageLoader" />}
      {!isLoading && imageUrl && (
        <>
          <Box
            data-testid="cardImageContainer"
            sx={{
              position: 'relative',
              width: {
                xs: `${cardFullWidth * 0.5}px`,
                sm: `${cardFullWidth * 0.7}px`,
                md: `${cardFullWidth * 0.9}px`,
                lg: `${cardFullWidth}px`,
              },
              height: {
                xs: `${cardFullHeight * 0.5}px`,
                sm: `${cardFullHeight * 0.7}px`,
                md: `${cardFullHeight * 0.9}px`,
                lg: `${cardFullHeight}px`,
              },
              '> span': {
                '> img': {
                  border: '2px dashed !important',
                  borderRadius: '35px',
                },
              },
              '@media print': {
                width: `${cardFullWidth * 0.7}px`,
                height: `${cardFullHeight * 0.7}px`,
              },
            }}
          >
            <Image
              src={imageUrl}
              alt="card"
              fill
              onLoadingComplete={onLoadingComplete}
            />
          </Box>
          <Box
            sx={{
              marginTop: (theme) => theme.spacing(2),
              '@media print': {
                display: 'none',
              },
            }}
          >
            <Button
              onClick={onDownload}
              data-testid="email-cta"
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.main,
              })}
            >
              {t('downloadCard')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
