import Compressor from 'compressorjs';

import type { CompressParams, CompressResult } from './compress.types';

const iterateQuality = (quality = 1) => quality - 0.1;
const asMegabytes = (bytes: number) => bytes / 1024 / 1024;

function compressByQuality(file: File, quality = 0.1): Promise<File> {
  return new Promise((resolve, reject) => {
    // Disable *no-new* rule since this is the way to use the compressorjs library.
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality,
      success: (compressedFile) => resolve(compressedFile as File),
      error: reject,
    });
  });
}

function compressByMaxSize(
  file: File,
  maxSizeMB: number,
  currentQuality: number,
): Promise<File> {
  if (currentQuality <= 0)
    return Promise.reject(
      new Error('Unable to compress the file to meet the required size'),
    );

  return compressByQuality(file, currentQuality).then((compressedFile) => {
    if (asMegabytes(compressedFile.size) > maxSizeMB)
      return compressByMaxSize(file, maxSizeMB, iterateQuality(currentQuality));

    return compressedFile;
  });
}

const getEntryQuality = (sizeMb: number) => {
  if (sizeMb > 40) return 0.1;
  if (sizeMb > 20) return 0.2;
  if (sizeMb > 5) return 0.3;

  return 1;
};

export async function compress(
  params: CompressParams,
): Promise<CompressResult> {
  if ('quality' in params)
    return compressByQuality(params.file, params.quality)
      .then((compressedFile) => {
        const result: CompressResult = {
          success: true,
          file: compressedFile,
        };
        return result;
      })
      .catch((error) => ({ success: false, error }));

  const entryQualityLevel = getEntryQuality(asMegabytes(params.file.size));
  return compressByMaxSize(params.file, params.maxSizeMB, entryQualityLevel)
    .then((compressedFile) => {
      const result: CompressResult = {
        success: true,
        file: compressedFile,
      };
      return result;
    })
    .catch((error) => ({ success: false, error }));
}
