export type StateAcronym = string;
export type StateName = string;
export type Zip = string;

export enum LocationType {
  GEO = 'G',
  ZIP = 'Z',
}

export interface ILocation {
  zip: Zip;
  stateAcronym: StateAcronym;
  stateName: StateName;
  stateId?: number;
  city: string;
  county: string;
  countyId?: number;
  type: LocationType;
}

export interface IGeoLocation extends ILocation {
  type: LocationType.GEO;
}

export interface IGlobalLocation extends IGeoLocation {
  country: string;
}

export interface IZipLocation extends ILocation {
  type: LocationType.ZIP;
}
