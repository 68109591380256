import { YCenteredLayout } from '@hc-frontend/core-ui-components';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

export function CardFooter() {
  const { t } = useTranslation('card');

  return (
    <YCenteredLayout
      direction={{ xs: 'column', sm: 'row' }}
      sx={{
        alignItems: 'center',
        gap: '3%',
      }}
      pb={5}
      pt={5}
    >
      <Typography variant="disclaimer">{t('disclaimer')}</Typography>
    </YCenteredLayout>
  );
}
