import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import type { Theme } from '@mui/material/styles';
import { styled, useTheme } from '@mui/material/styles';

import type { ListInfoCardProps } from './list-info-card.types';

type InfoCardDetailsProps = {
  theme: Theme;
};
const InfoCardDetailsWrapper = styled(Box)(
  ({ theme }: InfoCardDetailsProps) => ({
    backgroundColor: theme.grayShades[100],
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(4),
    boxShadow: `inset ${theme.shadows[1]}`,
  }),
);

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',
});

export function ListInfoCard({
  titlecomponent,
  messagecomponent,
  icon,
  handleInfoCard,
  cardName = 'list',
}: ListInfoCardProps) {
  const theme = useTheme();

  return (
    <Grid
      container
      wrap="nowrap"
      component={InfoCardDetailsWrapper}
      onClick={handleInfoCard}
    >
      <Grid item container xs={9}>
        <Grid item xs={12} md={5}>
          {titlecomponent}
        </Grid>
        <Grid item xs={12} md={7}>
          {messagecomponent}
        </Grid>
      </Grid>
      <Grid item xs={3} component={IconWrapper}>
        {icon ? (
          icon
        ) : (
          <IconButton
            data-testid={`${cardName}-info-card-button`}
            onClick={handleInfoCard}
            style={{
              color: theme.palette.grey['400'],
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
