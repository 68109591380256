import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { ExpandMoreIconButton } from '../../atoms/expand-more/expand-more';
import type { CollapsePanelProps } from './collapse-panel.types';

export const CollapsePanel = ({
  expanded,
  handleClick,
  icon = <ChevronRight />,
  title,
  children,
}: CollapsePanelProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          my: 5,
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <ExpandMoreIconButton
          testId="expand-current-orders"
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {icon}
        </ExpandMoreIconButton>
        <Typography variant="paragraphMD" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <Collapse
        in={expanded}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
      <Divider />
    </>
  );
};
