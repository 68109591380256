import { prefixEnvironment } from '@hc-frontend/core-utils-dom';
import type { DictionaryOfKeys } from '@hc-frontend/core-utils-types';

import { EnvironmentName } from '../../../enums/environment-names';

/**
 * Get the name of the environment.
 *
 * @param href - A valid URL
 * @returns The name of the environment
 *
 * @public
 */
function getEnvironmentName(href = window.location.hostname): EnvironmentName {
  const envs: DictionaryOfKeys<string, EnvironmentName> = {
    'qa.': EnvironmentName.QA,
    'stg.': EnvironmentName.STG,
  };

  return envs[prefixEnvironment(href)] || EnvironmentName.PROD;
}

export { getEnvironmentName };
