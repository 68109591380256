import type {
  AsSuccessOperationResult,
  ExperimentName,
  IBusinessEntity,
  IExperiment,
  ISuccessOperationResult,
} from '@hc-frontend/deprecated-entities';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  GetExperimentsTreatmentsRepository,
  GetUserAgentRepository,
  LogRepository,
  SetUserSegmentsRepository,
} from '@hc-frontend/deprecated-repositories';
import { SetUserSegmentsRepositoryType } from '@hc-frontend/deprecated-repositories';

import { createBusinessSuccessOperationResult } from '../../../helpers';
import { buildManagedValue } from '../../Entity';

export interface ExposeUserToExperimentProvider
  extends LogRepository<ErrorCodes> {
  getTreatments: GetExperimentsTreatmentsRepository<ErrorCodes>;
  getUserAgent: AsSuccessOperationResult<GetUserAgentRepository<ErrorCodes>>;
  setUserSegment: SetUserSegmentsRepository<ErrorCodes>;
}

export async function exposeUserToExperiments<
  T extends ExposeUserToExperimentProvider,
>(
  provider: T,
  ...experimentsToRun: ExperimentName[]
): Promise<
  ISuccessOperationResult<
    IBusinessEntity<{ [key in typeof experimentsToRun[number]]: IExperiment }>,
    ErrorCodes
  >
> {
  provider.logInfo('Exposing user to experiment', {
    experimentName: experimentsToRun,
  });
  const userAgentResult = await provider.getUserAgent();

  await provider.setUserSegment({
    type: SetUserSegmentsRepositoryType.USER_AGENT,
    userSegment: userAgentResult.data,
  });

  const treatmentResult = await provider.getTreatments(...experimentsToRun);

  if (treatmentResult.success) {
    const experiments = treatmentResult.data.reduce(
      (runningExperiments, experiment) => ({
        ...runningExperiments,
        [experiment.name]: {
          id: experiment.id,
          name: experiment.name,
          variant: {
            id: experiment.variant.id,
          },
        },
      }),
      {} as { [key in typeof experimentsToRun[number]]: IExperiment },
    );

    return createBusinessSuccessOperationResult(
      buildManagedValue(experiments),
    ) as ISuccessOperationResult<
      IBusinessEntity<{
        [key in typeof experimentsToRun[number]]: IExperiment;
      }>,
      ErrorCodes
    >;
  }

  provider.logError('Failed to get treatments', {
    operationErrors: [
      {
        message: ErrorCodes.GET_EXPERIMENT_TREATMENT_FAILED,
      },
    ],
  });

  return createBusinessSuccessOperationResult(
    buildManagedValue({}),
  ) as ISuccessOperationResult<
    IBusinessEntity<{
      [key in typeof experimentsToRun[number]]: IExperiment;
    }>,
    ErrorCodes
  >;
}
