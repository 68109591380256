import type { WithOptional } from '@hc-frontend/core-utils-types';
import type {
  AsSuccessOperationResult,
  IBusinessEntity,
  IOperationResult,
  IRequest,
  ISession,
  ISessionId,
  ITracking,
} from '@hc-frontend/deprecated-entities';
import { ErrorCodes, Products } from '@hc-frontend/deprecated-entities';
import type {
  ParseUserAgentRepository,
  ReadDomainRepository,
  ReadHeaderRepository,
  ReadParameterRepository,
  ReadUrlRepository,
} from '@hc-frontend/deprecated-repositories';

import {
  createBusinessErrorOperationResult,
  createBusinessSuccessOperationResult,
} from '../../../helpers';

export type BuildNewSessionProvider = {
  readUrl: AsSuccessOperationResult<ReadUrlRepository<ErrorCodes>>;
  readDomain: ReadDomainRepository<ErrorCodes>;
  readParameter: AsSuccessOperationResult<ReadParameterRepository<ErrorCodes>>;
  parseUserAgent: AsSuccessOperationResult<
    ParseUserAgentRepository<ErrorCodes>
  >;
  readHeader: AsSuccessOperationResult<ReadHeaderRepository<ErrorCodes>>;
};

/**
 * Create a new session object by filling data from session stores.
 *
 * @internal
 *
 * @param provider - The repository with operations to read data for creating the session object.
 *
 * @param sessionIdOptions - A previously created session id object, this object is useful to check if a new session should be created or not.
 * **The intention of constraining this parameter to be a `IBusinessEntity` is to previously create the id through a business use case.**
 *
 * @returns A valid session object.
 *
 * @remarks This function is aimed to be used only for session use cases and not to be exposed to the public.
 */
export async function buildNewSession<T extends BuildNewSessionProvider>(
  provider: T,
  sessionIdOptions: IBusinessEntity<Partial<Partial<ISessionId>>>,
): Promise<
  IOperationResult<WithOptional<ISession, 'sessionId' | 'userId'>, ErrorCodes>
> {
  const userAgentResult = await provider.parseUserAgent();
  const rootDomainResult = await provider.readDomain();
  const ipAddressResult = await provider.readHeader('x-forwarded-for');
  const referrerResult = await provider.readHeader('referrer');
  const landingPageResult = await provider.readUrl();
  const userAgentHeaderResult = await provider.readHeader('user-agent');

  const tracking: Partial<ITracking> = {
    src: (await provider.readParameter('src')).data,
    agentId: (await provider.readParameter('agent_id')).data,
    subid: (await provider.readParameter('subid')).data,
    aid: (await provider.readParameter('aid')).data,
    pubid: (await provider.readParameter('pubid')).data,
    gclid: (await provider.readParameter('gclid')).data,
    msclkid: (await provider.readParameter('msclkid')).data,
    irpid: (await provider.readParameter('irpid')).data,
    ircid: (await provider.readParameter('ircid')).data,
    iv: (await provider.readParameter('iv')).data,
    pcid: (await provider.readParameter('pcid')).data,
    prPid: (await provider.readParameter('pr_pid')).data,
    utmSource: (await provider.readParameter('utm_source')).data,
    utmCampaign: (await provider.readParameter('utm_campaign')).data,
    utmMedium: (await provider.readParameter('utm_medium')).data,
    utmContent: (await provider.readParameter('utm_content')).data,
    utmTerm: (await provider.readParameter('utm_term')).data,
  };

  if (!rootDomainResult.success)
    return createBusinessErrorOperationResult(
      ErrorCodes.MISSING_LANDING_PAGE_URL,
    );

  // TODO: validate src/subid or iv depending on corner cases
  const plainRequest: Partial<IRequest> = {
    rootDomain: rootDomainResult.data,
    ipAddress: ipAddressResult.data,
    userAgent: userAgentHeaderResult.data,
    landingPage: landingPageResult.data,
    referrer: referrerResult.data,
    device: userAgentResult.data.device,
  };

  const request = plainRequest as IRequest;

  // TODO: add email
  return createBusinessSuccessOperationResult({
    tracking,
    request,
    product: Products.Health, // TODO: Apply logic to determine product,
    ...sessionIdOptions,
  });
}
