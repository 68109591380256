import type {
  IUseCaseResponse,
  IZipLocation,
  Zip,
} from '@hc-frontend/deprecated-entities';
import { AdapterResponseStatus } from '@hc-frontend/deprecated-entities';

import type { ILocationByZipRepository } from '../../../repository/location-repository';

/**
 * Fetch all possible location information from a zip code
 *
 * @param repository - The repository instance to handle location. See {@link locationRepository}
 * @param zip - A valid zip code
 * @returns An async array of possible zip code matches. If there are no matches then an empty array is returned
 *
 * @public
 *
 * @example
 * ```ts
 * const location = await fetchAllLocationByZip(locationRepository, '3133'); // Array
 * ```
 */
async function fetchAllLocationsByZip<T extends ILocationByZipRepository>(
  repository: T,
  zip: Zip,
): Promise<IUseCaseResponse<IZipLocation[]>> {
  try {
    const { data, status } = await repository.fetchAllLocationsByZip(zip);

    if (status !== AdapterResponseStatus.Ok) {
      return {
        success: false,
        data: undefined,
        errors: [{ message: 'Bad service response' }],
      };
    }

    return {
      success: true,
      data,
      errors: [],
    };
  } catch (error) {
    // TODO: add raygun notification
    // TODO: Move standard error messages to new package
    return {
      success: false,
      data: undefined,
      errors: [{ message: 'Service error' }],
    };
  }
}

export { fetchAllLocationsByZip };
