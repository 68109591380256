import { trackUserClick } from '@hc-frontend/core-third-party-snowplow';
import { FilePicker } from '@hc-frontend/core-ui-components';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import type { Accept } from 'react-dropzone';
import { ErrorCode } from 'react-dropzone';

import { BackCard, FrontCard } from '../../atoms';
import {
  uploadBackIdPhotoEvent,
  uploadBackInsurancePhotoEvent,
  uploadFrontIdPhotoEvent,
  uploadFrontInsurancePhotoEvent,
} from '../../constants';
import { ShowImage } from '../../molecules';
import type { UploadCardsProps, UploadedCard } from './upload-id-cards.types';
import { DocumentType } from './upload-id-cards.types';

const accept: Accept = {
  'image/*': ['.tiff', '.bmp', '.jpeg', '.jpg', '.png'],
  // We'll going to disable this file extension as we agreed at the beginning.
  // 'application/pdf': ['.pdf'],
};

const maxSizeMB = 50;

const IconWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const mbToBytes = (mb: number) => mb * 1000 * 1000;

export function UploadCards({
  ns,
  currentCard,
  onCardUploaded,
  onShowError,
  documentType,
  existingCard,
  imageWidth = '288px',
  imageHeight = '144px',
}: UploadCardsProps) {
  const { t } = useTranslation(ns);

  const fileErrorMessages = {
    [ErrorCode.FileTooLarge]: t('fileTooLarge', { size: `${maxSizeMB}MB` }),
    [ErrorCode.FileInvalidType]: t('fileInvalidType'),
    [ErrorCode.FileTooSmall]: t('fileTooSmall'),
    [ErrorCode.TooManyFiles]: t('tooManyFiles'),
    'compression-error': t('compressionError'),
  };

  const handleDrop = (key: keyof UploadedCard) => (files: File[]) => {
    if (documentType === DocumentType.insurance && key === 'front') {
      trackUserClick(uploadFrontInsurancePhotoEvent);
    } else if (documentType === DocumentType.insurance && key === 'back') {
      trackUserClick(uploadBackInsurancePhotoEvent);
    } else if (documentType === DocumentType.id && key === 'front') {
      trackUserClick(uploadFrontIdPhotoEvent);
    } else if (documentType === DocumentType.id && key === 'back') {
      trackUserClick(uploadBackIdPhotoEvent);
    }
    onCardUploaded({ ...currentCard, [key]: files[0] });
  };

  const handleRemove = (key: keyof UploadedCard) => () => {
    onCardUploaded({ ...currentCard, [key]: undefined });
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          sm: 'auto',
          md: 'repeat(2, minmax(0, 1fr))',
        },
        justifyItems: 'center',
        alignItems: 'center',
        columnGap: (theme) => theme.spacing(4),
        marginTop: (theme) => theme.spacing(4),
      }}
    >
      {existingCard?.front ? (
        <ShowImage
          {...existingCard.front}
          width={imageWidth}
          height={imageHeight}
        />
      ) : (
        <FilePicker
          addText={t('uploadFront')}
          removeText={t('removeFile')}
          icon={
            <IconWrapper>
              <FrontCard />
            </IconWrapper>
          }
          accept={accept}
          maxSize={mbToBytes(maxSizeMB)}
          errorMessages={fileErrorMessages}
          onDrop={handleDrop('front')}
          onRemove={handleRemove('front')}
          currentFile={currentCard?.front}
          onShowError={(showError) => onShowError?.({ front: showError })}
          id="frontFilePicker"
          useCompression={true}
          compressedFileSizeMB={2}
        />
      )}
      {existingCard?.back ? (
        <ShowImage
          {...existingCard.back}
          width={imageWidth}
          height={imageHeight}
        />
      ) : (
        <FilePicker
          addText={t('uploadBack')}
          removeText={t('removeFile')}
          icon={
            <IconWrapper>
              <BackCard />
            </IconWrapper>
          }
          accept={accept}
          maxSize={mbToBytes(maxSizeMB)}
          errorMessages={fileErrorMessages}
          onDrop={handleDrop('back')}
          onRemove={handleRemove('back')}
          currentFile={currentCard?.back}
          onShowError={(showError) => onShowError?.({ back: showError })}
          id="backFilePicker"
          useCompression={true}
          compressedFileSizeMB={2}
        />
      )}
    </Box>
  );
}
