import { XCenteredLayout } from '@hc-frontend/core-ui-components';
import { noon } from '@hc-frontend/core-utils-function';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useTranslation } from 'next-i18next';

import type {
  ImageActionProps,
  ImageProps,
  ShowImageProps,
} from './show-image.types';

const Action = function ({
  text,
  onClick,
  icon,
  dataTestId,
}: ImageActionProps) {
  return (
    <Button
      variant="text"
      startIcon={icon}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Typography
        variant="paragraphSM"
        component="span"
        sx={{
          fontWeight: 600,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

const PdfPreview = function ({ fileName, src, width, height }: ImageProps) {
  return (
    <embed
      width={width}
      height={height}
      src={src}
      key={fileName}
      data-testid="pdf-preview"
    ></embed>
  );
};

const PicturePreview = function ({ fileName, src, width, height }: ImageProps) {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: width,
        height,
        borderColor: (theme) => theme.palette['grey'][500],
        borderStyle: 'solid',
        borderWidth: '1px',
      }}
      component="img"
      key={fileName}
      alt={fileName}
      src={src}
    />
  );
};

const MainContainer = styled('div')(`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`);

export function ShowImage({
  ns,
  fileName,
  image,
  uploadedDate,
  downloadAction,
  removeAction = noon,
  width = '300px',
  height = '150px',
  deleteTestId,
  downloadTestId,
}: ShowImageProps) {
  const { t } = useTranslation(ns);

  const fileType = fileName.split('.')[1];
  const tifThumbnailAsset = '/assets/tif_thumbnail.png';

  if (!image) {
    return null;
  }

  return (
    <MainContainer>
      <XCenteredLayout>
        <Typography
          variant="disclaimer"
          color={(theme: Theme) => theme.palette.secondary.main}
          sx={{
            width: '90%',
            marginBottom: (theme) => theme.spacing(),
          }}
        >
          {fileName}
        </Typography>
        <Typography variant="disclaimer" mb={2}>
          {uploadedDate}
        </Typography>
      </XCenteredLayout>
      <Box
        sx={{
          marginTop: 'auto',
          width: '100%',
        }}
      >
        {fileType === 'pdf' ? (
          <PdfPreview
            src={image}
            fileName={fileName}
            height={height}
            width={width}
          />
        ) : (
          <PicturePreview
            src={
              fileType === 'tiff' || fileType === 'tif'
                ? tifThumbnailAsset
                : image
            }
            fileName={fileName}
            width={width}
            height={height}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          marginTop: '-8px',
        }}
      >
        {removeAction && (
          <Action
            text={t('remove')}
            icon={<HighlightOffIcon fontSize="small" color="secondary" />}
            onClick={() => {
              removeAction();
            }}
            dataTestId={deleteTestId}
          />
        )}
        {downloadAction && (
          <Action
            text={t('download')}
            icon={<DownloadForOfflineIcon fontSize="small" color="secondary" />}
            onClick={() => {
              downloadAction();
            }}
            dataTestId={downloadTestId}
          />
        )}
      </Box>
    </MainContainer>
  );
}
