import { parseCookies } from '@hc-frontend/core-utils-dom';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadCookieValueRepository,
} from '@hc-frontend/deprecated-repositories';
import type { GetServerSidePropsContext } from 'next';
import type { NextRequest } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

interface NextReadCookieAdapterOptions {
  request: NextRequest;
  contextRequest: GetServerSidePropsContext['req'];
  contextResponse: GetServerSidePropsContext['res'];
}

function readOptions(
  options: Partial<NextReadCookieAdapterOptions>,
  key: string,
): Promise<string | undefined> {
  const { request, contextRequest, contextResponse } = options;

  if (request) return Promise.resolve(request.cookies.get(key)?.value);
  if (contextRequest) return Promise.resolve(contextRequest.cookies[key]);
  if (contextResponse)
    return Promise.resolve(
      parseCookies((contextResponse.getHeader('set-cookie') || []) as string[])[
        key
      ],
    );

  return Promise.reject(
    new Error('An option must be provided for reading a cookie'),
  );
}

async function readCookieAdapter(
  options: Partial<NextReadCookieAdapterOptions>,
  fallback: (keyof NextReadCookieAdapterOptions)[],
  key: string,
  index = 0,
): Promise<string | undefined> {
  if (index >= fallback.length) return Promise.resolve(undefined);

  const option = fallback[index];

  const result = await readOptions({ [option]: options[option] }, key);

  if (result) return Promise.resolve(result);

  return readCookieAdapter(options, fallback, key, index + 1);
}

export function nextReadCookieAdapterFactory(
  provider: LogRepository,
  options: Partial<NextReadCookieAdapterOptions>,
  fallback: (keyof NextReadCookieAdapterOptions)[],
) {
  return buildAdapter<ReadCookieValueRepository<ErrorCodes.COOKIE_NOT_READ>>()(
    provider,
    function nextReadCookieAdapter(key) {
      return readCookieAdapter(options, fallback, key);
    },
    ErrorCodes.COOKIE_NOT_READ,
  );
}
