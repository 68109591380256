/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorBoundary, useTreatment } from '@hc-frontend/core-ui-components';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import { snowplowTrackExperimentAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-snowplow';
import type { Loader } from 'next/dynamic';
import dynamic from 'next/dynamic';
import type { ComponentType } from 'react';
import { Suspense } from 'react';

import { useLogProvider } from '../../hooks';
import type {
  ComponentLoaderProps,
  ExperimentOptions,
} from './experiment.types';

/**
 *
 * Configure a component to be lazy loaded in the server side.
 *
 * @param component - The component to be lazy loaded
 *
 * @public
 *
 * @returns A component fully configured to be loaded in the server side lazily.
 *
 * @remarks
 * The component returned won't have flickers if parent component doesn't have flickers too.
 * **The call to this function should be on top of the file, see [#39073](https://github.com/vercel/next.js/issues/39073) issue and
 * the [Next.JS docs](https://nextjs.org/docs/advanced-features/dynamic-import)**.
 *
 * @example
 * ```tsx
 * const LazyComponent = configureLazyComponent(import('./component'));
 * ```
 *
 */
export function configureLazyComponent<T extends Loader<any>>(
  component: T,
): ComponentType<T extends Loader<infer P> ? P : never> {
  return dynamic<T extends Loader<infer P> ? P : never>(component, {
    ssr: true,
    suspense: true,
  });
}

/**
 * Configure a component loader with error boundaries and suspense.
 */
export function ComponentLoader({
  fallbackComponent, // Let the dev control the error boundary fallback
  children,
}: ComponentLoaderProps) {
  const logger = useLogProvider();

  return (
    <ErrorBoundary
      fallback={fallbackComponent}
      onError={(error) =>
        logger.logError(ErrorCodes.EXPERIMENT_COULD_NOT_BE_LOADED, {
          cause: error,
        })
      }
    >
      <Suspense fallback={null}>{children}</Suspense>
    </ErrorBoundary>
  );
}

/**
 * Experiment component. It will render the control or the variant depending on the experiment treatment.
 */
export function Experiment({
  experimentName,
  control,
  variants,
}: ExperimentOptions): JSX.Element {
  const logger = useLogProvider();

  const result = useTreatment(
    {
      experimentName,
      control,
      variants,
    },
    {
      ...logger,
      trackExperimentView: snowplowTrackExperimentAdapterFactory(logger),
    },
  );

  return result;
}

export default Experiment;
