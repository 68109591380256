export enum TrackEventElementId {
  EMAIL = 'email',
  TEXT = 'text',
  SEND_CARD = 'send_card',
  DOWNLOAD_CARD = 'download_card',
  PRINT_CARD = 'print_card',
}

export enum TrackEventCategory {
  RX_CARDS = 'rx_cards',
}

export enum TrackEventPlacement {
  FORM_BOTTOM = 'form-bottom',
  EMAIL = 'email',
}

export enum TrackEventAction {
  CLICK = 'click',
}
