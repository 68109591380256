import { loadObject, saveObject } from '@hc-frontend/core-utils-storage';
import type { IContact } from '@hc-frontend/deprecated-entities';

import { ObjectStorageKeys } from '../../../enums/objects-storage-keys';
import type { IContactRepository } from '../../../repository/contact-repository';

const contactRepositoryV1 = <IContactRepository>{
  getContact() {
    return loadObject<IContact>(ObjectStorageKeys.Contact);
  },
  saveContact(contact: IContact): void {
    saveObject(ObjectStorageKeys.Contact, contact);
  },
};

export { contactRepositoryV1 };
