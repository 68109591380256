import type { SessionId, UserId } from '@hc-frontend/deprecated-entities';
import type {
  ConditionalContextProvider,
  ContextEvent,
} from '@snowplow/browser-tracker';

import {
  hcSessionContextFilter,
  hcSessionContextGenerator,
} from './hc-session-global-context-generators';

type HcSessionData = {
  userId: UserId | null;
  sessionId: SessionId | null;
};

interface HcSessionDataProvider {
  readHcSessionContextData(event?: ContextEvent): HcSessionData;
}

const localStorageHcSessionDataProvider: HcSessionDataProvider = {
  readHcSessionContextData: (): HcSessionData => {
    try {
      const hcData = JSON.parse(window.localStorage.getItem('hc') || 'null');
      return {
        userId: hcData.healthcareUserId?.toString() || null,
        sessionId: hcData.healthcareSession?.toString() || null,
      };
    } catch (error) {
      return {
        userId: null,
        sessionId: null,
      };
    }
  },
};

function hcSessionContextProvider(
  dataProvider: HcSessionDataProvider,
): ConditionalContextProvider {
  return [
    hcSessionContextFilter(dataProvider),
    hcSessionContextGenerator(dataProvider),
  ];
}

export type { HcSessionData, HcSessionDataProvider };

export { hcSessionContextProvider, localStorageHcSessionDataProvider };
