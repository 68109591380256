import { downloadBase64File } from '@hc-frontend/core-utils-file';
import Box from '@mui/material/Box';
import { useCallback, useEffect } from 'react';

import { useTrackDownloadCard, useTrackPrintCard } from '../../hooks';
import {
  CardFooter,
  CardHeader,
  CardLeftContent,
  CardRigthContent,
} from '../../molecules';
import type { CardProps, OnLoadingCompleteProps } from './card.types';

export const onLoadingComplete = ({
  options,
  track,
}: OnLoadingCompleteProps) => {
  if (options.print) {
    setTimeout(() => {
      track(options.cardId);
      window.print();
    }, 1000);
  }
};

export function Card({ imageUrl, options }: CardProps) {
  const trackDownload = useTrackDownloadCard();
  const trackPrint = useTrackPrintCard();

  const trackAndDownloadCard = useCallback(() => {
    async function downloadCard() {
      try {
        await downloadBase64File(imageUrl, 'card.png');
      } catch (e) {
        // Do something about it
      }
    }

    // Setting timeout as snowplow is not ready when coming to this conditional
    setTimeout(() => {
      trackDownload(options.cardId);
    }, 1000);
    downloadCard();
  }, [imageUrl, options, trackDownload]);

  useEffect(() => {
    if (options.download && options.cardId && imageUrl) {
      trackAndDownloadCard();
    }
  }, [imageUrl, options.cardId, options.download, trackAndDownloadCard]);

  /* c8 ignore next */
  const onLoaded = () => onLoadingComplete({ options, track: trackPrint });

  return (
    <Box
      pl="5%"
      pr="5%"
      sx={{
        '@media print': {
          '@page': {
            size: 'letter',
            margin: 0,
            padding: 0,
          },
        },
      }}
    >
      <Box
        sx={(theme) => ({
          margin: theme.spacing(5, 0, 8, 0),
          '@media print': {
            margin: theme.spacing(1, 0),
          },
        })}
      >
        <CardHeader />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column-reverse',
            lg: 'row',
          },
          '@media print': {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Box
          sx={(theme) => ({
            margin: {
              lg: theme.spacing(4, 8, 8, 0),
            },
            '@media print': {
              margin: 0,
            },
          })}
        >
          <CardLeftContent />
        </Box>
        <Box
          sx={(theme) => ({
            margin: {
              xs: theme.spacing(0, 0, 2, 0),
              sm: theme.spacing(0, 0, 4, 0),
              md: theme.spacing(0, 0, 8, 0),
              lg: theme.spacing(8, 0, 0, 1),
            },
            '@media print': {
              margin: theme.spacing(4, 0, 4, 0),
            },
          })}
        >
          <CardRigthContent
            imageUrl={imageUrl}
            isLoading={false}
            onLoadingComplete={onLoaded}
            onDownload={trackAndDownloadCard}
          />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(4),
          '@media print': {
            marginTop: theme.spacing(1),
          },
        })}
      >
        <CardFooter />
      </Box>
    </Box>
  );
}
