/**
 * Removes the protocol and the `www.` fragment from an URL.
 *
 * @internal
 *
 * @param url - URL from where the protocol is removed
 *
 * @example
 * ```ts
 * removeProtocol('http://www.healthcare.com');
 * // => healthcare.com
 * ```
 */
export function removeProtocol(url: string): string {
  if (!url) return '';

  return url.replace(/^(\w+:)?\/+(www\.)?/, '');
}
