import { forwardRef } from 'react';
import type { NumberFormatProps } from 'react-number-format';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const ZipCodeInputFormat: React.FunctionComponent<
  CustomProps & NumberFormatProps
> = forwardRef<NumberFormat<unknown>, CustomProps>(function NumberFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="#####"
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
    />
  );
});
