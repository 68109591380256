import type {
  ErrorCodes,
  IBusinessEntity,
  IExperiment,
  ISuccessOperationResult,
} from '@hc-frontend/deprecated-entities';
import type { AddLogTagsRepository } from '@hc-frontend/deprecated-repositories';

import { createBusinessSuccessOperationResult } from '../../../helpers';
import { buildManagedValue } from '../../Entity';

export interface SetExperimentTagsProvider {
  addLogTags: AddLogTagsRepository;
}

/**
 * Set the core log tags for a page lifecycle including experiments, host and url.
 *
 * @param provider - The provider of the use case for reading the host, url and setting the log tags.
 *
 * @param runningExperiments - A dictionary of the running experiments in the page.
 *
 * @public
 *
 * @returns An operation result always returning success to avoid breaking the page lifecycle.
 */
export async function setExperimentLogTags<T extends SetExperimentTagsProvider>(
  provider: T,
  runningExperiments: { [key: string]: IExperiment },
): Promise<ISuccessOperationResult<IBusinessEntity<true>, ErrorCodes>> {
  const tags = Object.entries(runningExperiments).map(
    ([key, value]) => `${key}:${value.variant.id}`,
  );

  await provider.addLogTags(...tags);

  return createBusinessSuccessOperationResult(
    buildManagedValue(true),
  ) as ISuccessOperationResult<IBusinessEntity<true>, ErrorCodes>;
}
