import { pharmacyCardsTheme } from '@hc-frontend/core-ui-theme';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/system/ThemeProvider';
import React from 'react';

export function MuiProvider({ children }: React.PropsWithChildren) {
  return (
    <ThemeProvider theme={pharmacyCardsTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
