import Box from '@mui/material/Box';

export function LandingFaqLayout({ children }: React.PropsWithChildren) {
  return (
    <Box
      pt={{ xs: 6, lg: 9 }}
      px={(theme) => ({
        xs: 0,
        md: theme.typography.pxToRem(30),
        lg: '10vw',
      })}
      pb={9}
    >
      {children}
    </Box>
  );
}
