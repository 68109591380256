/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/metrics` is a package that contains...
 *
 * @packageDocumentation
 */

export * from './snowplow/cross-domain-linker-criterion';
export * from './snowplow/events';
export * from './snowplow/global-contexts/anonymized-pii';
export * from './snowplow/global-contexts/hc-session';
export * from './snowplow/hash-string';
export * from './snowplow/refresh-link-tracking';
export * from './snowplow/snowplow';
