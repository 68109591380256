import { isValidZipCodeFormat } from '@hc-frontend/core-utils-validations';

import type {
  ZipIntegrationControlsFormValidationRules,
  ZipIntegrationControlsTranslations,
} from './zip-integration-controls.types';

export const defaultValues = {
  city: '',
  state: '',
  zipCode: '',
};

export const generateValidations = (
  translations: ZipIntegrationControlsTranslations,
): ZipIntegrationControlsFormValidationRules => ({
  city: {
    required: {
      value: true,
      message: translations.cityRequiredError,
    },
    validate: (v) => (v.trim() ? true : translations.cityValidateError),
  },
  state: {
    required: {
      value: true,
      message: translations.stateRequiredError,
    },
  },
  zipCode: {
    required: {
      value: true,
      message: translations.zipRequiredError,
    },
    validate: (v) =>
      isValidZipCodeFormat(v) ? true : translations.zipPatternError,
  },
});
