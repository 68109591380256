import { US_STATES_LIST } from '@hc-frontend/core-utils-validations';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import type { FieldValues, Path } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { StateFormControlPropTypes } from './state-form-control.types';

export function StateFormControl<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  controlName,
  rules,
  inputId,
  label,
  errorMessage,
  isDisabled = false,
}: StateFormControlPropTypes<TFieldValues, TName>) {
  return (
    <FormControl fullWidth error={!!errorMessage}>
      <FormLabel htmlFor={inputId}>{label}</FormLabel>
      <Controller<TFieldValues>
        name={controlName}
        rules={rules}
        control={control}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            disabled={isDisabled}
            id={inputId}
            error={fieldState.invalid}
            data-testid={inputId}
            native
          >
            <option disabled value=""></option>
            {US_STATES_LIST.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>
        )}
      />
      <FormHelperText error>{errorMessage}</FormHelperText>
    </FormControl>
  );
}
