import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type { AddLogTagsRepository } from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';
import type { RaygunAdapterOptions } from '../types';

export const allTags: string[] = [];

function updateTags(newTags: string[]) {
  newTags.forEach((newTag) => {
    const newLabel = newTag.split(':')[0];

    const tagIndex = allTags.findIndex((tag) => tag.split(':')[0] === newLabel);

    if (tagIndex !== -1) {
      allTags.splice(tagIndex, 1, newTag);
    } else {
      allTags.push(newTag);
    }
  });
  return allTags;
}

export function raygunAddLogTagsAdapterFactory({
  raygun,
  rg4js,
  isLocal,
}: RaygunAdapterOptions) {
  return buildAdapter<AddLogTagsRepository, ErrorCodes.LOG_TAGS_NOT_ADDED>(
    function raygunAddLogTagsAdapter(...tags) {
      const updatedTags = updateTags(tags);
      if (rg4js) {
        rg4js('withTags', updatedTags);
      }

      if (raygun) {
        raygun.setTags(updatedTags);
      }

      return Promise.resolve();
    },
    isLocal,
    ErrorCodes.LOG_TAGS_NOT_ADDED,
    console.debug,
  );
}
