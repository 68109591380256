import type { StackProps } from '@mui/material/Stack';
import Stack from '@mui/material/Stack';

export function XCenteredLayout({ children, ...props }: StackProps) {
  return (
    <Stack alignItems="center" width="100%" {...props}>
      {children}
    </Stack>
  );
}
