/**
 * Returns true if the value is defined and not null
 * after type-checking the type is well defined
 * @public
 *
 * @param arg - A generic argument to check
 *
 * @returns A boolean indicating whether the arg is defined or not.
 *
 * @example
 * ```ts
 * const result = isDefined(undefined);
 * console.log(result);
 * // -> false
 * ```
 */
function isDefined<T>(arg: unknown): arg is T {
  return arg !== undefined && arg !== null;
}

export { isDefined };
