import type { Smartly } from '@hc-frontend/core-third-party-absmartly';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  SetUserSegmentsRepository,
} from '@hc-frontend/deprecated-repositories';
import { SetUserSegmentsRepositoryType } from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';

export function absmartlySetUserSegmentAdapterFactory(
  provider: LogRepository,
  smartly: Smartly,
) {
  return buildAdapter<
    SetUserSegmentsRepository<ErrorCodes.SET_USER_EXPERIMENT_SEGMENT_FAILED>
  >(smartly)(
    provider,
    function absmartlySetUserSegmentAdapter({ type, userSegment }) {
      if (type === SetUserSegmentsRepositoryType.LOCATION) {
        smartly.context.attributes({
          state: userSegment.stateName,
        });
      }

      if (type === SetUserSegmentsRepositoryType.ACCOUNT_TYPE) {
        smartly.context.attributes({
          accountType: userSegment.phoneNumber ? 'phoneNumber' : 'email',
        });
      }

      if (type === SetUserSegmentsRepositoryType.USER_AGENT) {
        smartly.context.attributes({
          user_agent: userSegment,
        });
      }

      return Promise.resolve();
    },
    ErrorCodes.SET_USER_EXPERIMENT_SEGMENT_FAILED,
  );
}
