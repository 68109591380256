import type {
  IApplicant,
  IChild,
  IMember,
  ISpouse,
} from '@hc-frontend/deprecated-entities';

import {
  findApplicant,
  findChildren,
  findSpouse,
} from '../find-member-by-type/find-member-by-type';

/**
 * Convert a member to a query string.
 *
 * @param suffix - The suffix to use for the query string.
 * @param member - The member to convert to a query string.
 * @returns - The query string representation of the member.
 *
 * @example
 * ```ts
 * const result = memberToQueryString('', {
 *   id: '0',
 *   type: MemberType.Applicant,
 *   gender: Gender.Male,
 *   dob: new Date('01/01/1990)
 *   age: 21,
 *   stringDOB: '01/01/1990
 * });
 * console.log(result);
 * // -> 'dob=01/01/1990&gender=f'
 * ```
 */
function memberToQueryString(suffix: string, member?: IMember): string {
  if (!member) {
    return '';
  }

  const { stringDOB, gender, tobacco, firstName, weight, height, lastName } =
    member;

  return [
    `dob${suffix}=${stringDOB}`,
    `gender${suffix}=${gender}`,
    tobacco && `tobacco${suffix}=${tobacco}`,
    firstName && `fname${suffix}=${firstName}`,
    lastName && `lname${suffix}=${lastName}`,
    weight && `weight${suffix}=${weight}`,
    height && `height${suffix}=${height.feet}_${height.inches}`,
  ]
    .filter(Boolean)
    .join('&');
}

/**
 * Convert an Applicant to a query string.
 *
 * @param members - The Applicant to convert to a query string.
 * @returns The query string representation of the members.
 *
 * @public
 *
 * @example
 * ```ts
 * const result = applicantToQueryString({
 *   id: '0',
 *   type: MemberType.Applicant,
 *   gender: Gender.Male,
 *   dob: new Date('01/01/1990)
 *   age: 21,
 *   stringDOB: '01/01/1990
 * });
 * console.log(result);
 * // -> 'dob=01/01/1990&gender=f'
 * ```
 */
function applicantToQueryString(applicant?: IApplicant): string {
  return memberToQueryString('', applicant);
}

/**
 * Convert an Spouse to a query string.
 *
 * @param members - The Spouse to convert to a query string.
 * @returns The query string representation of the members.
 *
 * @public
 *
 * @example
 * ```ts
 * const result = spouseToQueryString({
 *   id: '0',
 *   type: MemberType.Spouse,
 *   gender: Gender.Male,
 *   dob: new Date('01/01/1990)
 *   age: 21,
 *   stringDOB: '01/01/1990
 * });
 * console.log(result);
 * // -> 'dob_s=01/01/1990&gender_s=f'
 * ```
 */
function spouseToQueryString(spouse?: ISpouse): string {
  return memberToQueryString('_s', spouse);
}

/**
 * Convert children to a query string.
 *
 * @param children - The children to convert to a query string.
 * @returns The query string representation of the members.
 *
 * @public
 *
 * @example
 * ```ts
 * const result = childrenToQueryString([{
 *   id: '0',
 *   type: MemberType.Child,
 *   gender: Gender.Male,
 *   dob: new Date('01/01/1990)
 *   age: 21,
 *   stringDOB: '01/01/1990
 * }]);
 * console.log(result);
 * // -> 'dob_1=01/01/1990&gender_1=f'
 * ```
 */
function childrenToQueryString(children: IChild[]): string {
  return children
    .map((child, i) => memberToQueryString(`_${i + 1}`, child))
    .filter(Boolean)
    .join('&');
}

/**
 * Convert a list of members to a query string.
 *
 * @param members - The members to convert to a query string.
 * @returns The query string representation of the members.
 *
 * @public
 *
 * @example
 * ```ts
 * const result = membersToQueryString([{
 *   id: '0',
 *   type: MemberType.Applicant,
 *   gender: Gender.Male,
 *   dob: new Date('01/01/1990)
 *   age: 21,
 *   stringDOB: '01/01/1990
 * }, {
 *   id: '0',
 *   type: MemberType.Spouse,
 *   gender: Gender.Male,
 *   dob: new Date('01/01/1990)
 *   age: 21,
 *   stringDOB: '01/01/1990
 * }]);
 * console.log(result);
 * // -> 'dob=01/01/1990&gender=f&dob_s=01/01/1990&gender_s=f'
 * ```
 */
function membersToQueryString(members: IMember[]): string {
  return [
    applicantToQueryString(findApplicant(members)),
    spouseToQueryString(findSpouse(members)),
    childrenToQueryString(findChildren(members)),
  ]
    .filter(Boolean)
    .join('&');
}

export {
  applicantToQueryString,
  childrenToQueryString,
  membersToQueryString,
  spouseToQueryString,
};
