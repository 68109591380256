import { createTheme } from '@mui/material/styles';

import type { FontTheme } from './util/generate-font-face/generate-font-face';
import { withBaseTheme } from './with-base';

const fontsToLoadFromCDN: FontTheme[] = [
  {
    name: 'proxima-nova',
    weightOptions: [400, 600, 700],
  },
  {
    name: 'bree-serif',
    weightOptions: [400, 600, 700],
  },
];
const baseTheme = withBaseTheme(
  {
    typography: {
      fontFamily: 'proxima-nova, sans-serif',
      fontFamilySecondary: 'bree-serif, serif',
    },
    palette: {
      primary: {
        main: '#124CAF',
        light: '#3063BB',
        dark: '#303952',
      },
      secondary: {
        main: '#305DAB',
        dark: '#798AAD',
      },
      tertiary: {
        main: '#10449e',
        dark: '#B8B8B8',
      },
      background: {
        default: '#F5F5F5',
      },
      divider: '#D2D2D2',
      text: {
        secondary: '#303952',
      },
    },
  },
  fontsToLoadFromCDN,
);

export const insurerExtranetHealthCareComTheme = createTheme(baseTheme, {
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: 1,
          borderColor: baseTheme.grayShades[400],
          '.MuiButtonBase-root': {
            textTransform: 'none',
            padding: `${baseTheme.spacing(3)} ${baseTheme.spacing(6)}`,
            color: baseTheme.palette.primary.dark,
            borderRadius: `${baseTheme.borderRadius.medium} ${baseTheme.borderRadius.medium} 0 0`,
            background: baseTheme.grayShades[300],
            marginRight: baseTheme.spacing(2),
          },
          '.MuiButtonBase-root.Mui-selected': {
            background: baseTheme.palette.primary.light,
            color: baseTheme.grayShades[100],
          },
          '.MuiTabs-indicator': {
            background: 'none',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            padding: `${baseTheme.spacing(2)} ${baseTheme.spacing(
              8,
            )} ${baseTheme.spacing(2)} ${baseTheme.spacing(3)}`,
          },
        },
        icon: {
          color: `${baseTheme.palette.secondary.dark}`,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.paragraphMD,
          color: baseTheme.palette.primary.dark,
          border: `1px solid ${baseTheme.palette.primary.dark}`,
          '& input': {
            padding: `6px ${baseTheme.spacing(3)}`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: `${baseTheme.palette.background.paper}`,
          '.MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            border: '0',
          },
          '&.Mui-focused': {
            boxShadow: `0px 3px 10px ${baseTheme.grayShades[400]}`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.paragraphMD,
          textTransform: 'none',
          ':hover': {
            backgroundColor: baseTheme.palette.tertiary?.main,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.primary.dark,
          '&.Mui-checked': {
            color: baseTheme.palette.primary.dark,
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          '&.MuiInput-input': {
            padding: `6px ${baseTheme.spacing(8)} 6px ${baseTheme.spacing(3)}`,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus':
            {
              outline: 'none',
            },
          '.MuiDataGrid-toolbarContainer .MuiButton-root': {
            ...baseTheme.typography.paragraphSM,
            color: baseTheme.palette.text.primary,
            textTransform: 'Capitalize',
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
            '&:after': {
              border: 'transparent',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
          },
        },
        panel: {
          '.MuiDataGrid-filterForm': {
            gap: `${baseTheme.spacing(1)}`,
          },
          '.MuiDataGrid-panelWrapper': {
            padding: `${baseTheme.spacing(1)}`,
          },
          '.MuiDataGrid-panelHeader .MuiFormControl-root .MuiInputLabel-root:not(.Mui-focused)':
            {
              margin: `6px ${baseTheme.spacing(3)}`,
            },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'inherit',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'inherit',
          },
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
  },
});
