import {
  buildManagedValue,
  createBusinessErrorOperationResult,
  createBusinessSuccessOperationResult,
} from '@hc-frontend/deprecated-business';
import type {
  IBusinessEntity,
  IOperationResult,
} from '@hc-frontend/deprecated-entities';
import type { IRxCard } from '@hc-frontend/shells-rx-cards-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import { buildEmail } from '../build-email/build-email';
import { buildPhoneNumber } from '../build-phone-number/build-phone-number';
import { buildSessionId } from '../build-session-id/build-session-id';
import { buildUserId } from '../build-user-id/build-user-id';

export function buildNewCard({
  email,
  phoneNumber,
  hccId,
  sessionId,
}: Partial<
  Pick<IRxCard, 'email' | 'phoneNumber' | 'hccId' | 'sessionId'>
>): IOperationResult<
  IBusinessEntity<
    Partial<Pick<IRxCard, 'email' | 'phoneNumber' | 'hccId' | 'sessionId'>>
  >,
  RxCardsErrorCodes
> {
  const sessionInfo: Partial<IRxCard> = {};

  const hccIdResult = buildUserId(hccId);

  if (hccIdResult.success) sessionInfo.hccId = hccIdResult.data;

  const sessionIdResult = buildSessionId(sessionId);

  if (sessionIdResult.success) sessionInfo.sessionId = sessionIdResult.data;

  if (!hccIdResult.success || !sessionIdResult.success)
    return createBusinessErrorOperationResult(
      RxCardsErrorCodes.INVALID_SESSION_ID,
    );
  const phoneResult = buildPhoneNumber(phoneNumber);

  if (phoneResult.success)
    return createBusinessSuccessOperationResult(
      buildManagedValue({
        phoneNumber: phoneResult.data,
        ...sessionInfo,
      }),
    );
  if (
    phoneResult.errors.some(
      (error) => error.message !== RxCardsErrorCodes.PHONE_IS_REQUIRED,
    )
  )
    return phoneResult as IOperationResult<
      IBusinessEntity<
        Partial<Pick<IRxCard, 'email' | 'phoneNumber' | 'hccId' | 'sessionId'>>
      >,
      RxCardsErrorCodes
    >;

  const emailResult = buildEmail(email);

  if (emailResult.success)
    return createBusinessSuccessOperationResult(
      buildManagedValue({
        email: emailResult.data,
        ...sessionInfo,
      }),
    );

  if (
    emailResult.errors.some(
      (error) => error.message !== RxCardsErrorCodes.EMAIL_IS_REQUIRED,
    )
  )
    return emailResult as IOperationResult<
      IBusinessEntity<
        Partial<Pick<IRxCard, 'email' | 'phoneNumber' | 'hccId' | 'sessionId'>>
      >,
      RxCardsErrorCodes
    >;

  return createBusinessErrorOperationResult(
    RxCardsErrorCodes.EMAIL_OR_PHONE_REQUIRED,
  );
}
