import { noon } from '@hc-frontend/core-utils-function';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import type { ButtonProps, DrawerProps, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { CallToPharmacistModal } from '../../molecules/call-to-pharmacist-modal';
import type {
  DesktopTabProps,
  ExtendedPalette,
  HeaderCompanionPharmacyProps,
  HeaderCompanionPharmacyTranslations,
} from './header-companion.types';

const CallToButton = styled(Button)<ButtonProps>(() => ({
  borderRadius: 50,
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '17.05px',
  letter: '3%',
  height: 35,
}));

export const DesktopTabs = styled(Button, {
  shouldForwardProp: (prop: string) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }: DesktopTabProps) => ({
  color: isActive ? theme.palette.primary.main : theme.grayShades[700],
  borderRadius: 0,
  fontWeight: 700,
  fontSize: 16,
  ...(isActive && {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  }),
}));

export const DrawerContainer = styled(Drawer)<DrawerProps>(() => ({
  '.MuiDrawer-paper': {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '56px',
  },
}));

const HeaderCompanionPharmacy = ({
  isSignedIn,
  phone,
  translations,
  pages,
  menus,
  logoutCallback,
}: HeaderCompanionPharmacyProps): JSX.Element => {
  const router = useRouter();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const handleOpenNavMenu = () => {
    setMenuOpen(true);
  };
  const handleCloseNavMenu = () => {
    setMenuOpen(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <AppBar
      position="relative"
      color="inherit"
      elevation={0}
      sx={(theme) => ({
        backgroundColor: (theme) =>
          (theme.palette as ExtendedPalette).backgroundBanner.light,
        width: '100%',
        zIndex: theme.zIndex.drawer + 100,
      })}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {isMobile ? (
            <>
              <Box sx={{ display: { xs: 'flex' } }}>
                <IconButton
                  size="large"
                  aria-label="open menubar"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                >
                  <MenuIcon />
                </IconButton>
                <DrawerContainer
                  anchor={'left'}
                  open={isMenuOpen}
                  onClose={handleCloseNavMenu}
                  sx={{
                    '.MuiDrawer-paper': {
                      height: 'calc(100vh - 56px)',
                      boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.14)',
                    },
                    '.MuiBackdrop-root': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <div style={{ marginTop: '30px' }}>
                    {isSignedIn ? (
                      <Box
                        sx={{
                          marginLeft: '14px',
                          marginBottom: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography variant="h4">
                            {translations.welcomeTitle}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginRight: '12px',
                          }}
                        >
                          <CloseIcon onClick={handleCloseNavMenu} />
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          marginLeft: '14px',
                          marginBottom: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Image
                            data-testid="title"
                            src="/assets/pharmacy-logo.svg"
                            width="190"
                            height="28"
                            alt="HealthCare Pharmacy"
                            onClick={() => {
                              router.push('/');
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginRight: '12px',
                          }}
                        >
                          <CloseIcon onClick={handleCloseNavMenu} />
                        </Box>
                      </Box>
                    )}
                    {isSignedIn
                      ? menus.map((menu) => (
                          <List
                            sx={{
                              width: '100%',
                              maxWidth: 360,
                              bgcolor: 'background.paper',
                            }}
                            component="nav"
                            aria-labelledby="list-subheader"
                            key={menu.name}
                          >
                            <ListItemButton
                              sx={{ py: 0 }}
                              onClick={() => {
                                menu.routeTo && router.push(`/${menu.routeTo}`);
                                handleCloseNavMenu();
                              }}
                            >
                              <ListItemText
                                primary={
                                  translations[
                                    menu.name as keyof HeaderCompanionPharmacyTranslations
                                  ]
                                }
                              />
                            </ListItemButton>
                            <Box px={4}>
                              <Divider />
                            </Box>
                          </List>
                        ))
                      : pages.map((page) => (
                          <List
                            sx={{
                              width: '100%',
                              maxWidth: 360,
                              bgcolor: 'background.paper',
                            }}
                            component="nav"
                            aria-labelledby="list-subheader"
                            key={page.name}
                          >
                            <ListItemButton
                              sx={{ paddingBottom: 0, paddingTop: 0 }}
                              onClick={() => {
                                page.routeTo && router.push(page.routeTo);
                                handleCloseNavMenu();
                              }}
                            >
                              <ListItemText
                                primary={
                                  translations[
                                    page.name as keyof HeaderCompanionPharmacyTranslations
                                  ]
                                }
                              />
                            </ListItemButton>
                            <Divider />
                          </List>
                        ))}
                  </div>

                  <Box
                    sx={{
                      textAlign: 'start',
                      fontSize: '18',
                      mx: 4,
                      mt: 'auto',
                    }}
                  >
                    {isSignedIn ? (
                      <Typography variant="body1">
                        <Button variant="text" onClick={logoutCallback}>
                          <strong>{translations.signOut}</strong>
                        </Button>
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body1">
                          <a href="/registration" onClick={handleCloseNavMenu}>
                            <strong> {translations.createAccount}</strong>
                          </a>
                        </Typography>

                        <Typography variant="body1">
                          {translations.registered}
                          <span>
                            <a href="/login" onClick={handleCloseNavMenu}>
                              <strong>{translations.signIn}</strong>
                            </a>
                          </span>
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 9,
                    }}
                  >
                    <Box sx={{ marginTop: '31px', marginLeft: '12px' }}>
                      <PhoneInTalkIcon />
                    </Box>
                    <Box
                      sx={{
                        textAlign: 'center',
                        fontSize: '16',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={(theme) => ({
                          textAlign: 'center',
                          color: theme.palette.primary.main,
                          mx: {
                            xs: theme.spacing(2),
                          },
                          mt: {
                            xs: theme.spacing(3),
                          },
                        })}
                      >
                        {translations.toTalk}
                      </Typography>
                      <Typography variant="body2">
                        {translations.callUsAt}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    variant="disclaimer"
                    sx={{
                      display: 'flex',
                      justifySelf: 'flex-end',
                      mx: 3,
                      mt: 6,
                      mb: 2,
                    }}
                  >
                    Copyright © {new Date().getFullYear()} Companion, Inc.
                  </Typography>
                </DrawerContainer>
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                <Image
                  src="/assets/pharmacy-logo.svg"
                  width="190"
                  height="28"
                  alt="HealthCare Pharmacy"
                  onClick={() => {
                    if (isSignedIn) router.push('/home');
                    else router.push('/');
                  }}
                />
              </Box>

              <CallToButton
                data-testid="callToButton"
                variant="contained"
                href={`tel:${phone.number}`}
                sx={(theme: Theme) => ({
                  padding: `0 ${theme.spacing(2)}`,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.disclaimer['fontSize'],
                    lineHeight: theme.typography.disclaimer['lineHeight'],
                    padding: `${theme.spacing(1)} ${theme.spacing(4)}}`,
                  },
                })}
              >
                {translations.button}
              </CallToButton>
            </>
          ) : (
            <>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Image
                  src="/assets/pharmacy-logo.svg"
                  width="300"
                  height="28"
                  alt="HealthCare Pharmacy"
                  onClick={() => {
                    if (isSignedIn) router.push('/home');
                    else router.push('/');
                  }}
                />
              </Box>
              <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
                {isSignedIn ? null : (
                  <Box sx={{ display: { md: 'flex' } }}>
                    {pages.map((page) => (
                      <DesktopTabs
                        key={page.name}
                        variant="text"
                        isActive={router.pathname === page.routeTo}
                        onClick={
                          page.routeTo
                            ? () => {
                                router.push(page.routeTo as string);
                              }
                            : noon
                        }
                        sx={{
                          my: 2,
                          display: 'block',
                          ml: 4,
                        }}
                      >
                        {
                          translations[
                            page.name as keyof HeaderCompanionPharmacyTranslations
                          ]
                        }
                      </DesktopTabs>
                    ))}
                  </Box>
                )}
              </Box>
              <Box>
                {isSignedIn ? null : (
                  <DesktopTabs
                    onClick={() => router.push('/login')}
                    variant="text"
                    isActive={router.pathname === '/login'}
                    sx={{ mr: 4 }}
                    data-testid="signInButton"
                  >
                    {translations.signIn}
                  </DesktopTabs>
                )}
                <CallToButton
                  data-testid="callToButton"
                  variant="contained"
                  sx={(theme: Theme) => ({
                    padding: `0 ${theme.spacing(4)}`,
                    mr: 8,
                  })}
                  onClick={handleOpen}
                >
                  {translations.button}
                </CallToButton>

                <CallToPharmacistModal
                  phone={phone}
                  open={open}
                  setOpen={setOpen}
                  title={translations.modalTitle}
                  buttonText={translations.modalButton}
                ></CallToPharmacistModal>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export { HeaderCompanionPharmacy };
