import type { IPureFunction } from '@hc-frontend/core-utils-types';

const snowplowQueue: IPureFunction[] = [];
let queueDisabled = false;

export function getSnowplowQueue(): Readonly<IPureFunction[]> {
  return snowplowQueue;
}

export function disableSnowplowQueue() {
  queueDisabled = true;
}

export function exhaustSnowplowQueue() {
  snowplowQueue.forEach((fn) => fn());
  snowplowQueue.length = 0;
  disableSnowplowQueue();
}

export function pushToSnowplowQueue(...fns: IPureFunction[]) {
  snowplowQueue.push(...fns);
  if (queueDisabled) {
    exhaustSnowplowQueue();
  }
}
