import { dateToAge, dateToString } from '@hc-frontend/core-utils-date';
import type { DOB } from '@hc-frontend/deprecated-entities';

/**
 * Create a copy of member and set DOB, age and stringDOB
 *
 * @param member - member to update
 * @param dob - new DOB
 * @returns updated member
 *
 * @public
 *
 * @example
 * ```ts
 * const result = setDOBToMember(member, new Date())
 * ```
 */
function setDOBToMember<T>(member: T, dob: DOB): T {
  return {
    ...member,
    dob,
    stringDOB: dateToString(dob),
    age: dateToAge(dob),
  };
}

export { setDOBToMember };
