import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type { LogInfoRepository } from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../build-adapter/build-adapter';
import type { RaygunAdapterOptions } from '../types';

export function raygunLogInfoAdapterFactory({
  rg4js,
  isLocal,
}: RaygunAdapterOptions) {
  return buildAdapter<LogInfoRepository, ErrorCodes.LOG_INFO_FAILED>(
    function raygunLogInfoAdapter(message, data) {
      if (rg4js) {
        rg4js('recordBreadcrumb', message, { ...data, level: 'info' });
      }

      // TODO: Add support for Raygun Node

      return Promise.resolve();
    },
    isLocal,
    ErrorCodes.LOG_INFO_FAILED,
    console.info,
  );
}
