import { fromPhoneE164, getE164Parts } from '@hc-frontend/core-utils-format';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { CallToPharmacistModal } from '../../molecules/call-to-pharmacist-modal';
import type {
  FooterButtonProps,
  FooterLinkProps,
  FooterPharmacyProps,
  FooterTextProps,
} from './footer-pharmacy.types';

export function FooterText({
  children,
  variant = 'paragraphSM',
  ...props
}: FooterTextProps) {
  return (
    <Typography
      variant={variant}
      {...props}
      sx={(theme) => ({
        flexGrow: 1,
        color: theme.palette.primary.contrastText,
      })}
    >
      {children}
    </Typography>
  );
}

function FooterTitle({ children, variant, ...props }: FooterTextProps) {
  return (
    <FooterText
      variant={variant}
      fontFamily={(theme: Theme) => theme.typography.fontFamilySecondary}
      {...props}
    >
      {children}
    </FooterText>
  );
}

export function FooterSmallText({
  children,
}: Pick<FooterTextProps, 'children'>) {
  return <FooterText variant="disclaimer">{children}</FooterText>;
}

function FooterButton({ children, href, onClick }: FooterButtonProps) {
  return (
    <Button
      href={href}
      onClick={onClick}
      sx={(theme) => ({
        color: 'black',
        backgroundColor: theme.grayShades['300'],
        maxHeight: theme.spacing(6),
        fontSize: theme.spacing(3),
        padding: theme.spacing(3),
        mt: theme.spacing(2),
        mb: theme.spacing(7),
        lineHeight: theme.spacing(4),
        ':hover': {
          backgroundColor: theme.grayShades['400'],
        },
      })}
    >
      {children}
    </Button>
  );
}

export function FooterLogo() {
  return <FooterTitle variant="headingThree">HealthCare Pharmacy</FooterTitle>;
}

export function FooterContactUs({ phone, tty }: FooterPharmacyProps) {
  const { t } = useTranslation('footer');
  const email = t('email');

  return (
    <Stack spacing={4}>
      <FooterText>
        <strong>{t('contactUs')}</strong>
      </FooterText>
      <FooterText>
        <Link href={`tel:${phone}`}>{phone}</Link>
      </FooterText>
      <FooterText>
        <Link href={`tel:${tty}`}>{`${t('tty')}: ${tty}`}</Link>
      </FooterText>
      {email ? (
        <FooterText>
          <Link href={`mailto:${email}`}>{email}</Link>
        </FooterText>
      ) : null}
    </Stack>
  );
}

export function FooterCompany({ baseUrl }: FooterLinkProps) {
  const { t } = useTranslation('footer');

  return (
    <Stack spacing={4}>
      <FooterText>
        <strong>{t('company')}</strong>
      </FooterText>
      <FooterText>
        <a href={`${baseUrl}/about-us`} target="_blank" rel="noreferrer">
          {t('about')}
        </a>
      </FooterText>
    </Stack>
  );
}

export function FooterHaveQuestions({ phone }: FooterPharmacyProps) {
  const { t } = useTranslation('footer');

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <FooterTitle variant="headingTwo" mb="10px">
        {t('haveQuestions')}
      </FooterTitle>
      <FooterButton onClick={handleOpen} href={undefined}>
        {t('callAPharmacist')}
      </FooterButton>
      <CallToPharmacistModal
        phone={{
          number: phone,
          cta: fromPhoneE164(phone),
          parts: getE164Parts(phone),
          tty: null,
          schedule: null,
          extension: null,
        }}
        open={open}
        setOpen={setOpen}
        title={t('callUsAt')}
        buttonText={t('goBack')}
      />
    </Box>
  );
}

export function FooterCardDisclosure() {
  const { t } = useTranslation('footer');

  return (
    <>
      <FooterSmallText>{t('disclosure1')}</FooterSmallText>
      <br />
      <FooterSmallText>{t('disclosure2')}</FooterSmallText>
    </>
  );
}

export function FooterCopyright() {
  const { t } = useTranslation('footer');

  return (
    <FooterSmallText>
      {t('copyright', { year: new Date().getFullYear() })}
    </FooterSmallText>
  );
}

export function FooterNoticeOfPrivacyPractices({ baseUrl }: FooterLinkProps) {
  const { t } = useTranslation('footer');

  return (
    <FooterSmallText>
      <a
        href={`${baseUrl}/notice-of-privacy-practices`}
        target="_blank"
        rel="noreferrer"
      >
        {t('noticeOfPrivacyPractices')}
      </a>
    </FooterSmallText>
  );
}

export function FooterPatientRights({ baseUrl }: FooterLinkProps) {
  const { t } = useTranslation('footer');

  return (
    <FooterSmallText>
      <a
        href={`${baseUrl}/patient-rights-and-notices`}
        target="_blank"
        rel="noreferrer"
      >
        {t('patientRights')}
      </a>
    </FooterSmallText>
  );
}

export function FooterTermsOfUse({ baseUrl }: FooterLinkProps) {
  const { t } = useTranslation('footer');

  return (
    <FooterSmallText>
      <a href={`${baseUrl}/terms-of-use`} target="_blank" rel="noreferrer">
        {t('termOfUse')}
      </a>
    </FooterSmallText>
  );
}

export function FooterPrivacyPolicy({ baseUrl }: FooterLinkProps) {
  const { t } = useTranslation('footer');

  return (
    <FooterSmallText>
      <a href={`${baseUrl}/privacy-policy`} target="_blank" rel="noreferrer">
        {t('privacyPolicy')}
      </a>
    </FooterSmallText>
  );
}

export function FooterClinicalResources({ baseUrl }: FooterLinkProps) {
  const { t } = useTranslation('footer');

  return (
    <FooterSmallText>
      <a
        href={`${baseUrl}/clinical-resources`}
        target="_blank"
        rel="noreferrer"
      >
        {t('clinicalResources')}
      </a>
    </FooterSmallText>
  );
}
