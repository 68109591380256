import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';

import type { SidebarHoveredItemProps, SidebarProps } from './sidebar.types';

function HoverLayer() {
  return (
    <>
      <div
        data-testid="hover-layer"
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: '3px',
          background: '#FFFFFF',
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: 'rgba(250, 250, 250, 0.2)',
        }}
      ></div>
    </>
  );
}

function SidebarHoveredItem({
  id,
  onClick,
  children,
}: SidebarHoveredItemProps) {
  const [hover, setHover] = useState(false);

  return (
    <ListItemButton
      onClick={() => onClick(id)}
      sx={{
        minHeight: ({ spacing }) => spacing(6),
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && <HoverLayer />}
      {children}
    </ListItemButton>
  );
}

export function Sidebar({
  options,
  onClose,
  onClick,
  hideOptionText,
  header,
}: SidebarProps) {
  const HeaderComponent = header;

  return (
    <Drawer variant="permanent">
      {header && (
        <ListItemButton
          onClick={() => onClose && onClose()}
          sx={{
            maxHeight: ({ spacing }) => spacing(5),
            alignItems: 'flex-start',
          }}
        >
          <ListItem component="div">{HeaderComponent}</ListItem>
        </ListItemButton>
      )}
      <List>
        {options.map((option, index) => (
          <SidebarHoveredItem
            key={index.toString()}
            id={option.id}
            onClick={() => onClick(option.id)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}
            >
              {<option.icon />}
            </ListItemIcon>

            {!hideOptionText ? <ListItemText primary={option.text} /> : null}
          </SidebarHoveredItem>
        ))}
      </List>
    </Drawer>
  );
}
