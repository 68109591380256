import '@hc-frontend/core-ui-types';

import type { Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import deepmerge from '@mui/utils/deepmerge';

import type { FontTheme } from './util/generate-font-face/generate-font-face';
import { generateFontFace } from './util/generate-font-face/generate-font-face';

const baseOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 430,
      md: 768,
      lg: 1280,
      xl: 1980,
    },
  },
  spacing: [0, 4, 8, 12, 16, 24, 32, 40, 48, 60],
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
  },
};

const extraOptions = {
  grayShades: {
    100: 'hsl(0, 0%, 100%)',
    200: 'hsl(0, 0%, 95%)',
    300: 'hsl(0, 0%, 90%)',
    400: 'hsl(0, 0%, 75%)',
    500: 'hsl(0, 0%, 61%)',
    600: 'hsl(0, 0%, 45%)',
    700: 'hsl(0, 0%, 29%)',
    800: 'hsl(0, 0%, 15%)',
    900: 'hsl(207, 20%, 9%)',
  },
  borderRadius: {
    none: '0',
    small: '4px',
    medium: '6px',
    large: '10px',
    full: '9999px',
  },
};

export function withBaseTheme(options: ThemeOptions, fontsToLoad: FontTheme[]) {
  const fontBaseTheme = createTheme(
    deepmerge<ThemeOptions>(baseOptions, options),
    extraOptions,
  );

  const pxToRem = fontBaseTheme.typography.pxToRem;

  return createTheme(fontBaseTheme, {
    typography: {
      fontWeightMedium: 600, // semi-bold
      title: {
        fontSize: pxToRem(36),
        lineHeight: 'normal',
        fontFamily: fontBaseTheme.typography.fontFamilySecondary,
        letterSpacing: '1px',
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(46),
        },

        [fontBaseTheme.breakpoints.up('lg')]: {
          fontSize: pxToRem(50),
        },
      },

      titleSM: {
        fontSize: pxToRem(28),
        lineHeight: pxToRem(52),
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(36),
        },

        [fontBaseTheme.breakpoints.up('lg')]: {
          lineHeight: pxToRem(42),
        },
      },

      subTitle: {
        fontSize: pxToRem(28),
        lineHeight: pxToRem(52),
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(36),
        },

        [fontBaseTheme.breakpoints.up('lg')]: {
          lineHeight: pxToRem(42),
        },
      },

      headingOne: {
        fontSize: pxToRem(32),
        lineHeight: pxToRem(45),
        fontFamily: fontBaseTheme.typography.fontFamilySecondary,
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(34),
        },

        [fontBaseTheme.breakpoints.up('lg')]: {
          fontSize: pxToRem(36),
        },
      },

      headingTwo: {
        fontSize: pxToRem(30),
        lineHeight: pxToRem(36),
        fontWeight: fontBaseTheme.typography.fontWeightMedium,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(28),
          lineHeight: pxToRem(32),
        },

        [fontBaseTheme.breakpoints.up('lg')]: {
          fontSize: pxToRem(30),
        },
      },

      headingThree: {
        fontSize: pxToRem(24),
        lineHeight: pxToRem(30),
        fontWeight: fontBaseTheme.typography.fontWeightBold,
      },

      headingFour: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(28),
        fontWeight: fontBaseTheme.typography.fontWeightBold,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(20),
          lineHeight: pxToRem(26),
        },
      },

      paragraphLG: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(30),
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(20),
          lineHeight: pxToRem(26),
        },

        [fontBaseTheme.breakpoints.up('lg')]: {
          lineHeight: pxToRem(30),
        },
      },

      paragraphMD: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(22),
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(18),
          lineHeight: pxToRem(24),
        },
      },

      paragraphSM: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(22),
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(16),
        },
      },

      helpText: {
        fontSize: pxToRem(13),
        lineHeight: pxToRem(20),
        fontWeight: fontBaseTheme.typography.fontWeightRegular,

        [fontBaseTheme.breakpoints.up('md')]: {
          fontSize: pxToRem(14),
          lineHeight: pxToRem(20),
        },
      },

      disclaimer: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(18),
        fontWeight: fontBaseTheme.typography.fontWeightRegular,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: generateBaseStyles(fontBaseTheme, fontsToLoad),
      },
      MuiTypography: {
        defaultProps: {
          variant: 'paragraphLG',
          variantMapping: {
            title: 'h1',
            subTitle: 'h2',
            headingOne: 'h3',
            headingTwo: 'h3',
            headingThree: 'h3',
            headingFour: 'h3',
            paragraphLG: 'p',
            paragraphMD: 'p',
            paragraphSM: 'p',
            disclaimer: 'p',
            helpText: 'span',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true, // No more elevation, on the whole application!
        },
      },
    },
  });
}

function generateBaseStyles(theme: Theme, fontsToLoad: FontTheme[]) {
  return `
    ${generateFontFace(fontsToLoad)}
    *, *::before, *::after {
      box-sizing: border-box;
    }
    html,
    body {
      height: 100%;
      padding: 0;
      margin: 0;
      font-family: ${theme.typography.fontFamily};
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    input, button, textarea, select {
      font: inherit;
    }
    p, h1, h2, h3, h4, h5, h6 {
      overflow-wrap: break-word;
    }
    #__next {
      isolation: isolate;
      height: 100%;
    }
  `.replace(/ |\r\n|\n|\r/gm, '');
}
