import { isString } from '@hc-frontend/core-utils-validations';

/**
 * Returns true if the string is a valid quote id
 *
 * @param arg - A generic argument to check
 * @returns Returns true if the string is a valid quote id
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isValidQuoteId('21321');
 * console.log(result);
 * // -> true
 * ```
 * */
function isValidQuoteId(arg: unknown): arg is string {
  return isString(arg);
}

export { isValidQuoteId };
