import { isString } from '@hc-frontend/core-utils-validations';
import type { SessionId } from '@hc-frontend/deprecated-entities';

/**
 * Returns true if the string is a valid session id
 *
 * @param arg - A generic argument to check
 * @returns Returns true if the string is a valid session id
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isValidSessionId('21321');
 * console.log(result);
 * // -> true
 * ```
 * */
function isValidSessionId(arg: unknown): arg is SessionId {
  return isString(arg);
}

export { isValidSessionId };
