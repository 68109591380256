/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/util-dom` is a package that contains utility functions
 * independent of any project or business logic.
 *
 * @packageDocumentation
 */

export * from './environments-prefix';
export * from './get-cookie-value/get-cookie-value';
export * from './get-env-value/get-env-value';
export * from './get-root-domain/get-root-domain';
export * from './get-safe-url-parameters/get-safe-url-parameters';
export * from './parse-cookies/parse-cookies';
export * from './prefix-environment/prefix-environment';
export * from './prefix-environment-without-qa/prefix-environment-without-qa';
export * from './prefix-environment-without-qa-and-dev/prefix-environment-without-qa-and-dev';
