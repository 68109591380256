import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';

import type {
  NestedListProps,
  NestedListTranslations,
} from './nested-list.types';

const NestedList = ({ page, subPages, translations }: NestedListProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
      component="nav"
      aria-labelledby="list-subheader"
      key={page}
    >
      {subPages.length > 0 ? (
        <ListItemButton
          onClick={handleClick}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <ListItemText primary={page} />
          {open ? (
            <ExpandLess
              sx={(theme) => ({ color: theme.palette.secondary.main })}
            />
          ) : (
            <ExpandMore
              sx={(theme) => ({ color: theme.palette.secondary.main })}
            />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <ListItemText primary={page} />
        </ListItemButton>
      )}
      <Divider />
      {subPages.length > 0 ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {subPages.map((subPage) => (
            <List key={subPage.name} component="div" disablePadding>
              <ListItemButton key={subPage.name} sx={{ pl: 4 }}>
                <ListItemText
                  sx={{ paddingLeft: 4 }}
                  primary={
                    translations[subPage.name as keyof NestedListTranslations]
                  }
                />
              </ListItemButton>
              <Divider />
            </List>
          ))}
        </Collapse>
      ) : null}
    </List>
  );
};

export { NestedList };
