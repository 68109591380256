import type {
  IBusinessEntity,
  IOperationError,
} from '@hc-frontend/deprecated-entities';

import { buildManagedValue } from '../../Entity';

export function createLogError<T>(
  description: string,
  error: IOperationError<T>[],
): IBusinessEntity<Error> {
  return buildManagedValue(
    new Error(
      `${description}. ${error
        .map((e) => `Details='${JSON.stringify(e)}'`)
        .join(', ')}.`,
    ),
  );
}
