import { trackExperimentView } from '@hc-frontend/core-third-party-snowplow';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  TrackExperimentViewRepository,
} from '@hc-frontend/deprecated-repositories';
import { isNil, reject } from 'ramda';

import { buildAdapter } from '../build-adapter/build-adapter';
import { pushToSnowplowQueue } from '../queue/queue';

export function snowplowTrackExperimentAdapterFactory(
  provider: LogRepository,
  options?: Partial<{
    targetUrl: string;
    description: string;
  }>,
) {
  return buildAdapter<
    TrackExperimentViewRepository<ErrorCodes.TRACK_EXPERIMENT_FAILED>
  >()(
    provider,
    function snowplowTrackExperimentAdapter(experiment) {
      const payload: Parameters<typeof trackExperimentView>[0] = {
        experimentId: experiment.id.toString(),
        experimentName: experiment.name,
        variantIndex: experiment.variant.id,
        description: options?.description,
        targetUrl: options?.targetUrl,
        variantName: experiment.variant.name,
      };

      pushToSnowplowQueue(() =>
        trackExperimentView(
          reject(isNil, payload) as typeof payload, // Safe typecasting because we are rejecting nulls/undefined
        ),
      );

      return Promise.resolve();
    },
    ErrorCodes.TRACK_EXPERIMENT_FAILED,
  );
}
