import { toPhoneE164 } from '@hc-frontend/core-utils-format';
import type { Nullable } from '@hc-frontend/core-utils-types';
import {
  isEmptyString,
  isValidUSPhone,
} from '@hc-frontend/core-utils-validations';
import {
  createBusinessErrorOperationResult,
  createBusinessSuccessOperationResult,
} from '@hc-frontend/deprecated-business';
import type {
  IOperationResult,
  PhoneNumber,
} from '@hc-frontend/deprecated-entities';
import type { IRxCardEntity } from '@hc-frontend/shells-rx-cards-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import { buildManagedValue } from '../build-managed-value/build-managed-value';

export function buildPhoneNumber(
  phoneNumber?: Nullable<PhoneNumber>,
): IOperationResult<IRxCardEntity<PhoneNumber>, RxCardsErrorCodes> {
  if (isEmptyString(phoneNumber))
    return createBusinessErrorOperationResult(
      RxCardsErrorCodes.PHONE_IS_REQUIRED,
    );

  const phoneNumberValue = phoneNumber as PhoneNumber;
  if (!isValidUSPhone(phoneNumberValue))
    return createBusinessErrorOperationResult(
      RxCardsErrorCodes.PHONE_IS_INVALID,
    );

  return createBusinessSuccessOperationResult(
    buildManagedValue(toPhoneE164(phoneNumberValue)),
  );
}
