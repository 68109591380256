import type { FilePreview } from '@hc-frontend/core-ui-components';
import type { IPureFunction } from '@hc-frontend/core-utils-types';

import type { ShowImageProps } from '../../molecules';

export enum DocumentType {
  insurance = 'insurance',
  id = 'id',
}

export interface UploadedCard {
  front?: FilePreview;
  back?: FilePreview;
}

export interface ExistingCard {
  front?: ShowImageProps;
  back?: ShowImageProps;
}

export interface UploadCardsProps {
  /**
   * Translation namespace
   */
  ns: string;

  /**
   * Callback when files are uploaded successfully
   */
  onCardUploaded: IPureFunction<[UploadedCard], void>;

  /**
   * Preloaded card if modified previously
   */
  currentCard: UploadedCard;

  /**
   * Type of uploaded document (optional parameter for tracking purposes)
   */
  documentType?: DocumentType;

  /**
   * Callback when card has errors
   */
  onShowError?: IPureFunction<[{ [key in 'front' | 'back']?: boolean }], void>;

  /**
   * Links to already saved card
   */
  existingCard?: ExistingCard;

  /**
   * Image width for preview
   */
  imageWidth?: string;

  /**
   * Image height for preview
   */
  imageHeight?: string;
}
