import { getBrowserRaygun } from '@hc-frontend/core-third-party-raygun';
import {
  raygunAddLogTagsAdapterFactory,
  raygunLogErrorAdapterFactory,
  raygunLogInfoAdapterFactory,
  raygunSetLogUserAdapterFactory,
} from '@hc-frontend/shells-rx-cards-adapter-raygun';
import { useMemo } from 'react';

import { useAppConfig } from '../use-app-config';

export function useLogProvider() {
  const {
    public: {
      app: { isLocal },
    },
  } = useAppConfig();
  const rg4js = getBrowserRaygun();

  const logger = useMemo(
    () => ({
      logError: raygunLogErrorAdapterFactory({ rg4js, isLocal }),
      logInfo: raygunLogInfoAdapterFactory({ rg4js, isLocal }),
      addLogTags: raygunAddLogTagsAdapterFactory({ rg4js, isLocal }),
      setUser: raygunSetLogUserAdapterFactory({ rg4js, isLocal }),
    }),
    [rg4js, isLocal],
  );

  return logger;
}
