import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

export type DrawerContextType = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

export const drawerContextDefaultValues: DrawerContextType = {
  isMenuOpen: false,
  toggleMenu: () => undefined,
};

export const DrawerContext = createContext<DrawerContextType>(
  drawerContextDefaultValues,
);

export function useDrawer() {
  return useContext(DrawerContext);
}

type Props = {
  children: ReactNode;
};

export function DrawerProvider({ children }: Props) {
  const [isMenuOpen, setisMenuOpen] = useState<boolean>(true);

  const toggleMenu = () => {
    setisMenuOpen(!isMenuOpen);
  };

  const value = {
    isMenuOpen,
    toggleMenu,
  };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
}
