import { trackUserClick } from '@hc-frontend/core-third-party-snowplow';
import { useCallback } from 'react';

import {
  TrackEventAction,
  TrackEventCategory,
  TrackEventElementId,
  TrackEventPlacement,
} from './use-track-event.types';

export function useTrackEmailCTA() {
  return useCallback(
    () =>
      trackUserClick({
        label: 'Email',
        value: 'Click to enter email',
        elementId: TrackEventElementId.EMAIL,
        action: TrackEventAction.CLICK,
        category: TrackEventCategory.RX_CARDS,
        placement: TrackEventPlacement.FORM_BOTTOM,
      }),
    [],
  );
}

export function useTrackTextCTA() {
  return useCallback(
    () =>
      trackUserClick({
        label: 'Text',
        value: 'Click to enter Phone Number',
        elementId: TrackEventElementId.TEXT,
        action: TrackEventAction.CLICK,
        category: TrackEventCategory.RX_CARDS,
        placement: TrackEventPlacement.FORM_BOTTOM,
      }),
    [],
  );
}

export function useTrackSendCardCTA() {
  return useCallback(
    (trackId: string) =>
      trackUserClick({
        label: 'Send Card',
        value: trackId,
        elementId: TrackEventElementId.SEND_CARD,
        targetUrl: '/confirmation',
        action: TrackEventAction.CLICK,
        category: TrackEventCategory.RX_CARDS,
        placement: TrackEventPlacement.FORM_BOTTOM,
      }),
    [],
  );
}

export function useTrackDownloadCard() {
  return useCallback(
    (cardId: string) =>
      trackUserClick({
        label: 'Download My Card',
        value: cardId,
        elementId: TrackEventElementId.DOWNLOAD_CARD,
        action: TrackEventAction.CLICK,
        category: TrackEventCategory.RX_CARDS,
        placement: TrackEventPlacement.EMAIL,
        description: 'when user clicks on get Download Card CTA',
      }),
    [],
  );
}

export function useTrackPrintCard() {
  return useCallback(
    (cardId: string) =>
      trackUserClick({
        label: 'Print My Card',
        value: cardId,
        elementId: TrackEventElementId.PRINT_CARD,
        action: TrackEventAction.CLICK,
        category: TrackEventCategory.RX_CARDS,
        placement: TrackEventPlacement.EMAIL,
        description: 'when user clicks on Print My Card CTA',
      }),
    [],
  );
}
