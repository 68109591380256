import type { IOperationResult } from '@hc-frontend/deprecated-entities';

export function createBusinessSuccessOperationResult<T, E = string>(
  data: T,
): IOperationResult<T, E> {
  return {
    data,
    errors: [],
    success: true,
  };
}

export function createBusinessErrorOperationResult<T, E = string>(
  ...errors: E[]
): IOperationResult<T, E> {
  return {
    data: undefined,
    errors: errors.map((error) => ({ message: error })),
    success: false,
  };
}
