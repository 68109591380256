import Script from 'next/script';

interface Props {
  account: string;
}

/**
 * Injects UserWay script to Next.js applications.
 * @see https://userway.org
 */
export function UserWay({ account }: Props) {
  return (
    <>
      <Script
        data-testid="user-way-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(d){var s = d.createElement("script");
          s.setAttribute("data-account", "${account}");
          s.setAttribute("src", "https://cdn.userway.org/widget.js");
          (d.body || d.head).appendChild(s);})(document)
          `,
        }}
      />
      <noscript>
        Please ensure Javascript is enabled for purposes of{' '}
        <a href="https://userway.org">website accessibility</a>
      </noscript>
    </>
  );
}
