import { stringIsValidDate } from '@hc-frontend/core-utils-validations';

/**
 * Convert string 'MM/DD/YYYY' to date
 * @public
 *
 * @param value - Date string in 'MM/DD/YY' format
 *
 * @returns Date instance of the string date provided
 *
 * @example
 * ```ts
 * const newDate = stringToDate('02/29/2020');
 * console.log(newDate);
 * // -> 2020-02-29T00:00:00.000Z
 * ```
 */
function stringToDate(value: string): Date | undefined {
  return stringIsValidDate(value) ? new Date(value) : undefined;
}

export { stringToDate };
