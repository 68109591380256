import { generateV4UUID } from '@hc-frontend/core-utils-random';
import { isEmptyString } from '@hc-frontend/core-utils-validations';
import type {
  IBusinessEntity,
  IOperationResult,
  ISessionId,
  ISuccessOperationResult,
} from '@hc-frontend/deprecated-entities';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  FetchNewSessionRepository,
  LogRepository,
  SaveCookieValueRepository,
} from '@hc-frontend/deprecated-repositories';

import { createBusinessSuccessOperationResult } from '../../../helpers';
import { buildManagedValue } from '../../Entity/build-managed-value/build-managed-value';
import type { BuildNewSessionProvider } from '../../Entity/build-new-session/build-new-session';
import { buildNewSession } from '../../Entity/build-new-session/build-new-session';
import { overwriteError } from '../../Operation/overwrite-error/overwrite-error';
import { retryOperation } from '../../Operation/retry-operation/retry-operation';
import type { GetCurrentSessionIdsProvider } from '../get-current-session-ids/get-current-session-ids';
import { getCurrentSessionIds } from '../get-current-session-ids/get-current-session-ids';

const sessionExpirationAge = 4 * 60 * 60; // 4 hours as per TRON documentation

const userExpirationAge = 4 * 365 * 24 * 60 * 60; // 4 years

export type CreateSessionProvider = {
  fetchNewSession: FetchNewSessionRepository<ErrorCodes>;
  saveCookieValue: SaveCookieValueRepository<ErrorCodes>;
} & BuildNewSessionProvider &
  GetCurrentSessionIdsProvider &
  LogRepository<ErrorCodes>;

/**
 * Returns the session id object containing only the session id and user id to improve performance, letting the client to fetch the session lazily.
 *
 * @public
 *
 * @typeParam T - The type of the repository that should implement
 * the {@link ICreateSessionRepository} interface
 *
 * @param provider - The provider instance to handle session.
 *
 * @returns The session object. See {@link ISession}
 *
 * @example
 * ```ts
 * import {createSessionRepository} from '@hc-frontend/business-shared';
 *
 * const session = createSession(createSessionRepository);
 * ```
 */
async function createSession<T extends CreateSessionProvider>(
  provider: T,
): Promise<IOperationResult<IBusinessEntity<ISessionId>, ErrorCodes>> {
  provider.logInfo('Creating session started.');

  const existingSessionResult = await getCurrentSessionIds(provider); // Optimize, check if session is valid and reuse it.

  const existingSession = existingSessionResult.data;

  if (
    !isEmptyString(existingSession.sessionId) &&
    !isEmptyString(existingSession.userId)
  )
    return createBusinessSuccessOperationResult(
      existingSession as IBusinessEntity<ISessionId>,
    );

  const newSessionResult = await buildNewSession(provider, existingSession);

  if (!newSessionResult.success) {
    provider.logError('Current data for creating session is not valid', {
      operationErrors: newSessionResult.errors,
    });
    return overwriteError(
      newSessionResult,
      ErrorCodes.SOMETHING_WENT_WRONG,
      ErrorCodes.INVALID_SESSION_DATA,
    );
  }

  const newSession = newSessionResult.data;

  let createdSessionResult = await retryOperation(
    {
      operation: () => provider.fetchNewSession(newSession),
      unhandledError: ErrorCodes.SESSION_FAILED,
    },
    { maxRetries: 3, retryThrottling: 100 },
  );

  if (!createdSessionResult.success) {
    // Continue execution even if the session is not created
    // using a local session to avoid loosing traffic.
    provider.logError('Generating a new session because of service failed', {
      operationErrors: createdSessionResult.errors,
    });

    createdSessionResult = createBusinessSuccessOperationResult(
      buildManagedValue<ISessionId>({
        sessionId: generateV4UUID(),
        userId: generateV4UUID(),
      }),
    );
  }

  const createdSession = (
    createdSessionResult as ISuccessOperationResult<ISessionId>
  ).data;

  if (isEmptyString(existingSession.userId))
    // write it only if it's a new user
    await provider.saveCookieValue('healthcareUser', createdSession.userId, {
      maxAge: userExpirationAge,
      domain: newSession.request.rootDomain,
    });

  await provider.saveCookieValue(
    'healthcareSession',
    createdSession.sessionId,
    {
      maxAge: sessionExpirationAge,
      domain: newSession.request.rootDomain,
    },
  );

  return createBusinessSuccessOperationResult(
    buildManagedValue(createdSession),
  );
}

export { createSession };
