import type { GetCurrentSessionIdsProvider } from '@hc-frontend/deprecated-business';
import {
  buildExistingSessionId,
  createBusinessErrorOperationResult,
  createBusinessSuccessOperationResult,
  getCurrentSessionIds,
  overwriteError,
} from '@hc-frontend/deprecated-business';
import type {
  ErrorCodes,
  IOperationResult,
} from '@hc-frontend/deprecated-entities';
import type {
  DebounceRepository,
  GetConversionRepository,
  LogRepository,
  NavigateToRepository,
  ReportConversionRepository,
  SetUserSegmentsRepository,
  TrackGoalRepository,
} from '@hc-frontend/deprecated-repositories';
import { SetUserSegmentsRepositoryType } from '@hc-frontend/deprecated-repositories';
import type { IRxNewCard } from '@hc-frontend/shells-rx-cards-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';
import type {
  CreateRxCardRepository,
  TrackCreateCardCallToAction,
} from '@hc-frontend/shells-rx-cards-repositories';

import { buildNewCard } from '../../Entities';

export interface CreateRxCardProvider
  extends LogRepository,
    GetCurrentSessionIdsProvider {
  createRxCard: CreateRxCardRepository<RxCardsErrorCodes>;
  trackCreateCardCallToAction: TrackCreateCardCallToAction<RxCardsErrorCodes>;
  trackGoal: TrackGoalRepository<ErrorCodes>;
  reportConversion: ReportConversionRepository<RxCardsErrorCodes>;
  getConversion: GetConversionRepository<RxCardsErrorCodes>;
  navigateTo: NavigateToRepository<ErrorCodes>;
  debounce: DebounceRepository;
  setUserSegment: SetUserSegmentsRepository<ErrorCodes>;
}

/**
 *
 * Creates a Rx Card.
 *
 * @param provider - The repository instance with the adapter to use. See {@link createRxCardApiAdapter}
 *
 * @param card - Request object to create rxCard.
 *
 * @returns Always true if the document was successfully created, otherwise the reported errors by the adapter.
 *
 * @public
 *
 * @example
 * ```ts
 * await createRxCard(createRxCardApiAdapter, { phone: '', email: 'test@healthcare.com' });
 * ```
 */
async function createRxCard<
  T extends CreateRxCardProvider,
  TCard extends Pick<IRxNewCard, 'email' | 'phoneNumber'>,
>(provider: T, card: Pick<IRxNewCard, 'email' | 'phoneNumber'>) {
  return provider.debounce(
    1000,
    async (): Promise<IOperationResult<TCard, RxCardsErrorCodes>> => {
      // Infer type in debounced function
      provider.logInfo('Creating Rx Card started with reading session info');
      const currentSessionIdResult = await getCurrentSessionIds(provider);
      const sessionIdResult = await buildExistingSessionId(
        currentSessionIdResult.data,
      );

      if (!sessionIdResult.success) {
        return createBusinessErrorOperationResult(
          RxCardsErrorCodes.POST_RX_CARD,
        );
      }

      provider.logInfo('Creating Rx Card is building the new card');
      const newCardResult = buildNewCard({
        email: card.email,
        phoneNumber: card.phoneNumber,
        hccId: sessionIdResult.data.userId,
        sessionId: sessionIdResult.data.sessionId,
      });

      if (!newCardResult.success) {
        provider.logError('Card data is not valid', {
          operationErrors: newCardResult.errors,
        });
        return newCardResult as IOperationResult<TCard, RxCardsErrorCodes>;
      }

      provider.logInfo('Creating Rx Card is sending the request');
      const result = await provider.createRxCard(newCardResult.data);

      if (result.success) {
        provider.logInfo('Creating Rx Card is tracking the goal');
        await provider.setUserSegment({
          type: SetUserSegmentsRepositoryType.ACCOUNT_TYPE,
          userSegment: {
            email: newCardResult.data.email,
            phoneNumber: newCardResult.data.phoneNumber,
          },
        });
        await provider.trackGoal('rx_cards_landing_page_cta', {
          revenue: 1.5,
        });
        provider.logInfo('Creating Rx Card is tracking the call to action');
        await provider.trackCreateCardCallToAction(
          `${result.data.dataId},${
            newCardResult.data.email || newCardResult.data.phoneNumber
          }`,
        );
        const conversion = await provider.getConversion();

        if (conversion.success) {
          provider.logInfo('Creating Rx Card is reporting the conversion');
          await provider.reportConversion(conversion.data);
        } // If reading conversion fails, then there's nothing we can do about it but logging the error that commonly is done in the adapters layer.

        provider.logInfo('Creating Rx Card is navigating to confirmation page');
        await provider.navigateTo('/confirmation');

        return createBusinessSuccessOperationResult(
          newCardResult.data,
        ) as IOperationResult<TCard, RxCardsErrorCodes>;
      }

      return overwriteError(
        result,
        RxCardsErrorCodes.UNKNOWN_ERROR,
        RxCardsErrorCodes.POST_RX_CARD,
      );
    },
  );
}

export { createRxCard };
