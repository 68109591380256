import { isString } from '@hc-frontend/core-utils-validations';
import type { UserId } from '@hc-frontend/deprecated-entities';

/**
 * Regular expression used for user id format validation
 *
 * @public
 */
export const UID_REGEX = /^[A-Z0-9]{28,36}$/;

/**
 * Returns true if the string is a valid user id
 *
 * @param arg - A generic argument to check
 * @returns Returns true if the string is a valid user id
 *
 * @public
 *
 * @example
 * ```ts
 * const result = isValidUserId('21321');
 * console.log(result);
 * // -> true
 * ```
 * */
function isValidUserId(arg: unknown): arg is UserId {
  return isString(arg) && UID_REGEX.test(arg as string);
}

export { isValidUserId };
