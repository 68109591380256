import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'next-i18next';

function LandingFaqTitle({ children }: React.PropsWithChildren) {
  return (
    <Typography
      component="h1"
      variant="headingOne"
      fontWeight="600"
      mb={{ xs: 5, lg: 9 }}
      sx={{
        textAlign: 'center',
      }}
    >
      {children}
    </Typography>
  );
}

function LandingFaqSectionQuestion({ children }: React.PropsWithChildren) {
  return (
    <Typography
      variant="paragraphLG"
      component="h4"
      mb={{ xs: 1, md: 0 }}
      sx={(theme) => ({
        fontWeight: theme.typography.fontWeightBold,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
      })}
    >
      {children}
    </Typography>
  );
}

function LandingFaqSectionAnswer({ children }: React.PropsWithChildren) {
  return (
    <Typography
      variant="paragraphLG"
      component="p"
      mb={{ xs: 9, lg: 9 }}
      sx={(theme) => ({
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
      })}
    >
      {children}
    </Typography>
  );
}

export function LandingFaqSection() {
  const { t } = useTranslation('landing');

  return (
    <Container>
      <LandingFaqTitle>{t('faqSectionTitle')}</LandingFaqTitle>
      <Box mt="3.3vh">
        <Markdown
          options={{
            disableParsingRawHTML: true,
            wrapper: 'article',
            overrides: {
              h1: {
                component: LandingFaqSectionQuestion,
              },
              p: {
                component: LandingFaqSectionAnswer,
              },
            },
          }}
        >
          {t('faqSection')}
        </Markdown>
      </Box>
    </Container>
  );
}
