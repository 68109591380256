import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { alpha, createTheme } from '@mui/material/styles';

import type { FontTheme } from './util/generate-font-face/generate-font-face';
import { withBaseTheme } from './with-base';

const fontsToLoadFromCDN: FontTheme[] = [
  {
    name: 'proxima-nova',
    weightOptions: [400, 600, 700],
  },
  {
    name: 'bree-serif',
    weightOptions: [400, 600, 700],
  },
  {
    name: 'roboto-slab',
    weightOptions: [400, 600, 700],
  },
];

const baseTheme = withBaseTheme(
  {
    typography: {
      fontFamily: 'proxima-nova, sans-serif',
      fontFamilySecondary: 'bree-serif, serif',
      h1: {
        fontFamily: 'bree-serif, serif',
      },
      h3: {
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '30px',
      },
      h4: {
        fontWeight: 600,
      },
      button: {
        textTransform: 'revert',
        fontSize: 18,
      },
      caption: {
        lineHeight: 1.2,
      },
    },
    palette: {
      primary: {
        main: '#225478',
        dark: '#183D58',
      },
      secondary: {
        main: '#11427C',
        dark: '#082548',
        contrastText: 'white',
      },
      info: {
        main: '#19B4FA',
        dark: '#07A0E5',
      },
      error: {
        main: '#E45D59',
        light: '#F9EEEE',
        dark: '#B53131',
      },
      warning: {
        main: '#FFC24D',
      },
      success: {
        main: '#2AD2A0',
      },
      background: {
        default: '#FAFAFA',
      },
      text: {
        primary: '#12171B',
        disabled: '#4A4A4A',
      },
      gradient: {
        dark: 'linear-gradient(89.37deg, #0E283E 2.27%, #122E5D 100%)',
        main: 'linear-gradient(90deg, #316D98 0%, #11427C 100%)',
      },
      tertiary: {
        light: '#91BFF550',
        main: '#19B4FA',
        dark: '#07A0E5',
      },
      neutral: {
        primaryGray: '#12171B',
        secondaryGray: '#4A4A4A',
        tertiaryGray: '#9B9B9B',
        quaternaryGray: '#DBDBDB',
      },
      backgroundBanner: {
        light: '#FCF8F3',
        main: '#F5F9FB',
        contrastText: '#ffcc00',
        dark: '#F5F8FF',
      },
      tabs: {
        dark: '#2E2DA7',
        disabled: '#79716B',
      },
    },
  },
  fontsToLoadFromCDN,
);

export const pharmacyCustomerTheme = createTheme(baseTheme, {
  shadows: [
    'none',
    '0px -1px 0px rgb(219,219,219)',
    '0px 4px 4px rgba(0, 0, 0, 0.08)',
  ].concat(...baseTheme.shadows.slice(3)),
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          paddingTop: baseTheme.spacing(4),
          paddingBottom: baseTheme.spacing(4),
          '& .MuiAccordionSummary-content': {
            marginLeft: baseTheme.spacing(4),
            '&.Mui-expanded': {
              marginLeft: baseTheme.spacing(4),
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: baseTheme.spacing(4),
          paddingBottom: baseTheme.spacing(4),
          paddingLeft: baseTheme.spacing(8),
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          padding: baseTheme.spacing(3),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            borderColor: baseTheme.palette.error.main,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: '0 !important',
            },
          },
          borderRadius: '50px !important',
          border: `2px solid ${baseTheme.palette.primary.main}`,
          color: baseTheme.palette.primary.dark,
          lineHeight: '20px',
          transition: baseTheme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),
          '&:disabled': {
            borderColor: baseTheme.grayShades[300],
            color: baseTheme.grayShades[300],
          },
          '&:focus': {
            boxShadow: `${alpha(
              baseTheme.grayShades[900] ?? '',
              0.15,
            )} 0 4px 8px 0`,
            borderColor: baseTheme.palette.primary.main,
          },
        },
        input: {
          padding: '15px 22px !important',
          borderRadius: '50px !important',
          fontSize: 16,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: baseTheme.grayShades[900],
          fontWeight: 700,
          fontSize: 14,
          '&.Mui-focused': {
            color: baseTheme.palette.primary.dark,
          },
          '&.Mui-error': {
            color: baseTheme.palette.error.main,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          lineHeight: '20px',
          fontSize: 14,
          fontWeight: 400,
          color: baseTheme.grayShades[700],
          [baseTheme.breakpoints.down('sm')]: {
            lineHeight: '18px',
            fontSize: 13,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: baseTheme.typography.pxToRem(14),
          lineHeight: '143%',
          color: baseTheme.grayShades[100],
          backgroundColor: baseTheme.palette.primary.main,
        },
        arrow: {
          color: baseTheme.palette.primary.main,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        sx: {
          display: 'contents',
        },
      },

      styleOverrides: {
        root: {
          position: 'relative',
          left: -14,
          top: 12,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        contained: {
          borderRadius: 50,
          fontWeight: 700,
          fontSize: 20,
          lineHeight: '32px',
          letter: '2%',
          height: 58,
          padding: `0 ${baseTheme.spacing(8)}`,
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '25.6px',
          },
          '&:focus': {
            borderRadius: 50,
            outline: `${baseTheme.palette.primary.main} solid 1px `,
            outlineOffset: '4px',
          },
          '&.Mui-disabled': {
            backgroundColor: baseTheme.grayShades[200],
            color: baseTheme.grayShades[500],
          },
        },
        text: {
          '&.Mui-disabled': {
            color: baseTheme.palette.primary.main,
            fontSize: 14,
            lineHeight: '19px',
            backgroundColor: 'transparent',
          },
          color: baseTheme.palette.text.primary,
          fontSize: 14,
          lineHeight: '19px',
          backgroundColor: 'transparent',
          '&:focus': {
            color: baseTheme.palette.primary.dark,
          },
        },
        outlined: {
          borderRadius: 50,
          fontWeight: 700,
          fontSize: 20,
          lineHeight: '32px',
          letter: '2%',
          height: 58,
          padding: `0 ${baseTheme.spacing(8)}`,
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '25.6px',
          },
          '&:focus': {
            borderRadius: 50,
            outline: `${baseTheme.palette.primary.main} solid 1px `,
            outlineOffset: '4px',
          },
          '&.Mui-disabled': {
            backgroundColor: baseTheme.grayShades[200],
            color: baseTheme.grayShades[500],
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 10,
          borderRadius: 5,
          backgroundColor: baseTheme.grayShades[200],
        },
        bar: {
          borderRadius: 5,
          backgroundColor: baseTheme.palette.info.main,
          transition: 'transform .6s ease',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: 40,
          lineHeight: '46px',
          [baseTheme.breakpoints.down('md')]: {
            fontSize: 36,
            lineHeight: '42px',
          },
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: 28,
            lineHeight: '34px',
          },
        },
        h1: {
          fontSize: 36,
          lineHeight: '42px',
          [baseTheme.breakpoints.down('md')]: {
            fontSize: 34,
            lineHeight: '40px',
          },
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: 32,
            lineHeight: '38px',
          },
        },
        h2: {
          fontSize: 30,
          lineHeight: '36px',
          fontWeight: 700,
          [baseTheme.breakpoints.down('md')]: {
            fontSize: 28,
            lineHeight: '34px',
          },
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: 24,
            lineHeight: '32px',
          },
        },
        h4: {
          fontSize: 20,
          lineHeight: '26px',
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHeight: '24px',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDown,
      },
      styleOverrides: {
        icon: {
          right: 17,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.dialog-container': {
            width: '814px',
            height: '415px',
            left: '313px',
            top: '545px',
            bgcolor: 'background.paper',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            boxShadow: '0px 10px 18px rgba(0, 0, 0, 0.25)',
          },
        },
      },
    },
  },
});
