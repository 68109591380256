import type { EnvironmentPrefix } from '../environments-prefix';
import { prefixEnvironment } from '../prefix-environment/prefix-environment';
import { prefixEnvironmentWithoutQA } from '../prefix-environment-without-qa/prefix-environment-without-qa';
import { prefixEnvironmentWithoutQAandDEV } from '../prefix-environment-without-qa-and-dev/prefix-environment-without-qa-and-dev';

/**
 * Get the prefix environment: qa./stg.
 *
 * @param prefix - A fixed prefix if this should be static
 * @returns If prefix is given and if it's of type `string`, it will be returned otherwise the current url will be used for guessing the environment
 *
 * @public
 *
 * @example
 * ```ts
 * const stg = getValue(); // if `window.location.hostname` is similar to a.domain.stg.healthcare.com
 * const qa = getValue('qa.');
 * ```
 */
function getValue(prefix?: EnvironmentPrefix): string {
  return typeof prefix === 'string' ? prefix : prefixEnvironment();
}

/**
 * Get the prefix environment: stg.
 *
 * @param prefix - A fixed prefix if this should be static
 * @returns If prefix is given and if it's of type `string`, it will be returned otherwise the current url will be used for guessing the environment
 * but always return `stg.` instead of `qa.`
 *
 * @public
 *
 * @example
 * ```ts
 * const stg1 = getValueWithoutQA(); // if `window.location.hostname` is similar to a.domain.qa.healthcare.com
 * const stg2 = getValueWithoutQA('stg.');
 * ```
 */
function getValueWithoutQA(prefix?: EnvironmentPrefix): string {
  return typeof prefix === 'string' ? prefix : prefixEnvironmentWithoutQA();
}

function getValueWithoutQAandDEV(prefix?: EnvironmentPrefix): string {
  return typeof prefix === 'string'
    ? prefix
    : prefixEnvironmentWithoutQAandDEV();
}

export { getValue, getValueWithoutQA, getValueWithoutQAandDEV };
