/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/business` This package is a collection of functionalities
 * that encapsulate business logic.
 *
 * @packageDocumentation
 */

export * from './adapters';
export * from './enums';
export * from './helpers';
export * from './repository';
export * from './use-cases';
