/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FetchJSONError } from '@hc-frontend/core-utils-net';
import type {
  AdapterBuilderRepository,
  AdapterImplementation,
  AdapterImplementationResult,
} from '@hc-frontend/deprecated-repositories';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import type { IRxCardsServiceResponse } from '../dto';
import { mapError } from '../helpers';

export function buildAdapter<
  T extends AdapterImplementation,
  TDto extends IRxCardsServiceResponse<any>,
>(
  token?: string,
): AdapterBuilderRepository<
  T,
  unknown,
  [mapper: (result: NonNullable<TDto>) => AdapterImplementationResult<T>],
  TDto,
  RxCardsErrorCodes
> {
  return (provider, adapter, displayError, mapper) =>
    async (...args) => {
      try {
        if (!token) {
          const details = 'A token was not provided to the adapter.';

          provider.logError(displayError as string, {
            cause: new Error(details),
          });
          return await Promise.resolve({
            data: undefined,
            errors: [
              {
                message: displayError,
                details,
              },
            ],
            success: false,
          });
        }

        const response = await adapter(...args);
        const data = response?.data ?? null;
        const success = response?.success;
        const errors = response?.errors || [{ code: '0000000' }];

        if (!success) {
          const details = `${errors.map((e) => e.code).join('.')}`;

          provider.logError(displayError as string, {
            cause: new Error(details),
          });
          return {
            data: undefined,
            errors: errors.map(mapError),
            success: false,
          };
        }

        if (!data) {
          const details = 'Response was successful but empty.';

          provider.logError(displayError as string, {
            cause: new Error(details),
          });
          return {
            data: undefined,
            errors: [
              {
                message: RxCardsErrorCodes.NO_RESPONSE,
                details,
              },
            ],
            success: false,
          };
        }

        const mappedResult = await mapper(data as NonNullable<TDto>);

        return {
          data: mappedResult,
          errors: [],
          success: true,
        };
      } catch (serviceError) {
        const error = serviceError as FetchJSONError;

        try {
          const response: IRxCardsServiceResponse<
            AdapterImplementationResult<T>
          > | null = JSON.parse(error.message);
          const errors = response?.errors || [
            { code: '0000000', description: 'RX cards service API failed' },
          ];

          const details = (error as Error).message;
          provider.logError(displayError as string, {
            cause: new Error(details),
          });

          return {
            success: false,
            data: undefined,
            errors: errors.map(mapError),
          };
        } catch (unknownError) {
          const details = (error as Error).message;

          provider.logError(displayError as string, {
            cause: new Error(details),
          });

          return {
            data: undefined,
            errors: [
              {
                message: displayError,
                details,
              },
            ],
            success: false,
          };
        }
      }
    };
}
