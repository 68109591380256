import type {
  AgentId,
  IAgent,
  IUseCaseResponse,
} from '@hc-frontend/deprecated-entities';
import { AdapterResponseStatus } from '@hc-frontend/deprecated-entities';

import type { IAgentRepository } from '../../../repository/agent-repository';

/**
 * Fetch the agent information by passing his/her id.
 *
 * @param repository - The repository instance to handle agents. See {@link agentRepository}
 * @returns An async object with the agent information. If there is no information or there is a network error, null is returned.
 *
 * @public
 *
 * @example
 * ```ts
 * const agent = await fetchAgentInformation(agentRepository);
 * ```
 */

async function fetchAgentInformation<T extends IAgentRepository>(
  repository: T,
  agentId: AgentId,
): Promise<IUseCaseResponse<IAgent>> {
  try {
    const { data, status } = await repository.fetchAgentInformation(agentId);

    if (status !== AdapterResponseStatus.Ok) {
      return {
        success: false,
        data: undefined,
        errors: [{ message: 'Bad service response' }],
      };
    }

    return {
      success: true,
      data,
      errors: [],
    };
  } catch (error) {
    return {
      success: false,
      data: undefined,
      errors: [{ message: 'Service error' }],
    };
  }
}

export { fetchAgentInformation };
