import type { IBusinessEntity } from '@hc-frontend/deprecated-entities';

/**
 * Convert a given value into a managed value, which is a value that should be validated with a proper use case.
 *
 * @public
 *
 * @param value - The value to be casted to a validated entity.
 *
 * @returns The same value without any changes, only casted to a validated entity.
 *
 * @example
 * ```ts
 * const obj = { name: 'John Doe' };
 * const result = buildManagedValue(obj);
 * // -> result = { name: 'John Doe' } but casted to IRxCardValue<{ name: string }>
 *
 * console.log(obj === result);
 * // -> true
 * ```
 *
 */
export function buildManagedValue<TValue>(
  value: TValue,
): IBusinessEntity<TValue> {
  return value as IBusinessEntity<TValue>;
}
