import type {
  ContextEvent,
  ContextFilter,
  SelfDescribingJson,
} from '@snowplow/browser-tracker';

import type { HcSessionDataProvider } from './hc-session-global-context';

const SP_USER_ID_MAX_LENGTH = 64;
const SP_SESSION_ID_MAX_LENGTH = 64;

function hcSessionContextFilter(
  dataProvider: HcSessionDataProvider,
): ContextFilter {
  return () => {
    const data = dataProvider.readHcSessionContextData();
    return (
      data.userId != null &&
      data.sessionId != null &&
      data.userId.length <= SP_USER_ID_MAX_LENGTH &&
      data.sessionId.length <= SP_SESSION_ID_MAX_LENGTH
    );
  };
}

function hcSessionContextGenerator(
  dataProvider: HcSessionDataProvider,
): (args?: ContextEvent) => SelfDescribingJson {
  return () => {
    const data = dataProvider.readHcSessionContextData();
    return {
      schema: 'iglu:com.healthcare/ctx_tron_tracking/jsonschema/1-0-0',
      data: {
        user_id: data.userId,
        session_id: data.sessionId,
      },
    };
  };
}

export { hcSessionContextFilter, hcSessionContextGenerator };
