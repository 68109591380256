import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { cardBenefitList } from './card-left-content.config';
import type { ItemProps } from './card-left-content.types';

export function Item({ text }: ItemProps) {
  return (
    <ListItem sx={{ display: 'list-item', padding: 0 }}>
      <Typography component="div" variant="headingFour" color="text.main">
        <Box
          sx={(theme) => ({
            margin: theme.spacing(6, 0),
            fontWeight: theme.typography.fontWeightBold,
            '@media print': {
              margin: theme.spacing(1, 0),
              fontSize: '12px',
            },
          })}
        >
          {text}
        </Box>
      </Typography>
    </ListItem>
  );
}

export function CardLeftContent() {
  const { t } = useTranslation('card');

  return (
    <Box display="grid" gridTemplateRows="auto 1fr auto">
      <Typography
        variant="titleSM"
        color="primary.main"
        pb={6}
        fontWeight={(theme) => theme.typography.fontWeightBold}
      >
        {t('welcome')}
      </Typography>
      <Typography
        variant="headingFour"
        color="text.main"
        component="div"
        fontWeight={(theme) => theme.typography.fontWeightRegular}
        pb={6}
        sx={{
          padding: 0,
        }}
      >
        <Box
          sx={{
            padding: 0,
            textAlign: 'justify',
            fontSize: {
              xs: '1rem',
              sm: '1.1rem',
              md: '1.5rem',
            },
            lineHeight: {
              xs: '1.2',
              sm: '1.4',
              md: '1.5',
              lg: '1.7',
            },
            '@media print': {
              fontSize: '0.9rem',
              lineHeight: '1.5',
            },
          }}
        >
          {t('cardExplanation')}
        </Box>
      </Typography>
      <List
        sx={{
          listStyleType: 'disc',
          padding: '0 0 0 16px',
        }}
      >
        {cardBenefitList.map((benefit) => (
          <Item key={benefit} text={t(benefit)} />
        ))}
      </List>
    </Box>
  );
}
