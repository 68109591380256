export type FontThemeWeight = {
  weight: number;
  source: string;
};

export type FontTheme = {
  name: string;
  source?: string;
  weightOptions: number[] | FontThemeWeight[];
  fontFamily?: string;
};

/**
 * Returns a string with css to load font faces.
 *
 * @param fonts - The fonts to be loaded.
 * @returns The css to load fonts.
 *
 * @public
 *
 * @example
 * ```ts
 *  const fonts = generateFontFace(fonts);
 * ```
 */
function generateFontFace(fonts: FontTheme[]): string {
  let result = '';

  for (const font of fonts) {
    for (const options of font.weightOptions) {
      const weight = typeof options === 'number' ? options : options.weight;

      const src =
        font.source ||
        (typeof options === 'number'
          ? `url("https://cdn.pivothealth.com/fonts/v2/${font.name}/${options}.woff2")format("woff2"),
      url("https://cdn.pivothealth.com/fonts/v2/${font.name}/${options}.woff")format("woff"),
      url("https://cdn.pivothealth.com/fonts/v2/${font.name}/${options}.ttf")format("truetype")`
          : options.source);

      const fontWeight = font.source ? '' : `font-weight: ${weight};`;

      result += `@font-face {
        font-style: normal;
        ${fontWeight}
        font-family: "${font.name}";
        src: local("${font.name}"),${src};
        font-display: swap;
        unicode-range: U+000-5FF
      }`;
    }
  }

  return result.replace(/ |\r\n|\n|\r/gm, '');
}

export { generateFontFace };
