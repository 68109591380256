import {
  ADDRESS_LINE_MAX_LENGTH,
  ADDRESS_LINE_REGEX,
  ALPHA_NUM_REGEX,
  isValidUSPhone,
  PHARMACY_NAME_MAX_LENGTH,
  US_PHONE,
  ZIP_CODE_REGEX,
} from '@hc-frontend/core-utils-validations';

import type {
  TransferPharmacyFormTranslations,
  TransferPharmacyFormValidationRules,
} from './transfer-pharmacy-form.types';

export const defaultValues = {
  name: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  phoneNumber: '',
};

export const generateValidations = (
  translations: TransferPharmacyFormTranslations,
): TransferPharmacyFormValidationRules => ({
  name: {
    required: {
      value: true,
      message: translations.nameRequiredError,
    },
    pattern: {
      value: ALPHA_NUM_REGEX,
      message: translations.namePatternError,
    },
    maxLength: {
      value: PHARMACY_NAME_MAX_LENGTH,
      message: translations.maxCharacter,
    },
  },
  streetAddress: {
    required: {
      value: true,
      message: translations.addressRequiredError,
    },
    pattern: {
      value: ADDRESS_LINE_REGEX,
      message: translations.addressPatternError,
    },
    maxLength: {
      value: ADDRESS_LINE_MAX_LENGTH,
      message: translations.maxCharacter,
    },
  },
  city: {
    required: {
      value: true,
      message: translations.cityRequiredError,
    },
  },
  state: {
    required: {
      value: true,
      message: translations.stateRequiredError,
    },
  },
  zipCode: {
    required: {
      value: true,
      message: translations.zipRequiredError,
    },
    pattern: {
      value: ZIP_CODE_REGEX,
      message: translations.zipPatternError,
    },
  },
  phoneNumber: {
    required: {
      value: true,
      message: translations.phoneRequiredError,
    },
    pattern: {
      value: US_PHONE,
      message: translations.phonePatternError,
    },

    validate: (v) =>
      isValidUSPhone(v, { enable800: true }) || translations.phoneValidateError,
  },
});
