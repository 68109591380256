import { pick } from 'ramda';

/**
 * Create an object by picking some of its keys
 * @public
 *
 * @typeParam T - The input type of the object
 * @typeParam TOut - The output object with the specified keys
 *
 * @param object - The object to be transformed by picking some keys
 * @param keys - The keys to pick
 *
 * @returns A new copy of the object only with the keys specified
 *
 * @example
 * ```ts
 * const coolObject = pickKeys({status: 'amICool', option1: 'yes', option2: 'no'}, 'status', 'option1'); // {status: 'amICool', option1: 'yes'}
 * ```
 */
function pickKeys<T, K extends string | number | symbol>(
  object: T,
  ...keys: readonly K[]
): Pick<T, Exclude<keyof T, Exclude<keyof T, K>>> {
  return pick(keys, object);
}

export { pickKeys };
