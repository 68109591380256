import { getRootDomain } from '../url/url';

const hcDomains: string[] = [
  'healthcare.com',
  'pivothealth.com',
  'cobrahealthcare.com',
  'healthcareinsider.com',
  'healthcaresmb.com',
  'medicareguide.com',
  'pivotmedicare.com',
  'healthsherpa.com',
];

type CrossDomainLinkerCriterion = (
  element: HTMLAnchorElement | HTMLAreaElement,
) => boolean;

/**
 * Evaluates if the link target is outside the current domain and if the target
 * is from Healthcare, Inc.
 *
 * v.0.12.0;
 * @public
 *
 * @param linkElement - Element which was clicked by the user.
 * @returns true if the link target is outside the current domain and if the
 * link target is a domain from the following list:
 * - healthcare.com
 * - pivothealth.com
 * - cobrahealthcare.com
 * - healthcareinsider.com
 * - healthcaresmb.com
 * - medicareguide.com
 * - pivotmedicare.com
 * - healthsherpa.com
 * Otherwise, it returns false.
 *
 * @example
 * ```ts
 * import {
 *   linksToHcDomainCriterion,
 * } from '@hc-frontend/core-third-party-snowplow';
 *
 * const defaultCriterion = linksToHcDomainCriterion(element);
 * ```
 */
function linksToHcDomainCriterion(
  linkElement: HTMLAnchorElement | HTMLAreaElement,
) {
  const targetDomain = getRootDomain(linkElement.href);
  return getRootDomain() !== targetDomain && hcDomains.includes(targetDomain);
}

export { linksToHcDomainCriterion };
export type { CrossDomainLinkerCriterion };
