import type { HttpResponse } from '@hc-frontend/core-utils-net';
import { fetchJSON } from '@hc-frontend/core-utils-net';

import { getPivotBackendURI } from '../config';
import type { IFetchAgentInformationServiceResponse } from '.';

/**
 * Fetch the information of the agent from HC backend
 *
 * @param agentId - The agent id
 * @returns The agent information if found, otherwise null
 *
 * @public
 *
 * @remarks
 * The API sometimes returns code `204` but without data, in this case null is returned
 *
 * @example
 * ```ts
 * const agentInfo = await fetchAgentInformationService('1');
 * ```
 */

function fetchAgentInformationService(
  agentId: string,
): Promise<HttpResponse<IFetchAgentInformationServiceResponse>> {
  const agentUrl = `${getPivotBackendURI()}/agent/${agentId}`;
  return fetchJSON<IFetchAgentInformationServiceResponse>(agentUrl);
}

export { fetchAgentInformationService };
