import IconButton from '@mui/material/IconButton';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import type { ExpandMoreProps } from './expand-more.types';

export const ExpandMoreIconButton = styled((props: ExpandMoreProps) => {
  const { expand, testId, ...other } = props;
  return <IconButton data-testid={testId} sx={{ padding: 0 }} {...other} />;
})(({ theme, expand }: { theme: Theme; expand?: boolean }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(90deg)',
  margin: 0,
  marginRight: theme.spacing(3),
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
