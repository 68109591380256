import { noon } from '@hc-frontend/core-utils-function';
import Clear from '@mui/icons-material/Clear';
import Create from '@mui/icons-material/Create';
import Done from '@mui/icons-material/Done';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  ButtonWrapper,
  DetailsHeader,
  EditInput,
  InfoWrapper,
  PlaceHolderWrapper,
  ViewEditDetailsWrapper,
} from './view-edit-details.common';
import type { ViewEditDetailsTypes } from './view-edit-details.types';

export const ViewEditDetails = ({
  title,
  titleTestId = null,
  TitleWrapper = DetailsHeader,
  details,
  detailFor,
  placeHolder,
  DetailsWrapper = InfoWrapper,
  editPlaceHolder,
  editRule = { required: false },
  editInputComponent = 'input',
  notEditable,
  editLabel,
  editIcon = <Create />,
  saveLabel,
  saveIcon = <Done />,
  cancelLabel,
  cancelIcon = <Clear />,
  handleEditClick = noon,
  handleSave = noon,
  handleCancel = noon,
}: ViewEditDetailsTypes) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      details,
    },
  });
  const formID = 'edit-form-' + detailFor;

  useEffect(() => {
    setValue('details', details);
  }, [details, setValue]);

  // Method to handle click on Edit Button
  const onEditClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setIsEditing(true);
    handleEditClick(e);
  };

  // Method to handle click on Cancel Button
  const onCancelClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setIsEditing(false);
    handleCancel(e);
    setValue('details', details);
  };

  const onSave = async (data: { details: string }) => {
    setIsLoading(true);
    const saveResponse = await handleSave(data.details);
    setIsLoading(false);
    setIsEditing(false);
    if (!saveResponse) setValue('details', details);
  };

  const PlaceHolder = () =>
    placeHolder ? <PlaceHolderWrapper>{placeHolder}</PlaceHolderWrapper> : null;

  const DetailsComponent = () =>
    details ? <DetailsWrapper>{details}</DetailsWrapper> : <PlaceHolder />;

  const useOutsideClickDetector = (ref: RefObject<HTMLParagraphElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          ref?.current &&
          !ref?.current.contains(event.target as unknown as Node)
        ) {
          setIsEditing(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef<HTMLParagraphElement>(null);
  useOutsideClickDetector(wrapperRef);

  return (
    <Grid
      ref={wrapperRef}
      container
      component={ViewEditDetailsWrapper}
      alignItems="center"
      minHeight="62px"
    >
      <Grid item container xs={isEditing ? 12 : 9} md={9} alignItems="center">
        <Grid item xs={12} md={5} data-testid={titleTestId}>
          <TitleWrapper>{title}</TitleWrapper>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          {isEditing ? (
            <form id={formID} onSubmit={handleSubmit(onSave)}>
              <FormControl fullWidth error={!!errors.details?.message}>
                <Controller<{ details: string }>
                  name="details"
                  rules={editRule}
                  control={control}
                  render={({ field, fieldState }) => (
                    <EditInput
                      {...field}
                      fullWidth
                      id="details"
                      type="text"
                      error={fieldState.invalid}
                      placeholder={editPlaceHolder}
                      inputComponent={editInputComponent}
                    />
                  )}
                />
                <FormHelperText error>{errors.details?.message}</FormHelperText>
              </FormControl>
            </form>
          ) : (
            <DetailsComponent />
          )}
        </Grid>
      </Grid>
      <Grid item xs={isEditing ? 12 : 3} md={3}>
        {!notEditable && (
          <ButtonWrapper>
            {isEditing ? (
              <>
                <Button
                  variant="text"
                  sx={{ mr: 2 }}
                  startIcon={cancelIcon}
                  onClick={onCancelClick}
                  data-testid={`${titleTestId}-cancel`}
                >
                  {cancelLabel}
                </Button>
                <Button
                  form={formID}
                  type="submit"
                  loading={isLoading}
                  variant="contained"
                  startIcon={saveIcon}
                  loadingPosition="start"
                  data-testid={`${titleTestId}-save`}
                >
                  {saveLabel}
                </Button>
              </>
            ) : (
              <Button
                variant="text"
                startIcon={editIcon}
                onClick={onEditClick}
                data-testid={`${titleTestId}-edit`}
              >
                {editLabel}
              </Button>
            )}
          </ButtonWrapper>
        )}
      </Grid>
    </Grid>
  );
};
