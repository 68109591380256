import type { SelfDescribingJson } from '@snowplow/browser-tracker';
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';

interface UserClickEventData {
  category: string;
  placement: string;
  action: string;
  label: string;
  value: string;
  elementId?: string;
  targetUrl?: string;
  description?: string;
}

/**
 * Track User-click event in Snowplow.
 * v.0.13.0;
 * @public
 *
 * @param eventData - Object that contains event properties described in the User Click Event document
 * (see https://healthcareinc.atlassian.net/l/cp/HNaWAs71 ).
 *
 * @example
 * ```ts
 *
 * import {trackUserClick} from '@hc-frontend/core-third-party-snowplow';
 *
 * trackUserClick({
 *   category: 'landing',
 *   placement: 'hero-section',
 *   action: 'submit',
 *   label: 'See Plans',
 *   value: '...',
 *   elementId: '...',
 *   targetUrl: '...',
 *   description: '...',
 * });
 * ```
 */
function trackUserClick(
  eventData: UserClickEventData,
  contexts?: Array<SelfDescribingJson> | null,
): void {
  const {
    category,
    placement,
    action,
    label,
    value,
    elementId,
    targetUrl,
    description,
  } = eventData;
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.healthcare/user_click/jsonschema/1-0-3',
      data: {
        category,
        placement,
        action,
        label,
        value,
        element_id: elementId,
        target_url: targetUrl,
        description,
      },
    },
    context: contexts,
  });
}

export { trackUserClick };
