import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { JSONObject, WithOptional } from '@hc-frontend/core-utils-types';
import type { ISession, ISessionId } from '@hc-frontend/deprecated-entities';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  FetchNewSessionRepository,
  LogRepository,
} from '@hc-frontend/deprecated-repositories';

import { buildAdapter } from '../../build-adapter/build-adapter';
import type {
  ISharedSessionApiPayloadDTO,
  ISharedSessionApiResponseDTO,
} from '../../dto';

export function mapPayload(
  payload: WithOptional<ISession, 'sessionId' | 'userId'>,
): ISharedSessionApiPayloadDTO {
  return {
    TrafficSource: 'DIRECT',
    UserContext: {
      UserId: payload.userId || '',
      SessionId: '',
      ParentSessionId: payload.sessionId,
      ClientId: '',
      UserAgent: payload.request.userAgent,
      IpAddress: payload.request.ipAddress,
      Browser: '',
      BrowserVersion: '',
      OperatingSystem: '',
      OperatingSystemVersion: '',
      Device: payload.request.device,
      Location: '',
      Network: '',
      IsNewUser: 0,
      LoggedInAgentId: '',
    },
    Label: {
      Channel: 'SEM',
      Domain: payload.request.rootDomain,
      Product: payload.product,
      Src: payload.tracking.src || '',
      Source: payload.request.referrer,
      SubId: payload.tracking.subid,
      Medium: payload.tracking.utmMedium,
      Keyword: payload.tracking.utmTerm,
      KeywordId: '',
      Campaign: payload.tracking.utmCampaign,
      CampaignId: '',
      AdGroup: '',
      AdGroupId: '',
      AdId: '',
      Creative: '',
      CreativeId: '',
      LandingPage: payload.request.landingPage,
      MatchType: '',
      ImpactRadiusCampaignId: '',
      OptimizelyExperimentId: '',
      PublisherCampaignId: payload.tracking.pcid,
      Ircid: payload.tracking.ircid,
      Gclid: payload.tracking.gclid,
      UtmRedirect: '',
      RedirectionDomain: '',
      TargetId: '',
      Network: '',
      Placement: '',
      Target: '',
      FeedItemId: '',
      AdPosition: '',
      BiddingToolParameterStatus: '',
      BiddingToolParameterError: '',
      AudienceId: '',
      LocationId: '',
      BiddingTool_Leadid: '',
      BiddingTool_Device: '',
      QueryTxt: '',
      BiddingTool_TrafficSourceId: '',
      BiddingTool_version: '',
      agent_id: payload.tracking.agentId,
    },
    BiddingToolContext: {
      Name: '',
      ValueId: '',
    },
    FlowContext: {
      FlowId: '',
      ExperimentId: '',
      FlowVersion: '',
      ExperimentVariation: '',
      FlowStep: '',
    },
    InvalidSessionContext: {
      IsInvalid: false,
      InvalidDescription: '',
      InvalidCode: 0,
    },
    SubIdContext: {
      TargetingState: '',
      Channel: '',
      Vertical: '',
      Source: '',
      Domain: '',
      Device: '',
      Carrier: '',
      CampaignType: '',
      Theme: '',
      MatchType: '',
    },
  };
}

export function mapResponse(
  response: ISharedSessionApiResponseDTO,
): ISessionId {
  return {
    sessionId: response.sessionId,
    userId: response.UserId,
  };
}

export function fetchNewSessionAdapterFactory(
  provider: LogRepository,
  baseUrl: string,
) {
  return buildAdapter<
    FetchNewSessionRepository<ErrorCodes.SESSION_NOT_CREATED>,
    ISharedSessionApiResponseDTO | undefined
  >()(
    provider,
    async function fetchNewSessionAdapter(payload) {
      const { parsedBody } = await fetchJSON<ISharedSessionApiResponseDTO>(
        `${baseUrl}/session`,
        {
          body: mapPayload(payload) as unknown as JSONObject,
        },
      );

      return parsedBody;
    },
    ErrorCodes.SESSION_NOT_CREATED,
    mapResponse,
  );
}
