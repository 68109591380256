/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/third-party-next-userway`.
 *
 * @packageDocumentation
 */

export * from './userway';
