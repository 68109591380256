import { DeviceName } from '../../../enums/device-names';

/**
 * Calculates the device name based on the
 * ranges of viewport widths. This is used just as a fallback of get
 * the device name in the campaign URL parameters.
 *
 * @param minTablet - Limit between mobile and tablet.
 * @param maxTablet - Limit between tablet and desktop.
 * @returns The device name.
 *
 * @public
 */
function getDeviceByViewportWidth(
  minTablet = 720,
  maxTablet = 1024,
): DeviceName {
  const viewportWidth = window.screen.availWidth;
  let deviceFound = DeviceName.Desktop;

  if (viewportWidth <= minTablet) {
    deviceFound = DeviceName.Mobile;
  } else if (viewportWidth > minTablet && viewportWidth <= maxTablet) {
    deviceFound = DeviceName.Tablet;
  }

  return deviceFound;
}

export { getDeviceByViewportWidth };
