import type { IDictionary } from '@hc-frontend/core-utils-types';
import type {
  ExperimentName,
  IExperiment,
  IGeoLocation,
  IOperationResult,
} from '@hc-frontend/deprecated-entities';
import type { IRxNewCard } from '@hc-frontend/shells-rx-cards-entities';

export interface GetExperimentsTreatmentsRepository<E> {
  (...experimentsToRun: ExperimentName[]): Promise<
    IOperationResult<IExperiment[], E, IDictionary<string>>
  >;
}

export enum SetUserSegmentsRepositoryType {
  LOCATION = 'LOCATION',
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
  USER_AGENT = 'USER_AGENT',
}

export interface SetUserSegmentsRepository<E> {
  <
    T extends
      | {
          type: SetUserSegmentsRepositoryType.LOCATION;
          userSegment: IGeoLocation;
        }
      | {
          type: SetUserSegmentsRepositoryType.ACCOUNT_TYPE;
          userSegment: IRxNewCard;
        }
      | {
          type: SetUserSegmentsRepositoryType.USER_AGENT;
          userSegment: string;
        },
  >(
    args: T,
  ): Promise<IOperationResult<void, E, IDictionary<string>>>;
}

export interface TrackGoalRepository<E> {
  <T>(goalName: string, goalValue?: T): Promise<
    IOperationResult<void, E, IDictionary<string>>
  >;
}
