import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  NavigateToRepository,
} from '@hc-frontend/deprecated-repositories';
import type { NextRouter } from 'next/router';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextPushPageAdapterFactory(
  provider: LogRepository,
  router: NextRouter,
) {
  return buildAdapter<NavigateToRepository<ErrorCodes.MOVE_TO_VIEW_FAILED>>()(
    provider,
    async function nextPushPageAdapter(path) {
      const result = await router.push(path);

      if (!result) throw new Error(`Pushing page ${path} failed.`);
    },
    ErrorCodes.MOVE_TO_VIEW_FAILED,
  );
}
