import { isDefined } from '@hc-frontend/core-utils-validations';

import { EnvironmentPrefix } from '../environments-prefix';

type ValidEnvironment = keyof typeof EnvironmentPrefix;

/**
 * Get the prefix of the environment in the given url or current if not passed
 * @public
 *
 * @param hostname - An optional domain.
 *
 * @returns "qa." if url has qa, local or localhost in its parts. "stg." if url has stg in its parts otherwise empty because there is no environment
 *
 * @example
 * ```ts
 * const qaPrefix = prefixEnvironment("https://enroll.qa.pivothealth.com");
 * ```
 */
function prefixEnvironment(hostname = ''): string {
  const environmentKey =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (process.env.NEXT_PUBLIC_APP_ENVIRONMENT || process.env.ENVIRONMENT) ?? '';

  if (
    environmentKey in EnvironmentPrefix &&
    isDefined<ValidEnvironment>(environmentKey)
  )
    return EnvironmentPrefix[environmentKey];

  const href = hostname || window.location.hostname;
  const link = document.createElement('a');
  link.href = href;
  let result = '';

  link.hostname.split('.').forEach((s) => {
    if (!result) {
      const key = s.toUpperCase();

      if (key in EnvironmentPrefix && isDefined<ValidEnvironment>(key)) {
        result = EnvironmentPrefix[key];
      }
    }
  });

  return result;
}

export { prefixEnvironment };
