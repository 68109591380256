/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IPureFunction } from '@hc-frontend/core-utils-types';
import type { IOperationResult } from '@hc-frontend/deprecated-entities';
import type {
  IRxCardEntity,
  RxCardsErrorCodes,
} from '@hc-frontend/shells-rx-cards-entities';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

export function useValidateEntity<
  T extends IPureFunction<
    any[],
    IOperationResult<IRxCardEntity<any>, RxCardsErrorCodes>
  >,
>(buildEntity: T) {
  const { t } = useTranslation('errors');
  const validate = useCallback(
    (
      ...value: T extends IPureFunction<
        infer A,
        IOperationResult<IRxCardEntity<any>, RxCardsErrorCodes>
      >
        ? A
        : never[]
    ): boolean | string => {
      const result = buildEntity(...value);

      if (result.success) return true;

      return result.errors.map((e) => t(e.message, e.info)).join();
    },
    [t, buildEntity],
  );

  return validate;
}
