import { isString } from '../is-string/is-string';
import { EMAIL_REGEX } from '../regex';

/**
 * Returns true if the string is a valid email
 * @public

 * @param arg - A generic argument to check
 *
 * @returns A boolean indicating whether the arg is a valid name or not.
 *
 * @example
 * ```ts
 * const result = isValidEmail('qa@healthcare.com');
 * console.log(result);
 * // -> true
 * ```
 * */
function isValidEmail<T>(arg: unknown): arg is T {
  return isString(arg) && EMAIL_REGEX.test(arg as string);
}

export { isValidEmail };
