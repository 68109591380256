import type {
  IGeoLocation,
  IOperationResult,
} from '@hc-frontend/deprecated-entities';
import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type { FetchLocationByIpRepository } from '@hc-frontend/deprecated-repositories';

import { overwriteError } from '../../Operation';

interface FetchLocationByIpProvider {
  fetchLocationByIp: FetchLocationByIpRepository<ErrorCodes>;
}

/**
 * Fetch the location information from HTTP request IP. This function is valid only for USA IP's.
 *
 * @param provider - The repository instance to handle location. See {@link locationRepository}
 * @returns An async object with the location information. If there is no information or the IP is not in USA then null is returned
 *
 * @public
 *
 * @example
 * ```ts
 * const location = await fetchLocationByIp(locationRepository);
 * ```
 */
async function fetchLocationByIp<T extends FetchLocationByIpProvider>(
  provider: T,
): Promise<IOperationResult<IGeoLocation, ErrorCodes>> {
  const result = await provider.fetchLocationByIp();

  if (result.success) return result;

  return overwriteError(
    result,
    ErrorCodes.UNKNOWN_ERROR,
    ErrorCodes.FETCH_LOCATION_BY_IP_FAILED,
  );
}

export { fetchLocationByIp };
