/* eslint-disable @typescript-eslint/no-explicit-any */
import { createBusinessErrorOperationResult } from '@hc-frontend/deprecated-business';
import type { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';
import { nAry } from 'ramda';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type OmitFirstItem<T extends any[]> = T extends [any, ...infer U] ? U : never;
type WithToken = (token: string, ...args: any[]) => any;
type WithTokenReturnType<T extends WithToken> = Promise<Awaited<ReturnType<T>>>;

export function useReCaptchaVerify<T extends WithToken>(
  handler: T,
  captchaError: RxCardsErrorCodes,
): (...args: OmitFirstItem<Parameters<T>>) => WithTokenReturnType<T> {
  const { executeRecaptcha } = useGoogleReCaptcha();

  async function verifiedHandler(
    ...args: OmitFirstItem<Parameters<T>>
  ): WithTokenReturnType<T> {
    if (!executeRecaptcha) {
      return Promise.resolve(
        createBusinessErrorOperationResult(captchaError),
      ) as WithTokenReturnType<T>;
    }

    const token = await executeRecaptcha();

    if (!token) {
      return Promise.resolve(
        createBusinessErrorOperationResult(captchaError),
      ) as WithTokenReturnType<T>;
    }

    return handler(token, ...args);
  }

  return nAry(handler.length - 1, verifiedHandler) as (
    ...args: any[]
  ) => any as (...args: OmitFirstItem<Parameters<T>>) => WithTokenReturnType<T>;
}
