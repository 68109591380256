import type {
  IApplicant,
  IChild,
  IDependent,
  IMember,
  ISpouse,
} from '@hc-frontend/deprecated-entities';
import { MemberType } from '@hc-frontend/deprecated-entities';

/**
 * Get the applicant of a member
 *
 * @param members - The members to search in
 * @returns The applicant of the member
 *
 * @public
 *
 * @example
 * ```ts
 * const applicant = findApplicant(members);
 * ```
 */
function findApplicant(members: IMember[]): IApplicant | undefined {
  const applicant = members.find(
    (member) => member.type === MemberType.Applicant,
  );
  if (applicant) {
    return applicant as IApplicant;
  }

  return undefined;
}

/**
 * Get the spouse of a member
 *
 * @param members - The members to search in
 * @returns The Spouse of the member
 *
 * @public
 *
 * @example
 * ```ts
 * const spouse = findSpouse(members);
 * ```
 */
function findSpouse(members: IMember[]): ISpouse | undefined {
  const spouse = members.find((member) => member.type === MemberType.Spouse);
  if (spouse) {
    return spouse as ISpouse;
  }

  return undefined;
}

/**
 * Get children of a member
 *
 * @param members - The members to search in
 * @returns The children of the member
 *
 * @public
 *
 * @example
 * ```ts
 * const applicant = findChildren(members, memberId);
 * ```
 */
function findChildren(members: IMember[]): IChild[] {
  return members.filter(
    (member) => member.type === MemberType.Child,
  ) as IChild[];
}

/**
 * Get non-applicant members
 *
 * @param members - Members
 * @returns Non-applicant members
 *
 * @public
 *
 * @example
 * ```ts
 * const result = findDependents(members);
 * ```
 */
function findDependents(members: IMember[]): IDependent[] {
  return members.filter(
    (member) => member.type !== MemberType.Applicant,
  ) as IDependent[];
}

export { findApplicant, findChildren, findDependents, findSpouse };
