/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/core-utils-storage` is a package that contains utility functions
 * independent of any project or business logic.
 *
 * @packageDocumentation
 */

export * from './command-queue/command-queue';
export * from './load-object/load-object';
export * from './save-object/save-object';
