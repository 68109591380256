import { stringIsValidIsoDate } from '@hc-frontend/core-utils-validations';

/**
 * Convert string 'YYYY-MM-DD' to date
 *
 * @public
 *
 * @param value - Date string in 'MM/DD/YYYY' format
 *
 * @returns Date instance of the string date provided
 *
 * @remarks
 *
 * In order to keep consistency across all of our date functions, we need to add the current offset to the date provided by value.
 * If we dont' add the offset then we're going to have differences with locale times.
 *
 * @example
 * ```ts
 * const newDate = isoStringDateToDate('1992-08-30');
 * console.log(newDate);
 * // -> 1992-08-30T06:00:00.000Z
 * ```
 */
function isoStringDateToDate(value: string): Date | undefined {
  if (!stringIsValidIsoDate(value)) return undefined;
  const [year, month, day] = value.split('-');

  return new Date(`${year}/${month}/${day}`);
}

export { isoStringDateToDate };
