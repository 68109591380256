import type { DataExperiments } from '@hc-frontend/core-third-party-absmartly';
import { ErrorBoundary } from '@hc-frontend/core-ui-components';
import { getCookieValue } from '@hc-frontend/core-utils-dom';
import {
  asSuccessOperationResult,
  setExperimentLogTags,
  setLogUser,
} from '@hc-frontend/deprecated-business';
import type { IExperiment } from '@hc-frontend/deprecated-entities';
import { absmartlySetUserSegmentAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-absmartly';
import { fetchLocationByIpAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-shared-net';
import { userViewAnyPage } from '@hc-frontend/shells-rx-cards-business';
import {
  useAppConfig,
  useLogProvider,
  useSmartly,
} from '@hc-frontend/shells-rx-cards-ui';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import NextErrorComponent from 'next/error';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { SSRConfig } from 'next-i18next';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { EmptyLayout } from '../layouts';
import config from '../next-i18next.config';
import {
  ExperimentProvider,
  MuiProvider,
  SnowplowProvider,
} from '../providers';
import { ConfigProvider } from '../providers/config-provider';

export type PageProps = { [key in keyof SSRConfig]: SSRConfig[key] } & {
  runningExperiments?: { [key: string]: IExperiment };
  experimentData?: DataExperiments;
};

type NextPageWithLayout = NextPage<PageProps> & {
  layout?: React.FunctionComponent;
  title: string;
  description: string;
};

type AppPropsWithLayout = AppProps<PageProps> & {
  Component: NextPageWithLayout;
};

function DisplayError() {
  const { t } = useTranslation('errors');
  return <NextErrorComponent statusCode={0} title={t('siteError')} />;
}

function DisplayHead({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  const { t } = useTranslation('seo');
  return (
    <Head>
      <title>{`${t(title, { ns: 'seo' })} | ${t('company', {
        ns: 'seo',
      })}`}</title>
      <meta name="description" content={t(description, { ns: 'seo' })} />
    </Head>
  );
}

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const Layout = Component.layout ?? EmptyLayout;
  const { title, description } = Component;

  const router = useRouter();
  const logger = useLogProvider();
  const {
    public: {
      app: { environment, isLocal },
    },
  } = useAppConfig();
  const { smartly, resetSmartly } = useSmartly(pageProps.experimentData);

  useEffect(() => {
    setLogUser(
      {
        setUser: logger.setUser,
        readCookieValue: asSuccessOperationResult(
          logger,
          function readCookieAdapter(cookieName) {
            return Promise.resolve({
              success: true,
              data: getCookieValue(cookieName) || undefined,
              errors: [],
            });
          },
          undefined,
        ),
        readHost: asSuccessOperationResult(
          logger,
          function readHostAdapter() {
            return Promise.resolve({
              success: true,
              data: window.location.host,
              errors: [],
            });
          },
          '',
        ),
        readPath: asSuccessOperationResult(
          logger,
          function readPathAdapter() {
            return Promise.resolve({
              success: true,
              data: window.location.pathname,
              errors: [],
            });
          },
          '',
        ),
        addLogTags: logger.addLogTags,
      },
      'rx-cards',
      environment,
      'client',
    )
      .then(() =>
        setExperimentLogTags(
          {
            addLogTags: logger.addLogTags,
          },
          pageProps?.runningExperiments ?? {}, //ERROR pages and confirmation page does not have experiments.
        ),
      )
      .then(() =>
        userViewAnyPage(
          {
            ...logger,
            fetchLocationByIp: fetchLocationByIpAdapterFactory(logger),
            setUserSegment: absmartlySetUserSegmentAdapterFactory(
              logger,
              smartly.instance,
            ),
            getUserAgent: () =>
              Promise.resolve({
                success: true,
                data: navigator.userAgent,
                errors: [],
              }),
          },
          router.pathname,
        ),
      );

    return () => {
      resetSmartly();
    };
  }, [
    isLocal,
    router.pathname,
    smartly,
    environment,
    pageProps.runningExperiments,
    logger,
    resetSmartly,
  ]);

  return (
    <ErrorBoundary
      onError={(error) =>
        logger.logError('send', {
          cause: error,
        })
      }
      fallback={<DisplayError />}
    >
      <ConfigProvider>
        <SnowplowProvider>
          <DisplayHead title={title} description={description} />
          <ExperimentProvider runningExperiments={pageProps.runningExperiments}>
            <MuiProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </MuiProvider>
          </ExperimentProvider>
        </SnowplowProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(CustomApp, config);
