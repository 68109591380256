import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadPathRepository,
} from '@hc-frontend/deprecated-repositories';
import type { GetServerSidePropsContext } from 'next';
import type { NextRequest } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

interface NextReadHostAdapterOptions {
  request: NextRequest;
  contextRequest: GetServerSidePropsContext['req'];
  contextResponse: GetServerSidePropsContext['res'];
}

function readOptions(
  options: Partial<NextReadHostAdapterOptions>,
): Promise<string | undefined> {
  const { request, contextRequest, contextResponse } = options;
  if (request) return Promise.resolve(request.headers.get('host') ?? undefined);
  if (contextRequest) return Promise.resolve(contextRequest.headers.host);
  if (contextResponse)
    return Promise.resolve(contextResponse.getHeader('host') as string);

  return Promise.reject(
    new Error('An option must be provided for reading a host'),
  );
}

async function readHostAdapter(
  options: Partial<NextReadHostAdapterOptions>,
  fallback: (keyof NextReadHostAdapterOptions)[],
  index = 0,
): Promise<string | undefined> {
  if (index >= fallback.length) return Promise.resolve(undefined);

  const option = fallback[index];

  const result = await readOptions({ [option]: options[option] });

  if (result) return Promise.resolve(result);

  return readHostAdapter(options, fallback, index + 1);
}

export function nextReadHostAdapterFactory(
  provider: LogRepository,
  options: Partial<NextReadHostAdapterOptions>,
  fallback: (keyof NextReadHostAdapterOptions)[],
) {
  return buildAdapter<ReadPathRepository<ErrorCodes.HOST_NOT_READ>>()(
    provider,
    async function nextReadHostAdapter() {
      const result = await readHostAdapter(options, fallback);

      if (!result)
        throw new Error('No host found in url for request or response');

      return result;
    },

    ErrorCodes.HOST_NOT_READ,
  );
}
