import type { Smartly } from '@hc-frontend/core-third-party-absmartly';
import { useBusinessCase } from '@hc-frontend/core-ui-components';
import { getCookieValue } from '@hc-frontend/core-utils-dom';
import type { LogRepository } from '@hc-frontend/deprecated-repositories';
import {
  absmartlySetUserSegmentAdapterFactory,
  absmartlyTrackGoalAdapterFactory,
} from '@hc-frontend/shells-rx-cards-adapter-absmartly';
import { pixelReportConversionAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-conversion';
import { createRxCardApiAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-net';
import { nextGetCreateCardConversionAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-next';
import { nextPushPageAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-shared-next';
import { snowplowTrackCreateCardCTAAdapterFactory } from '@hc-frontend/shells-rx-cards-adapter-snowplow';
import { createRxCard } from '@hc-frontend/shells-rx-cards-business';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { curry } from 'ramda';
import { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useAppConfig } from '../use-app-config';
import { useLogProvider } from '../use-log-provider';
import { useSmartly } from '../use-smartly';

const submitCard = async (
  token: () => Promise<string> | undefined, // To bypass ramda issues with currying, expecting a function solves the issue
  rxCardsApi: string,
  provider: LogRepository,
  router: NextRouter,
  getSmartly: () => Smartly,
  data: Parameters<typeof createRxCard> extends [...unknown[], infer D]
    ? D
    : never,
) =>
  createRxCard(
    {
      ...provider,
      createRxCard: createRxCardApiAdapterFactory(provider, rxCardsApi, {
        captchaToken: await token?.(),
      }),
      getConversion: nextGetCreateCardConversionAdapterFactory(provider),
      navigateTo: nextPushPageAdapterFactory(provider, router),
      reportConversion: pixelReportConversionAdapterFactory(provider),
      trackCreateCardCallToAction:
        snowplowTrackCreateCardCTAAdapterFactory(provider),
      trackGoal: absmartlyTrackGoalAdapterFactory(provider, getSmartly()),
      setUserSegment: absmartlySetUserSegmentAdapterFactory(
        provider,
        getSmartly(),
      ),
      readCookieValue: (key) =>
        Promise.resolve({
          success: true,
          data: getCookieValue(key),
          errors: [],
        }), // TODO: move to an adapter and use it in all places, also enhance it to handle errors gracefully
      debounce: (ms, fn) =>
        new Promise((resolve) => {
          setTimeout(() => fn().then(resolve), ms);
        }), // TODO: move to an adapter and use it in all places, also enhance it to handle errors gracefully
    },
    data,
  );

export function useSubmitCard() {
  const {
    public: {
      app: { rxCardsApi },
    },
  } = useAppConfig();

  const router = useRouter();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { smartly } = useSmartly();

  const logger: LogRepository = useLogProvider();

  useEffect(() => {
    router.prefetch('/confirmation');
  }, [router]);

  return useBusinessCase(
    curry(submitCard)(
      () => executeRecaptcha?.(),
      rxCardsApi,
      logger,
      router,
      () => smartly.instance,
    ),
    {
      defaultError: RxCardsErrorCodes.POST_RX_CARD,
    },
  );
}
