/**
 * @packageDocumentation
 *
 * <img src="https://placecorgi.herokuapp.com/300/250" align="right" />
 *
 * Why does this library exist?
 *
 * `@hc-frontend/deprecated-services` is a package containing general purpose
 *  services that could be used by any application.
 *
 * @packageDocumentation
 */

export * from './config';
export * from './fetch-active-applications';
export * from './fetch-agent-information';
export * from './fetch-all-locations-by-zip';
export * from './fetch-call-campaign';
export * from './fetch-location-by-ip';
export * from './fetch-lowest-prices-by-state';
export * from './fetch-medicare-enrollment-prospect';
export * from './fetch-okta-authn';
export * from './fetch-okta-authz';
export * from './fetch-okta-token';
export * from './fetch-session';
export * from './fetch-sponsored-ads';
export * from './post-google-recaptcha-verify';
