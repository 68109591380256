import './pixels-types.d';

import type { ReporConversionProps } from './report-conversion.types';

const isNotBrowser = () =>
  typeof window === 'undefined' || typeof document === 'undefined';

const internalGTag = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isNotBrowser() || !(window as { [key: string]: any })['gtag'])
    return false;
  return gtag;
};

const internalBingTag = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isNotBrowser() || !(window as { [key: string]: any })['uetq'])
    return false;
  return uetq;
};

const internalFacebookTag = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isNotBrowser() || !(window as { [key: string]: any })['fbq'])
    return false;
  return fbq;
};

/**
 * Report a Google event
 *
 *
 * @param eventID - string ID of the form `${environment.GOOGLE_ADS_ID}/${eventID}`
 *
 * @returns A promise that would fail if gtag is not loaded into the browser
 *
 * @example
 * ```ts
 * await reportGoogleConversion(`${environment.GOOGLE_ADS_ID}/${eventID}`)
 * ```
 */
export const reportGoogleConversion = (eventID: string) => {
  const tag = internalGTag();

  return new Promise((resolve, reject) => {
    if (tag) {
      tag('event', 'conversion', {
        send_to: eventID,
        event_callback: resolve,
      });
    } else {
      reject('[GA] GoogleAnalytics (gtag command) is not initialized');
    }
  });
};

/**
 * Report a Bing event
 *
 *
 * @param eventID - string event Bing ID
 *
 * @returns A promise that would fail if uetq is not loaded into the browser
 *
 * @example
 * ```ts
 * await reportBingConversion('123')
 * ```
 */
export const reportBingConversion = (eventID: string) => {
  const tag = internalBingTag();

  return new Promise((resolve, reject) => {
    if (tag) {
      tag.push('event', eventID, {});
      resolve(true);
    } else {
      reject(
        '[BA] Bing Ads Universal Event Tracking (uetq command) is not initialized',
      );
    }
  });
};

/**
 * Report a Facebook event
 *
 *
 * @param eventID - string event Facebook ID
 *
 * @returns A promise that would fail if fbq is not loaded into the browser
 *
 * @example
 * ```ts
 * await reportFacebookConversion('123')
 * ```
 */
export const reportFacebookConversion = (eventID: string) => {
  const tag = internalFacebookTag();

  return new Promise((resolve, reject) => {
    if (tag) {
      tag('track', eventID, {});
      resolve(true);
    } else {
      reject('[FA] Facebook Pixel Tracking (fbq command) is not initialized');
    }
  });
};

/**
 * Report a conversion
 *
 *
 * @param gaId - string event Google ID
 * @param bingId - string event Bing ID
 * @param fbId - string event Facebook ID
 *
 * @returns A promise that would fail if there is an issue with any conversion
 *
 * @example
 * ```ts
 * await reportConversion({gaId: '123', bingId: '123', fbId: '123'})
 * ```
 */
export const reportConversion = ({
  gaId,
  bingId,
  fbId,
}: ReporConversionProps) => {
  const conversions = [];

  if (gaId) {
    conversions.push(reportGoogleConversion(gaId));
  }

  if (bingId) {
    conversions.push(reportBingConversion(bingId));
  }

  if (fbId) {
    conversions.push(reportFacebookConversion(fbId));
  }

  return Promise.all(conversions);
};
