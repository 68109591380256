import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadPathRepository,
} from '@hc-frontend/deprecated-repositories';
import type { GetServerSidePropsContext } from 'next';
import type { NextRequest } from 'next/server';

import { buildAdapter } from '../build-adapter/build-adapter';

interface NextReadPathAdapterOptions {
  request: NextRequest;
  contextRequest: GetServerSidePropsContext['req'];
  contextResponse: GetServerSidePropsContext['res'];
}

function readOptions(
  options: Partial<NextReadPathAdapterOptions>,
): Promise<string | undefined> {
  const { request, contextRequest, contextResponse } = options;
  if (request) return Promise.resolve(request.nextUrl.pathname);
  if (contextRequest) return Promise.resolve(contextRequest.url);
  if (contextResponse) return Promise.resolve(contextResponse.req.url);

  return Promise.reject(
    new Error('An option must be provided for reading a path'),
  );
}

async function readPathAdapter(
  options: Partial<NextReadPathAdapterOptions>,
  fallback: (keyof NextReadPathAdapterOptions)[],
  index = 0,
): Promise<string | undefined> {
  if (index >= fallback.length) return Promise.resolve(undefined);

  const option = fallback[index];

  const result = await readOptions({ [option]: options[option] });

  if (result) return Promise.resolve(result);

  return readPathAdapter(options, fallback, index + 1);
}

export function nextReadPathAdapterFactory(
  provider: LogRepository,
  options: Partial<NextReadPathAdapterOptions>,
  fallback: (keyof NextReadPathAdapterOptions)[],
) {
  return buildAdapter<ReadPathRepository<ErrorCodes.PATH_NOT_READ>>()(
    provider,
    async function nextReadPathAdapter() {
      const result = await readPathAdapter(options, fallback);

      if (!result)
        throw new Error('No path found in url for request or response');

      return result;
    },
    ErrorCodes.PATH_NOT_READ,
  );
}
