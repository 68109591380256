import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

type SideBarProps = {
  open?: boolean;
  width: number;
  collapseWhenIsHide: boolean;
};

type SideBarPropsWithTheme = {
  theme: Theme;
} & SideBarProps;

export const SideBarLayoutStyled = styled('aside', {
  shouldForwardProp: (prop: string) => {
    const propsNames = ['open', 'collapseWhenIsHide'];
    return !propsNames.includes(prop);
  },
})<SideBarProps>(
  ({ theme, open, width, collapseWhenIsHide }: SideBarPropsWithTheme) => ({
    background: theme.palette.background.default,
    zIndex: 3,
    ...(open && {
      width: `${width}px`,
      position: 'static',

      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      [theme.breakpoints.down('sm')]: {
        width: '80%',
        position: 'absolute',
        height: '100%',
      },
    }),

    ...(!open && {
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(collapseWhenIsHide
        ? {
            width: 0,
            display: 'none',
          }
        : {
            width: theme.spacing(7),
            display: 'initial',
          }),

      overflowX: 'hidden',
    }),
  }),
);

export const ContentLayoutStyled = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }: { theme: Theme; open?: boolean }) => ({
  display: 'flex',
  flex: 1,
  position: 'relative',
  '&::after': {
    content: '""',
    top: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
    height: '100%',
    background: theme.palette.grey[900], // theme.grayShades[900] was undefined here
    opacity: '0.5',
    ...(!open && {
      display: 'none',
    }),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
