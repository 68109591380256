import type {
  GetConversionRepository,
  LogRepository,
} from '@hc-frontend/deprecated-repositories';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import { buildAdapter } from '../build-adapter/build-adapter';
import { getRxCardsConfig } from '../get-config-adapter/get-config-adapter';

export function nextGetCreateCardConversionAdapterFactory(
  provider: LogRepository,
) {
  return buildAdapter<
    GetConversionRepository<RxCardsErrorCodes.GET_CREATE_CARD_CONVERSION_FAILED>
  >()(
    provider,
    async function nextGetCreateCardConversionAdapter() {
      const {
        public: {
          pixels: {
            events: { signUp },
            headerIds,
          },
        },
      } = getRxCardsConfig();

      return Promise.resolve({
        bingId: signUp.bing,
        facebookId: signUp.facebook,
        googleId: `${headerIds.google}/${signUp.google}`,
      });
    },
    RxCardsErrorCodes.GET_CREATE_CARD_CONVERSION_FAILED,
  );
}
