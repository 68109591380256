import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

/**
 * Error codes for RX Cards services.
 *
 * @public
 */
export enum RxCardsServiceErrorCodes {
  '0700003' = RxCardsErrorCodes.EMAIL_OR_PHONE_REQUIRED,
  'phoneNumber' = RxCardsErrorCodes.PHONE_NUMBER_INCORRECT_FORMAT,
}
