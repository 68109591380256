import type { DeviceName } from '../../../enums/device-names';
import { getDeviceByViewportWidth } from '../get-device-by-viewport-width/get-device-by-viewport-width';
import { getDeviceFromIv } from '../get-device-from-iv/get-device-from-iv';

/**
 * Return device name from "{@link https://healthcareinc.atlassian.net/wiki/spaces/REPORTING/pages/107675730/IV+Parameter+structure|IV Parameter}"
 * or based on screen width as fallback.
 *
 * @param iv - A valid iv parameter string.
 * @returns The device value.
 *
 * @public
 */
function getDeviceNameForSession(iv: string): DeviceName {
  return getDeviceFromIv(iv) || getDeviceByViewportWidth();
}

export { getDeviceNameForSession };
