import { normalize } from '../../hash-string';
import type { AnonymizedPiiData, AnonymizedPiiDataProvider } from './types';

function normalizeIfNotNullOrEmpty(value: string | null) {
  return value && value?.length > 0 ? normalize(value) : null;
}

function toIsoDateIfNotNull(value: string) {
  if (value && value?.length > 0) {
    const dateArray = value.split('/');
    if (dateArray.length === 3) {
      return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
    }
    return null;
  }
  return null;
}

const localStorageAnonymizedPiiDataProvider: AnonymizedPiiDataProvider = {
  readPiiContextData: (): AnonymizedPiiData => {
    try {
      const hcData = JSON.parse(window.localStorage.getItem('hc') || 'null');
      const { contact } = hcData;
      const firstMember = hcData.members?.length > 0 ? hcData.members[0] : null;
      return {
        email: normalizeIfNotNullOrEmpty(contact?.email_address),
        phoneNumber: normalizeIfNotNullOrEmpty(contact?.phone_home),
        dob: toIsoDateIfNotNull(firstMember?.dob),
        gender: firstMember?.gender?.toString() || null,
        zipCode: hcData.location?.zip_code || null,
      };
    } catch (error) {
      return {
        email: null,
        phoneNumber: null,
        dob: null,
        gender: null,
        zipCode: null,
      };
    }
  },
};

export {
  localStorageAnonymizedPiiDataProvider,
  normalizeIfNotNullOrEmpty,
  toIsoDateIfNotNull,
};
