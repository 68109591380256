import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import type { ItemMenuComponentProps } from '../menu/menu.types';
import {
  HoverLayerStyled,
  ListItemButtonStyled,
} from './menu-basic-item.styles';

export function BasicMenuItem({
  item,
  onClick,
  isSelected,
  typographyVariant = 'paragraphSM',
  hoverOn = true,
}: ItemMenuComponentProps & { hoverOn?: boolean }) {
  const ItemIcon = item.icon;

  return (
    <ListItem disablePadding onClick={() => onClick && onClick(item)}>
      <ListItemButtonStyled selected={isSelected} hoverOn={hoverOn}>
        {ItemIcon ? (
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
            }}
          >
            <ItemIcon />
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={
            <Typography variant={typographyVariant}>{item.name}</Typography>
          }
        />
      </ListItemButtonStyled>
    </ListItem>
  );
}

export function BasicMenuItemWithHoverLayer({
  item,
  onClick,
  isSelected,
  typographyVariant,
}: ItemMenuComponentProps) {
  const [hover, setHoverOrSelected] = useState(false);
  return (
    <ListItemButton
      onMouseEnter={() => !isSelected && setHoverOrSelected(true)}
      onMouseLeave={() => !isSelected && setHoverOrSelected(false)}
    >
      <BasicMenuItem
        item={item}
        onClick={onClick}
        hoverOn={false}
        typographyVariant={typographyVariant}
      />
      {isSelected || hover ? (
        <HoverLayerStyled data-testid="menu-item-hover-layer" />
      ) : null}
    </ListItemButton>
  );
}
