import type {
  ABSmartlySDKConfig,
  EventLoggerCallback,
  SDK,
  SDKContextParams,
} from '@absmartly/javascript-sdk';
import absmartly, { mergeConfig } from '@absmartly/javascript-sdk';

import { removeProtocol } from '../remove-protocol';
import { runOverrides } from '../run-overrides';
import type {
  ABSmartlyApplicationConfig,
  Smartly,
  SmartlySettings,
} from '../types';

function createSDK(
  { apiKey, application, environment }: ABSmartlyApplicationConfig,
  eventLogger: EventLoggerCallback,
) {
  return new absmartly.SDK({
    endpoint: 'https://healthcare.absmartly.io/v1',
    apiKey,
    environment,
    application,
    eventLogger,
    timeout: 10_000, // timeout fix - default is 3s
  });
}

/**
 * Singleton: Only one instance of the SDK Context is created
 * @see https://github.com/absmartly/javascript-sdk#readme.
 *
 * @public
 *
 * @returns SDK Context instance
 */
export function initSmartly(settings: SmartlySettings): Smartly {
  const {
    options,
    config,
    eventLogger,
    url = window.location.href,
    existingData,
  } = settings;
  const parsedUrl = new URL(url);
  const { userId: healthcareUserId, sessionId: healthcareSession } = options;
  const { environment, application } = config;

  const sdk: SDK = createSDK(config, eventLogger);

  const request: SDKContextParams = {
    // tracking units
    units: {
      UserId: healthcareUserId,
      SessionId: healthcareSession,
    },
  };

  // here we call the server to download all data needed for all experiments
  const sdkContext = existingData
    ? sdk.createContextWith(request, existingData)
    : sdk.createContext(request);

  // useful when need the data used to initialize the SDK
  const sdkConfig: ABSmartlySDKConfig = {
    environment,
    application: application.name,
    url: removeProtocol(parsedUrl.origin + parsedUrl.pathname),
    userId: healthcareUserId,
    sessionId: healthcareSession,
  };

  // iterate over all querystring parameters,
  // and run overrides if they are present.
  // @see https://github.com/absmartly/javascript-sdk#overriding-treatment-variants
  runOverrides(sdkContext, parsedUrl.href);

  sdkContext.mergeConfig = mergeConfig.bind(sdkContext, sdkContext);

  return {
    context: sdkContext,
    config: sdkConfig,
    isReady: true,
    request,
  };
}
