import { DialogHC } from '@hc-frontend/core-ui-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import type { CallToPharmacistModalProps } from './call-to-pharmacist.types';

const DialogStyle = {
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const GoBackBtn = styled(Button)({
  width: '262px',
  height: '58px',
  alignSelf: 'center',
  marginTop: '55px',
});

const CallToPharmacistModal = ({
  phone,
  open,
  title,
  subtitle,
  buttonText,
  isInformationModal,
  backButtonText,
  handleExit,
  setOpen,
}: CallToPharmacistModalProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <DialogHC
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      withCloseButton={true}
      sx={DialogStyle}
      maxWidth={false}
    >
      {isInformationModal ? (
        <Container
          sx={{
            height: '415px',
            bgcolor: 'background.paper',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: isMobile ? '90vw' : '60vw',
            padding: '10vh 18vw !important',
          }}
        >
          <Typography
            variant="h1"
            sx={{ textAlign: 'center', fontWeight: '400' }}
          >
            {title}
          </Typography>
          <Typography
            variant="paragraphMD"
            component="div"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              textAlign: 'center',
              marginTop: '1%',
            })}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="h2"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              textAlign: 'center',
              margin: '8%',
            })}
          >
            <Link
              href={`tel:${phone.number}`}
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              {phone.cta}
            </Link>
          </Typography>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
              sx={{ width: '46%', margini: '4%' }}
            >
              <ArrowBackIcon /> {backButtonText}
            </Button>
            <Button onClick={handleExit} sx={{ width: '46%', margin: '4%' }}>
              {buttonText}
            </Button>
          </Grid>
        </Container>
      ) : (
        <Container className={'dialog-container'}>
          <Typography
            variant="h1"
            sx={{ textAlign: 'center' }}
            data-testid="callToPharmacistTitle"
          >
            {title}
          </Typography>
          <Typography
            variant="title"
            sx={(theme) => ({
              textAlign: 'center',
              color: theme.palette.primary.main,
            })}
            data-testid="callToPharmacistPhone"
          >
            {phone.number}
          </Typography>
          <GoBackBtn
            data-testid="callToPharmacistBack"
            onClick={() => {
              setOpen(false);
            }}
          >
            {buttonText}
          </GoBackBtn>
        </Container>
      )}
    </DialogHC>
  );
};

export { CallToPharmacistModal };
