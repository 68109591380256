import { ErrorCodes } from '@hc-frontend/deprecated-entities';
import type {
  LogRepository,
  ReadUrlRepository,
} from '@hc-frontend/deprecated-repositories';
import type { GetServerSidePropsContext } from 'next';

import { buildAdapter } from '../build-adapter/build-adapter';

export function nextReadUrlIncomingMessageAdapterFactory(
  provider: LogRepository,
  request: GetServerSidePropsContext['req'],
  isLocal: boolean,
) {
  return buildAdapter<ReadUrlRepository<ErrorCodes.URL_NOT_READ>>()(
    provider,
    function nextReadUrlIncomingMessageAdapter() {
      return Promise.resolve(
        `https://${
          isLocal
            ? 'local.rxcards.healthcare.com'
            : request.headers.host || 'local.rxcards.healthcare.com'
        }${request.url}`,
      );
    },
    ErrorCodes.URL_NOT_READ,
  );
}
