import { XCenteredLayout } from '@hc-frontend/core-ui-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useTranslation } from 'next-i18next';

import { CloudImage } from '../../atoms';
import type { NationWideBannerImageProps } from './nationwide-banner.types';

function NationWideBannerImage({
  xs,
  lg,
  hlg,
  assetName,
  alt,
}: NationWideBannerImageProps) {
  return (
    <Grid item xs="auto">
      <Box
        minWidth={{
          xs,
          lg,
        }}
      >
        <CloudImage
          assetName={assetName}
          height={hlg}
          width={lg}
          priority
          alt={alt}
        />
      </Box>
    </Grid>
  );
}

export function NationwideBanner() {
  const { t } = useTranslation('landing');

  return (
    <XCenteredLayout
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[50],
        textAlign: 'center',
      })}
    >
      <Typography
        variant="headingTwo"
        fontWeight="bold"
        m={6}
        color="neutral.tertiaryGray"
        component="h2"
      >
        {t('availableAtPharmaciesNationwide', {
          totalPharmacies: Number(42000).toLocaleString(),
        })}
      </Typography>
      <Grid
        container
        justifyContent={{ xs: 'center', md: 'space-evenly' }}
        spacing={{
          xs: 5,
        }}
        my={6}
      >
        <NationWideBannerImage
          assetName="walgreens.webp"
          hlg={23}
          lg={118}
          xs={91}
          alt="Walgreens is one of the participating Pharmacies for using this Rx Savings Card"
        />
        <NationWideBannerImage
          assetName="walmart.webp"
          hlg={29}
          lg={125}
          xs={97}
          alt="Walmart is one of the participating Pharmacies for using this Rx Savings Card"
        />
        <NationWideBannerImage
          assetName="rite-aid.webp"
          hlg={35}
          lg={76}
          xs={58}
          alt="Rite Aid is one of the participating Pharmacies for using this Rx Savings Card"
        />
        <NationWideBannerImage
          assetName="publix.webp"
          hlg={23}
          lg={89}
          xs={69}
          alt="Publix is one of the participating Pharmacies for using this Rx Savings Card"
        />
        <NationWideBannerImage
          assetName="cvs.webp"
          hlg={20}
          lg={160}
          xs={69}
          alt="CVS pharmacy is one of the participating Pharmacies for using this Rx Savings Card"
        />
        <NationWideBannerImage
          assetName="safeway.webp"
          hlg={18}
          lg={100}
          xs={76}
          alt="Safeway is one of the participating Pharmacies for using this Rx Savings Card"
        />
        <NationWideBannerImage
          assetName="costco.webp"
          hlg={25}
          lg={90}
          xs={68}
          alt="Costco is one of the participating Pharmacies for using this Rx Savings Card"
        />
        <NationWideBannerImage
          assetName="kroger.webp"
          hlg={48}
          lg={89}
          xs={67}
          alt="Kroger is one of the participating Pharmacies for using this Rx Savings Card"
        />
      </Grid>
    </XCenteredLayout>
  );
}
