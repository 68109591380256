import type { ReporConversionProps } from '@hc-frontend/core-third-party-pixels';
import { reportConversion } from '@hc-frontend/core-third-party-pixels';
import type {
  LogRepository,
  ReportConversionRepository,
} from '@hc-frontend/deprecated-repositories';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import { buildAdapter } from '../build-adapter/build-adapter';

export function pixelReportConversionAdapterFactory(provider: LogRepository) {
  return buildAdapter<
    ReportConversionRepository<RxCardsErrorCodes.REPORT_CONVERSION_FAILED>
  >()(
    provider,
    async function reportConversionAdapter(conversion) {
      await reportConversion({
        bingId: conversion.bingId,
        fbId: conversion.facebookId,
        gaId: conversion.googleId,
      } as ReporConversionProps);

      return Promise.resolve();
    },
    RxCardsErrorCodes.REPORT_CONVERSION_FAILED,
  );
}
