import type { StyledComponent } from '@emotion/styled';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { MUIStyledCommonProps } from '@mui/system';
import NextLink from 'next/link';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useState } from 'react';

import { useDrawer } from './drawer.context';
import type {
  DrawerHeaderParams,
  DrawerHoveredItemProps,
  DrawerProps,
} from './drawer.types';

export const DRAWER_WIDTH = 100;

function HoverOrSelectedLayer() {
  return (
    <>
      <Box
        data-testid="hover-layer"
        sx={{
          background: (theme: Theme) => `${theme.palette.warning.main}`,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: '5px',
        }}
      ></Box>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: -1,
          background: 'rgba(0, 0, 0, 0.2)',
        }}
      ></Box>
    </>
  );
}

function DrawerHoveredOrSelectedItem({
  item,
  onClick,
  children,
  isSelected,
}: DrawerHoveredItemProps) {
  const [hover, setHoverOrSelected] = useState(false);

  return (
    <NextLink href={item.url} passHref>
      <MuiLink
        sx={{
          textDecoration: 'none',
        }}
      >
        <ListItemButton
          onClick={() => onClick && onClick(item)}
          sx={{
            minHeight: ({ spacing }) => spacing(6),
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          onMouseEnter={() => !isSelected && setHoverOrSelected(true)}
          onMouseLeave={() => !isSelected && setHoverOrSelected(false)}
        >
          {children}
          {(isSelected || hover) && <HoverOrSelectedLayer />}
        </ListItemButton>
      </MuiLink>
    </NextLink>
  );
}

export const DrawerHeader: StyledComponent<
  MUIStyledCommonProps<Theme>,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = styled('div')(({ theme }: DrawerHeaderParams) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));

export function Drawer({
  menuItems,
  onClose,
  onClick,
  hideOptionText,
  header,
  children,
  isPermanent = true,
  isItemSelected = (_item: string) => false,
  translationFunction = (value) => value,
}: DrawerProps) {
  const HeaderComponent = header;

  //Hooks
  const { isMenuOpen, toggleMenu } = useDrawer();

  return (
    <MuiDrawer
      variant={isPermanent ? 'permanent' : 'persistent'}
      anchor="left"
      open={isMenuOpen}
    >
      <DrawerHeader>
        {header && (
          <ListItemButton
            onClick={() => onClose && onClose()}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0',
            }}
          >
            <ListItem
              component="div"
              sx={{
                justifyContent: 'center',
              }}
            >
              {HeaderComponent}
            </ListItem>
          </ListItemButton>
        )}
        {isPermanent === false && (
          <IconButton
            onClick={toggleMenu}
            sx={{
              position: 'absolute',
              left: `${DRAWER_WIDTH - 30}px`,
            }}
            data-testid="toggle-menu-button"
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
      </DrawerHeader>
      <List>
        {menuItems.map((item, index) => (
          <DrawerHoveredOrSelectedItem
            key={index.toString()}
            item={item}
            onClick={() => onClick && onClick(item)}
            isSelected={isItemSelected(item.url)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}
            >
              {<item.icon />}
            </ListItemIcon>

            {!hideOptionText ? (
              <ListItemText
                primary={
                  <Typography variant="paragraphSM">
                    {translationFunction(item.id)}
                  </Typography>
                }
              />
            ) : null}
          </DrawerHoveredOrSelectedItem>
        ))}
      </List>
      {children}
    </MuiDrawer>
  );
}
