import type { Nullable } from '@hc-frontend/core-utils-types';
import {
  EMAIL_REGEX,
  isEmptyString,
} from '@hc-frontend/core-utils-validations';
import {
  createBusinessErrorOperationResult,
  createBusinessSuccessOperationResult,
} from '@hc-frontend/deprecated-business';
import type { Email, IOperationResult } from '@hc-frontend/deprecated-entities';
import type { IRxCardEntity } from '@hc-frontend/shells-rx-cards-entities';
import { RxCardsErrorCodes } from '@hc-frontend/shells-rx-cards-entities';

import { buildManagedValue } from '../build-managed-value/build-managed-value';

export function buildEmail(
  email?: Nullable<Email>,
): IOperationResult<IRxCardEntity<Email>, RxCardsErrorCodes> {
  if (isEmptyString(email))
    return createBusinessErrorOperationResult(
      RxCardsErrorCodes.EMAIL_IS_REQUIRED,
    );

  const emailValue = email as Email;

  if (!EMAIL_REGEX.test(emailValue))
    return createBusinessErrorOperationResult(
      RxCardsErrorCodes.EMAIL_IS_INVALID,
    );

  if (/noemail/gi.test(emailValue))
    return createBusinessErrorOperationResult(
      RxCardsErrorCodes.EMAIL_IS_INVALID,
    );

  return createBusinessSuccessOperationResult(buildManagedValue(emailValue));
}
