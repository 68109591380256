import { fetchJSON } from '@hc-frontend/core-utils-net';
import type { IGlobalLocation } from '@hc-frontend/deprecated-entities';
import { ErrorCodes, LocationType } from '@hc-frontend/deprecated-entities';
import type {
  FetchLocationByIpRepository,
  LogRepository,
} from '@hc-frontend/deprecated-repositories';
import { always, applySpec, prop } from 'ramda';

import { buildAdapter } from '../../build-adapter/build-adapter';
import type { ILocationByIpResponse } from '../../dto';

const mapResponse = applySpec<IGlobalLocation>({
  zip: prop('postal'),
  stateAcronym: prop('region_code'),
  stateName: prop('region'),
  city: prop('city'),
  county: prop('county'),
  country: prop('country'),
  type: always(LocationType.GEO),
});

export function fetchLocationByIpAdapterFactory(provider: LogRepository) {
  return buildAdapter<
    FetchLocationByIpRepository<ErrorCodes.FETCH_LOCATION_BY_IP_FAILED>,
    ILocationByIpResponse | undefined
  >()(
    provider,
    async function fetchLocationByIpAdapter() {
      const { parsedBody } = await fetchJSON<ILocationByIpResponse>(
        'https://geoip.healthcare.com',
      );

      return parsedBody;
    },
    ErrorCodes.FETCH_LOCATION_BY_IP_FAILED,
    mapResponse,
  );
}
