import {
  alphaNumericAssert,
  CheckboxLabels,
} from '@hc-frontend/core-ui-components';
import { propAccessor } from '@hc-frontend/core-utils-object';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import { newMultiAddFieldRules } from './add-dynamic-textfield.config';
import type {
  ControlledFields,
  MultiaddLabelProp,
} from './add-dynamic-textfield.types';
type AddBtnProp = {
  theme: Theme;
};
const Button = styled('button')(({ theme }: AddBtnProp) => ({
  border: 'none',
  borderBottom: `1px solid ${theme.grayShades[600]}`,
  color: theme.grayShades[600],
  backgroundColor: 'transparent',
  paddingLeft: '0px',
  paddingBottom: '3px',
  marginBlock: '10px ',
  width: '100%',
  textAlign: 'left',
  fontSize: '14px',
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  left: '-5px',
  cursor: 'pointer',
  ':hover': {
    color: theme.grayShades[500],
    opacity: 100,
  },
  ':disabled': { color: theme.grayShades[300] },
  ':focus': { color: theme.palette.primary.main, opacity: 100 },
}));

export function AddDynamicTextField({
  labelText,
  values,
  isChecked = false,
  isAddDisabled = false,
  handleCheckBox,
  setValues,
  checkBoxLabelText = '',
  addTextButton,
  translations,
  maxCharactersValue,
  form,
}: MultiaddLabelProp) {
  const {
    register,
    control,
    trigger,
    watch,
    resetField,
    formState: { errors },
  } = form;
  const fieldArray = `${labelText}array`;
  const { append, remove } = useFieldArray({
    control,
    name: fieldArray,
  });
  const watchFieldArray = watch(fieldArray, '');
  const watchFirstField = watch(labelText, '');
  const [autofocus, setAutofocus] = useState(false);

  useEffect(() => {
    if (isChecked) {
      resetField(labelText);
      resetField(fieldArray);
    }
  }, [fieldArray, isChecked, labelText, resetField]);

  useEffect(() => {
    if (watchFieldArray !== undefined && watchFieldArray.length > 0) {
      const tempValue: string[] = [watchFirstField];
      watchFieldArray.forEach((val: ControlledFields) => {
        val && tempValue.push(val.name);
      });

      setValues(tempValue);
    }
  }, [watchFirstField, watchFieldArray, setValues]);

  const onAdd = () => {
    trigger([labelText, fieldArray], { shouldFocus: true });
    const newArray = [...values];
    append({
      name: '',
    });
    setValues(newArray);
    setAutofocus(true);
  };

  const validationRules = newMultiAddFieldRules(
    translations,
    maxCharactersValue,
    isChecked,
  );
  useEffect(() => {
    trigger([fieldArray]);
  }, [watchFirstField, fieldArray, trigger]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors[labelText]}
            data-testid="dynamicItem1"
          >
            <FormLabel
              sx={{
                marginBottom: '10px',
                fontWeight: '700',
                fontSize: '14px',
              }}
            >
              {labelText} {values.length > 1 ? 1 : ''}
            </FormLabel>
            <Controller
              name={labelText}
              rules={{
                ...alphaNumericAssert(validationRules.dynamicTextField),
                maxLength: {
                  value: maxCharactersValue,
                  message: translations.maxLimitError.errorMsg,
                },
                validate: undefined,
              }}
              control={control}
              render={({ fieldState }) => (
                <InputBase
                  data-testid="dynamic-text-field"
                  id={labelText}
                  {...register(labelText, {
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = [...values];
                      newValue[0] = e.target.value;
                      setValues(newValue);
                    },
                  })}
                  disabled={isChecked}
                  error={fieldState.invalid}
                  value={values[0] || ''}
                  fullWidth
                />
              )}
            />
            <FormHelperText data-testid={'dynamicTextField'} error>
              {errors[labelText]?.message as string}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      {values.length > 1 &&
        values.slice(1).map((val: string, index: number) => (
          <Grid key={index} container>
            <Grid item xs={12}>
              <FormControl
                error={!!propAccessor(errors, `${fieldArray}.${index}`)}
                data-testid={`dynamicItem${index + 2}`}
                fullWidth
              >
                <FormLabel
                  htmlFor={`${fieldArray}.${index}.name`}
                  sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: '700',
                    fontSize: '14px',
                  }}
                >
                  {labelText} {index + 2}
                </FormLabel>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                  }}
                >
                  <Controller
                    name={`${fieldArray}.${index}.name`}
                    control={control}
                    rules={{
                      maxLength: {
                        value: maxCharactersValue,
                        message: translations.maxLimitError.errorMsg,
                      },
                      ...alphaNumericAssert(validationRules.dynamicTextField),
                      validate: (value) => {
                        const latestValues = [
                          { name: watchFirstField },
                          ...watchFieldArray,
                        ];
                        const firstOccurrence = latestValues.findIndex(
                          (latestVal: { name: string }) =>
                            latestVal.name === value,
                        );
                        return firstOccurrence !== -1 &&
                          firstOccurrence !== index + 1
                          ? translations.duplicateError.errorMsg
                          : true;
                      },
                    }}
                    render={({ fieldState }) => {
                      return (
                        <InputBase
                          key={`${fieldArray}.${index}.name`}
                          id={`${fieldArray}.${index}.name`}
                          disabled={isChecked}
                          inputProps={{
                            'data-testid': `${fieldArray}.${index}.name`,
                          }}
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus={autofocus}
                          error={fieldState.invalid}
                          value={val}
                          {...register(`${fieldArray}.${index}.name` as const, {
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const newValue = [...values];
                              newValue[index + 1] = e.target.value;
                              setValues(newValue);
                              trigger(fieldArray, { shouldFocus: true });
                            },
                          })}
                          fullWidth
                        />
                      );
                    }}
                  />

                  <Button
                    data-testid={`delete-btn-${index}`}
                    sx={{ width: '30px', marginLeft: '3px', border: 'none' }}
                    type="button"
                    onClick={() => {
                      remove(index);
                      trigger();
                      values.splice(index + 1, 1);
                    }}
                  >
                    <CancelOutlinedIcon
                      sx={{ marginLeft: '5px', cursor: 'pointer' }}
                    />
                  </Button>
                </Box>
                <FormHelperText
                  data-testid={`testarray.${index}.error`}
                  error={!!errors[fieldArray]}
                >
                  {propAccessor(errors, `${fieldArray}.${index}.name.message`)}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        ))}
      {handleCheckBox && (
        <Grid item xs={12} py={3}>
          <CheckboxLabels
            labelText={checkBoxLabelText}
            isChecked={isChecked}
            handleChange={handleCheckBox}
          />
        </Grid>
      )}
      <Stack>
        <Button
          data-testid="add"
          type="button"
          onClick={onAdd}
          disabled={
            isChecked ||
            !!errors[labelText] ||
            !!errors[fieldArray] ||
            isAddDisabled
          }
        >
          <AddIcon />
          {addTextButton}
        </Button>
      </Stack>
    </>
  );
}
