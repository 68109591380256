import type { IDictionary } from '@hc-frontend/core-utils-types';
import type { ObjPred } from 'ramda';
import { pickBy } from 'ramda';

/**
 * Filter dictionary keys using a predicate
 * @public
 *
 * @param predicate - The function to be applied to every key in the dictionary (Root level only)
 * @param input - The dictionary to be filtered
 *
 * @returns A subset of the input object
 *
 * @example
 * ```ts
 * const config = {
 *   key: 'value',
 *   key2: 'value2',
 * }
 * const result = filterDictionary(
 * (value, key) => key === 'key',
 * config
 * );
 * console.log(result);
 * // -> { key: 'value' }
 * ```
 */
function filterDictionary<T>(
  predicate: (value: T, key: string) => boolean,
  input: IDictionary<T>,
): Partial<IDictionary<T>> {
  return pickBy(predicate as ObjPred<IDictionary<T>>, input);
}

export { filterDictionary };
