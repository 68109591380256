import type { SDKContext } from '@absmartly/javascript-sdk';
import { isEmptyString } from '@hc-frontend/core-utils-validations';

import {
  experimentRegex,
  getExperimentUrlParams,
} from '../get-experiment-url-params';

/**
 * Remove the EXP- prefix form query parameter key.
 *
 * @internal
 *
 * @param experimentKey - The query parameter to remove the prefix if it exists.
 *
 * @returns The query parameter key without the EXP- prefix
 *
 * @example
 * ```ts
 * removePrefix('EXP-123')
 * // => 'EXP-123'
 * ```
 */
export function removePrefix(experimentKey: string): string {
  return experimentKey.replace(experimentRegex, '');
}

/**
 * Retrieve or parse a given URL and extract any EXP- query parameter and force an experiment variant if necessary.
 *
 * @internal
 *
 * @param smartlySDK - The current A/B Smartly context.
 *
 * @param url - Optional. The URL to use for computing parameters overrides, otherwise the window.location object will be used.
 *
 * @example
 * ```ts
 * runOverrides(context, `${process.env.BASE_URL}/${process.env.DEV_FORCED_EXPERIMENTS}`);
 * ```
 */
export function runOverrides(smartlySDK: SDKContext, url: string): void {
  const urlParams = getExperimentUrlParams(url);

  Object.keys(urlParams).forEach((urlKey) => {
    const experimentFromUrl = urlKey.toUpperCase();
    if (experimentRegex.test(experimentFromUrl)) {
      const forceVariant = Number(urlParams[urlKey]);
      if (!isEmptyString(urlParams[urlKey]) && forceVariant >= 0) {
        // experiment name should be upper-case
        const experimentName = removePrefix(urlKey);
        // overrides are not taken into account
        // for experiments running in Production environment.
        // @see https://github.com/absmartly/javascript-sdk#overriding-treatment-variants
        smartlySDK.override(experimentName, forceVariant);
      }
    }
  });
}
