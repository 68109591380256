import { Statement } from '@hc-frontend/core-ui-components';
import { capitalize } from '@hc-frontend/core-utils-format';
import { Gender } from '@hc-frontend/deprecated-entities';
import type { IRxUser } from '@hc-frontend/shells-rx-entities';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';

type UserInfo = Partial<
  Pick<IRxUser, 'firstName' | 'lastName' | 'gender' | 'dateOfBirth'>
>;

export type PatientBasicInfoProps = {
  userInfo: UserInfo;
  infoOf: string;
};

export function PatientBasicInfo({
  userInfo,
  infoOf,
}: PatientBasicInfoProps): JSX.Element {
  const { t } = useTranslation('account-page');

  const firstName = userInfo?.firstName ?? t('name', { ns: 'form' }) ?? '';
  const lastName = userInfo?.lastName ?? '';

  const getGenderLabel = (gender?: Gender) => {
    if (gender === undefined) {
      return t('gender', { ns: 'form' });
    }

    const labels = {
      [Gender.Male]: t('male', { ns: 'form' }),
      [Gender.Female]: t('female', { ns: 'form' }),
    };

    return labels[gender];
  };

  const getDateOfBirthLabel = (dob?: Date) => {
    return dob ? format(dob, 'MM/dd/yyyy') : 'MM/DD/YYYY';
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: 4 }}>
        <Avatar
          sx={{
            backgroundColor: (theme) => theme.palette['tertiary']?.light,
            color: (theme) => theme.palette['primary'].main,
            fontWeight: 'bold',
            height: 64,
            width: 64,
          }}
        >
          {capitalize(firstName).charAt(0)}
          {capitalize(lastName).charAt(0)}
        </Avatar>
      </Box>

      <Box
        sx={{
          maxWidth: '90%',
          pr: 4,
        }}
      >
        <Statement
          title={{
            text: `${capitalize(firstName)} ${capitalize(lastName)}`,
            props: {
              variant: 'headingTwo',
              align: 'left',
              fontWeight: 'bold',
              color: (theme: Theme) => theme.palette['primary'].dark,
            },
          }}
          body={{
            text: `${infoOf} • ${getGenderLabel(
              userInfo?.gender,
            )} • ${getDateOfBirthLabel(userInfo?.dateOfBirth)}`,
            props: {
              variant: 'paragraphMD',
              align: 'left',
              noWrap: true,
              fontWeight: 'bold',
              flexWrap: 'wrap',
            },
          }}
        />
      </Box>
    </Box>
  );
}
