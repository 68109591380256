import type { IDictionary } from '@hc-frontend/core-utils-types';

import { DeviceName } from '../../../enums/device-names';
import { getValueFromIv } from '../get-value-from-iv/get-value-from-iv';

/**
 * Get the device from "{@link https://healthcareinc.atlassian.net/wiki/spaces/REPORTING/pages/107675730/IV+Parameter+structure|IV Parameter}".
 *
 * @param iv - A valid iv parameter string.
 * @returns The device value.
 *
 * @public
 */
function getDeviceFromIv(iv: string): DeviceName | '' {
  const ivDevice = getValueFromIv(iv, 'd').toLowerCase();
  const deviceMap: IDictionary<DeviceName> = {
    m: DeviceName.Mobile,
    p: DeviceName.Mobile,
    t: DeviceName.Tablet,
    c: DeviceName.Desktop,
    tablet: DeviceName.Tablet,
    mobile: DeviceName.Mobile,
    computer: DeviceName.Desktop,
    desktop: DeviceName.Desktop,
  };

  return deviceMap[ivDevice] || '';
}

export { getDeviceFromIv };
