import Box from '@mui/material/Box';

import {
  ContentLayoutStyled,
  SideBarLayoutStyled,
} from './admin-layout.styles';
import type { AdminTemplateProps, SideBarSettings } from './admin-layout.types';

const defaultSideBarSettings: SideBarSettings = {
  width: 220,
  collapseWhenIsHide: false,
};

export function AdminLayout({
  children,
  header,
  sideBarContent,
  openSideBar,
  sideBarSettings = defaultSideBarSettings,
}: AdminTemplateProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        component={'header'}
        sx={{
          width: '100%',
        }}
      >
        {header}
      </Box>

      <ContentLayoutStyled open={openSideBar}>
        <SideBarLayoutStyled
          width={sideBarSettings.width}
          open={openSideBar}
          collapseWhenIsHide={sideBarSettings.collapseWhenIsHide}
        >
          {sideBarContent}
        </SideBarLayoutStyled>

        <Box
          component={'main'}
          sx={{
            flex: 1,
          }}
        >
          {children}
        </Box>
      </ContentLayoutStyled>
    </Box>
  );
}
